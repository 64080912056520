import { MedicalNecessityDsm5Criteria } from "@utils/constants";
import { addYears, differenceInMonths, differenceInYears, format, isValid, parse } from "date-fns";
import { GraphQLError } from "graphql";

import { ApiLearnerAvailabilityTimeOfDayChoices } from "@api/graphql/types-and-hooks";
import { useAdminData } from "@providers/AdminDataProvider";

export const capitalizeString = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const getTimeByShift = (shiftChoice: ApiLearnerAvailabilityTimeOfDayChoices): string => {
  switch (shiftChoice) {
    case "MORNING":
      return "8:00am - 12:00pm";
    case "AFTERNOON":
      return "12:00pm - 3:00pm";
    case "MIDDAY":
      return "3:00pm - 5:30pm";
    case "EVENING":
      return "5:30pm - 7:30pm";
    default:
      return "";
  }
};

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export const convertHeadersToObject = (headers: HeadersInit): Record<string, string> => {
  const result: Record<string, string> = {};

  if (Array.isArray(headers)) {
    headers.forEach(([key, value]) => {
      result[key] = value;
    });
  } else if (headers instanceof Headers) {
    headers.forEach((value, key) => {
      result[key] = value;
    });
  } else {
    Object.assign(result, headers);
  }

  return result;
};

export const getDifferenceInYearsAndMonths = (startDate: Date, endDate: Date): string => {
  const years = differenceInYears(endDate, startDate);
  const adjustedStartDate = addYears(startDate, years);
  const months = differenceInMonths(endDate, adjustedStartDate);

  return `${years} years ${months ? `${months} months` : ""}`;
};

export const formatDateTime = (datetime: Date): string => {
  try {
    let formattedDate = format(datetime, "MM/dd/yyyy h:mm a");
    formattedDate = formattedDate.replace("AM", "A.M.").replace("PM", "P.M.");
    return formattedDate;
  } catch (error) {
    return "-";
  }
};

export const formatDate = (datetime: Date | string): string => {
  try {
    let dateObj: Date | null = null;

    if (typeof datetime === "string") {
      // Try different common date formats
      const formats = [
        "yyyy-MM-dd", // 2023-01-31
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", // ISO format
        "yyyy-MM-dd'T'HH:mm:ss", // ISO without timezone
        "MM/dd/yyyy", // 01/31/2023
        "dd/MM/yyyy", // 31/01/2023
        "MM-dd-yyyy", // 01-31-2023
      ];

      // Try each format until one works
      let parsed: Date | null = null;
      for (const fmt of formats) {
        parsed = parse(datetime, fmt, new Date());
        if (isValid(parsed)) {
          dateObj = parsed;
          break;
        }
      }

      // If none of the formats worked, try the built-in Date constructor
      if (!parsed || !isValid(parsed)) {
        const builtInParsed = new Date(datetime);
        if (isValid(builtInParsed)) {
          dateObj = builtInParsed;
        } else {
          throw new Error("Invalid date format");
        }
      }

      if (!dateObj) {
        throw new Error("Failed to parse date");
      }
    } else if (datetime instanceof Date) {
      dateObj = datetime;
    } else {
      throw new Error("Input must be a Date object or date string");
    }

    return format(dateObj, "MM/dd/yyyy");
  } catch (error) {
    return "-";
  }
};

export const formatTime_hh_mm = (datetime: Date): string => {
  try {
    let formattedTime = format(datetime, "hh:mm a"); // 12-hour format with AM/PM
    formattedTime = formattedTime.replace("AM", "A.M.").replace("PM", "P.M.");
    return formattedTime;
  } catch (error) {
    return "-";
  }
};

export const formatDateYYYY_MM_DD = (datetime: Date): string => {
  try {
    const formattedDate = format(datetime, "yyyy-MM-dd");

    return formattedDate;
  } catch (error) {
    return "-";
  }
};

export const formatDurationToHrMin = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours}hr ${minutes}min`;
};

export const formatDurationToMinSec = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes}m ${remainingSeconds}s`;
};

const padToTwoDigits = (num: number): string => num.toString().padStart(2, "0");

export const formatSecondsToHHMMSS = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}:${padToTwoDigits(remainingSeconds)}`;
};

export const formatVideoSecondsToHHMMSS = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  if (hours === 0) return `${minutes}:${padToTwoDigits(remainingSeconds)}`;
  return `${hours}:${minutes}:${padToTwoDigits(remainingSeconds)}`;
};

export const isMissingValue = (value: string | undefined | null): string => {
  return value ?? "-";
};

export const isAuthorized = (list1: string[]): boolean | null => {
  const { roles } = useAdminData();
  if (roles.length) {
    const set1 = new Set(roles);
    for (const item of list1) {
      if (set1.has(item)) {
        return true;
      }
    }
    return false;
  }
  return false;
};

export type HeroIconType = React.ForwardRefExoticComponent<
  Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
    title?: string | undefined;
    titleId?: string | undefined;
  } & React.RefAttributes<SVGSVGElement>
>;

export const getEnvFromHostname = (hostname: string): "dev" | "prod" | "local" | "staging" | "unknown-env" => {
  switch (hostname) {
    case "portal.dev.fronterahealth.com":
      return "dev";
    case "portal.staging.fronterahealth.com":
      return "staging";
    case "portal.fronterahealth.com":
      return "prod";
    case "localhost":
      return "local";
    default:
      return "unknown-env";
  }
};

export const getTracingUrlFromHostname = (
  hostname: string,
):
  | "https://dev.us-west-2.foundational.frontera.health/graphql/"
  | "https://prod.us-west-2.foundational.frontera.health/graphql/"
  | "https://staging.us-west-2.foundational.frontera.health/graphql/"
  | undefined => {
  switch (hostname) {
    case "portal.dev.fronterahealth.com":
      return "https://dev.us-west-2.foundational.frontera.health/graphql/";
    case "portal.staging.fronterahealth.com":
      return "https://staging.us-west-2.foundational.frontera.health/graphql/";
    case "portal.fronterahealth.com":
      return "https://prod.us-west-2.foundational.frontera.health/graphql/";
    case "localhost":
      return undefined;
    default:
      return undefined;
  }
};

const getCookieDomainFromHostname = (
  hostname: string,
):
  | ".dev.fronterahealth.com"
  | ".staging.fronterahealth.com"
  | ".fronterahealth.com"
  | ".local.fronterahealth.com"
  | ".unknown-cookie.fronterahealth.com" => {
  switch (hostname) {
    case "portal.dev.fronterahealth.com":
      return ".dev.fronterahealth.com";
    case "portal.staging.fronterahealth.com":
      return ".staging.fronterahealth.com";
    case "portal.fronterahealth.com":
      return ".fronterahealth.com";
    case "localhost":
      return ".local.fronterahealth.com";
    default:
      console.error("Unexpected hostname, this should not happen");
      return ".unknown-cookie.fronterahealth.com";
  }
};

export const APP_ENV = getEnvFromHostname(window.location.hostname);
export const tracingUrl = getTracingUrlFromHostname(window.location.hostname);
export const cookieDomain = getCookieDomainFromHostname(window.location.hostname);

// Helper type
export type RequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;

export const graphqlErrorHandler = (error: GraphQLError) => {
  const errorMessage = error.message;
  const jsonStartIndex = errorMessage.indexOf("{");
  const jsonString = errorMessage.substring(jsonStartIndex);
  const errorData = JSON.parse(jsonString);

  interface GraphQLError {
    message: string;
  }

  const errorMessages = errorData.response.errors.map((err: GraphQLError) => err.message);

  return errorMessages;
};

export const scrollToDivById = (divId: string) => {
  if (divId) {
    const divElement = document.getElementById(divId);
    if (divElement) {
      divElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }
};

export const indexToAlphabet = (index: number): string => {
  if (index < 0) throw new Error("Index must be a non-negative number.");

  let result = "";
  while (index >= 0) {
    const charCode = (index % 26) + 97; // 97 is the ASCII code for 'a'
    result = String.fromCharCode(charCode) + result;
    index = Math.floor(index / 26) - 1;
  }
  return result;
};

export const getHierarchicalIdentifier = (level1Index: number, level2Index?: number, level3Index?: number): string => {
  let identifier = `${level1Index + 1}`;
  if (level2Index !== undefined) {
    identifier += indexToAlphabet(level2Index);
  }
  if (level3Index !== undefined) {
    identifier += `${level3Index + 1}`;
  }
  return `${identifier}. `;
};

export const parseHyphenatedText = (input: string): string => {
  return input
    .replace(/-/g, " ") // Replace hyphens with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

export const convertChecklistToString = (
  selectedItems: Record<string, boolean>,
  criteria: MedicalNecessityDsm5Criteria[],
): string => {
  const sectionGroups: string[] = [];

  criteria.forEach((section) => {
    const selectedInSection: string[] = [];

    section.checklistItems.forEach((item) => {
      if (selectedItems[item.id]) {
        selectedInSection.push(item.title.toLowerCase());
      }
    });

    if (selectedInSection.length > 0) {
      sectionGroups.push(`${section.name} ${selectedInSection.join(", ")}`);
    }
  });

  return sectionGroups.join("; ");
};

export const convertStringToChecklist = (
  criteriaString: string | null | undefined,
  criteria: MedicalNecessityDsm5Criteria[],
): Record<string, boolean> => {
  const selectedItems: Record<string, boolean> = {};

  // Initialize all items to false
  criteria.forEach((section) => {
    section.checklistItems.forEach((item) => {
      selectedItems[item.id] = false;
    });
  });

  if (!criteriaString) return selectedItems;

  // Split by sections (separated by semicolons)
  const sections = criteriaString.split(";").map((s) => s.trim());

  sections.forEach((sectionText) => {
    criteria.forEach((section) => {
      // Check each item in the criteria against the full section text
      section.checklistItems.forEach((item) => {
        if (sectionText.toLowerCase().includes(item.title.toLowerCase())) {
          selectedItems[item.id] = true;
        }
      });
    });
  });

  return selectedItems;
};

export const parseMarkdown = (text: string) => {
  return text
    .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>") // Bold: **text**
    .replace(/\*(.*?)\*/g, "<i>$1</i>") // Italic: *text*
    .replace(/__(.*?)__/g, "<b>$1</b>") // Bold: __text__
    .replace(/_(.*?)_/g, "<i>$1</i>") // Italic: _text_
    .replace(/# (.*?)\n/g, "<h1>$1</h1>") // Header 1: # text
    .replace(/## (.*?)\n/g, "<h2>$1</h2>") // Header 2: ## text
    .replace(/### (.*?)\n/g, "<h3>$1</h3>") // Header 3: ### text
    .replace(/\n/g, "<br>"); // Newlines to <br>
};

export const truncateText = (text: string, wordCount: number = 50) => {
  const words = text.split(" ");
  if (words.length > wordCount) {
    return `${words.slice(0, wordCount).join(" ")}...`;
  }
  return text;
};

export const formatDuration = (startTime: number, endTime: number): string => {
  const duration = endTime - startTime;

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  if (hours > 0) {
    return `${hours} hr ${minutes} min ${seconds} sec`;
  } else if (minutes > 0) {
    return `${minutes} min ${seconds} sec`;
  } else {
    return `${seconds} sec`;
  }
};

export const emptyFunction = () => Promise.resolve({});

export const downloadFileBlob = (file?: string) => {
  if (!file) return;
  const byteCharacters = atob(file);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create blob and download
  const blob = new Blob([byteArray], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "report.docx";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
