import {
  ArrowDownIcon,
  ArrowUpIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon,
  QueueListIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useMemo } from "react";

import {
  ApiOrganizationAbExportTemplateNameChoices,
  ApiReportSectionFieldsFieldTypeChoices,
  ReportSectionFieldsType,
  ReportSectionsType,
  useDeleteReportSectionFieldMutation,
  useMoveReportSectionFieldToAnotherSectionMutation,
  useMoveReportSectionFieldUpAndDownMutation,
  useUpdateReportSectionFieldMutation,
} from "@api/graphql/types-and-hooks";
import { FieldBlockCard } from "@components/FieldComponent/FieldBlockCard";
import { notifyError } from "@components/notifications/notifications";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/AssessmentReportV2Details/Components/helper";
import {
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/AssessmentReportV2Details/Components/hooks";
import { useAdminData } from "@providers/AdminDataProvider";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

interface FieldBlockCardWrapperProps {
  section: ReportSectionsType;
  field: ReportSectionFieldsType;
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
}

export const FieldBlockCardWrapper: React.FC<FieldBlockCardWrapperProps> = ({ field, section, permissions }) => {
  const { assessmentQuery } = useAssessmentBuilderData();
  const deleteField = useDeleteReportSectionFieldMutation({});
  const moveReportSectionFieldUpAndDown = useMoveReportSectionFieldUpAndDownMutation({});
  const moveReportSectionFieldToAnotherSection = useMoveReportSectionFieldToAnotherSectionMutation({});
  const { orgnizationDetailsQuery } = useAdminData();

  const isCustomTemplateUser =
    orgnizationDetailsQuery.data?.getUserOrgnizationDetails?.abExportTemplateName !=
      ApiOrganizationAbExportTemplateNameChoices.AssessmentReportDocxTemplateDocx || false;

  const updateContent = useUpdateReportSectionFieldMutation({});
  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({});
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const { isPending } = updateContent;

  const handleOnSave = async (content: string) => {
    await updateContent
      .mutateAsync({
        reportSectionId: section.id ?? "<missing-section-id>",
        reportSectionFieldId: field?.id ?? "<missing-section-field-id>",
        content: content,
      })
      .then((data) => {
        if (
          data.updateReportSectionField?.status &&
          field.fieldType !== ApiReportSectionFieldsFieldTypeChoices.Wysiwyg
        ) {
          assessmentQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while updating field content : ", error);
        notifyError("Error Updating Field Content");
      });
  };

  const onDeleteField = async () => {
    await deleteField
      .mutateAsync({
        sectionId: section.id ?? "<missing-section-id>",
        fieldId: field?.id ?? "<missing-field-id>",
      })
      .then((data) => {
        if (data.deleteReportSectionField?.status) {
          assessmentQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while deleting field : ", error);
        notifyError("Error Deleting Field");
      });
  };

  const handleMoveSectionFieldUpAndDown = async (moveUp: boolean) => {
    // moveUp = True to move up and False to move down
    await moveReportSectionFieldUpAndDown
      .mutateAsync({
        reportSectionId: section.id ?? "<missing-section-id>",
        fieldId: field?.id ?? "<missing-field-id>",
        moveUp: moveUp,
      })
      .then((data) => {
        if (data?.moveReportSectionFieldUpAndDown?.status) {
          assessmentQuery?.refetch();
        } else if (data.moveReportSectionFieldUpAndDown?.status === false) {
          notifyError(data.moveReportSectionFieldUpAndDown?.message ?? "Error moving field in the specified direction");
        }
      })
      .catch((error) => {
        console.error("Error moving field : ", error);
        notifyError("Error Moving Field");
      });
  };

  const handleMoveReportSectionFieldToAnotherSection = async (targetSection: ReportSectionsType) => {
    await moveReportSectionFieldToAnotherSection
      .mutateAsync({
        fieldId: field?.id ?? "<missing-field-id>",
        targetSectionId: targetSection?.id ?? "<missing-target-section-id>",
      })
      .then((data) => {
        if (data?.moveReportSectionFieldToAnotherSection?.status) {
          assessmentQuery?.refetch();
        } else if (data.moveReportSectionFieldToAnotherSection?.status === false) {
          notifyError(data.moveReportSectionFieldToAnotherSection?.message ?? "Error moving field to target section");
        }
      })
      .catch((error) => {
        console.error("Error moving field : ", error);
      });
  };

  const getPageSections = useGetFilteredPageSectionsByLocation(section.pageLocation as FilterSectionSubRoute);
  const getMoveableSectionsList = useMemo(() => {
    return getPageSections
      .filter((item) => item.id !== section.id)
      .map((item) => ({
        label: item.title,
        Icon: DocumentTextIcon,
        callback: () => handleMoveReportSectionFieldToAnotherSection(item),
      }));
  }, [getPageSections, section.id]);

  const actionButtonMenu = !isCustomTemplateUser
    ? [
        {
          label: "Move Up",
          Icon: ArrowUpIcon,
          callback: () => handleMoveSectionFieldUpAndDown(true),
        },
        {
          label: "Move Down",
          Icon: ArrowDownIcon,
          callback: () => handleMoveSectionFieldUpAndDown(false),
        },
        {
          label: "Move to Section",
          Icon: QueueListIcon,
          submenu: getMoveableSectionsList.length
            ? getMoveableSectionsList
            : [{ label: "No Section Found", Icon: ExclamationTriangleIcon }],
        },
        {
          label: "Remove Field",
          Icon: TrashIcon,
          textColorClassName: "text-red-600",
          callback: onDeleteField,
        },
      ]
    : undefined;

  return (
    <FieldBlockCard
      permissions={permissions}
      field={field}
      isSaving={isPending}
      onSave={handleOnSave}
      S3BucketUrlRetriever={(params) =>
        S3BucketUrlRetriever({
          ...params,
          fieldId: field.id,
        })
      }
      S3BucketUploadCallback={(params) =>
        S3BucketUploadCallback({
          ...params,
          fieldId: field.id,
        })
      }
      reportFileUploadCompleteCallback={() => Promise.resolve()}
      fileParseStatusRetriever={(fileId) =>
        new Promise((resolve) => {
          setTimeout(() => resolve({ fileId, status: "done" }), 1000);
        })
      }
      removeFileCallback={() => Promise.resolve()}
      actionButtonMenu={actionButtonMenu}
    />
  );
};
