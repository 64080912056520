import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";

import { useGetMediaCommentRepliesQuery } from "@api/graphql/types-and-hooks";
import { CommentCard } from "@pages/VideoSessions/components/Comments/CommentCard";
import CommentForm from "@pages/VideoSessions/components/Comments/CommentForm";
import { transformRepliesData } from "@pages/VideoSessions/components/Comments/helper";
import { DataLoading } from "@pages/VideoSessions/components/Utils/DataLoading";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface CommentThreadProps {
  onClick: (time: number) => void;
  activeCommentId?: string | null;
  onActiveCommentChange?: (commentId: string | null) => void;
}

export const CommentThread: React.FC<CommentThreadProps> = ({ onClick, activeCommentId, onActiveCommentChange }) => {
  const [expandedCommentId, setExpandedCommentId] = useState<string | null>(null);
  const [localActiveCommentId, setLocalActiveCommentId] = useState<string | null>(null);
  const [activeReplyFormId, setActiveReplyFormId] = useState<string | null>(null);
  const commentRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement | null>(null);
  const [hoveredThreadId, setHoveredThreadId] = useState<string | null>(null);
  const effectiveActiveCommentId = activeCommentId || localActiveCommentId;

  const {
    commentsByMediaIdQuery: { refetch: refetchComments },
    allFilteredComments,
  } = useVideoSessionData();

  const {
    data: commentReplies,
    isFetching,
    refetch: refetchCommentReplies,
  } = useGetMediaCommentRepliesQuery(
    {
      parentCommentId: expandedCommentId || "",
    },
    {
      queryKey: ["getMediaCommentReplies"],
      enabled: expandedCommentId !== null && expandedCommentId !== undefined,
    },
  );

  const handleCommentClick = (commentId: string, time?: number) => {
    const newActiveId = commentId === effectiveActiveCommentId ? null : commentId;
    setLocalActiveCommentId(newActiveId);
    if (onActiveCommentChange) {
      onActiveCommentChange(newActiveId);
    }
    if (time !== undefined) {
      onClick(time);
    }
  };

  // Handle click outside the comment thread
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (commentRef.current && !commentRef.current.contains(event.target as Node)) {
        setLocalActiveCommentId(null);
        if (onActiveCommentChange) {
          onActiveCommentChange(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onActiveCommentChange]);

  // Handle click outside for reply form
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        setActiveReplyFormId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Scroll to active comment when it changes
  useEffect(() => {
    if (activeCommentId && commentRef.current) {
      const activeElement = commentRef.current.querySelector(`[data-comment-id="${activeCommentId}"]`);
      if (activeElement) {
        activeElement.scrollIntoView({ behavior: "smooth", block: "center" });

        // If the comment is not expanded and has a parent, expand it
        const parentId = (activeElement as HTMLElement).dataset.parentId;
        if (parentId) {
          setExpandedCommentId(parentId);
        }
      }
    }
  }, [activeCommentId]);

  const handleEditSubmit = () => {
    refetchComments();
    if (expandedCommentId) {
      refetchCommentReplies();
    }
  };

  return (
    <div ref={commentRef} className="flex flex-col space-y-4">
      {allFilteredComments.map((comment) => {
        const isExpanded = expandedCommentId === comment.id;
        const isActive = effectiveActiveCommentId === comment.id;

        return (
          <div
            key={comment.id}
            data-comment-id={comment.id}
            onClick={() => handleCommentClick(comment.id)}
            className={`relative flex flex-col items-start p-4 pb-6 bg-white border border-limestone-200 rounded-2xl gap-6 ${
              isActive ? "border-limestone-900" : "hover:border-limestone-300"
            }`}
            onMouseEnter={() => setHoveredThreadId(comment.id)}
            onMouseLeave={() => setHoveredThreadId(null)}
          >
            <CommentCard
              {...comment}
              handleEditSubmit={handleEditSubmit}
              onTimeClick={(time) => handleCommentClick(comment.id, time)}
              hoveredThreadId={hoveredThreadId}
            />

            {/* Replies section */}
            {comment.totalReplies > 0 && (
              <>
                {isExpanded && isFetching ? (
                  <div className="w-full">
                    <DataLoading text="Loading Replies..." />
                  </div>
                ) : isExpanded && commentReplies ? (
                  transformRepliesData(commentReplies).map((reply) => (
                    <div
                      key={reply?.id}
                      data-comment-id={reply.id}
                      data-parent-id={comment.id}
                      onClick={() => handleCommentClick(reply.id)}
                      className={`w-full ${effectiveActiveCommentId === reply.id ? "ring-2 ring-inset ring-hatch-600" : ""}`}
                    >
                      <CommentCard
                        {...reply}
                        onTimeClick={(time) => handleCommentClick(reply.id, time)}
                        handleEditSubmit={handleEditSubmit}
                        hoveredThreadId={hoveredThreadId}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    {comment.totalReplies > 1 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setExpandedCommentId(comment.id);
                          setLocalActiveCommentId(comment.id);
                          if (onActiveCommentChange) {
                            onActiveCommentChange(comment.id);
                          }
                        }}
                        className="text-interaction-primary text-sm font-medium mx-10"
                      >
                        {`Show ${comment.totalReplies - 1} additional ${
                          comment.totalReplies - 1 === 1 ? "comment" : "comments"
                        }`}
                      </button>
                    )}
                    {comment.lastReply?.length && (
                      <div className="w-full" data-comment-id={comment.lastReply[0].id} data-parent-id={comment.id}>
                        <CommentCard
                          {...comment.lastReply[0]}
                          onTimeClick={(time) => onClick(time)}
                          handleEditSubmit={handleEditSubmit}
                          hoveredThreadId={hoveredThreadId}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {/* Reply form section */}
            <div
              ref={activeReplyFormId === comment.id ? formRef : null}
              className="flex flex-row flex-start relative w-full"
            >
              {activeReplyFormId === comment.id ? (
                <CommentForm
                  parentId={`${comment.id}`}
                  commentType={comment.type}
                  mediaStartTime={comment.time}
                  handleCommentSubmit={() => {
                    setActiveReplyFormId(null);
                    handleEditSubmit();
                  }}
                />
              ) : (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveReplyFormId(comment.id);
                  }}
                  className="text-interaction-primary text-sm font-medium mx-10"
                >
                  Reply
                </button>
              )}
            </div>

            {/* Collapse button */}
            {isExpanded && (
              <div className="flex flex-row justify-end w-full">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpandedCommentId(null);
                    setLocalActiveCommentId(comment.id);
                    if (onActiveCommentChange) {
                      onActiveCommentChange(comment.id);
                    }
                  }}
                  className="flex flex-row text-interaction-primary text-sm font-medium mx-6"
                >
                  Collapse comments <ChevronUpDownIcon className="h-5 w-5 text-interaction-primary" />
                </button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
