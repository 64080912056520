import React, { useEffect, useState } from "react";

import { Dialog, MultiSelect, MultiSelectItem, Paragraph } from "@fronterahealth/frontera-ui-components";

import { useUpdateLearnerProvidersMutation } from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { AssignableLearnerProvidersType } from "@pages/ClientDetailsSubPages/ClientProviders/ClientProviders";
import { useClientData } from "@providers/ClientProvider";

interface AddLearnerProvidersProps {
  setDialogOpen: (open: boolean) => void;
  isDialogOpen: boolean;
  onAddProvider?: () => void;
  selectedProviders?: AssignableLearnerProvidersType[] | null;
  clientId?: string;
}

export const AddLearnerProviders: React.FC<AddLearnerProvidersProps> = ({
  isDialogOpen,
  setDialogOpen,
  selectedProviders,
  clientId,
  onAddProvider,
}) => {
  const [selectedProvidersToAdd, setSelectedProvidersToAdd] = useState<MultiSelectItem[]>([]);
  const { assignableLearnerProviders, isProvidersLoading } = useClientData();
  const updateLearnerProviderMutation = useUpdateLearnerProvidersMutation({
    onSuccess: (data) => {
      if (data.updateLearnerProviders?.status) {
        notifySuccess(data?.updateLearnerProviders?.message || "");
        if (onAddProvider) onAddProvider();
      } else {
        notifyError("Failed to add providers!");
      }
    },
  });

  useEffect(() => {
    if (selectedProviders?.length) {
      setSelectedProvidersToAdd(
        selectedProviders.map((item) => ({
          id: item.id,
          primary: item.name,
          secondary: item.email,
        })),
      );
    }
  }, [selectedProviders]);

  const onAddProviders = async () => {
    if (!selectedProvidersToAdd) return;
    const newProviderIdSet = selectedProvidersToAdd.map((item) => item.id);

    await updateLearnerProviderMutation.mutateAsync({
      providerIds: newProviderIdSet as string[],
      learnerId: clientId ? clientId : "",
    });
    setDialogOpen(false);
  };

  return (
    <Dialog
      title="Add Provider Mapping to Client"
      open={isDialogOpen}
      setOpen={setDialogOpen}
      primaryButton={{
        text: "Add",
        onClick: onAddProviders,
      }}
      secondaryButton={{ text: "Cancel", onClick: () => setDialogOpen(false) }}
      size="xl"
    >
      <div className="flex flex-col gap-6">
        <Paragraph>
          Select an existing provider to map to this client’s profile. If you don’t see the provider you’re looking for,
          they may not have a profile yet. You’ll need to create a new provider profile to proceed.
        </Paragraph>
        <MultiSelect
          title={"Current providers"}
          items={assignableLearnerProviders.sort((a, b) => (a.primary > b.primary ? 1 : -1))}
          placeholderText={"current providers"}
          mode={"tags"}
          isLoading={isProvidersLoading}
          selected={selectedProvidersToAdd}
          setSelected={setSelectedProvidersToAdd}
        />
      </div>
    </Dialog>
  );
};
