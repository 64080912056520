import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { useEffect } from "react";

import { SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  AiSuggestedShortTermGoalsType,
  CreateGoalBankShortTermGoalsInput,
  ShortTermGoalBankInput,
  ShortTermGoalType,
  ShortTermTimelineEstimationEnums,
  useCreateGoalBankShortTermGoalsMutation,
  useGetUserOrgnizationDetailsQuery,
  useGoalCategoriesQuery,
  useGoalCategoryConfigQuery,
} from "@api/graphql/types-and-hooks";
import { FolderManagementAction } from "@components/GoalsBank/constants";
import { FolderManagementDialog } from "@components/GoalsBank/dialogs/FolderManagementDialog";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { useGoalBankFolders } from "@components/GoalsBank/hooks/useGoalBankFolders";
import {
  findFolderSelection,
  getFolderSelections,
  getOrgFolderSection,
  getUserFolderSection,
} from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

interface GoalBankShortTermGoalsSaveToFolderProps {
  onCloseClick: () => void;
  open: boolean;
  refetch: () => void;
  aiShortTermGoals: AiSuggestedShortTermGoalsType[];
  shortTermGoals: ShortTermGoalType[];
}

export const GoalBankShortTermGoalsSaveToFolder: React.FC<GoalBankShortTermGoalsSaveToFolderProps> = ({
  onCloseClick,
  open,
  refetch,
  aiShortTermGoals,
  shortTermGoals,
}) => {
  const { user } = useAuth0();
  const orgName = `${user![config.auth0Audience + "/orgName"] ?? ""}`;
  const createShortTermGoal = useCreateGoalBankShortTermGoalsMutation({});
  const { userFolders, orgFolders } = useGoalBankFolders();
  const userFoldersSection = getUserFolderSection(userFolders);
  const orgFoldersSection = getOrgFolderSection(orgFolders, orgName);
  const goalCategoryConfig = useGoalCategoryConfigQuery({ organizationId: user?.org_id }, { enabled: !!user?.org_id });
  const orgnizationDetailsQuery = useGetUserOrgnizationDetailsQuery(
    {},
    {
      queryKey: ["get-account-details"],
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: "always",
      staleTime: Infinity,
    },
  );
  const { data: organizationDetailsData } = orgnizationDetailsQuery;
  const goalCategoryName = organizationDetailsData?.getUserOrgnizationDetails?.useLongTermGoals
    ? "Long Term Goal"
    : goalCategoryConfig.data?.goalCategoryConfig?.edges.at(0)?.node?.categoryConfigType ?? "";

  const getShortTermGoalBankInput = (
    params: CreateGoalBankShortTermGoalsInput,
    goal: AiSuggestedShortTermGoalsType | ShortTermGoalType,
  ) => {
    return {
      longTermGoalBankId: params.shorttermGoalsData?.at(0)?.longTermGoalBankId,
      description: goal.description,
      goalName: goal.goalName,
      masteryCriteria: goal.masteryCriteria,
      programGoal: goal.programGoal,
      timelineEstimationType: goal.timelineEstimationType
        ? (convertDBString(goal.timelineEstimationType) as ShortTermTimelineEstimationEnums)
        : null,
      timelineEstimationValue: goal.timelineEstimationValue,
    } as ShortTermGoalBankInput;
  };

  const createAiShortTermGoalMutation = (
    params: CreateGoalBankShortTermGoalsInput,
    aiShortTermGoal: AiSuggestedShortTermGoalsType,
  ) => {
    createShortTermGoal.mutateAsync(
      {
        input: {
          shorttermGoalsData: [
            {
              ...getShortTermGoalBankInput(params, aiShortTermGoal),
              aiSuggestedShortGoal: aiShortTermGoal.id,
            } as ShortTermGoalBankInput,
          ],
          folderId: params.folderId,
        },
      },
      {
        onSuccess: () => {
          onCloseClick();
          notifySuccess("Saved To Folder");
          refetch();
          reset();
        },
      },
    );
  };

  const createShortTermGoalMutation = (params: CreateGoalBankShortTermGoalsInput, shortTermGoal: ShortTermGoalType) => {
    createShortTermGoal.mutateAsync(
      {
        input: {
          shorttermGoalsData: [
            {
              ...getShortTermGoalBankInput(params, shortTermGoal),
              aiSuggestedShortGoal: shortTermGoal.aiGenerated ? shortTermGoal.aiSuggestedShortGoal?.id : null,
            },
          ],
          folderId: params.folderId,
        },
      },
      {
        onSuccess: () => {
          onCloseClick();
          notifySuccess("Saved To Folder");
          refetch();
          reset();
        },
      },
    );
  };

  const { formState, RegisteredFormSelected, reset, onSubmit, watch } = useFormUtils<CreateGoalBankShortTermGoalsInput>(
    {
      mutationFn: async (params) => {
        if (!params.folderId || !params.shorttermGoalsData?.[0]?.longTermGoalBankId) {
          console.error("Missing required fields");
          return;
        }
        aiShortTermGoals.forEach(
          async (aiShortTermGoal) => await createAiShortTermGoalMutation(params, aiShortTermGoal),
        );
        shortTermGoals.forEach(async (shortTermGoal) => await createShortTermGoalMutation(params, shortTermGoal));
      },
    },
  );
  const folderIdWatch = watch("folderId");
  const { longTermGoals } = useGetLongTermGoals(folderIdWatch ?? userFolders.at(0)?.id ?? orgFolders.at(0)?.id);
  const useLongTermGoals = organizationDetailsData?.getUserOrgnizationDetails?.useLongTermGoals;
  const { data: categoryData } = useGoalCategoriesQuery({ organizationId: user?.org_id }, { enabled: !!user?.org_id });

  useEffect(() => {
    reset((prev) => ({
      ...prev,
      shorttermGoalsData: [{ ...prev.shorttermGoalsData?.[0], longTermGoalBankId: "" }],
    }));
  }, [folderIdWatch, reset]);

  const foldersSelection = getFolderSelections(userFoldersSection, orgFoldersSection);
  const placeholderText = () => {
    const selection = findFolderSelection(
      folderIdWatch ?? userFolders.at(0)?.id ?? orgFolders.at(0)?.id,
      foldersSelection,
    );
    return selection ? selection.primary : "Select a folder";
  };
  const longTermGoalsSelectItems =
    (longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => {
      return {
        id: edge?.node?.id,
        primary: edge?.node?.goalName,
        secondary: convertReadableString(edge?.node?.goalType),
      };
    }) as SelectItem[]) || [];

  const goalCategories =
    (categoryData?.goalCategories?.edges.map((categoryEdge) => {
      return {
        id: longTermGoals?.getGoalBankLongTermGoals?.edges
          .filter((edge) => edge?.node?.goalName.trim() === categoryEdge?.node?.name?.trim())
          .at(0)?.node?.id,
        primary: categoryEdge?.node?.name,
        secondary: "",
      };
    }) as SelectItem[]) || [];

  return (
    <FolderManagementDialog
      action={FolderManagementAction.SAVE}
      open={open}
      onCancelClick={() => {
        reset();
        onCloseClick();
      }}
      onSaveClick={onSubmit}
    >
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormSelected
          formKey="folderId"
          required
          formState={formState}
          items={foldersSelection}
          title={"Select Folder "}
          placeholderText={placeholderText()}
        />
        <RegisteredFormSelected
          required={true}
          formKey="shorttermGoalsData.0.longTermGoalBankId"
          formState={formState}
          items={useLongTermGoals ? longTermGoalsSelectItems : goalCategories}
          title={goalCategoryName}
          placeholderText={`Select ${goalCategoryName}`}
        />
      </FormContainer>
    </FolderManagementDialog>
  );
};
