import React from "react";

interface InitialsViewProps {
  firstName: string;
  lastName: string;
  className?: string;
}

const AVATAR_COLORS = {
  orange: "#DE6146",
  teal: "#075985",
  green: "#284137",
  purple: "#6b21a8",
  red: "#991b1b",
  brown: "#95887B",
} as const;

const hashString = (str: string): number => {
  return Math.abs(str.split("").reduce((hash, char) => (hash * 31 + char.charCodeAt(0)) | 0, 0));
};
// Get deterministic color based on user's full name
const getColorForUser = (firstName: string, lastName: string): string => {
  const fullName = `${firstName}${lastName}`.toLowerCase();
  const hash = hashString(fullName);
  const colors = Object.values(AVATAR_COLORS);
  const index = hash % colors.length;
  return colors[index];
};

const getInitials = (firstName: string, lastName: string): string => {
  const firstInitial = firstName ? firstName[0].toUpperCase() : "";
  const lastInitial = lastName ? lastName[0].toUpperCase() : "";
  return `${firstInitial}${lastInitial}`;
};

export const UserInitialsView: React.FC<InitialsViewProps> = ({ firstName, lastName, className }) => {
  const backgroundColor = getColorForUser(firstName, lastName);

  return (
    <span
      className={`flex items-center justify-center w-6 h-6 max-w-6 max-h-6 rounded-full text-xs text-white leading-none ${className || ""}`}
      style={{ backgroundColor }}
    >
      {getInitials(firstName, lastName)}
    </span>
  );
};
