import { QueryClient } from "@tanstack/react-query";
import { GenderApiValues, GenderMappingToApi, GenderMappingToUi, GenderUiValues } from "@utils/constants";
import { convertHeadersToObject } from "@utils/utils";
import { RequestDocument } from "graphql-request";
import { Link } from "react-router-dom";

import { BreadcrumbLink, MultiSelectItem } from "@fronterahealth/frontera-ui-components";

import { FronteraGraphQLClient } from "@api/graphql/graphql-service";
import {
  ApiAssessmentClientDetailsGenderChoices,
  ApiLearnerMetadataGenderChoices,
  ApiProviderMetadataGenderChoices,
  ApiReportClientDetailsGenderChoices,
  LearnerFileByIdDocument,
} from "@api/graphql/types-and-hooks";

export const getGenderValue = (
  value:
    | GenderApiValues
    | null
    | undefined
    | ApiProviderMetadataGenderChoices
    | ApiAssessmentClientDetailsGenderChoices
    | ApiLearnerMetadataGenderChoices
    | ApiReportClientDetailsGenderChoices,
) => {
  const genderValue = value === "M" || value === "F" ? value : value?.toLowerCase();
  if (genderValue) return GenderMappingToUi[genderValue as GenderApiValues];

  return null;
};

export const setGenderValue = (value: GenderUiValues | null | undefined | string) => {
  if (value) return GenderMappingToApi[value as GenderUiValues];
  return null;
};

export const capitalizeString = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const pathNameToBreadCrumbLinks = (pathname: string): BreadcrumbLink[] => {
  const links = pathname.slice(1).split("/");

  const linksToRender = links.length > 2 ? links.slice(0, 2) : links;

  return linksToRender.map((link, index) => {
    const decodedLink = decodeURI(link);

    return {
      name: link.toLocaleUpperCase(),
      current: link === links[links.length - 1],
      type: "component",
      Component: (
        <Link to={`/${links.slice(0, index + 1).join("/")}`}>
          {`${decodedLink
            .split("-")
            .map((word) => word)
            .join(" ")}`}
        </Link>
      ),
    };
  });
};

export const formatPlayerTime = (startSeconds: number): string => {
  const hours = Math.floor(startSeconds / 3600);
  const minutes = Math.floor((startSeconds % 3600) / 60);
  const seconds = Math.floor(startSeconds % 60);

  // Pad with leading zeros if necessary
  const hoursStr = hours.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  const secondsStr = seconds.toString().padStart(2, "0");

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
};

export const getPronoun = (
  gender:
    | GenderApiValues
    | null
    | undefined
    | ApiProviderMetadataGenderChoices
    | ApiAssessmentClientDetailsGenderChoices
    | ApiLearnerMetadataGenderChoices
    | ApiReportClientDetailsGenderChoices,
): "She/Her" | "He/His" | "They/Them" | "Prefer Not to Answer" => {
  switch (gender) {
    case "F":
      return "She/Her";
    case "M":
      return "He/His";
    case "NON_BINARY":
    case "AGENDER":
    case "GENDERQUEER_OR_GENDERFLUID":
    case "GENDER_NOT_LISTED":
    case "QUESTIONING_OR_UNSURE":
      return "They/Them";
    case "PREFER_NOT_TO_DISCLOSE":
    case null:
    case undefined:
      return "Prefer Not to Answer";
    default:
      return "Prefer Not to Answer";
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupArrayByKeyValue = (arr: Record<string, any>[], key: string) => {
  return arr.reduce((accumulator, current) => {
    const value = current[key];
    if (!accumulator[value]) {
      accumulator[value] = [];
    }
    accumulator[value].push(current);
    return accumulator;
  }, {});
};

export const getIdList = (items: MultiSelectItem[]): string[] => {
  return items
    .map((item) => {
      if (item.id) {
        return item.id;
      } else if (item.primary) {
        return item.primary
          .toLowerCase() // convert to lowercase
          .replace(/ /g, "-") // replace spaces with hyphens
          .replace(/[^a-z0-9-]/g, ""); // remove non-alphanumeric characters except hyphens
      } else {
        return "";
      }
    })
    .filter((id) => id !== ""); // filter out any empty strings
};

export const matchOrCreateItems = (strings: string[], matchArray: MultiSelectItem[]): MultiSelectItem[] => {
  return strings.map((str) => {
    const match = matchArray.find((item) => item.id === str || item.primary.toLowerCase() === str.toLowerCase());
    if (match) {
      return match;
    } else {
      const primary = str
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return {
        id: str,
        primary: primary,
      };
    }
  });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const customClient = async ({
  token,
  document,
  variables,
}: {
  token: string;
  document: RequestDocument;
  variables: Record<string, unknown>; // Accepts any set of variables
}) => {
  const authHeaders: HeadersInit = new Headers();
  authHeaders.append("Authorization", `Bearer ${token}`);
  const headers = convertHeadersToObject(authHeaders);
  const response = await FronteraGraphQLClient.request(document, variables, headers);
  return response;
};

export const downloadLearnerFileById = async ({ token, fileId }: { token: string; fileId: string }) => {
  try {
    const response = await queryClient.fetchQuery({
      queryKey: ["get-learner-file-by-id", fileId],
      queryFn: () => customClient({ token, document: LearnerFileByIdDocument, variables: { fileId } }),
    });

    const fileData = response.learnerFileById;

    if (fileData?.signedS3LearnerFile) {
      const response = await fetch(fileData.signedS3LearnerFile);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileData.originalFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke object URL to free memory
      window.URL.revokeObjectURL(blobUrl);
    } else {
      console.error("No valid file data found.");
    }
  } catch (error) {
    console.error("File download failed:", error);
  }
};
