import { PRIORITIZE_PAGE_CONTENT } from "@utils/constants";
import { getIdList, matchOrCreateItems } from "@utils/helpers";
import { nonNullable } from "@utils/utils";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { FileInstance, MultiSelect, MultiSelectItem } from "@fronterahealth/frontera-ui-components";

import {
  ApiLearnerFileFileKindChoices,
  ApiLearnerFileStatusChoices,
  ApiReportSectionsAiGeneratedStatusChoices,
  AssessmentToolType,
  ReportPageLocationEnums,
  ReportTypeEnums,
  useAddUpdateReportTreatmentPlanPrioritizeFieldsMutation,
  useAssessmentToolsQuery,
} from "@api/graphql/types-and-hooks";
import { ConfirmGenerationDialog } from "@components/ConfirmGenerationDialog/ConfirmGenerationDialogV2";
import { ContentRow } from "@components/ContentRow/ContentRow";
import { EnhanceAccuracyDialog, getAccuracyContentList } from "@components/EnhanceAccuracyDialog/EnhanceAccuracyDialog";
import { REASONS_TO_BE_OPEN, ValidationNoticeDialog } from "@components/ValidationNoticeDialog/ValidationNoticeDialog";
import { notifyError } from "@components/notifications/notifications";
import { FooterButtonRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/FooterButtonRow";
import { Assessments } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/Assessments/Assessments";
import { BHTIAFiles } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/BHTIAFiles";
import { BehavioralObservations } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/BehavioralObservations.tsx";
import { ClinicalNotes } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/ClinicalNotes";
import { DiagnosisReport } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/DiagnosisReport";
import { IntakeCalls } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/IntakeCall";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/AssessmentReportV2Details/Components/helper";
import { useAdminData } from "@providers/AdminDataProvider";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

export const ApiStatusToFileStatus: { [key in ApiLearnerFileStatusChoices]: FileInstance["fileStatus"] } = {
  [ApiLearnerFileStatusChoices.Uploaded]: "done",
  [ApiLearnerFileStatusChoices.Pending]: "pending",
  [ApiLearnerFileStatusChoices.ProcessError]: "parsing-failed",
  [ApiLearnerFileStatusChoices.ProcessSuccess]: "done",
  [ApiLearnerFileStatusChoices.Processing]: "parsing",
  [ApiLearnerFileStatusChoices.AiGenerating]: "ai-generating",
  [ApiLearnerFileStatusChoices.AiGeneratingError]: "ai-generation-failed",
};

export const UploadFiles: React.FC = () => {
  const { assessmentQuery } = useAssessmentBuilderData();
  const { isCatalightUser, isLearnUser } = useAdminData();
  const { assessmentId: reportId } = useParams();
  const go = useNavigate();

  const files = assessmentQuery.data?.getReports?.edges?.[0]?.node?.reportFiles.edges?.map((edge) => edge?.node);
  const { refetch } = assessmentQuery;
  const response = useAssessmentToolsQuery({ reportType: ReportTypeEnums.InitialAssessment });
  const supportedAssessmentsList: AssessmentToolType[] =
    response.data?.assessmentTools?.filter((item) => !!item).filter((tool) => tool.parsingSupported) ?? [];

  const filesForAssessment = files?.filter((file) => file?.status !== "PENDING")?.filter(nonNullable);

  const existingIntakeCalls = filesForAssessment?.filter(
    (file) => file.fileKind === ApiLearnerFileFileKindChoices.IntakeCall,
  );

  const existingDiagnosis = filesForAssessment
    ?.filter((file) => file.fileKind === ApiLearnerFileFileKindChoices.DiagnosisReport)
    ?.pop();

  const existingAssessments = filesForAssessment?.filter((file) =>
    supportedAssessmentsList.map((a) => a.id).includes(file.fileKind),
  );

  const existingClinicalNotes = filesForAssessment?.filter((file) =>
    [ApiLearnerFileFileKindChoices.ClinicalNotes].includes(file.fileKind),
  );

  const existingBHTIAFiles = filesForAssessment?.filter((file) =>
    [ApiLearnerFileFileKindChoices.Bhtia].includes(file.fileKind),
  );

  const existingBehavioralObservations = filesForAssessment?.filter((file) =>
    [ApiLearnerFileFileKindChoices.BehavioralObservations].includes(file.fileKind),
  );

  const [promptDialogOpen, setPromptDialogOpen] = React.useState<boolean>(false);
  const [validationDialogOpen, setValidationDialogOpen] = React.useState<REASONS_TO_BE_OPEN | null>(null);
  const [accuracyDialogOpen, setAccuracyDialogOpen] = React.useState<boolean>(false);
  const [accuracyDialogContent, setAccuracyDialogContent] = React.useState<string[]>([]);
  const [selectedSafetyConcern, setSelectedSafetyConcern] = React.useState<MultiSelectItem[] | []>([]);
  const [showAccuracyContentWarning] = useLocalStorage("show-accuracy-content-warning", true);

  const nextPage: FilterSectionSubRoute = "report-part-1";
  const nextPageFilteredSections = useGetFilteredPageSectionsByLocation(nextPage);
  const addUpdatePrioritizeMutation = useAddUpdateReportTreatmentPlanPrioritizeFieldsMutation({});

  const nextPageAlreadyGenerated = nextPageFilteredSections.some(
    (section) =>
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending ||
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted,
  );

  const { safetyConcern } = PRIORITIZE_PAGE_CONTENT;
  const reportTreatmentPlanPrioritizeFields =
    assessmentQuery?.data?.getReports?.edges?.[0]?.node?.reportTreatmentPlanPrioritizeFields;

  useEffect(() => {
    if (reportTreatmentPlanPrioritizeFields && reportTreatmentPlanPrioritizeFields.safetyConcerns) {
      setSelectedSafetyConcern(
        matchOrCreateItems(
          reportTreatmentPlanPrioritizeFields.safetyConcerns as string[],
          safetyConcern.optionItems as MultiSelectItem[],
        ),
      );
    }
  }, [reportTreatmentPlanPrioritizeFields]);

  const handlePrimaryButtonAction = async () => {
    await addUpdatePrioritizeMutation.mutateAsync(
      {
        reportId: reportId ? reportId : "missing-report-id",
        prioritizeFieldsDetails: {
          familyLife: reportTreatmentPlanPrioritizeFields?.familyLife ?? [],
          communicationAbility: reportTreatmentPlanPrioritizeFields?.communicationAbility ?? [],
          engagementAbility: reportTreatmentPlanPrioritizeFields?.engagementAbility ?? false,
          socialDesires: reportTreatmentPlanPrioritizeFields?.socialDesires,
          safetyConcerns: selectedSafetyConcern.length
            ? getIdList(selectedSafetyConcern as unknown as MultiSelectItem[])
            : [],
        },
      },
      {
        onError: async (error) => {
          console.error("Error Saving Challenging Behaviors", error);
          notifyError("Error Saving Challenging Behaviors");
        },
      },
    );

    if (nextPageAlreadyGenerated) {
      go(`../${nextPage}`);
      return;
    }

    try {
      const updatedFiles = (await refetch()).data?.getReports?.edges?.[0]?.node?.reportFiles.edges?.map(
        (edge) => edge?.node,
      );

      const filesForAssessment = updatedFiles?.filter((file) => file?.status !== "PENDING")?.filter(nonNullable);

      const existingAssessments = filesForAssessment?.filter((file) =>
        supportedAssessmentsList.map((a) => a.id).includes(file.fileKind),
      );

      const parsingFiles = existingAssessments?.filter(
        (file) => file.status === ApiLearnerFileStatusChoices.Processing,
      );

      const errorFiles = existingAssessments?.filter(
        (file) => file.status === ApiLearnerFileStatusChoices.ProcessError,
      );

      if (existingAssessments?.length === 0) {
        setValidationDialogOpen("MISSING_FILES");
        return;
      }

      if (parsingFiles && parsingFiles.length > 0) {
        setValidationDialogOpen("FILES_VALIDATING");
        return;
      }

      if (errorFiles && errorFiles.length > 0) {
        setValidationDialogOpen("PARSING_FAILED");
        return;
      }

      const tempAccuracyContentList = getAccuracyContentList({
        existingAssessments: existingAssessments?.length === 0,
        existingDiagnosis: !existingDiagnosis,
        existingBHTIAFiles: isCatalightUser && existingBHTIAFiles?.length === 0,
        existingClinicalNotes: existingClinicalNotes?.length === 0,
        existingIntakeCalls: existingIntakeCalls?.length === 0,
      });

      if (tempAccuracyContentList.length > 0 && showAccuracyContentWarning) {
        setAccuracyDialogContent(tempAccuracyContentList);
        setAccuracyDialogOpen(true);
        return;
      } else {
        setPromptDialogOpen(true);
      }
    } catch (error) {
      console.error("Assessment file retrieval error: ", error);
      notifyError("Assessment file retrieval error");
    }
  };

  return (
    <div className="flex w-full flex-col gap-y-4">
      <ValidationNoticeDialog reasonToBeOpen={validationDialogOpen} closeDialog={() => setValidationDialogOpen(null)} />
      <ConfirmGenerationDialog
        type={ReportPageLocationEnums.ReportPart_1}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      <EnhanceAccuracyDialog
        accuracyDialogContent={accuracyDialogContent}
        accuracyDialogOpen={accuracyDialogOpen}
        setAccuracyDialogOpen={setAccuracyDialogOpen}
        onContinue={() => setPromptDialogOpen(true)}
      />
      <IntakeCalls
        existingIntakeCalls={
          existingIntakeCalls?.map((a) => ({
            fileName: a.originalFileName || "<missing-file-name>",
            fileSize: a.fileSize,
            fileStatus: ApiStatusToFileStatus[a.status || ApiLearnerFileStatusChoices.Pending],
            metadata: a.metadata,
            fileKind: a.fileKind || "<missing-file-kind>",
            fileId: a.id,
          })) || []
        }
      />
      <DiagnosisReport
        existingDiagnosis={
          existingDiagnosis
            ? {
                fileName: existingDiagnosis.originalFileName || "<missing-file-name>",
                fileSize: existingDiagnosis.fileSize,
                fileStatus: ApiStatusToFileStatus[existingDiagnosis.status || ApiLearnerFileStatusChoices.Pending],
                metadata: existingDiagnosis.metadata,
                fileKind: existingDiagnosis.fileKind || "<missing-file-kind>",
                fileId: existingDiagnosis.id,
              }
            : undefined
        }
      />
      {isCatalightUser ? (
        <BHTIAFiles
          existingBHTIAFiles={
            existingBHTIAFiles?.map((a) => ({
              fileName: a.originalFileName,
              fileSize: a.fileSize,
              fileStatus: ApiStatusToFileStatus[a?.status || ApiLearnerFileStatusChoices.Pending],
              metadata: a.metadata,
              fileKind: a.fileKind,
              fileId: a.id,
            })) || []
          }
        />
      ) : null}
      {isLearnUser ? (
        <BehavioralObservations
          existingBehavioralObservations={
            existingBehavioralObservations?.map((a) => ({
              fileName: a.originalFileName,
              fileSize: a.fileSize,
              fileStatus: ApiStatusToFileStatus[a?.status || ApiLearnerFileStatusChoices.Pending],
              metadata: a.metadata,
              fileKind: a.fileKind,
              fileId: a.id,
            })) || []
          }
        />
      ) : null}
      <Assessments
        existingAssessments={
          existingAssessments?.map((a) => ({
            fileName: a.originalFileName,
            fileSize: a.fileSize,
            fileStatus: ApiStatusToFileStatus[a?.status || ApiLearnerFileStatusChoices.Pending],
            metadata: a.metadata,
            fileKind: a.fileKind,
            fileId: a.id,
            assessmentTemplateType: a.assessmentTemplateType,
          })) || []
        }
      />
      <ClinicalNotes
        existingClinicalNotes={
          existingClinicalNotes?.map((a) => ({
            fileName: a.originalFileName,
            fileSize: a.fileSize,
            fileStatus: ApiStatusToFileStatus[a?.status || ApiLearnerFileStatusChoices.Pending],
            metadata: a.metadata,
            fileKind: a.fileKind,
            fileId: a.id,
          })) || []
        }
      />
      <ContentRow
        title="Challenging Behaviors"
        subtitle="Add any behaviors that require a Functional Behavior Assessment"
      >
        <MultiSelect
          mode="tags"
          title="Challenging Behaviors"
          items={safetyConcern.optionItems as MultiSelectItem[]}
          placeholderText=""
          selected={selectedSafetyConcern}
          setSelected={setSelectedSafetyConcern}
          className="z-20"
        />
      </ContentRow>
      <FooterButtonRow
        primaryButtonTitle="Next"
        primaryButtonDisabled={assessmentQuery.isFetching}
        primaryButtonAction={handlePrimaryButtonAction}
        skipNavigate
      />
    </div>
  );
};
