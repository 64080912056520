import { FeatureFlagSwitch } from "@utils/FeatureFlags/FeatureFlagSwitch.tsx";
import { downloadFileBlob } from "@utils/utils.ts";
import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import { Button, Paragraph, SkeletonLoader, TrainStopProgressNav } from "@fronterahealth/frontera-ui-components";

import {
  ApiReportReportStatusChoices,
  ExportTypeEnum,
  ReportStatusEnums,
  ReportType,
  useGetExportReportDocumentQuery,
  useUpdateReportMutation,
} from "@api/graphql/types-and-hooks";
import ExportWithTemplateV2 from "@components/PDFGenerator/ExportWithTemplateV2";
import { MissingReportPage } from "@pages/AssessmentReportV2Details/MissingReportPage";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

// BACKPORT: To Be used globally
export type BaseReportPageRoute =
  | "client-details"
  | "upload-files"
  | "report-part-1"
  | "report-part-2"
  | "review-and-sign";

export type InitialAssessmentPageRoute = BaseReportPageRoute | ("prioritize" | "long-term-goals" | "short-term-goals");

export type DiagnosisEvaluationPageRoute = BaseReportPageRoute | ("conclusions" | "recommendations" | "appendix");

export const routeLabelMapping: { [key in InitialAssessmentPageRoute]: string } = {
  "client-details": "Details",
  "upload-files": "Upload Files",
  "report-part-1": "Report Part 1",
  prioritize: "Prioritize",
  "long-term-goals": "Long Term Goals",
  "short-term-goals": "Short Term Goals",
  "report-part-2": "Report Part 2",
  "review-and-sign": "Review",
};

export const orderedAssessmentSubRoutes: InitialAssessmentPageRoute[] = [
  "client-details",
  "upload-files",
  "report-part-1",
  "prioritize",
  "long-term-goals",
  "short-term-goals",
  "report-part-2",
  "review-and-sign",
];

export const baseRoutes: InitialAssessmentPageRoute[] = [
  "client-details",
  "upload-files",
  "report-part-1",
  "prioritize",
];

export const finalRoutes: InitialAssessmentPageRoute[] = ["report-part-2", "review-and-sign"];

export const useOrderedAssessmentReportSubRoutes = () => {
  const { assessmentQuery } = useAssessmentBuilderData();
  const assessmentReport = assessmentQuery.data?.getReports?.edges?.[0]?.node;
  const goalRoutes: InitialAssessmentPageRoute[] = assessmentReport?.reportTreatmentPlan?.useLongTermGoals
    ? ["long-term-goals", "short-term-goals"]
    : ["short-term-goals"];

  const orderedRoutes = [...baseRoutes, ...goalRoutes, ...finalRoutes] as InitialAssessmentPageRoute[];

  return {
    orderedRoutes,
    getNextRoute: (route: InitialAssessmentPageRoute) => {
      const currentIndex = orderedRoutes.indexOf(route);
      if (route === "review-and-sign") return "review-and-sign";
      return currentIndex >= 0 && currentIndex < orderedRoutes.length - 1 ? orderedRoutes[currentIndex + 1] : null;
    },
    getPastRoute: (route: InitialAssessmentPageRoute) => {
      const currentIndex = orderedRoutes.indexOf(route);
      return currentIndex > 0 ? orderedRoutes[currentIndex - 1] : "client-details";
    },
    statusFromCurrentRoute: (currentRoute: InitialAssessmentPageRoute, navStepRoute: InitialAssessmentPageRoute) => {
      return currentRoute === navStepRoute
        ? "current"
        : orderedRoutes.indexOf(currentRoute) > orderedRoutes.indexOf(navStepRoute)
          ? "complete"
          : "upcoming";
    },
  };
};

export const AssessmentReportV2Details: React.FC = () => {
  const { pathname, state } = useLocation();
  const { assessmentQuery } = useAssessmentBuilderData();

  const assessment = assessmentQuery.data?.getReports?.edges[0]?.node as ReportType;
  if (!assessment) return <MissingReportPage />;

  const { refetch: getExportReport, isLoading: isReportExporting } = useGetExportReportDocumentQuery(
    {
      reportId: assessment.id,
      exportType: ExportTypeEnum.Docx,
    },
    {
      enabled: false,
      retry: false,
    },
  );

  const updateAssessment = useUpdateReportMutation({});

  const { orderedRoutes, statusFromCurrentRoute } = useOrderedAssessmentReportSubRoutes();

  const { isPending } = updateAssessment;

  const updateAssessmentStatus = async () => {
    await updateAssessment
      .mutateAsync({
        reportId: assessment.id,
        status: ReportStatusEnums.Draft,
      })
      .then((data) => {
        if (data.updateReport?.status) {
          assessmentQuery?.refetch();
        }
      });
  };

  const reportIsCompleted = assessment.reportStatus === ApiReportReportStatusChoices.Completed;

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as InitialAssessmentPageRoute) ||
    ("upload-files" as InitialAssessmentPageRoute);

  const clientName = `${assessment?.reportClientDetails?.clientFirstName || "N/A"} ${assessment?.reportClientDetails?.clientLastName || ""}`;

  return (
    <div className="-mt-12 flex w-full flex-col items-start justify-between">
      <div className="d-flex flex-col sticky top-4 pt-3  w-full z-20">
        <div className="-mt-7 flex flex-col gap-y-2 bg-white -ml-6 w-[calc(100%+48px)] p-4 ">
          <div className="flex w-full items-center justify-between px-2">
            <SkeletonLoader loading={!assessment}>
              <Paragraph colorType="primary" displayType="loud">
                {clientName} Assessment Report{" "}
              </Paragraph>
            </SkeletonLoader>
            {reportIsCompleted && (
              <div>
                <Button
                  text="Edit"
                  disabled={isPending}
                  appearance="secondary"
                  onClick={() => updateAssessmentStatus()}
                  className="mr-2 ring-green-400"
                />
                <FeatureFlagSwitch
                  flagKey="dynamic-report-output-diagnosis-builder"
                  flagEnabled={
                    <Button
                      text={isReportExporting ? "Exporting..." : "Export"}
                      disabled={isReportExporting}
                      appearance="primary"
                      onClick={() => {
                        getExportReport().then((queryResult) => {
                          downloadFileBlob(queryResult.data?.exportReportDocument?.file);
                        });
                      }}
                    />
                  }
                  flagDisabled={<ExportWithTemplateV2 reportData={assessment} />}
                />
              </div>
            )}
          </div>
        </div>
        {!reportIsCompleted ? (
          <div className="-ml-8 w-[calc(100%+64px)] bg-background-secondary">
            <TrainStopProgressNav
              steps={orderedRoutes.map((route, index) => {
                return {
                  index: index + 1,
                  name: routeLabelMapping[route],
                  status: statusFromCurrentRoute(currentRoute, route),
                  to: route,
                  navigationToEnabled: true,
                  Component: ({ children, className, to }) => (
                    <Link to={to} className={className} state={state}>
                      {children}
                    </Link>
                  ),
                };
              })}
            />
          </div>
        ) : null}
      </div>
      <div className="w-full mt-4 py-8 h-full">
        <Outlet />
      </div>
    </div>
  );
};
