import { datadogRum } from "@datadog/browser-rum";

import { FileUploadAndParseProps } from "@fronterahealth/frontera-ui-components";

import { useCreateReportLogoMutation, useUpdateReportLogoUrlMutation } from "@api/graphql/types-and-hooks";

export const useGetS3BucketLogoUrlRetriever: ({
  metadata,
  reportId,
}: {
  metadata?: object;
  reportId: string;
}) => FileUploadAndParseProps["S3BucketUrlRetriever"] = ({ metadata: _metadata, reportId }) => {
  const createFileMutation = useCreateReportLogoMutation({ throwOnError: false });
  const metadata = _metadata ? JSON.stringify(_metadata) : undefined;

  return async ({ fileKind, fileName, mimeType, fileSize }) => {
    console.debug(
      `%c<FILE-API> | Requesting S3 URL | fileKind: ${fileKind} fileName: ${fileName} mimeType: ${mimeType} reportId: ${reportId} metadata: ${metadata}`,
      "color: black; background: red",
    );

    try {
      const res = await createFileMutation.mutateAsync({
        input: {
          fileSize,
          mimeType,
          originalFileName: fileName,
          reportId: reportId,
        },
      });

      datadogRum.addAction("create-logo-file", {
        fileMetadata: { fileKind, mimeType, fileName, metadata },
      });

      const bucket = res?.createReportLogo?.fileUrl || "<missing-bucket-url>";
      const fileId = reportId || "<missing-file-id>";

      return { bucket, fileId };
    } catch (err) {
      console.error("Error retrieving S3 bucket URL:", err);
      throw new Error(String(err));
    }
  };
};

export const useGetS3BucketUploadLogoCallback: ({
  refetch,
  reportId,
}: {
  refetch: () => Promise<void> | unknown;
  reportId: string;
}) => FileUploadAndParseProps["S3BucketUploadCallback"] = ({ refetch, reportId }) => {
  const updateFileUrlMutation = useUpdateReportLogoUrlMutation({ throwOnError: false });

  const uploadWithRetry = async ({
    file,
    mimeType,
    url,
    retries = 5,
    attempt = 1,
  }: {
    file: File;
    mimeType: string;
    url: string;
    retries?: number;
    attempt?: number;
  }): Promise<Response> => {
    try {
      console.debug(`Attempt ${attempt}: Uploading file: ${file.name} to S3 URL: ${url}`);

      const response = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": mimeType },
        body: file,
      });

      if (response.status === 200) {
        return response;
      }

      throw new Error(`S3 upload failed: ${response.status} ${response.statusText}`);
    } catch (err) {
      if (attempt < retries) {
        const backoff = 100 * 2 ** (attempt - 1);
        console.warn(`Upload attempt ${attempt} failed. Retrying in ${backoff}ms...`, err);
        await new Promise((resolve) => setTimeout(resolve, backoff));
        return uploadWithRetry({ file, mimeType, url, retries, attempt: attempt + 1 });
      }

      console.error("Max retries reached. Upload failed.", err);
      throw err;
    }
  };

  return async ({ file, mimeType, url }) => {
    try {
      await uploadWithRetry({ file, mimeType, url });

      datadogRum.addAction("field-file-uploaded-successfully", { fileMetadata: { fileName: file.name, mimeType } });

      const res = await updateFileUrlMutation.mutateAsync({
        input: {
          reportId: reportId,
          originalFileName: file.name,
        },
      });

      if (res?.updateReportLogoUrl?.status) {
        await refetch();
      }
    } catch (err) {
      console.error("Error uploading file to S3:", err);
      throw new Error(String(err));
    }
  };
};
