import { SideFilterPanel } from "@pages/VideoSessions/components/SideFilterPanel/SideFilterPanel";
import { useAdminData } from "@providers/AdminDataProvider";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

export const SideFilterBar = () => {
  const { roles } = useAdminData();
  const { filterIsReviewed, setFilterIsReviewed } = useVideoSessionData();

  const items = [
    { label: "All Videos", active: filterIsReviewed === undefined },
    { label: "Not Yet Reviewed", active: filterIsReviewed === false },
    { label: "Reviewed", active: filterIsReviewed },
  ];

  return (
    <div className="flex flex-col justify-center">
      <SideFilterPanel
        items={items.map((item) => ({
          ...item,
        }))}
        onSelect={(label) => {
          if (label === "Reviewed") setFilterIsReviewed(true);
          else if (label === "Not Yet Reviewed") setFilterIsReviewed(false);
          else setFilterIsReviewed(undefined);
        }}
        showMediaFilters={roles.includes("bcba") ? true : false}
      />
    </div>
  );
};
