import React, { ChangeEvent, useEffect, useState } from "react";

import { CheckboxList } from "@fronterahealth/frontera-ui-components";
import {
  PopoverMenu,
  PopoverMenuItem,
} from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

interface InputData {
  options: string[];
  answer: string[];
}

export interface CheckboxItem {
  id: string;
  title: string;
  defaultChecked?: boolean;
  secondaryTitle?: string;
}

interface CheckboxListFieldProps {
  inputJson: InputData;
  /**
   * Callback that receives an InputData object:
   * { options: string[], answer: string[] }
   */
  onChange: (json: InputData) => Promise<void>;
  actionButtonMenu: PopoverMenuItem[];
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
  displayOnlyMode: boolean;
}

export const CheckboxListField: React.FC<CheckboxListFieldProps> = ({
  inputJson,
  onChange,
  actionButtonMenu,
  permissions,
  displayOnlyMode = false,
}) => {
  const convertOptions = (opts: string[]): CheckboxItem[] =>
    opts.map((o) => ({
      id: o,
      title: o,
      defaultChecked: false,
    }));

  const [options, setOptions] = useState<CheckboxItem[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    if (Array.isArray(inputJson.options)) {
      const converted = convertOptions(inputJson.options);
      const updatedOptions = converted.map((item) => ({
        ...item,
        defaultChecked: inputJson.answer.includes(item.id),
      }));
      setOptions(updatedOptions);
    } else {
      console.error("Invalid inputJson: 'options' must be an array");
      setOptions([]);
    }
    if (Array.isArray(inputJson.answer)) {
      setSelectedIds(inputJson.answer);
    } else {
      console.error("Invalid inputJson: 'answer' must be an array");
      setSelectedIds([]);
    }
  }, [inputJson]);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    let updatedSelected: string[];
    if (checked) {
      updatedSelected = [...selectedIds, value];
    } else {
      updatedSelected = selectedIds.filter((id) => id !== value);
    }
    setSelectedIds(updatedSelected);

    const output: InputData = {
      options: options.map((item) => item.title),
      answer: updatedSelected,
    };

    onChange(output);
  };

  return (
    <div className="group relative pr-6">
      <div data-testid="checkbox-input-container">
        <div className="relative overflow-auto">
          <CheckboxList
            items={options}
            title=""
            legend="Options"
            orientation={options.length > 3 ? "vertical" : "horizontal"}
            onChange={handleCheckboxChange}
            disabled={displayOnlyMode}
            variant={displayOnlyMode ? "default" : "report"}
          />
        </div>
      </div>
      <div
        data-testid="checkbox-actions-panel"
        className={`group absolute -right-1 top-1 flex-col gap-y-4 ${
          permissions.canEdit ? "opacity-0 group-hover:opacity-100" : "pointer-events-none opacity-0"
        } mt-2 transition-all`}
      >
        {actionButtonMenu.length ? (
          <PopoverMenu mode="secondary" menuItems={actionButtonMenu ?? []} openDirection="to-bottom-left" />
        ) : null}
      </div>
    </div>
  );
};
