import { PlusIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";

import { Button, Small } from "@fronterahealth/frontera-ui-components";

import { AddRecommendationsBank } from "@components/RecommendationsBank/AddRecommendationsBank/AddRecommendationsBank";
import { ContentBlockCard } from "@components/SectionWidget/ContentBlockCard";
import {
  ContentTypeAndBespokeType,
  SupportedContentType,
  getContentTypeAndBespokeType,
} from "@components/SectionWidget/SectionWidget";
import { useAdminData } from "@providers/AdminDataProvider.tsx";
import { useEvaluationData } from "@providers/EvaluationProvider";

interface AddContentMenuProps {
  sectionId: string;
  sectionType: "regular" | "recommendation";
  supportedContentTypes: SupportedContentType[];
  onAddContent: (contentTypeAndBespokeType: ContentTypeAndBespokeType, rankOrder: number) => Promise<unknown>;
  newRankOrder: number;
}

export const AddContentMenu: React.FC<AddContentMenuProps> = ({
  sectionId,
  sectionType = "regular",
  supportedContentTypes,
  onAddContent,
  newRankOrder,
}) => {
  const [showContentMenu, setShowContentMenu] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { evaluationQuery } = useEvaluationData();
  const { hasCustomOutputTemplate } = useAdminData();

  if (hasCustomOutputTemplate) return;

  const closeContentMenu = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setShowContentMenu(false);
    }
  };

  useEffect(() => {
    if (showContentMenu) {
      document.addEventListener("mousedown", closeContentMenu);
    }
    return () => {
      document.removeEventListener("mousedown", closeContentMenu);
    };
  }, [showContentMenu]);

  const handleAddContent = async (type: SupportedContentType) => {
    const contentTypeAndBespokeType = getContentTypeAndBespokeType(type);
    if (contentTypeAndBespokeType) {
      await onAddContent(contentTypeAndBespokeType, newRankOrder);
    }
    setShowContentMenu(false);
  };

  return (
    <div className="flex justify-center">
      {showContentMenu ? (
        sectionType === "recommendation" ? (
          <div className="flex items-center space-x-2">
            <AddRecommendationsBank
              aiGoals={[]}
              sectionId={sectionId}
              onAdd={async () => {
                await evaluationQuery.refetch();
                setShowContentMenu(false);
              }}
              newRankOrder={newRankOrder}
            />
            <Button
              text={
                <Small className={"flex items-center px-2 text-white"}>
                  <PlusIcon className="mr-2 h-4 w-4" />
                  Custom Recommendation
                </Small>
              }
              buttonAction="action"
              appearance={"primary"}
              onClick={() => handleAddContent("Text")}
            />
          </div>
        ) : (
          <div className="flex flex-row mt-4" ref={divRef}>
            {supportedContentTypes.map((_typeName, index) => (
              <ContentBlockCard
                key={`${index}_${_typeName}`}
                typeName={_typeName}
                onClick={() => handleAddContent(_typeName)}
              />
            ))}
          </div>
        )
      ) : (
        <>
          <div className="px-4 -mb-4">
            <div
              data-testid="add-content-hover-area"
              className="flex items-center w-full group"
              onClick={() => setShowContentMenu(true)}
            >
              <div
                className={`transition-all my-3 group-hover:border-limestone-200 border border-limestone-300 w-16`}
              />
              <div className={`px-2 text-center`}>
                <Small
                  data-testid="add-content-button"
                  displayType="normal"
                  className={`cursor-pointer group-hover:text-text-primary transition-all `}
                >
                  Add {sectionType === "regular" ? "Content" : "Recommendation"}
                </Small>
              </div>
              <div
                className={`transition-all my-3 group-hover:border-limestone-200 border border-limestone-300 w-16`}
              ></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
