import {
  ApiMediaCommentsCommentTypeChoices,
  ApiTherapySessionSessionStatusChoices,
} from "@api/graphql/types-and-hooks";

export const EMOJI_BUTTONS = [
  { emoji: "❤️", text: "Love", value: ApiMediaCommentsCommentTypeChoices.Love },
  { emoji: "👍", text: "Praise", value: ApiMediaCommentsCommentTypeChoices.Praise },
  { emoji: "💫", text: "Feedback", value: ApiMediaCommentsCommentTypeChoices.Feedback },
  { emoji: "🤔", text: "Question", value: ApiMediaCommentsCommentTypeChoices.Question },
] as const;

// Define the emoji type based on the buttons
export type EmojiType = (typeof EMOJI_BUTTONS)[number]["emoji"];

export const NO_MEDIA_STATUS = [
  ApiTherapySessionSessionStatusChoices.Failed,
  ApiTherapySessionSessionStatusChoices.Processing,
  ApiTherapySessionSessionStatusChoices.Scheduled,
  ApiTherapySessionSessionStatusChoices.Uploading,
];
