import React from "react";
import { useLocalStorage } from "usehooks-ts";

import { CheckboxList, Dialog, Paragraph } from "@fronterahealth/frontera-ui-components";

interface EnhanceAccuracyDialogProps {
  accuracyDialogOpen: boolean;
  setAccuracyDialogOpen: (bool: boolean) => void;
  accuracyDialogContent: string[];
  onContinue: () => void;
}

interface getAccuracyContentListProps {
  existingIntakeCalls: boolean;
  existingDiagnosis: boolean;
  existingBHTIAFiles: boolean;
  existingAssessments: boolean;
  existingClinicalNotes: boolean;
}

export const getAccuracyContentList = (files: getAccuracyContentListProps): string[] => {
  return [
    files.existingIntakeCalls && "Intake Interviews",
    files.existingDiagnosis && "Diagnosis Reports",
    files.existingBHTIAFiles && "BHTIA Files",
    files.existingAssessments && "Assessment Scoring Files",
    files.existingClinicalNotes && "Clinical Notes",
  ].filter(Boolean) as string[];
};

export const EnhanceAccuracyDialog: React.FC<EnhanceAccuracyDialogProps> = ({
  accuracyDialogOpen,
  setAccuracyDialogOpen,
  accuracyDialogContent,
  onContinue,
}) => {
  const handleClose = () => setAccuracyDialogOpen(false);
  const [, setShowAccuracyContentWarning] = useLocalStorage("show-accuracy-content-warning", true);

  return (
    <Dialog
      size="xl"
      open={accuracyDialogOpen}
      setOpen={setAccuracyDialogOpen}
      title={"Enhance Your Report Accuracy"}
      primaryButton={{
        text: "Upload More Files",
        buttonAction: "regular",
        onClick: handleClose,
        className: "px-6",
      }}
      secondaryButton={{
        text: "Continue Anyway",
        onClick: () => {
          onContinue();
          handleClose();
        },
        className: "ring-1 ring-green-700",
      }}
    >
      <Paragraph displayType="normal" colorType="primary" className="mb-4">
        For the most accurate and complete Assessment Report, we recommend uploading additional client files. These
        include
      </Paragraph>
      {accuracyDialogContent.map((item, index) => (
        <Paragraph key={index} displayType="normal" colorType="primary" className="">
          <div className="flex items-center gap-2">
            <span className="text-lg">•</span>
            <span>{item}</span>
          </div>
        </Paragraph>
      ))}
      <CheckboxList
        items={[
          {
            title: "Don’t show this warning again",
            id: "accuracyContentWarning",
          },
        ]}
        title={""}
        legend={""}
        onChange={(e) => setShowAccuracyContentWarning(!e.target.checked)}
      />
    </Dialog>
  );
};
