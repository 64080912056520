import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { GenderApiValues } from "@utils/constants";
import { getIdList, setGenderValue } from "@utils/helpers";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, MultiSelectItem, Paragraph, SelectItem, Small } from "@fronterahealth/frontera-ui-components";

import {
  CaregiverInput,
  CreateLearnerCaregiverMutationVariables,
  useCreateLearnerCaregiverMutation,
} from "@api/graphql/types-and-hooks";
import { ContentRow } from "@components/ContentRow/ContentRow";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { AddUpdateCaregiverDialogue } from "@pages/ClientDetailsSubPages/Caregivers/AddUpdateCaregiverDialogue";
import { getGenderValue } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useAdminData } from "@providers/AdminDataProvider";
import { useClientData } from "@providers/ClientProvider";

interface NewClientLiteProps {
  setIsFormDirty?: (val: boolean) => void;
}

export const NewClientLite: React.FC<NewClientLiteProps> = ({ setIsFormDirty }) => {
  const { learnersQuery, isPassageHealthUser } = useAdminData();
  const [searchParams] = useSearchParams();
  const { assignableLearnerProviders, isProvidersLoading } = useClientData();
  const { refetch: refetchLearners } = learnersQuery;

  const formRef = useRef<HTMLFormElement | null>(null);
  const [caregiverList, setCaregiverList] = useState<CaregiverInput[]>([]);
  const [isNewCaregiverDialogueOpen, setIsNewCaregiverDialogueOpen] = useState<boolean>(false);
  const [selectedCaregiver, setSelectedCaregiver] = useState<CaregiverInput | null>(null);
  const [caregiverError, setCaregiverError] = useState<string | null>(null);

  const createLearnerCaregiver = useCreateLearnerCaregiverMutation({
    onSuccess: async (data) => {
      if (data.createLearnerCaregiver?.status) {
        notifySuccess("Client created successfully");
        refetchLearners();
        go("/clients");
      } else if (!data.createLearnerCaregiver?.status && data.createLearnerCaregiver?.message) {
        notifyError(data.createLearnerCaregiver?.message);
      } else {
        notifyError("Something Went Wrong!");
      }
    },
  });

  const go = useNavigate();

  const { formState, onSubmit, RegisteredFormInput, RegisteredFormSelected, watch, RegisteredFormMultiSelect } =
    useFormUtils<CreateLearnerCaregiverMutationVariables>({
      mutationFn: async (params) => {
        if (isPassageHealthUser && caregiverList.length === 0) {
          setCaregiverError("At least one caregiver is required.");
          return;
        }

        const { learner, providerIds: providers } = params;
        const caregiver = caregiverList.length ? caregiverList[0] : null;

        const newParams = {
          learner: {
            ...learner,
            gender: learner.gender ? setGenderValue(learner.gender) : undefined,
          },
          caregiver: caregiver
            ? {
                ...caregiver,
                smsAgreement: Boolean(caregiver?.smsAgreement),
              }
            : undefined,
          sendIntake: false,
          providerIds: providers ? getIdList(providers as unknown as MultiSelectItem[]) : null,
        };

        await createLearnerCaregiver!.mutateAsync(newParams as CreateLearnerCaregiverMutationVariables);
      },
      defaultValues: {
        learner: {
          firstName: "",
          lastName: "",
          birthDate: "",
          gender: undefined,
        },
      },
    });

  // calculation for tracks any change in the form.

  const initialValues = useRef(watch());

  useEffect(() => {
    const currentValues = watch();
    if (
      (JSON.stringify(currentValues) !== JSON.stringify(initialValues.current) || caregiverList.length) &&
      setIsFormDirty
    ) {
      setIsFormDirty(true);
    }
  }, [watch, caregiverList]);

  const handleEdit = (caregiver: CaregiverInput) => {
    setSelectedCaregiver(caregiver);
    setIsNewCaregiverDialogueOpen(true);
  };

  const handleDelete = (index: number) => {
    setCaregiverList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const genderWatch = watch("learner.gender");

  return (
    <div className="w-full">
      <FormContainer onSubmit={onSubmit} ref={formRef}>
        <div className={`${!searchParams.get("client-type") ? "mt-10" : ""} flex w-full flex-col gap-y-4`}>
          <ContentRow title="Client Details">
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learner.firstName"
                inputSize="full"
                formState={formState}
                label="First Name"
              />
              <RegisteredFormInput
                formKey="learner.lastName"
                inputSize="full"
                formState={formState}
                label="Last Name"
              />
            </div>

            <div className="gap-x-4 lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learner.birthDate"
                inputSize="full"
                type="date"
                formState={formState}
                label="Date of Birth"
              />
            </div>
            <div />
            <DoubleColumnContainer>
              <RegisteredFormSelected
                formKey="learner.gender"
                formState={formState}
                placeholderText={""}
                title="Gender"
                required={false}
                items={
                  Object.values(GenderApiValues).map((o) => ({
                    primary: getGenderValue(o),
                  })) as SelectItem[]
                }
              />
              {genderWatch === "Gender identity is not listed" && (
                <RegisteredFormInput
                  formKey="learner.specifyGender"
                  formState={formState}
                  required={false}
                  label="Specify Gender"
                />
              )}
            </DoubleColumnContainer>
          </ContentRow>

          <ContentRow title="Caregiver Details" subtitle={!isPassageHealthUser ? "(Optional)" : null}>
            <div className="col-span-3 flex flex-col">
              {caregiverList.length ? (
                <div className="mt-4 flex flex-col gap-3">
                  {caregiverList.map((caregiver, index) => (
                    <div
                      key={caregiver.caregiverId}
                      className="grid grid-cols-9 bg-white items-center justify-between border p-3 rounded-lg shadow-sm"
                    >
                      <Small
                        displayType="loud"
                        className="col-span-4"
                      >{`${caregiver.firstName} ${caregiver.lastName}`}</Small>
                      <Small colorType="secondary" className="col-span-4">
                        {caregiver.email}
                      </Small>

                      <div className="flex gap-3">
                        <PencilIcon className="w-5 h-5  cursor-pointer" onClick={() => handleEdit(caregiver)} />
                        <TrashIcon className="w-5 h-5 cursor-pointer" onClick={() => handleDelete(index)} />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col gap-3">
                  <Paragraph colorType="secondary">No caregivers added yet</Paragraph>
                  <Button
                    text="Add Caregiver"
                    appearance="secondary"
                    onClick={() => {
                      setSelectedCaregiver(null);
                      setIsNewCaregiverDialogueOpen(true);
                    }}
                  />
                </div>
              )}
              {/* Show error when clicking submit */}
              {caregiverError && <Small className="text-red-500">{caregiverError}</Small>}
            </div>
          </ContentRow>
          <ContentRow title="Providers" subtitle="Assign the providers that will be working with this client.">
            <RegisteredFormMultiSelect
              title={"Assign providers"}
              items={assignableLearnerProviders.sort((a, b) => (a.primary > b.primary ? 1 : -1))}
              placeholderText={"available providers"}
              formKey="providerIds"
              formState={formState}
              mode={"tags"}
              isLoading={isProvidersLoading}
              required={false}
            />
          </ContentRow>
        </div>
        <div className="mt-6">
          <SubmitButton isLoading={formState.isSubmitting} buttonText="Save & Continue" />
        </div>
        {isNewCaregiverDialogueOpen ? (
          <AddUpdateCaregiverDialogue
            isDialogOpen={isNewCaregiverDialogueOpen}
            setDialogOpen={setIsNewCaregiverDialogueOpen}
            onAddCaregiver={(caregiver) => {
              setCaregiverList([{ ...caregiver }]);
              setCaregiverError(null);
            }}
            selectedCaregiver={selectedCaregiver}
          />
        ) : null}
      </FormContainer>
    </div>
  );
};
