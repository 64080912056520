import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { APP_ENV, graphqlErrorHandler } from "@utils/utils";
import { GraphQLError } from "graphql";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Dialog, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import {
  ApiReportSectionsAiGeneratedStatusChoices,
  ReportPageLocationEnums,
  ReportType,
  useCreateDefaultReportSectionsMutation,
  useCreateReportTemplateSectionsMutation,
} from "@api/graphql/types-and-hooks";
import { notifyError } from "@components/notifications/notifications";
import {
  InitialAssessmentPageRoute,
  useOrderedAssessmentReportSubRoutes,
} from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

interface ConfirmGenerationContentProps {
  type: ReportPageLocationEnums;
  promptDialogOpen: boolean;
  setPromptDialogOpen: (bool: boolean) => void;
  onboardingData?: string;
}

export const GenerationTypeLabelMapping: { [key in ReportPageLocationEnums]: string } = {
  [ReportPageLocationEnums.ReportPart_1]: "Report Part 1",
  [ReportPageLocationEnums.ReportPart_2]: "Report Part 2",
  [ReportPageLocationEnums.Conclusions]: "Conclusions",
  [ReportPageLocationEnums.Recommendations]: "Recommendations",
  [ReportPageLocationEnums.Appendix]: "Appendix",
  [ReportPageLocationEnums.TreatmentPlan]: "Treatment Plan",
};

const checkIsPredictionGenerating = (
  page: ReportPageLocationEnums,
  assessment: ReportType | undefined | null,
): boolean => {
  if (!assessment) return false;
  return assessment?.reportSections?.edges
    .map((edge) => edge?.node)
    .filter((section) => section?.pageLocation === page)
    .some(
      (section) => section?.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
    );
};

const checkIsPredictionGeneratedAlready = (
  page: ReportPageLocationEnums,
  assessment: ReportType | undefined | null,
): boolean => {
  if (!assessment) return false;
  return assessment.reportSections.edges
    .map((edge) => edge?.node)
    .filter((section) => section?.pageLocation === page)
    .some(
      (section) => section?.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted,
    );
};

export const ConfirmGenerationDialog: React.FC<ConfirmGenerationContentProps> = ({
  type,
  promptDialogOpen,
  setPromptDialogOpen,
}) => {
  const { pathname } = useLocation();
  const go = useNavigate();
  const { assessmentId: reportId } = useParams();
  const { assessmentQuery } = useAssessmentBuilderData();
  const flags = useFlags();

  const assessment = assessmentQuery.data?.getReports?.edges[0]?.node as ReportType;

  const isPredictionGenerating = checkIsPredictionGenerating(type, assessment);
  const isPredictionGeneratedAlready = checkIsPredictionGeneratedAlready(type, assessment);

  const createDefaultReportSectionsMutation = useCreateDefaultReportSectionsMutation();
  const createReportTemplateSections = useCreateReportTemplateSectionsMutation({});

  const [showNotice, setShowNotice] = React.useState<boolean>(isPredictionGeneratedAlready || false);

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as InitialAssessmentPageRoute) ||
    ("upload-files" as InitialAssessmentPageRoute);

  const { getNextRoute } = useOrderedAssessmentReportSubRoutes();
  const nextRoute = getNextRoute(currentRoute);

  const generationTypeLabel = GenerationTypeLabelMapping[type];

  const handleDialogClick = useCallback(async () => {
    if (isPredictionGenerating) {
      setPromptDialogOpen(false);
    } else if (showNotice && (APP_ENV === "dev" || APP_ENV === "local")) {
      setShowNotice(false);
    } else {
      try {
        if (flags["db-diagnosis-builder-report-template"]) {
          await createReportTemplateSections.mutateAsync({
            input: { reportId: reportId || "<missing-report-id>", pageLocation: type },
          });
        } else {
          await createDefaultReportSectionsMutation.mutateAsync({
            reportId: reportId || "<missing-report-id>",
            pageLocation: type,
          });
        }
        go(`../${nextRoute}`);
      } catch (err) {
        const errorMessages = graphqlErrorHandler(err as GraphQLError);
        for (const errorMessage of errorMessages) {
          notifyError(errorMessage);
        }
        console.error(`Failed to kickoff ${generationTypeLabel} Generation`, err);
        return;
      }
    }
  }, []);

  return (
    <Dialog
      size="xl"
      open={promptDialogOpen}
      setOpen={setPromptDialogOpen}
      title={showNotice || isPredictionGenerating ? "Notice" : `Confirm ${generationTypeLabel} Generation`}
      primaryButton={{
        text:
          createDefaultReportSectionsMutation.isPending || createReportTemplateSections.isPending
            ? "Generating..."
            : showNotice
              ? "Yes, Continue"
              : isPredictionGenerating
                ? "Okay"
                : "Generate",
        buttonAction: showNotice ? "destructive" : "regular",
        onClick: handleDialogClick,
        disabled: createDefaultReportSectionsMutation.isPending,
        className: "px-6",
      }}
      secondaryButton={
        !showNotice && isPredictionGenerating
          ? undefined
          : {
              text: showNotice ? "No, Cancel" : "Cancel",
              onClick: () => {
                if (showNotice) {
                  setPromptDialogOpen(false);
                } else {
                  setPromptDialogOpen(false);
                  if (isPredictionGeneratedAlready) setShowNotice(true);
                }
              },
              className: "px-6 hover:no-underline",
            }
      }
    >
      {showNotice ? (
        <>
          <Paragraph displayType="normal" colorType="secondary">
            You are about to resubmit a previous step. This will cause the upcoming pages of your assessment report to
            be regenerated. Any changes you previously made to these pages will be overwritten.
          </Paragraph>
          <Paragraph displayType="normal" colorType="secondary" className="my-4">
            Would you like to proceed?
          </Paragraph>
          <div className="p-4 bg-red-50">
            <Paragraph displayType="loud" className="inline !text-red-500">
              <ExclamationTriangleIcon className="h-5 w-5 text-red-500 inline mr-2" />
              Warning:{" "}
            </Paragraph>
            <Paragraph displayType="normal" colorType="secondary" className="inline !text-red-500">
              &nbsp; This action is not reversible
            </Paragraph>
          </div>
        </>
      ) : isPredictionGenerating ? (
        <>
          <Paragraph displayType="normal" colorType="secondary">
            Content for another step is currently being generated. This may take a few moments. Please wait until the
            process is complete before proceeding.
          </Paragraph>
        </>
      ) : (
        <Small>
          By clicking "Generate", you will be kicking off the {generationTypeLabel} generation step. This step will take
          several minutes. While {generationTypeLabel} generation is happening, you will not be able to edit your
          report.
        </Small>
      )}
    </Dialog>
  );
};
