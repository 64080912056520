import { useAuth0 } from "@auth0/auth0-react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { convertHeadersToObject } from "@utils/utils";
import { format } from "date-fns";
import { useEffect } from "react";

import { Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

import { FronteraGraphQLClient } from "@api/graphql/graphql-service";
import {
  GetMediaByProviderDocument,
  MediaSortByEnum,
  MediaType,
  TherapySessionStatusEnums,
} from "@api/graphql/types-and-hooks";
import { FeedMediaCard } from "@pages/VideoSessions/components/Media/FeedMediaCard";
import { FeedSkeletonLoader } from "@pages/VideoSessions/components/Media/FeedSkeletonLoader";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

export type MediaCardType = Pick<
  MediaType,
  | "id"
  | "mediaType"
  | "totalDuration"
  | "mediaStatus"
  | "createdDate"
  | "therapySession"
  | "commentsCount"
  | "mediaClassifications"
  | "isViewed"
>;

export const MediaCards: React.FC = () => {
  const {
    filterSelectedClients,
    filterSelectedProviders,
    filterSessionDates,
    filterSelectedRating,
    sortBy,
    setAllFilteredMedias,
    allFilteredMedias,
    filterIsReviewed,
    filterByUploadStatus,
    isUserRBT,
    setIsMediaLoading,
    isMediaLoading,
  } = useVideoSessionData();

  const { getAccessTokenSilently } = useAuth0();

  const finalUploadStatus = filterByUploadStatus.flatMap((item) => {
    try {
      const parsed = JSON.parse(item.id);
      return Array.isArray(parsed) ? parsed : [parsed];
    } catch {
      return [item.id];
    }
  });

  const fetchMediaByProvider = async ({ pageParam = { after: null } }) => {
    const { after } = pageParam;
    const variables = {
      after,
      sortBy: (sortBy.id as unknown as MediaSortByEnum) || MediaSortByEnum.DescSessionUploadDate,
      learnerIds: filterSelectedClients.map((item) => item.id),
      providerIds: filterSelectedProviders.map((item) => item.id),
      sessionStartDate: filterSessionDates?.startDate ? format(filterSessionDates.startDate, "yyyy-MM-dd") : undefined,
      sessionEndDate: filterSessionDates?.endDate ? format(filterSessionDates.endDate, "yyyy-MM-dd") : undefined,
      sessionRating: parseInt(filterSelectedRating?.id || "") || undefined,
      isReviewed: filterIsReviewed,
      sessionStatus: finalUploadStatus.length
        ? (finalUploadStatus as unknown as TherapySessionStatusEnums[])
        : undefined,
    };

    const token = await getAccessTokenSilently();
    const authHeaders: HeadersInit = new Headers();
    authHeaders.append("Authorization", `Bearer ${token}`);
    const headers = convertHeadersToObject(authHeaders);
    const response = await FronteraGraphQLClient.request(GetMediaByProviderDocument, variables, headers);
    return response.getMediaByProvider;
  };

  const getMediaByProviderQuery = useInfiniteQuery({
    queryKey: [
      "getMediaByProvider",
      filterSelectedClients,
      filterSelectedProviders,
      filterSessionDates?.startDate && filterSessionDates?.endDate,
      filterSelectedRating,
      filterIsReviewed,
      finalUploadStatus,
      sortBy,
    ],
    queryFn: fetchMediaByProvider,
    initialPageParam: { after: null },
    getNextPageParam: (lastPage) => {
      if (lastPage.pageInfo.hasNextPage) {
        return {
          after: lastPage.pageInfo.endCursor,
        };
      }
      return undefined; // No more pages to fetch
    },
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const {
    data: allMediaData,
    isFetchingNextPage: isMediaNextPageFetching,
    isFetching: isMediaFetching,
    hasNextPage: isMediaHasNextPage,
    fetchNextPage: fetchMediaNextPage,
    isError,
  } = getMediaByProviderQuery;

  useEffect(() => {
    if (isMediaFetching && !isMediaNextPageFetching) {
      setIsMediaLoading(true);
    } else {
      setIsMediaLoading(false);
    }
  }, [isMediaFetching]);

  useEffect(() => {
    if (isMediaHasNextPage && !isMediaNextPageFetching) {
      fetchMediaNextPage();
    }
  }, [isMediaHasNextPage, isMediaNextPageFetching, fetchMediaNextPage]);

  useEffect(() => {
    if (allMediaData) {
      const dataTemp = allMediaData.pages
        .map((group) => {
          return [...group.edges];
        })
        .flat();
      const displayedMedia = dataTemp.map((item) => item?.node as MediaCardType).filter(Boolean) ?? [];
      setAllFilteredMedias(displayedMedia);
    }
  }, [allMediaData]);

  if (isMediaLoading && !isMediaNextPageFetching) {
    return <FeedSkeletonLoader />;
  }

  if (isError) {
    return <div>Error loading media</div>;
  }

  return (
    <div className="grid gap-4">
      <div
        className={`grid ${allFilteredMedias.length ? "mobile:grid-cols-2 tablet:grid-cols-2 web:grid-cols-3 items-center" : "grid-cols-1"} gap-4`}
      >
        {allFilteredMedias.length ? (
          allFilteredMedias.map((media) => <FeedMediaCard media={media} key={media.id} />)
        ) : (
          <div className="flex flex-col pt-[80px] items-center justify-center">
            <div className="flex flex-col gap-3 items-center justify-center max-w-[412px]">
              <Heading type="h2">No video recordings yet!</Heading>
              {isUserRBT ? (
                <Paragraph>
                  Once you record and fully upload a session from your video recording tablet, it will appear here.
                  Check back after you’ve recorded and uploaded your first video!
                </Paragraph>
              ) : (
                <Paragraph>
                  {" "}
                  It looks like no videos have been uploaded yet. Some recordings may still be in progress, but once
                  your RBT completes an upload, their video sessions will appear here.
                </Paragraph>
              )}
            </div>
          </div>
        )}
      </div>
      {isMediaNextPageFetching ? <FeedSkeletonLoader /> : null}
    </div>
  );
};
