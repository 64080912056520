import { isNil } from "lodash";
import React, { useEffect, useState } from "react";

import { Dialog, SelectItem } from "@fronterahealth/frontera-ui-components";

import { CaregiverInput, useUpdateLearnerCaregiverDetailsMutation } from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import {
  NEAREST_LOCATIONS_OPTIONS,
  REFERRAL_SOURCE_OPTIONS,
  SMSAgreementItem,
} from "@pages/NewClient/NewClientOnboarding/NewClientOnboarding.interfaces";
import { useAdminData } from "@providers/AdminDataProvider";
import { useClientData } from "@providers/ClientProvider";

export type caregiverListType = CaregiverInput & {
  intakeInvite?: string | null;
  invitationUrl?: string | null;
  auth0Id?: string | null;
};

interface AddUpdateCaregiverDialogueProps {
  setDialogOpen: (open: boolean) => void;
  isDialogOpen: boolean;
  onAddCaregiver?: (caregiver: CaregiverInput) => void;
  selectedCaregiver?: caregiverListType | null;
  clientId?: string;
}

type AdditionalProperty = {
  isExistingCaregiver: string;
};
export const AddUpdateCaregiverDialogue: React.FC<AddUpdateCaregiverDialogueProps> = ({
  isDialogOpen,
  setDialogOpen,
  onAddCaregiver,
  selectedCaregiver,
  clientId,
}) => {
  const { isOnboardingEnabled } = useAdminData();
  const { caregivers, isCaregiverLoading } = useClientData();
  const [caregiver, setCaregiver] = useState<CaregiverInput | null>(null);

  const updateLearnerCaregiverDetailsMutation = useUpdateLearnerCaregiverDetailsMutation({});

  const {
    formState,
    onSubmit,
    reset,
    setValue,
    unregister,
    watch,
    RegisteredFormSelected,
    RegisteredFormInput,
    RegisteredPhoneNumberInput,
    RegisteredCheckboxList,
    RegisteredFormRadioInput,
  } = useFormUtils<CaregiverInput & AdditionalProperty>({
    mutationFn: async (params) => {
      const { isExistingCaregiver: _, ...newParams } = params;

      if (onAddCaregiver) {
        onAddCaregiver(newParams as unknown as CaregiverInput);
      }
      if (clientId) {
        await updateLearnerCaregiverDetailsMutation.mutateAsync({
          caregiverDetails: {
            ...newParams,
            smsAgreement: newParams.smsAgreement === ("yes" as unknown as boolean),
          },
          learner: clientId ? clientId : "",
        });
      }
      setDialogOpen(false);
    },
    defaultValues: {
      isExistingCaregiver: "no",
    },
  });

  useEffect(() => {
    if (selectedCaregiver) {
      if (selectedCaregiver.caregiverId) {
        setCaregiver(selectedCaregiver);
      }
    }
  }, [selectedCaregiver]);

  const isExistingCaregiver = watch("isExistingCaregiver");
  const selectedCaregiverID = isExistingCaregiver === "yes" ? watch("caregiverId") : null;
  useEffect(() => {
    if (selectedCaregiverID) {
      const availableCaregiver = caregivers.find((caregiver) => caregiver.id === selectedCaregiverID);
      if (availableCaregiver) {
        setCaregiver({
          caregiverId: availableCaregiver?.id,
          firstName: availableCaregiver.caregivermetadata?.firstName,
          lastName: availableCaregiver.caregivermetadata?.lastName,
          email: availableCaregiver.caregivermetadata?.email,
          nearestLocation: availableCaregiver.caregivermetadata?.nearestLocation,
          phoneNumber1: availableCaregiver.caregivermetadata?.phoneNumber1,
          referralSource: availableCaregiver.caregivermetadata?.referralSource,
          smsAgreement: availableCaregiver.caregivermetadata?.smsAgreement ? "yes" : "no",
        } as unknown as CaregiverInput);
      }
    }
    if (!selectedCaregiverID) {
      unregister("caregiverId", { keepDefaultValue: true });
    }
  }, [selectedCaregiverID, unregister, reset]);
  useEffect(() => {
    if (caregiver) {
      setValue("caregiverId", caregiver.caregiverId);
      setValue("firstName", caregiver.firstName || "");
      setValue("lastName", caregiver.lastName || "");
      setValue("email", caregiver.email || "");
      setValue("nearestLocation", caregiver.nearestLocation || "");
      setValue("phoneNumber1", caregiver.phoneNumber1 || "");
      setValue("referralSource", caregiver.referralSource || "");
      setValue("smsAgreement", caregiver.smsAgreement);
    }
  }, [caregiver]);

  const disableFields = isExistingCaregiver === "yes" && !selectedCaregiver;
  return (
    <FormContainer onSubmit={onSubmit}>
      <Dialog
        title={selectedCaregiver ? "View or Edit Caregiver Info" : "Add a caregiver"}
        open={isDialogOpen}
        setOpen={setDialogOpen}
        primaryButton={{
          text: selectedCaregiver ? "Update" : "Add",
          onClick: onSubmit,
        }}
        secondaryButton={{ text: "Cancel", onClick: () => setDialogOpen(false) }}
        size="xl"
        overflowBehavior="scroll"
      >
        <div className="col-span-3 p-4 flex flex-col">
          {!selectedCaregiver ? (
            <DoubleColumnContainer>
              <RegisteredFormRadioInput
                formState={formState}
                formKey="isExistingCaregiver"
                title="Existing Caregiver?"
                items={[
                  {
                    id: "no",
                    title: "No",
                  },
                  {
                    id: "yes",
                    title: "Yes",
                  },
                ]}
              />
            </DoubleColumnContainer>
          ) : null}
          {isExistingCaregiver === "yes" && (
            <RegisteredFormSelected
              formKey="caregiverId"
              formState={formState}
              placeholderText={""}
              title="Select Existing Caregiver"
              isLoading={isCaregiverLoading}
              items={
                caregivers
                  .sort((a, b) => (a.caregivermetadata?.firstName > b.caregivermetadata?.firstName ? 1 : -1))
                  .map((o) => ({
                    primary: `${o?.caregivermetadata?.firstName} ${o?.caregivermetadata?.lastName}`,
                    secondary: o.caregivermetadata?.email,
                    id: o?.id,
                  })) as unknown as SelectItem[]
              }
            />
          )}
          <div className="gap-x-4  lg:grid lg:grid-cols-2">
            <RegisteredFormInput
              formKey="firstName"
              inputSize="full"
              formState={formState}
              label="First Name"
              disabled={disableFields}
              readOnly={!isNil(selectedCaregiverID)}
            />
            <RegisteredFormInput
              formKey="lastName"
              inputSize="full"
              formState={formState}
              label="Last Name"
              disabled={disableFields}
              readOnly={!isNil(selectedCaregiverID)}
            />
          </div>

          <RegisteredFormInput
            formKey="email"
            inputSize="full"
            formState={formState}
            label="Email Address"
            readOnly={!isNil(selectedCaregiverID)}
            disabled={disableFields || !isNil(selectedCaregiver?.intakeInvite)}
          />
          {isOnboardingEnabled ? (
            <>
              <RegisteredPhoneNumberInput
                formKey="phoneNumber1"
                formState={formState}
                defaultCountry="US"
                label="Phone Number"
                readOnly={!isNil(selectedCaregiverID)}
                required={false}
                disabled={disableFields}
              />

              <RegisteredFormSelected
                formKey="nearestLocation"
                formState={formState}
                placeholderText={""}
                title="Nearest Location"
                errorMessage={formState.errors.nearestLocation?.message}
                items={NEAREST_LOCATIONS_OPTIONS.map((o) => ({ primary: o }))}
                readOnly={!isNil(selectedCaregiverID)}
                disabled={disableFields}
                required={false}
              />

              <RegisteredFormSelected
                formKey="referralSource"
                formState={formState}
                placeholderText={""}
                required={false}
                title="Referral Source"
                items={REFERRAL_SOURCE_OPTIONS.map((o) => ({ primary: o }))}
                readOnly={!isNil(selectedCaregiverID)}
                disabled={disableFields}
              />

              <RegisteredCheckboxList
                formKey="smsAgreement"
                formState={formState}
                required={false}
                title="SMS Agreement"
                items={SMSAgreementItem}
                readOnly={!isNil(selectedCaregiverID)}
                disabled={disableFields}
              />
            </>
          ) : null}
        </div>
      </Dialog>
    </FormContainer>
  );
};
