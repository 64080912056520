import { GenderApiValues } from "@utils/constants";
import { getGenderValue, setGenderValue } from "@utils/helpers";
import { formatDateYYYY_MM_DD } from "@utils/utils";
import { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Heading, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  CreateLearnerCargiverWithMinimalInfoMutationVariables,
  useCreateLearnerCargiverWithMinimalInfoMutation,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import {
  AssessmentReportSubRoute,
  useOrderedAssessmentReportSubRoutes,
} from "@pages/AssessmentReportDetails/AssessmentReportDetails";
import { FooterButtonRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/FooterButtonRow";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

interface RowProps extends PropsWithChildren {
  title: string;
  isLast?: boolean;
}
export const Row: React.FC<RowProps> = ({ title, children }) => {
  return (
    <div className="my-8 flex w-full flex-col items-end">
      <div className="grid w-full grid-cols-8 items-start">
        <div className="col-span-2 flex flex-col">
          <Heading type="h4">{title}</Heading>
        </div>
        <div className="col-span-6 flex flex-col ">{children}</div>
      </div>
    </div>
  );
};

export const ClientDetails: React.FC = () => {
  const { assessmentReport, refetch } = useAssessmentBuilderData();
  const learnerId = assessmentReport?.learner?.id || null;
  const go = useNavigate();
  const { pathname } = useLocation();

  const createLearnerCargiverWithMinimalInfoMutation = useCreateLearnerCargiverWithMinimalInfoMutation({});

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as AssessmentReportSubRoute) ||
    ("upload-files" as AssessmentReportSubRoute);
  const { getNextRoute } = useOrderedAssessmentReportSubRoutes();

  const nextRoute = getNextRoute(currentRoute);
  const { isPending } = createLearnerCargiverWithMinimalInfoMutation;

  const { onSubmit, formState, RegisteredFormInput, RegisteredFormSelected, reset, watch } =
    useFormUtils<CreateLearnerCargiverWithMinimalInfoMutationVariables>({
      mutationFn: async (params) => {
        await createLearnerCargiverWithMinimalInfoMutation.mutateAsync(
          {
            assessmentId: assessmentReport.id,
            learnerCaregiverMinimalInput: {
              ...params.learnerCaregiverMinimalInput,
              gender: setGenderValue(params.learnerCaregiverMinimalInput.gender),
            },
          },
          {
            onSuccess: async () => {
              await refetch(); // refetch assessmentBuilderProvide data
              notifySuccess("Successfully Created Client");
              go(`../${nextRoute}`);
            },
            onError: async (error) => {
              console.error("Error creating Client> ", error);
              notifyError("Error Creating Client");
            },
          },
        );
      },
    });

  useEffect(() => {
    if (assessmentReport) {
      const formattedGender = getGenderValue(assessmentReport?.assessmentClientDetails?.gender) || "";
      reset({
        learnerCaregiverMinimalInput: {
          clientFirstName: assessmentReport?.assessmentClientDetails?.clientFirstName,
          clientLastName: assessmentReport?.assessmentClientDetails?.clientLastName || "",
          birthDate: assessmentReport?.assessmentClientDetails?.birthDate,
          address: assessmentReport?.assessmentClientDetails?.address || "",
          addressLine2: assessmentReport?.assessmentClientDetails?.addressLine2,
          gender: formattedGender,
          specifyGender: assessmentReport?.assessmentClientDetails?.specifyGender || "",
          city: assessmentReport?.assessmentClientDetails?.city,
          state: assessmentReport?.assessmentClientDetails?.state,
          zip: assessmentReport?.assessmentClientDetails?.zip,
          caregiverFirstName: assessmentReport?.assessmentClientDetails?.guardianFirstName,
          caregiverLastName: assessmentReport?.assessmentClientDetails?.guardianLastName || "",
          caregiver2FirstName: assessmentReport?.assessmentClientDetails?.guardian2FirstName || "",
          caregiver2LastName: assessmentReport?.assessmentClientDetails?.guardian2LastName || "",
        },
      });
    } else {
      reset({ learnerCaregiverMinimalInput: {} });
    }
  }, [assessmentReport]);

  const genderWatch = watch("learnerCaregiverMinimalInput.gender");

  const isReadOnly = learnerId ? true : false;

  return (
    <div className="flex w-full flex-col ">
      <FormContainer onSubmit={onSubmit}>
        <Row title="Client Details">
          <div className="col-span-3 flex flex-col">
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.clientFirstName"
                inputSize="full"
                formState={formState}
                label="Client’s First Name"
                readOnly={isReadOnly}
              />
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.clientLastName"
                inputSize="full"
                formState={formState}
                label="Client's Last Name"
                readOnly={isReadOnly}
              />
            </div>
          </div>
          <div className="gap-x-4 lg:grid lg:grid-cols-2">
            <RegisteredFormInput
              formKey="learnerCaregiverMinimalInput.birthDate"
              inputSize="full"
              max={formatDateYYYY_MM_DD(new Date())}
              type="date"
              formState={formState}
              label="Date of Birth"
              readOnly={isReadOnly}
            />
            <div />
          </div>
          {isReadOnly ? (
            <DoubleColumnContainer>
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.gender"
                formState={formState}
                label="Gender"
                readOnly
                inputSize="full"
              />
              {genderWatch === "Gender identity is not listed" && (
                <RegisteredFormInput
                  formKey="learnerCaregiverMinimalInput.specifyGender"
                  formState={formState}
                  required={false}
                  label="Specify Gender"
                  readOnly
                  inputSize="full"
                />
              )}
            </DoubleColumnContainer>
          ) : (
            <DoubleColumnContainer>
              <RegisteredFormSelected
                formKey="learnerCaregiverMinimalInput.gender"
                formState={formState}
                placeholderText={""}
                title="Gender"
                readOnly={isReadOnly}
                items={
                  Object.values(GenderApiValues).map((o) => ({
                    primary: getGenderValue(o),
                  })) as SelectItem[]
                }
              />
              {genderWatch === "Gender identity is not listed" && (
                <RegisteredFormInput
                  formKey="learnerCaregiverMinimalInput.specifyGender"
                  formState={formState}
                  required={false}
                  label="Specify Gender"
                  readOnly={isReadOnly}
                />
              )}
            </DoubleColumnContainer>
          )}

          <div className="col-span-3 flex flex-col">
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.address"
                inputSize="full"
                required={false}
                formState={formState}
                label="Address Line 1"
                readOnly={isReadOnly}
              />
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.addressLine2"
                inputSize="full"
                required={false}
                formState={formState}
                label="Address Line 2"
                readOnly={isReadOnly}
              />
            </div>
          </div>
          <div className="col-span-3 flex flex-col">
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.city"
                inputSize="full"
                required={false}
                formState={formState}
                label="City"
                readOnly={isReadOnly}
              />

              <div className="gap-x-4  lg:grid lg:grid-cols-2">
                <RegisteredFormInput
                  formKey="learnerCaregiverMinimalInput.state"
                  inputSize="full"
                  required={false}
                  formState={formState}
                  label="State / Province"
                  readOnly={isReadOnly}
                />
                <RegisteredFormInput
                  formKey="learnerCaregiverMinimalInput.zip"
                  inputSize="full"
                  required={false}
                  formState={formState}
                  label="ZIP / Postal Code"
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>

          <div className="col-span-3 flex flex-col">
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.caregiverFirstName"
                inputSize="full"
                formState={formState}
                label="Guardian’s First Name"
                readOnly={isReadOnly}
              />
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.caregiverLastName"
                inputSize="full"
                formState={formState}
                label="Guardian’s Last Name"
                readOnly={isReadOnly}
              />
            </div>
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.caregiver2FirstName"
                inputSize="full"
                formState={formState}
                label="Second Guardian’s First Name"
                required={false}
                readOnly={isReadOnly}
              />
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.caregiver2LastName"
                inputSize="full"
                formState={formState}
                label="Second Guardian’s Last Name"
                required={false}
                readOnly={isReadOnly}
              />
            </div>
          </div>
        </Row>
        {learnerId ? (
          <FooterButtonRow primaryButtonTitle="Continue" />
        ) : (
          <FooterButtonRow primaryButtonTitle="Continue" primaryButtonType="submit" primaryButtonLoading={isPending} />
        )}
      </FormContainer>
    </div>
  );
};
