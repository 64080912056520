import { ArrowDownIcon, ArrowUpIcon, SparklesIcon, TrashIcon } from "@heroicons/react/24/outline";
import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PropsWithChildren, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ApiOrganizationAbExportTemplateNameChoices,
  ApiReportSectionsAiGeneratedStatusChoices,
  ReportSectionsType,
  useCreateReportSectionFieldMutation,
  useDeleteReportSectionMutation,
  useMoveReportSectionUpAndDownMutation,
  useStartSingleReportTemplateSectionWorkflowMutation,
  useStartSingleSectionWorkflowMutation,
  useUpdateReportSectionMutation,
} from "@api/graphql/types-and-hooks";
import { AddContentMenu } from "@components/SectionWidget/AddContentMenuV2";
import {
  ContentTypeAndBespokeType,
  SectionWidget,
  SupportedContentType,
} from "@components/SectionWidget/SectionWidgetV2";
import { notifyError } from "@components/notifications/notifications";
import { FieldComponentWrapper } from "@pages/AssessmentReportV2Details/Components/FieldComponentWrapper";
import { getNewFieldRankOrder, getSortedFieldsByRankOrder } from "@pages/EvaluationDetails/Components/helper";
import { useAdminData } from "@providers/AdminDataProvider";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

interface SectionWidgetWrapperProps extends PropsWithChildren {
  sectionType?: "recommendation" | "regular";
  section: ReportSectionsType;
  supportedContentTypes: SupportedContentType[];
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
  allowInLineEdit?: boolean;
  showErrorWarning?: boolean;
}

export const SectionWidgetWrapper: React.FC<SectionWidgetWrapperProps> = ({
  sectionType = "regular",
  section,
  permissions,
  supportedContentTypes,
  allowInLineEdit = false,
  showErrorWarning = false,
  children,
}) => {
  const { assessmentId } = useParams();
  const { assessmentQuery } = useAssessmentBuilderData();
  const updateSection = useUpdateReportSectionMutation({});
  const deleteSection = useDeleteReportSectionMutation({});
  const addSectionField = useCreateReportSectionFieldMutation({});
  const moveReportSectionUpAndDown = useMoveReportSectionUpAndDownMutation({});
  const [isInLineEditMode, setIsInLineEditMode] = useState<boolean>(false);
  const { orgnizationDetailsQuery } = useAdminData();
  const flags = useFlags<FeatureFlags>();

  const isCustomTemplateUser =
    orgnizationDetailsQuery.data?.getUserOrgnizationDetails?.abExportTemplateName !=
      ApiOrganizationAbExportTemplateNameChoices.AssessmentReportDocxTemplateDocx || false;

  const startSingleSectionWorkflow = useStartSingleSectionWorkflowMutation({});
  const startSingleReportTemplateSectionWorkflow = useStartSingleReportTemplateSectionWorkflowMutation({});

  const sectionStillGenerating =
    section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending;

  const sectionErrored = section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Failure;

  const onEditSection = async (newHeading: string) => {
    await updateSection
      .mutateAsync({
        reportSectionId: section.id ?? "<missing-section-id>",
        title: newHeading,
      })
      .then((data) => {
        if (data.updateReportSection?.status) {
          assessmentQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while updating section title : ", error);
        notifyError("Error Updating Section Title");
      });
  };

  const onDeleteSection = async () => {
    await deleteSection
      .mutateAsync({
        reportId: assessmentId ?? "<missing-evaluation-id>",
        reportSectionId: section.id ?? "<missing-section-id>",
      })
      .then((data) => {
        if (data.deleteReportSection?.status) {
          assessmentQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while deleting section : ", error);
        notifyError("Error Deleting Section");
      });
  };

  const onAddFieldComp = async (contentTypeAndBespokeType: ContentTypeAndBespokeType, newRankOrder: number) => {
    await addSectionField
      .mutateAsync({
        reportId: assessmentId ?? "<missing-evaluation-id>",
        reportSectionId: section.id ?? "<missing-section-id>",
        fieldData: {
          title: "Enter Title",
          rankOrder: newRankOrder ?? getNewFieldRankOrder(section),
          fieldType: contentTypeAndBespokeType.contentType,
          bespokeType: contentTypeAndBespokeType.bespokeType,
        },
      })
      .then((data) => {
        if (data?.createReportSectionField?.status) {
          assessmentQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while adding section field : ", error);
        notifyError("Error Adding Section Field");
      });
  };

  const handleMoveSectionUpAndDown = async (moveUp: boolean) => {
    // moveUp = True to move up and False to move down
    await moveReportSectionUpAndDown
      .mutateAsync({
        reportId: assessmentId ?? "<missing-evaluation-id>",
        sectionId: section.id ?? "<missing-section-id>",
        moveUp: moveUp,
        pageLocation: section.pageLocation,
      })
      .then((data) => {
        if (data?.moveReportSectionUpAndDown?.status) {
          assessmentQuery?.refetch();
        } else if (data.moveReportSectionUpAndDown?.status === false) {
          notifyError(data.moveReportSectionUpAndDown?.message ?? "Error moving section in the specified direction");
        }
      })
      .catch((error) => {
        console.error("Error moving section : ", error);
        notifyError("Error Moving Section");
      });
  };

  const handleSetIsInLineEditMode = async (value: boolean) => {
    if (!value) await assessmentQuery?.refetch(); // A refetch is needed to ensure we retrieve the updated WYSIWYG content since we're not triggering a refetch for WYSIWYG content updates.
    setIsInLineEditMode(value);
  };

  const actionButtonMenu = !isCustomTemplateUser
    ? [
        {
          label: "Move Up",
          Icon: ArrowUpIcon,
          callback: () => handleMoveSectionUpAndDown(true),
        },
        {
          label: "Move Down",
          Icon: ArrowDownIcon,
          callback: () => handleMoveSectionUpAndDown(false),
        },
        {
          label: "Delete",
          Icon: TrashIcon,
          textColorClassName: "text-red-600",
          callback: onDeleteSection,
        },
      ]
    : [];

  // NOTE: sectionIdentifier only is populated for our AI fields to map them back to the original name
  // we use this to determine which sections are AI generate (and thus get the regenerate option)
  // and which are user-created
  if (section.sectionIdentifier) {
    actionButtonMenu.unshift({
      label: "Regenerate",
      Icon: SparklesIcon,
      callback: async () => {
        flags["db-diagnosis-builder-report-template"]
          ? await startSingleReportTemplateSectionWorkflow.mutateAsync({ sectionId: section.id })
          : await startSingleSectionWorkflow.mutateAsync({ sectionId: section.id });
        await assessmentQuery.refetch();
      },
    });
  }

  const fields = getSortedFieldsByRankOrder(section);

  const isLoading = sectionStillGenerating || startSingleSectionWorkflow.isPending;

  return (
    <SectionWidget
      sectionType={sectionType}
      sectionRankOrder={section.rankOrder}
      key={section.id}
      id={section.id}
      onAddContent={onAddFieldComp}
      onEditSection={onEditSection}
      permissions={permissions}
      sectionData={{ heading: section.title, icon: "document" }}
      supportedContentTypes={supportedContentTypes}
      placeholder="Enter section title"
      allowInLineEdit={allowInLineEdit}
      setIsInLineEditMode={handleSetIsInLineEditMode}
      isInLineEditMode={isInLineEditMode}
      actionButtonMenu={actionButtonMenu}
      isLoading={isLoading}
      isError={sectionErrored}
      hasFields={fields.length > 0}
      showErrorWarning={showErrorWarning}
      onDeleteCallback={onDeleteSection}
      onRegenerateCallback={async () => {
        flags["db-diagnosis-builder-report-template"]
          ? await startSingleReportTemplateSectionWorkflow.mutateAsync({ sectionId: section.id })
          : await startSingleSectionWorkflow.mutateAsync({ sectionId: section.id });
        await assessmentQuery.refetch();
      }}
    >
      {fields.map((field) => (
        <div onMouseOver={(e) => e.stopPropagation()} key={`${field?.id}-${isInLineEditMode}`}>
          <FieldComponentWrapper
            section={section}
            field={field ?? undefined}
            permissions={{
              canEdit: isInLineEditMode ? isInLineEditMode : permissions.canEdit,
              canDelete: isInLineEditMode ? isInLineEditMode : permissions.canDelete,
            }}
          />
          {/* Add Blocks */}
          {permissions.canEdit && !sectionStillGenerating ? (
            <AddContentMenu
              className="mt-10 -mb-2"
              sectionId={section.id}
              sectionType={sectionType}
              onAddContent={onAddFieldComp}
              newRankOrder={field.rankOrder + 1}
              supportedContentTypes={supportedContentTypes}
            />
          ) : (
            <></>
          )}
        </div>
      ))}
      {children}
    </SectionWidget>
  );
};
