import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { pathNameToBreadCrumbLinks } from "@utils/helpers";
import { formatDate } from "@utils/utils";
import { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import { Breadcrumb, Button, Heading, Small } from "@fronterahealth/frontera-ui-components";

import { ApiOnboardingFormStatusStatusChoices, LearnersQuery } from "@api/graphql/types-and-hooks";
import { NotAllowedPage } from "@components/Authorize/Authorize";
import { ClientTable, FronteraClient } from "@components/ClientTable/ClientTable";
import { useAdminData } from "@providers/AdminDataProvider";
import { CreateClientDialog } from "@routes/ClientDashboard/CreateClientDialog";
import { numberOfOnboardingSectionsCompleted } from "@routes/ClientDashboard/helpers";

const learnersToFronteraClient = (learners: LearnersQuery["learner"]): (FronteraClient | null)[] => {
  return (
    learners?.map((learner) => {
      const intakePages = learner.formStatusForLearner.edges.map((edge) => edge?.node);
      const intakeFinished = intakePages.find(
        (node) => node?.status === ApiOnboardingFormStatusStatusChoices.Completed && node.formName === "submit",
      );

      const intakeInProgress =
        intakePages.filter((page) => page?.status === ApiOnboardingFormStatusStatusChoices.Completed).length > 0;

      const intakeStarted = !!learner?.relationshipSet?.edges?.[0]?.node?.caregiver.auth0User?.auth0id;

      const intakePagesCompleted = numberOfOnboardingSectionsCompleted(intakePages || []);

      if (!learner.learnermetadata) return null;
      const { createdDate, id } = learner;
      const { firstName, lastName, diagnosisAppointmentDate } = learner.learnermetadata!;
      const { firstName: caregiverFirstName, lastName: caregiverLastName } = learner.relationshipSet.edges[0]?.node
        ?.caregiver.caregivermetadata || { firstName: "N/A", lastName: "" };
      const nearestLocation =
        learner.relationshipSet.edges[0]?.node?.caregiver.caregivermetadata?.nearestLocation || "";
      const base64DecodedLearnedId = atob(learner.id);
      const databaseId = base64DecodedLearnedId.split("LearnerType:")?.pop();
      const fronteraClient: FronteraClient = {
        id: id,
        clientId: databaseId || "<missing-database-id>",
        name: `${firstName} ${lastName}`,
        caregiver: `${caregiverFirstName} ${caregiverLastName}`,
        created: new Date(createdDate),
        intake: intakeFinished ? "Complete" : intakeInProgress ? "In Progress" : intakeStarted ? "Started" : "Awaiting",
        intakePagesCompleted: intakePagesCompleted,
        nextAppointment: diagnosisAppointmentDate ? new Date(diagnosisAppointmentDate) : new Date(), // weather diagnoses or assessment else null
        stage: diagnosisAppointmentDate ? "Diagnosis Pending" : "Assessment Pending",
        nearestLocation,
        dob: formatDate(learner.learnermetadata.birthDate),
      };
      return fronteraClient;
    }) || []
  );
};

export const ClientDashboard: React.FC = () => {
  const { pathname } = useLocation();
  const go = useNavigate();
  const [isNewClientDialogOpen, setIsNewClientDialogOpen] = useState<boolean>(false);
  const breadcrumbLinks = pathNameToBreadCrumbLinks(pathname);

  const { learnersQuery, isOnboardingEnabled } = useAdminData();

  const { data, isFetching, refetch } = learnersQuery;

  const clients = learnersToFronteraClient(data?.learner);

  if (!isOnboardingEnabled) {
    return <NotAllowedPage />;
  }

  return (
    <div className="mx-auto flex w-full  flex-col content-between items-center lg:flex-col lg:items-start">
      <Breadcrumb
        links={breadcrumbLinks}
        homeLink={{
          name: "Home",
          Component: <Link to={`/clients`}>Home</Link>,
          current: false,
          type: "component",
        }}
      />
      <div className="mt-4 flex w-full items-center justify-between">
        {pathname === "/clients" ? (
          <Heading className="" type="h1">
            Clients
          </Heading>
        ) : null}
        {pathname === "/clients" ? (
          <div className="flex items-center">
            <Button
              text="New Client"
              appearance="primary"
              onClick={() => {
                if (isOnboardingEnabled) {
                  setIsNewClientDialogOpen(true);
                } else {
                  go("/clients/client-lite");
                }
              }}
            />

            <Button
              text={
                <div className="flex items-center">
                  <Small>Update</Small>
                  <ArrowPathIcon className={`ml-1 h-4 w-4 text-text-secondary ${isFetching ? "animate-spin" : ""}`} />
                </div>
              }
              appearance="link"
              onClick={() => {
                refetch();
              }}
            />
          </div>
        ) : null}
      </div>
      {pathname === "/clients" ? (
        <ClientTable
          loading={isFetching}
          clients={clients}
          onRowClick={(client) =>
            go(`/clients/${client.id}`, {
              state: {
                id: client.id,
                name: client.name,
                intake: client.intake,
                stage: client.stage,
              },
            })
          }
        />
      ) : (
        <Outlet />
      )}
      {isNewClientDialogOpen ? (
        <CreateClientDialog isDialogOpen={isNewClientDialogOpen} setDialogOpen={setIsNewClientDialogOpen} />
      ) : null}
    </div>
  );
};
