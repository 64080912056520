import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo, useState } from "react";

import {
  ReportType,
  useAddOrUpdateReportSignatureSectionDetailMutation,
  useCreateReportFromTemplateMutation,
  useUpdateReportStatusAndUploadReportFileMutation,
} from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAdminData } from "@providers/AdminDataProvider";

interface UseSignatureHandlerProps {
  report: ReportType;
  isCompleted: boolean;
  refetchData: () => Promise<void> | unknown;
}

export const useSignatureHandler = ({ report, isCompleted, refetchData }: UseSignatureHandlerProps) => {
  const [signature, setSignature] = useState<string>("");
  const [closingRemark, setClosingRemark] = useState<string>("");
  const [professionalTitle, setProfessionalTitle] = useState<string>("");
  const [licenseNumber, setLicenseNumber] = useState<string>("");
  const [timestamp, setTimestamp] = useState<Date>(new Date());
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const { loginProviderDetailsQuery } = useAdminData();
  const flags = useFlags();
  const providerMetadata = loginProviderDetailsQuery.data?.getLoginProvider?.providermetadata;

  const addOrUpdateSignatureMutation = useAddOrUpdateReportSignatureSectionDetailMutation({});
  const updateStatusAndUploadMutation = useUpdateReportStatusAndUploadReportFileMutation({});
  const createFromTemplateMutation = useCreateReportFromTemplateMutation({});

  useEffect(() => {
    if (!report) return;

    setClosingRemark(report.signatureSectionDetail?.closingRemark ?? "");

    if (isCompleted) {
      setSignature(report.signatureSectionDetail?.signedBy || "");
      setTimestamp(new Date(report.signatureSectionDetail?.signedAt) || new Date());
      setProfessionalTitle(report.signatureSectionDetail?.professionalTitle ?? "");
      setLicenseNumber(report.signatureSectionDetail?.licenseNumber ?? "");
    } else {
      setProfessionalTitle(
        report.signatureSectionDetail?.professionalTitle ?? providerMetadata?.professionalTitle ?? "",
      );
      setLicenseNumber(report.signatureSectionDetail?.licenseNumber ?? providerMetadata?.licenseNumber ?? "");
    }
  }, [report, isCompleted, providerMetadata]);

  const onSuccess = useMemo(() => {
    return async () => {
      setIsGenerating(false);
      await refetchData();
      notifySuccess("Successfully Submitted Report");
    };
  }, [refetchData]);

  const onError = useMemo(() => {
    return async (error: unknown) => {
      setIsGenerating(false);
      console.error("Error Saving Report", error);
      notifyError("Error Saving Report");
    };
  }, []);

  const handleSignReport = async (file: File | null) => {
    await addOrUpdateSignatureMutation
      .mutateAsync({
        input: {
          reportId: report?.id ?? "<missing-id>",
          signedBy: signature,
          signedAt: timestamp,
          closingRemark: closingRemark,
          licenseNumber: licenseNumber,
          professionalTitle: professionalTitle,
        },
      })
      .catch((error) => {
        console.error("Error saving signature: ", error);
        notifyError("Error saving signature");
      });

    await loginProviderDetailsQuery.refetch(); // refetch required here to pull the updated professionalTitle and licenseNumber
    if (flags["dynamic-report-output-diagnosis-builder"]) {
      return await createFromTemplateMutation.mutateAsync({ reportId: report?.id || "" }, { onSuccess, onError });
    }
    await updateStatusAndUploadMutation.mutateAsync(
      {
        reportId: report?.id || "",
        reportFile: file,
      },
      {
        onSuccess,
        onError,
      },
    );
  };

  return {
    signature,
    setSignature,
    closingRemark,
    setClosingRemark,
    professionalTitle,
    setProfessionalTitle,
    licenseNumber,
    setLicenseNumber,
    timestamp,
    setTimestamp,
    isGenerating,
    setIsGenerating,
    handleSignReport,
  };
};
