import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import {
  ReportType,
  ReportTypeEnums,
  useFilesForAssessmentQuery,
  useGetReportsQuery,
  useGetUserAccountDetailsQuery,
} from "@api/graphql/types-and-hooks";
import { AssessmentReport, AssessmentReportsTable } from "@components/AssessmentReportsTable/AssessmentReportsTableV2";
import { NewAssessmentDialog } from "@components/NewAssessmentDialog/NewAssessmentDialogV2";
import { WalkthroughDialog } from "@components/WalkthroughDialog/WalkthroughDialog";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import "@components/notifications/notifications.css";
import { useAdminData } from "@providers/AdminDataProvider";
import { getStatusAndRedirectRouteFromReport } from "@routes/AssessmentReportsV2/AssessmentReportsHelper";
import { CreditHeader } from "@routes/AssessmentReportsV2/CreditHeader";

const maxFiles = 200;

export const AssessmentReportsV2: React.FC = () => {
  const go = useNavigate();
  const { pathname } = useLocation();
  const { assessmentCreditsRemaining, isSelfServeUser } = useAdminData();

  const [tableData, setTableData] = useState<AssessmentReport[]>([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { assessmentId } = useParams();
  const { isLoading, isError, error, data: filesData } = useFilesForAssessmentQuery({ assessmentId });

  useEffect(() => {
    if (assessmentId && !isLoading && !isError && filesData) {
      const currentAssessment = filesData?.getAssessments?.edges[0]?.node;
      const numberOfFiles = currentAssessment?.files?.edges?.length || 0;
      if (numberOfFiles > maxFiles) {
        notifyError(`You have reached the maximum number of files of ${maxFiles} allowed for this assessment`);
      }
    } else if (isError) {
      console.error("Error fetching files for assessment", error);
    }
  }, [isLoading]);

  const accountDetails = useGetUserAccountDetailsQuery(
    {},
    {
      queryKey: ["get-account-details"],
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      staleTime: Infinity,
      enabled: isSelfServeUser,
    },
  );

  const { refetch: accountRefetch } = accountDetails;
  const { isLearnUser } = useAdminData();

  const getAssessmentsQuery = useGetReportsQuery(
    { records: 500, reportType: ReportTypeEnums.InitialAssessment },
    {
      queryKey: ["get-assessments"],
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: "always",
      staleTime: Infinity,
    },
  );

  const previousAbPath = useRef<string>("");

  useEffect(() => {
    const abPath = pathname.split("/assessment-reports-v2")?.pop() || "";
    if (abPath === "" && previousAbPath.current.length) {
      refetch();
      if (isSelfServeUser) {
        accountRefetch();
      }
    }
    previousAbPath.current = abPath;
  }, [pathname]);

  const { data, refetch, isFetching } = getAssessmentsQuery;
  useEffect(() => {
    if (data?.getReports?.edges) {
      const edges = data.getReports.edges;
      const mappedData = edges
        .map((edge) => {
          const node = edge?.node;
          if (!node) {
            return null;
          }
          const clientFirstName = node?.reportClientDetails?.clientFirstName || "N/A";
          const clientLastName = node?.reportClientDetails?.clientLastName || "";
          const clientName = `${clientFirstName} ${clientLastName}`;
          const { status, redirectRoute } = getStatusAndRedirectRouteFromReport(node as ReportType);

          const report: AssessmentReport = {
            id: node?.id,
            clientName,
            learnerId: node?.learner?.id,
            dateCreated: new Date(node?.createdDate),
            lastUpdated: new Date(node?.lastUpdatedDate),
            status,
            redirectRoute,
          };

          return report;
        })
        .filter(Boolean) as AssessmentReport[];

      setTableData(mappedData); // Set the table data
    }
  }, [data]);

  useEffect(() => {
    if (window.location.search.includes("redirect_status=succeeded")) {
      notifySuccess("Payment successful");
    } else if (window.location.search.includes("redirect_status=failed")) {
      notifyError("Payment failed");
    }
  }, []);

  return (
    <div className="mx-auto  w-full flex-col content-between items-center lg:flex-col lg:items-start">
      {pathname === "/assessment-reports-v2" ? (
        <CreditHeader
          isSelfServeUser={isSelfServeUser}
          setDialogOpen={setDialogOpen}
          credits={assessmentCreditsRemaining}
          refetch={refetch}
        />
      ) : null}
      {isLearnUser ? <div className="pendo-banner" /> : null}
      {pathname === "/assessment-reports-v2" ? (
        <AssessmentReportsTable
          reports={tableData}
          refetch={getAssessmentsQuery.refetch}
          loading={isFetching}
          onRowClick={(report) => {
            go(`${report.id}/${report.redirectRoute}`, {
              state: { ...report },
            });
          }}
        />
      ) : (
        <Outlet />
      )}
      <NewAssessmentDialog
        isSelfServeUser={isSelfServeUser}
        isDialogOpen={isDialogOpen}
        credits={assessmentCreditsRemaining}
        setDialogOpen={(open) => setDialogOpen(open)}
      />

      <WalkthroughDialog />
    </div>
  );
};
