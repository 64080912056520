import { QueryObserverResult } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AssessmentType, GetAssessmentQuery, useGetAssessmentQuery } from "@api/graphql/types-and-hooks";
import { AssessmentReportSubRoute } from "@pages/AssessmentReportDetails/AssessmentReportDetails";

interface AssessmentBuilderDataI {
  assessmentReport: AssessmentType;
  refetch: () => Promise<QueryObserverResult<GetAssessmentQuery, unknown>>;
}

export const AssessmentBuilderContext = createContext<AssessmentBuilderDataI | undefined>(undefined);

export const useAssessmentBuilderData = () => {
  const context = useContext(AssessmentBuilderContext);
  if (context === undefined) {
    throw new Error("AssessmentReportDetails must be wrapped with AssessmentBuilderProvider");
  }
  return context;
};

export const AssessmentBuilderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { assessmentId } = useParams();
  const go = useNavigate();
  const { pathname } = useLocation();

  const assessmentQuery = useGetAssessmentQuery(
    {
      assessmentId: assessmentId || "<missing-assessment-id>",
    },
    {
      queryKey: ["get-assessment", assessmentId],
    },
  );

  if (!assessmentQuery) return null;

  const { data, refetch, isLoading } = assessmentQuery;
  const assessmentReport = data?.getAssessments?.edges?.[0]?.node as AssessmentType;

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as AssessmentReportSubRoute) ||
    ("client-details" as AssessmentReportSubRoute);

  if (assessmentReport && !assessmentReport.learner && currentRoute !== "client-details") go(`client-details`);

  if (isLoading) return null;

  return (
    <AssessmentBuilderContext.Provider
      value={{
        assessmentReport,
        refetch,
      }}
    >
      {children}
    </AssessmentBuilderContext.Provider>
  );
};
