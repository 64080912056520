import { PropsWithChildren } from "react";

import { Heading, Small } from "@fronterahealth/frontera-ui-components";

interface ContentRowProps extends PropsWithChildren {
  title: string;
  isLast?: boolean;
  showTemplateDownloadLink?: boolean;
  subtitle?: string | React.ReactNode;
}
export const ContentRow: React.FC<ContentRowProps> = ({ title, subtitle, showTemplateDownloadLink, children }) => {
  return (
    <div className="flex w-full flex-col items-end">
      <div className="grid w-full grid-cols-8 items-start">
        <div className="col-span-3 flex flex-col">
          <Heading type="h4">{title}</Heading>
          {typeof subtitle === "string" ? (
            <Small className="max-w-[80%]">
              {subtitle}
              {showTemplateDownloadLink ? (
                <>
                  {" "}
                  For best results, use our scoring templates found{" "}
                  <span
                    onClick={() => window.open("/downloads", "_blank")}
                    className="bg-transparent text-interaction-primary cursor-pointer"
                  >
                    here.
                  </span>
                </>
              ) : (
                <></>
              )}
            </Small>
          ) : (
            subtitle
          )}
        </div>
        <div className="col-span-5 flex flex-col rounded-xl border border-limestone-100 bg-white p-6">{children}</div>
      </div>
    </div>
  );
};
