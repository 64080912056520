import { CheckboxItem, PRIORITIZE_PAGE_CONTENT } from "@utils/constants";
import { getIdList, matchOrCreateItems } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { MultiSelectItem, Paragraph } from "@fronterahealth/frontera-ui-components";
import { RadioInputItem } from "@fronterahealth/frontera-ui-components/RadioInput/RadioInput";

import {
  AiPredictionOfEnum,
  ApiAssessmentAiReport1PredictionStatusChoices,
  AssessmentPrioritizeFieldsInput,
  useAddUpdatePrioritizeFieldsMutation,
  useGetAssessmentQuery,
} from "@api/graphql/types-and-hooks";
import { ConfirmGenerationDialog } from "@components/ConfirmGenerationDialog/ConfirmGenerationDialog";
import { ContentRow } from "@components/ContentRow/ContentRow";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { transformQueryData } from "@components/forms/utils";
import { notifyError } from "@components/notifications/notifications";
import { FooterButtonRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/FooterButtonRow";
import { ReportNotReadyYet } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/components/ReportNotReadyYet";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

export const Prioritize: React.FC = () => {
  const { refetch, assessmentReport } = useAssessmentBuilderData();
  const { assessmentId } = useParams();
  const [promptDialogOpen, setPromptDialogOpen] = useState<boolean>(false);
  const { safetyConcern, communicationAbility, familyLife, engagementAbility, socialDesires } = PRIORITIZE_PAGE_CONTENT;

  const addUpdatePrioritizeMutation = useAddUpdatePrioritizeFieldsMutation({});

  const { data: assessmentQueryData } = useGetAssessmentQuery(
    {
      assessmentId: assessmentId || "<missing-assessment-id>",
    },
    {
      queryKey: ["get-assessment", assessmentId],
      refetchOnWindowFocus: false,
    },
  );

  const type = assessmentReport?.useLongTermGoals ? AiPredictionOfEnum.LongTermGoal : AiPredictionOfEnum.ShortTermGoal;
  const {
    onSubmit,
    RegisteredCheckboxList,
    formState,
    RegisteredFormRadioInput,
    RegisteredFormTextArea,
    RegisteredFormMultiSelect,
    reset,
  } = useFormUtils<AssessmentPrioritizeFieldsInput>({
    defaultValues: {
      safetyConcerns: [],
      engagementAbility: false,
      familyLife: [],
      communicationAbility: [],
      socialDesires: "",
    },
    mutationFn: async (params) => {
      const variable = {
        ...params,
        engagementAbility: params.engagementAbility === ("yes" as unknown as boolean),
      };

      await addUpdatePrioritizeMutation.mutateAsync(
        {
          assessmentId: assessmentId ? assessmentId : "",
          assessmentPrioritizeFieldsDetails: {
            ...variable,
            familyLife: variable.familyLife.length
              ? getIdList(variable.familyLife as unknown as MultiSelectItem[])
              : [],
            communicationAbility: variable.communicationAbility || [],
            safetyConcerns: variable.safetyConcerns.length
              ? getIdList(variable.safetyConcerns as unknown as MultiSelectItem[])
              : [],
          },
        },
        {
          onSuccess: async () => {
            await refetch(); // refetch assessmentBuilderProvide data
            setPromptDialogOpen(true);
          },
          onError: async (error) => {
            console.error("Error Saving Prioritize Page", error);
            notifyError("Error Saving Prioritize Page");
          },
        },
      );
    },
  });

  useEffect(() => {
    if (assessmentReport) {
      const selectedAssessmenmt = assessmentReport;

      if (selectedAssessmenmt) {
        const converted = transformQueryData(selectedAssessmenmt, {
          "assessmentPrioritizeFields.engagementAbility": (value) => (value === false || value === "no" ? "no" : "yes"),
        });

        if (converted.assessmentPrioritizeFields) {
          reset({
            ...converted.assessmentPrioritizeFields,
            safetyConcerns: converted.assessmentPrioritizeFields?.safetyConcerns
              ? matchOrCreateItems(
                  converted.assessmentPrioritizeFields.safetyConcerns as string[],
                  safetyConcern.optionItems as MultiSelectItem[],
                )
              : [],
            familyLife: converted.assessmentPrioritizeFields?.familyLife
              ? matchOrCreateItems(
                  converted.assessmentPrioritizeFields.familyLife as string[],
                  familyLife.optionItems as MultiSelectItem[],
                )
              : [],
          } as unknown as AssessmentPrioritizeFieldsInput);
        }
      }
    }
  }, [reset, assessmentReport]);

  const aiReportPartOneStatus = assessmentQueryData?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
    ?.aiReport1PredictionStatus;

  const reportNotYetKickedOff = aiReportPartOneStatus === ApiAssessmentAiReport1PredictionStatusChoices.Pending;

  if (reportNotYetKickedOff) {
    return <ReportNotReadyYet item="Prioritization" />;
  }

  return (
    <>
      <ConfirmGenerationDialog
        type={type}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      <FormContainer onSubmit={onSubmit}>
        <div className="flex flex-col w-full gap-y-4">
          <ContentRow title={safetyConcern.title} subtitle={safetyConcern.description}>
            <Paragraph>{safetyConcern.question}</Paragraph>
            <RegisteredFormMultiSelect
              required={false}
              mode="tags"
              title={""}
              items={safetyConcern.optionItems as MultiSelectItem[]}
              formKey={"safetyConcerns"}
              formState={formState}
              placeholderText=""
              className="-mt-6"
            />
          </ContentRow>
          <ContentRow title={familyLife.title} subtitle={familyLife.description}>
            <Paragraph>{familyLife.question}</Paragraph>
            <RegisteredFormMultiSelect
              required={false}
              mode="tags"
              title={""}
              items={familyLife.optionItems as MultiSelectItem[]}
              formKey={"familyLife"}
              formState={formState}
              placeholderText="need(s)"
              className="-mt-6"
            />
          </ContentRow>
          <ContentRow title={engagementAbility.title} subtitle={engagementAbility.description}>
            <Paragraph>{engagementAbility.question}</Paragraph>
            <RegisteredFormRadioInput
              title={""}
              items={engagementAbility.optionItems as RadioInputItem[]}
              formKey={"engagementAbility"}
              formState={formState}
            />
          </ContentRow>
          <ContentRow title={communicationAbility.title} subtitle={communicationAbility.description}>
            <Paragraph>{communicationAbility.question}</Paragraph>
            <RegisteredCheckboxList
              required={false}
              formState={formState}
              orientation="vertical"
              formKey="communicationAbility"
              title={""}
              items={communicationAbility.optionItems as CheckboxItem[]}
            />
          </ContentRow>
          <ContentRow title={socialDesires.title} subtitle={socialDesires.description}>
            <RegisteredFormTextArea
              required={false}
              formState={formState}
              formKey="socialDesires"
              label={socialDesires.question}
            />
          </ContentRow>
          <FooterButtonRow
            primaryButtonType="submit"
            primaryButtonDisabled={formState.isSubmitting}
            primaryButtonLoading={formState.isSubmitting}
          />
        </div>
      </FormContainer>
    </>
  );
};
