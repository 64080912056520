import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { scrollToDivById } from "@utils/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { ApiReportReportStatusChoices, ReportType } from "@api/graphql/types-and-hooks";
import useGenerateDocx from "@components/HtmlToDocx/useGenerateDOCX";
import { Legend } from "@components/Legend/Legend";
import { SupportedContentType } from "@components/SectionWidget/SectionWidget";
import { SignatureSection } from "@components/SignatureSection/SignatureSection";
import { notifyWarn } from "@components/notifications/notifications";
import { useSignatureHandler } from "@hooks/useSignatureHandler";
import { ReviewHeader } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReviewAndSign";
import { FooterButtonRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/FooterButtonRow";
import { ReportSummaryPart2 } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/ReportSummaryPart2.tsx";
import { StickyNav } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ReportSummaries/StickyNav/StickyNav";
import { GeneralInfoSection } from "@pages/AssessmentReportV2Details/Components/GeneralInfoSection";
import { SectionWidgetWrapper } from "@pages/AssessmentReportV2Details/Components/SectionWidgetWrapper";
import { useGetFilteredPageSectionsByLocation } from "@pages/AssessmentReportV2Details/Components/helper";
import { findErrorSection } from "@pages/EvaluationDetails/Components/helper";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

export const ReviewAndSign: React.FC = () => {
  const { assessmentId } = useParams();
  const [isWarningVisible, setIsWarningVisible] = useState<boolean>(false);
  const filteredSections = useGetFilteredPageSectionsByLocation("report-part-1"); // only quering report page one section here
  const appendixSections = useGetFilteredPageSectionsByLocation("appendix");
  const flags = useFlags<FeatureFlags>();
  const { assessmentQuery } = useAssessmentBuilderData();
  const assessmentReport = assessmentQuery?.data?.getReports?.edges?.[0]?.node;
  const reportIsCompleted =
    assessmentReport?.reportStatus === ApiReportReportStatusChoices.Completed ||
    (flags["dynamic-report-output-diagnosis-builder"] &&
      assessmentReport?.reportStatus === ApiReportReportStatusChoices.Generating);

  const { isLoading } = assessmentQuery;
  const {
    signature,
    setSignature,
    closingRemark,
    setClosingRemark,
    professionalTitle,
    setProfessionalTitle,
    licenseNumber,
    setLicenseNumber,
    timestamp,
    setTimestamp,
    isGenerating,
    setIsGenerating,
    handleSignReport,
  } = useSignatureHandler({
    report: assessmentReport as unknown as ReportType,
    isCompleted: reportIsCompleted,
    refetchData: assessmentQuery?.refetch,
  });

  const { generateDocx, loading } = useGenerateDocx({
    fetchLogoUrl: async () => {
      const res = await assessmentQuery?.refetch();
      const report = res.data?.getReports?.edges?.[0]?.node as unknown as ReportType;
      return report?.reportHeaderLogoSignedUrl;
    },
  });

  const supportedContentTypes = ["Text", "Table"] as SupportedContentType[];
  if (flags["upload-images-component"]) {
    supportedContentTypes.push("Image");
  }

  return (
    <div className="flex w-full flex-col">
      <ReviewHeader />
      <div className="grid grid-cols-4 gap-x-1">
        <div id="EvaluationReport" className={`col-span-3 flex w-full flex-col gap-y-4`}>
          <GeneralInfoSection />
          {filteredSections.map((section) => (
            <SectionWidgetWrapper
              key={section.id}
              section={section}
              permissions={{
                canDelete: false,
                canEdit: false,
              }}
              supportedContentTypes={supportedContentTypes}
              allowInLineEdit={!reportIsCompleted}
              showErrorWarning={isWarningVisible}
            />
          ))}
          <ReportSummaryPart2 isReviewPage={true} showErrorWarning={isWarningVisible} key={assessmentId} />
          <SignatureSection
            signature={signature}
            setSignature={setSignature}
            closingRemark={closingRemark}
            setClosingRemark={setClosingRemark}
            timestamp={timestamp}
            setTimestamp={setTimestamp}
            professionalTitle={professionalTitle}
            setProfessionalTitle={setProfessionalTitle}
            licenseNumber={licenseNumber}
            setLicenseNumber={setLicenseNumber}
            isReadOnly={reportIsCompleted || isGenerating}
          />
          {flags["dynamic-report-output-diagnosis-builder"] ? (
            <>
              <div className=" -mt-4">
                <Legend title="Appendix" position="center" />
              </div>
              {appendixSections.map((section) => (
                <SectionWidgetWrapper
                  key={section.id}
                  section={section}
                  permissions={{
                    canDelete: false,
                    canEdit: false,
                  }}
                  supportedContentTypes={supportedContentTypes}
                  allowInLineEdit={!reportIsCompleted}
                />
              ))}
            </>
          ) : null}
        </div>

        <div className="flex w-full flex-col pl-8">
          <StickyNav sections={filteredSections} />
        </div>
      </div>
      {!reportIsCompleted ? (
        <FooterButtonRow
          primaryButtonTitle={flags["dynamic-report-output-diagnosis-builder"] ? "Complete Report" : "Sign & Complete"}
          primaryButtonLoading={loading || isGenerating || isLoading}
          primaryButtonAction={async () => {
            const errorSection = findErrorSection(filteredSections);
            if (errorSection) {
              setIsWarningVisible(true);
              scrollToDivById(errorSection.id);
            } else if (signature.replace(/\s/g, "").length === 0) {
              notifyWarn("To generate the report, you need to provide your signature.");
              scrollToDivById("signature");
            } else {
              setIsGenerating(true);
              const element = document.getElementById("EvaluationReport");
              if (!flags["dynamic-report-output-diagnosis-builder"]) {
                const file = await generateDocx(element);
                if (file) {
                  await handleSignReport(file);
                }
              } else {
                await handleSignReport(null);
              }
            }
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
