import { emptyFunction, getDifferenceInYearsAndMonths } from "@utils/utils";
import { format, parseISO } from "date-fns";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { SectionWidget } from "@components/SectionWidget/SectionWidget";
import { useEvaluationData } from "@providers/EvaluationProvider";

interface CustomFieldProps {
  title: string;
  content: string;
}

export const CustomField: React.FC<CustomFieldProps> = ({ title, content }) => {
  return (
    <div className="mb-4 mt-4 flex flex-col">
      <label className="block">
        <Small className="">{title}</Small>
      </label>
      <Paragraph displayType="normal" colorType="primary">
        {content}
      </Paragraph>
    </div>
  );
};

export const GeneralInfoSection: React.FC = () => {
  const { evaluationQuery } = useEvaluationData();
  const evaluation = evaluationQuery.data?.getReports?.edges[0]?.node;
  const clientDetails = evaluation?.reportClientDetails;
  const clientName = `${clientDetails?.clientFirstName ?? ""} ${clientDetails?.clientLastName ?? ""}`;

  const isoString = new Date().toISOString();
  const birthDateOrDate = clientDetails?.birthDate || isoString;
  const birthDateFormatted = format(parseISO(birthDateOrDate), "MM/dd/yyyy");
  const ageAtEvaluation = getDifferenceInYearsAndMonths(birthDateOrDate, evaluation?.createdDate);

  return (
    <SectionWidget
      sectionType={"regular"}
      key="General-Information"
      id="GeneralInformation"
      onAddContent={emptyFunction}
      onEditSection={emptyFunction}
      permissions={{ canEdit: false, canDelete: false }}
      sectionData={{ heading: "Client Details", icon: "document" }}
      supportedContentTypes={[]}
      placeholder=""
      allowInLineEdit={false}
      setIsInLineEditMode={() => {}}
      isInLineEditMode={false}
      actionButtonMenu={[]}
      isLoading={false}
      isError={false}
    >
      <CustomField title={"Client's Name"} content={clientName} />
      <CustomField title={"Date of Birth"} content={birthDateFormatted} />
      <CustomField title={"Age at Evaluation"} content={ageAtEvaluation} />
    </SectionWidget>
  );
};
