import { useAuth0 } from "@auth0/auth0-react";
import { downloadLearnerFileById } from "@utils/helpers";

import { ExistingFileInterface, FileUploadAndParse } from "@fronterahealth/frontera-ui-components";

import { FileTypeEnum } from "@api/graphql/types-and-hooks";
import { ContentRow } from "@components/ContentRow/ContentRow";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/hooks";

interface DiagnosisReportProps {
  existingDiagnosis: ExistingFileInterface | undefined;
}
export const DiagnosisReport: React.FC<DiagnosisReportProps> = ({ existingDiagnosis }) => {
  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({});
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const { getAccessTokenSilently } = useAuth0();
  const fileKind: FileTypeEnum = FileTypeEnum.DiagnosisReport;

  return (
    <ContentRow
      title="Diagnosis Report"
      subtitle="Diagnosis report from the psychologist that contains the autism diagnosis."
    >
      <FileUploadAndParse
        key={JSON.stringify(existingDiagnosis)}
        fileKind={fileKind}
        existingFiles={existingDiagnosis ? [existingDiagnosis] : undefined}
        title={"Diagnosis Report"}
        singleFileUpload
        supportedFileTypes={["pdf", "jpg", "jpeg", "png"]}
        maxSize={"1GB"}
        S3BucketUrlRetriever={S3BucketUrlRetriever}
        S3BucketUploadCallback={S3BucketUploadCallback}
        reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
        fileParseStatusRetriever={fileParseStatusRetriever}
        removeFileCallback={removeFileCallback}
        hintText="Optional"
        allowFileDownload={true}
        downloadFileCallback={async (fileId) => {
          const token = await getAccessTokenSilently();
          await downloadLearnerFileById({ fileId: fileId, token: token });
        }}
      />
    </ContentRow>
  );
};
