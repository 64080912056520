import { getGenderValue, getPronoun } from "@utils/helpers";
import { formatDate, formatDateYYYY_MM_DD, formatTime_hh_mm, getDifferenceInYearsAndMonths } from "@utils/utils";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import { snakeCase } from "lodash";
import PizZip from "pizzip";
import React from "react";

import { Button } from "@fronterahealth/frontera-ui-components";

import {
  ApiOrganizationAbExportTemplateNameChoices,
  Maybe,
  ReportSectionsTypeEdge,
  ShortTermGoalType,
} from "@api/graphql/types-and-hooks";
import { getRadioAndSelectFieldAnswer } from "@components/FieldComponent/utils";
import {
  getFieldContentByFieldIdentifier,
  getSectionObjectBySectionIdentifier,
  organizeCatalightTreatmentPlanGoals,
  transformCheckboxOptionsToBoolean,
  transformDynamicTableJson,
  transformServicesTables,
  transformVinelandFormData,
  transformVinelandTableData,
} from "@components/PDFGenerator/catalightUtils";
import {
  ExportWithTemplateV2Props,
  FieldMap,
  FieldMapValue,
  getReportTemplateFont,
  getReportTemplatePath,
  markdownToDocxtemplater,
  sortReportSectionsByRankOrder,
  verifyUniqueFieldMapKeys,
} from "@components/PDFGenerator/docxTemplateUtils";
import { useAdminData } from "@providers/AdminDataProvider";

const ExportWithTemplate: React.FC<ExportWithTemplateV2Props> = ({ reportData }) => {
  const { orgnizationDetailsQuery, isCatalightUser } = useAdminData();
  const { data, isLoading } = orgnizationDetailsQuery;

  const exportTemplate =
    data?.getUserOrgnizationDetails?.abExportTemplateName ||
    ApiOrganizationAbExportTemplateNameChoices.AssessmentReportDocxTemplateDocx;

  const font = getReportTemplateFont(exportTemplate);

  const getBaseFieldMap = (): FieldMap => {
    const clientInformationSectionEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Client_Information",
    )?.node?.sectionField.edges;

    const clientName =
      `${reportData.reportClientDetails?.clientFirstName} ${reportData.reportClientDetails?.clientLastName}`.trim();
    const age = getDifferenceInYearsAndMonths(reportData.reportClientDetails?.birthDate, reportData?.createdDate);
    const address =
      getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Caregiver_Legal_Guardian_Address") ??
      [reportData.reportClientDetails?.address, reportData.reportClientDetails?.addressLine2]
        .filter(Boolean)
        .join("\n");

    return {
      clientName,
      clientRaceEthnicity: getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Client_Race_Ethnicity"),
      dateOfBirth: formatDate(reportData.reportClientDetails?.birthDate),
      age: age || "",
      preferredLanguage: getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Preferred_Language"),
      isLanguageLineUsed:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Language_Line_Use"),
        ) === "Yes",
      gender:
        getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Client_Gender") ??
        getGenderValue(reportData?.reportClientDetails?.gender),
      pronoun:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Client_Pronouns"),
        ) ?? getPronoun(reportData?.reportClientDetails?.gender),
      guardianName:
        getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Caregiver_Legal_Guardian_Name") ??
        `${reportData.reportClientDetails?.guardianFirstName} ${reportData.reportClientDetails?.guardianLastName}`,
      address,
      cityStateZip: `${reportData.reportClientDetails?.city}, ${reportData.reportClientDetails?.state} ${reportData.reportClientDetails?.zip}`,
      clientResidesWith: getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Client_Resides_With"),
      clientAddressDifferent: getFieldContentByFieldIdentifier(
        clientInformationSectionEdges,
        "Client_Address_Different_Parent_Guardian",
      ),
      OOSA_Yes_No: getRadioAndSelectFieldAnswer(
        getFieldContentByFieldIdentifier(clientInformationSectionEdges, "OOSA_Yes_No"),
      ),
      treatmentLocation: getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Treatment_Location_If_Yes"),
      clientPhoneNumber: getFieldContentByFieldIdentifier(
        clientInformationSectionEdges,
        "Phone_Number_Caregiver_Client",
      ),
      treatmentTeam: getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Treatment_Team"),
      clientDiagnosis: getFieldContentByFieldIdentifier(
        clientInformationSectionEdges,
        "Diagnosis_listed_on_authorization",
      ),
      diagnosingMDPsychologistInfo: getFieldContentByFieldIdentifier(
        clientInformationSectionEdges,
        "Diagnosing_MD_Psychologist_Info",
      ),
      projectedOfferBHT: getFieldContentByFieldIdentifier(clientInformationSectionEdges, "Projected_Offer_BHT"),
      currentDate: new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      priorities: reportData.reportTreatmentPlanPrioritizeFields?.socialDesires,
      reportCreatedDate: formatDateYYYY_MM_DD(reportData?.createdDate),
      reportCreatedTime: formatTime_hh_mm(reportData?.createdDate),
      reportCreatedByUserEmail: reportData.createdBy.email,
      signatureName: reportData.signatureSectionDetail?.signedBy,
      licenseNumber: reportData.signatureSectionDetail?.licenseNumber ?? "",
      professionalTitle: reportData.signatureSectionDetail?.professionalTitle ?? "",
      signatureDate: formatDate(reportData.signatureSectionDetail?.signedAt),
    };
  };

  const getAcademicPerformanceFieldMap = (): FieldMap => {
    const catalightAcademicPerformance = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Academic_Performance_School",
    )?.node;

    return {
      isIEP:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(catalightAcademicPerformance?.sectionField.edges, "IEP"),
        ) === "Yes",
      is504:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(catalightAcademicPerformance?.sectionField.edges, "504"),
        ) === "Yes",
      educationalProgram: getRadioAndSelectFieldAnswer(
        getFieldContentByFieldIdentifier(catalightAcademicPerformance?.sectionField.edges, "Educational_Program"),
      ),
      isGeneralEducation:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(catalightAcademicPerformance?.sectionField.edges, "General_Education"),
        ) === "Yes",
      isPerformanceLow:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            catalightAcademicPerformance?.sectionField.edges,
            "Performance_in_General_Education_if_yes_above",
          ),
        ) === "Low",
      isPerformanceModerate:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            catalightAcademicPerformance?.sectionField.edges,
            "Performance_in_General_Education_if_yes_above",
          ),
        ) === "Moderate",
      isPerformanceHigh:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            catalightAcademicPerformance?.sectionField.edges,
            "Performance_in_General_Education_if_yes_above",
          ),
        ) === "High",
      isPerformanceEmpty:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            catalightAcademicPerformance?.sectionField.edges,
            "Performance_in_General_Education_if_yes_above",
          ),
        ) === "",
      educationalSetting: getRadioAndSelectFieldAnswer(
        getFieldContentByFieldIdentifier(catalightAcademicPerformance?.sectionField.edges, "Educational_Setting"),
      ),
    };
  };

  const getRecommendationsFieldMap = (): FieldMap => {
    const catalightRecommendations = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Recommendations",
    )?.node;

    return {
      CCSRecommendationsBasedonBHTIA: getRadioAndSelectFieldAnswer(
        getFieldContentByFieldIdentifier(
          catalightRecommendations?.sectionField.edges,
          "CCS_Recommendations_Based_on_BHT_Initial_Assessment",
        ),
      ),
      recommendations: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightRecommendations?.sectionField.edges, "Recommendations"),
        font,
      ),
      authorizationRequestHours: getFieldContentByFieldIdentifier(
        catalightRecommendations?.sectionField.edges,
        "Authorization_Request_Hours_agreed_by_client_family",
      ),
    };
  };

  const getCaregiverMediatedTreatmentFieldMap = (): FieldMap => {
    const caregiverMediatedTreatment = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Caregiver_Mediated_Treatment",
    )?.node;

    return {
      isCaregiverClinicallyIndicated:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            caregiverMediatedTreatment?.sectionField.edges,
            "Is_Caregiver_Mediated_Treatment_Option_Clinically_Indicated",
          ),
        ) === "Yes",
      caregiverRationaleIfNo: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(
          caregiverMediatedTreatment?.sectionField.edges,
          "If_No_please_provide_rationale",
        ),
        font,
      ),
      caregiverTreatmentOption: getRadioAndSelectFieldAnswer(
        getFieldContentByFieldIdentifier(
          caregiverMediatedTreatment?.sectionField.edges,
          "Caregiver_Mediated_Treatment_Option",
        ),
      ),
      isCaregiverHoursOffered:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            caregiverMediatedTreatment?.sectionField.edges,
            "Were_the_above_hours_offered_to_the_client_family",
          ),
        ) === "Yes",
      isCaregiverClientAgreement:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            caregiverMediatedTreatment?.sectionField.edges,
            "Caregiver_Client_agree_with_clinical_recommendation",
          ),
        ) === "Yes",
      caregiverReasonForDisagreement: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(
          caregiverMediatedTreatment?.sectionField.edges,
          "If_no_please_provide_reason_caregiver_client_did_not_agree",
        ),
        font,
      ),
      caregiverClinicalIndicationReasons: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(
          caregiverMediatedTreatment?.sectionField.edges,
          "Caregiver_Mediated_Treatment_is_Clinically_Indicated_for_Following_Reasons",
        ),
        font,
      ),
    };
  };

  const getPractitionerMediatedTreatmentFieldMap = (): FieldMap => {
    const practitionerMediatedTreatment = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Practitioner_Mediated_Treatment",
    )?.node;

    return {
      practitionerTreatmentOption: getRadioAndSelectFieldAnswer(
        getFieldContentByFieldIdentifier(
          practitionerMediatedTreatment?.sectionField.edges,
          "Practitioner_Mediated_Treatment_Option",
        ),
      ),
      isPractitionerHoursOffered:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            practitionerMediatedTreatment?.sectionField.edges,
            "Were_the_above_hours_offered_to_the_client_family_for_practitioner",
          ),
        ) === "Yes",
      isPractitionerClientAgreement:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            practitionerMediatedTreatment?.sectionField.edges,
            "Caregiver_Client_agree_with_clinical_recommendations",
          ),
        ) === "Yes",
      practitionerReasonForDisagreement: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(
          practitionerMediatedTreatment?.sectionField.edges,
          "If_no_please_provide_reason_as_to_why_caregiver_client_did_not_agree",
        ),
        font,
      ),
      practitionerClinicalIndicationReasons: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(
          practitionerMediatedTreatment?.sectionField.edges,
          "Practitioner_Mediated_Treatment_is_Clinically_Indicated_for_Following_Reasons",
        ),
        font,
      ),
      socialSkillsGroupDescription: getRadioAndSelectFieldAnswer(
        getFieldContentByFieldIdentifier(
          practitionerMediatedTreatment?.sectionField.edges,
          "Social_Skills_Group_Description_if_applicable",
        ),
      ),
    };
  };

  const getRecordReviewFieldMap = (): FieldMap => {
    const recordReviewEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Record_Review",
    )?.node?.sectionField.edges;

    return {
      asdDiagnosticEvaluation: getFieldContentByFieldIdentifier(recordReviewEdges, "ASD_Diagnostic_Evaluation"),
      vinelandAssessment: getFieldContentByFieldIdentifier(recordReviewEdges, "Vineland_Assessment"),
      behavioralHealthTreatmentReport: getFieldContentByFieldIdentifier(recordReviewEdges, "BHTIA"),
      assessmentAppointments: transformDynamicTableJson(
        getFieldContentByFieldIdentifier(recordReviewEdges, "Assessment_Appointments"),
        ["date", "times", "location", "assessmentMethods", "evaluatorPresent"],
      ),
      isCareCoordinationOccurred:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            recordReviewEdges,
            "Did_care_coordination_occur_during_this_assessment_period",
          ),
        ) === "Yes",
      careCoordinationRationale: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(recordReviewEdges, "If_No_Please_provide_client_specific_rationale"),
        font,
      ),
      coordinationOfCare: transformDynamicTableJson(
        getFieldContentByFieldIdentifier(recordReviewEdges, "Coordination_of_Care"),
        ["typeOfCollaboration", "nameAndRole", "datesAndFrequencyOfCollaboration"],
      ),
    };
  };

  const getSleepChecklistFieldMap = (): FieldMap => {
    const sleepChecklistEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Sleep_Checklist",
    )?.node?.sectionField.edges;

    return {
      sleepProblemSignificant: getRadioAndSelectFieldAnswer(
        getFieldContentByFieldIdentifier(sleepChecklistEdges, "Is_sleep_bedtime_a_significant_problem"),
      ),
      caregiverTrainingGoalsSleep: getRadioAndSelectFieldAnswer(
        getFieldContentByFieldIdentifier(sleepChecklistEdges, "Caregiver_training_goals_addressing_sleep_bedtime"),
      ),
      clinicalRationaleIfNo: getFieldContentByFieldIdentifier(
        sleepChecklistEdges,
        "If_No_provide_a_clinical_rationale",
      ),
      ...transformCheckboxOptionsToBoolean(
        getFieldContentByFieldIdentifier(sleepChecklistEdges, "Sleep_goals_addressed"),
      ),
    };
  };

  const getFunctionalBehaviorFieldMap = (): FieldMap => {
    const functionalBehaviorEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Functional_Behavior",
    )?.node?.sectionField.edges;

    return {
      isPhysicalInterventionIndicated:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(functionalBehaviorEdges, "Is_physical_intervention_clinically_indicated"),
        ) === "Yes",
      isInterventionReviewedByCCS:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            functionalBehaviorEdges,
            "If_physical_intervention_is_clinically_indicated_has_the_intervention_been_reviewed_and_approved_by_CCS",
          ),
        ) === "Yes",
      isInterventionReviewedWithCaregiver:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            functionalBehaviorEdges,
            "Has_the_intervention_been_reviewed_with_caregiver_client_and_are_they_in_agreement_with_described_intervention",
          ),
        ) === "Yes",
      assessmentToolSources: getRadioAndSelectFieldAnswer(
        getFieldContentByFieldIdentifier(
          functionalBehaviorEdges,
          "If_Dangerous_Behaviors_are_Present_list_assessment_tool_sources_used",
        ),
      ),
      otherAssessmentToolSource: getFieldContentByFieldIdentifier(
        functionalBehaviorEdges,
        "If_selecting_other_in_above_list_the_tool_source",
      ),
      behaviorSupportPlan: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(functionalBehaviorEdges, "Behavior_Support_Plan_if_indicated"),
        font,
      ),
      behaviorCrisisPlan: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(functionalBehaviorEdges, "Behavior_Crisis_Plan"),
        font,
      ),
    };
  };

  const getBarriersToServiceFieldMap = (): FieldMap => {
    const barriersToServiceEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Barriers_to_Service",
    )?.node?.sectionField.edges;

    return {
      isEnvironmentalConcerns:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            barriersToServiceEdges,
            "Environmental_or_family_concerns_that_are_likely_to_have_significantly_impacted_service_delivery_in_the_last_treatment_period",
          ),
        ) === "Yes",
      environmentalConcernsDescription: getFieldContentByFieldIdentifier(
        barriersToServiceEdges,
        "If_selecting_Yes_provide_a_description_of_the_barriers_to_treatment",
      ),
      isDangerousBehaviors:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            barriersToServiceEdges,
            "Does_Client_Exhibit_Dangerous_Behaviors_inclusive_of_any_dangerous_behaviors_observed_during_or_outside_treatment",
          ),
        ) === "Yes",
      ...transformCheckboxOptionsToBoolean(
        getFieldContentByFieldIdentifier(barriersToServiceEdges, "If_Yes_above_please_select_all_that_apply"),
      ),
      isBehaviorSupportPlan:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            barriersToServiceEdges,
            "Behavior_Support_Plan_BSP_to_be_implemented_see_BSP_above",
          ),
        ) === "Yes",
      behaviorSupportPlanRationale: getFieldContentByFieldIdentifier(
        barriersToServiceEdges,
        "If_No_provide_a_rationale",
      ),
    };
  };

  const getDischargeExitPlanFieldMap = (): FieldMap => {
    const dischargeExitPlanEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Discharge_Exit_Plan",
    )?.node?.sectionField.edges;

    return {
      isMeetsMedicalNecessity:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            dischargeExitPlanEdges,
            "Yes_if_client_continues_to_meet_the_needs_for_medical_necessity_for_treatment",
          ),
        ) === "Yes",
      anticipatedDischargeDate: getFieldContentByFieldIdentifier(dischargeExitPlanEdges, "Anticipated_Discharge_Date"),
    };
  };

  const getTreatmentPlanReviewFieldMap = (): FieldMap => {
    const treatmentPlanReviewEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Treatment_Plan_Review",
    )?.node?.sectionField.edges;

    return {
      isReportReviewedWithFamily:
        getRadioAndSelectFieldAnswer(
          getFieldContentByFieldIdentifier(
            treatmentPlanReviewEdges,
            "Report_and_Clinical_Recommendations_have_been_reviewed_and_agreed_upon_with_client_family",
          ),
        ) === "Yes",
      treatmentPlanReviewDate: getFieldContentByFieldIdentifier(
        treatmentPlanReviewEdges,
        "Treatment_Plan_Review_Date_with_Family_if_above_yes",
      ),
      reasonIfNotReviewed: getFieldContentByFieldIdentifier(treatmentPlanReviewEdges, "Reason_if_above_no"),
    };
  };

  const getClientDetailsFieldMap = (): FieldMap => {
    const catalightClientDetailsEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_Client_Details",
    )?.node?.sectionField.edges;

    return {
      mrn: getFieldContentByFieldIdentifier(catalightClientDetailsEdges, "MRN"),
      dateOfReport:
        getFieldContentByFieldIdentifier(catalightClientDetailsEdges, "Date_of_Report") ??
        formatDate(reportData.signatureSectionDetail?.lastUpdatedDate),
      documentedReasonForReferral: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightClientDetailsEdges, "Reason_for_Referral"),
        font,
      ),
      clientChosenName: getFieldContentByFieldIdentifier(catalightClientDetailsEdges, "Client_Chosen_Name"),
    };
  };

  const getHistoryOfServicesFieldMap = (): FieldMap => {
    const catalightHistoryOfServicesEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Catalight_History_Of_Services",
    )?.node?.sectionField.edges;

    return {
      ...transformServicesTables(
        getFieldContentByFieldIdentifier(catalightHistoryOfServicesEdges, "Educational_Services_Table"),
        "educationalServices",
      ),
      ...transformServicesTables(
        getFieldContentByFieldIdentifier(catalightHistoryOfServicesEdges, "Other_Services_Table"),
        "otherServices",
      ),

      familyConstellation: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightHistoryOfServicesEdges, "Family_Constellation"),
        font,
      ),
      significantBirthAndMedicalHistory: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightHistoryOfServicesEdges, "Significant_Birth_Medical_History"),
        font,
      ),
      educationalServicesTotalHours: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightHistoryOfServicesEdges, "Educational_Services_Total_Hours"),
        font,
      ),
      otherServicesTotalHours: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightHistoryOfServicesEdges, "Other_Services_Total_Hours"),
        font,
      ),
    };
  };

  const getAssessmentWriteupsFieldMap = (): FieldMap => {
    const catalightAssessmentWriteupsEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Assessment_Writeups",
    )?.node?.sectionField.edges;

    return {
      ...transformVinelandTableData(
        getFieldContentByFieldIdentifier(catalightAssessmentWriteupsEdges, "catalight_vineland_scores_table"),
      ),

      ...transformVinelandFormData(
        getFieldContentByFieldIdentifier(catalightAssessmentWriteupsEdges, "catalight_vineland_metadata"),
      ),
      assessmentResults: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightAssessmentWriteupsEdges, "vineland_assessment_results"),
        font,
      ),
      eflWriteup: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightAssessmentWriteupsEdges, "EFL_Quick_Assessment"),
        font,
      ),
      vbmappWriteup: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightAssessmentWriteupsEdges, "VBMAPP_Assessment"),
        font,
      ),
      fastWriteup: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightAssessmentWriteupsEdges, "FAST_Assessment"),
        font,
      ),
      qabfWriteup: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightAssessmentWriteupsEdges, "QABF_Assessment"),
        font,
      ),
    };
  };

  const getDomainLevelStrengthsFieldMap = (): FieldMap => {
    const catalightDomainLevelStrengthsEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Domain_Level_Strengths",
    )?.node?.sectionField.edges;

    return {
      receptiveCommunicationStrengths: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightDomainLevelStrengthsEdges, "Receptive_Communication_Strengths"),
        font,
      ),
      expressiveCommunicationStrengths: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightDomainLevelStrengthsEdges, "Expressive_Communication_Strengths"),
        font,
      ),
      pragmaticCommunicationStrengths: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightDomainLevelStrengthsEdges, "Pragmatic_Communication_Strengths"),
        font,
      ),
      selfHelpDailyLivingSkillsStrengths: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightDomainLevelStrengthsEdges, "Self_Help_Daily_Living_Skills_Strengths"),
        font,
      ),
      behaviorCurrentlyExhibits: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightDomainLevelStrengthsEdges, "Behavior_Currently_Exhibits"),
        font,
      ),
      caregiverTrainingStrengths: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightDomainLevelStrengthsEdges, "Caregiver_Training_Strengths"),
        font,
      ),
    };
  };

  const getSummaryOfAssessmentResultsFieldMap = (): FieldMap => {
    const catalightSummaryOfAssessmentResultsEdges = getSectionObjectBySectionIdentifier(
      reportData?.reportSections.edges,
      "Summary_Of_Assessment_Results",
    )?.node?.sectionField.edges;

    return {
      summaryOfBehavioralAndAdaptiveConcerns: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(
          catalightSummaryOfAssessmentResultsEdges,
          "Summary_of_Behavioral_and_Adaptive_Concerns",
        ),
        font,
      ),
      summaryOfStrengths: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightSummaryOfAssessmentResultsEdges, "Summary_of_Strengths"),
        font,
      ),
    };
  };

  const getCatalightFieldMap = (sortedReportFields: Maybe<ReportSectionsTypeEdge>[]): FieldMap => {
    const catalightDesiredOutcomes = getSectionObjectBySectionIdentifier(sortedReportFields, "Desired_Outcomes");
    const catalightFadePlan = getSectionObjectBySectionIdentifier(sortedReportFields, "Fade_Plan");
    const catalightPreferenceAssessment = getSectionObjectBySectionIdentifier(
      sortedReportFields,
      "Preference_Assessment",
    );
    return {
      ...getClientDetailsFieldMap(),
      ...getBaseFieldMap(), // Client Information Fields
      ...getAcademicPerformanceFieldMap(), // Academic Performance (School) fields
      ...getRecommendationsFieldMap(), // Recommendations Fields
      ...getCaregiverMediatedTreatmentFieldMap(), // Caregiver Mediated Treatment fields
      ...getPractitionerMediatedTreatmentFieldMap(), // Practitioner Mediated Treatment fields
      ...getRecordReviewFieldMap(), // Record Review fields
      ...getSleepChecklistFieldMap(),
      ...getFunctionalBehaviorFieldMap(),
      ...getBarriersToServiceFieldMap(),
      ...getDischargeExitPlanFieldMap(),
      ...getTreatmentPlanReviewFieldMap(),
      ...getHistoryOfServicesFieldMap(),
      ...getAssessmentWriteupsFieldMap(),
      ...getDomainLevelStrengthsFieldMap(),
      ...getSummaryOfAssessmentResultsFieldMap(),
      desiredOutcomes: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(
          catalightDesiredOutcomes?.node?.sectionField.edges,
          "Desired_Outcomes_of_Behavioral_Health_Treatment",
        ),
        font,
      ),
      fadePlan: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(catalightFadePlan?.node?.sectionField.edges, "Fade_Plan"),
        font,
      ),
      preferenceAssessment: markdownToDocxtemplater(
        getFieldContentByFieldIdentifier(
          catalightPreferenceAssessment?.node?.sectionField.edges,
          "Preference_Assessment",
        ),
        font,
      ),
    };
  };

  const generateDocument = async (
    templateData: Record<string, string | ShortTermGoalType[] | FieldMapValue | undefined>,
  ) => {
    try {
      const response = await fetch(getReportTemplatePath(exportTemplate));
      if (!response.ok) throw new Error("Network response was not ok");

      const buffer = await response.arrayBuffer();
      const zip = new PizZip(buffer);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.setData(templateData);
      doc.render();

      const blob = doc.getZip().generate({ type: "blob" });
      const clientName = templateData?.clientName || "unknown";
      saveAs(blob, `${snakeCase(clientName.toString())}_assessment_report.docx`);
    } catch (error) {
      console.error("Error generating document:", error);
    }
  };

  const handleExport = async () => {
    try {
      const sortedReportFields = sortReportSectionsByRankOrder(reportData?.reportSections?.edges);
      const baseFieldMap = getBaseFieldMap();

      if (isCatalightUser) {
        const organizedCatalightGoals = organizeCatalightTreatmentPlanGoals(reportData);
        const catalightFieldMap = getCatalightFieldMap(sortedReportFields);

        const organizedKeys = Object.keys(organizedCatalightGoals);
        const catalightKeys = Object.keys(catalightFieldMap);

        const duplicateKeys = organizedKeys.filter((key) => catalightKeys.includes(key));
        if (duplicateKeys.length > 0) {
          console.error(
            `Duplicate keys found between organizedCatalightGoals and catalightFieldMap: ${duplicateKeys.join(", ")}`,
          );
          throw new Error(`Duplicate keys found between data objects: ${duplicateKeys.join(", ")}`);
        }

        // Then verify each object individually has no duplicates
        verifyUniqueFieldMapKeys(organizedCatalightGoals);
        verifyUniqueFieldMapKeys(catalightFieldMap);

        await generateDocument({
          ...organizedCatalightGoals,
          ...catalightFieldMap,
        });
      } else {
        // Verify that the baseFieldMap has no duplicates
        verifyUniqueFieldMapKeys(baseFieldMap);

        await generateDocument(baseFieldMap);
      }
    } catch (error) {
      console.error("Error preparing export data:", error);
    }
  };

  return <Button text="Export" disabled={isLoading} id="report-export-btn" appearance="link" onClick={handleExport} />;
};

export default ExportWithTemplate;
