import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Button,
  CalendarDateRange,
  CalendarPicker,
  SearchableDropdown,
  SearchableDropdownItem,
  Select,
  SelectItem,
} from "@fronterahealth/frontera-ui-components";

import {
  MediaSortByEnum,
  TherapySessionStatusEnums,
  useGetProvidersForSessionQuery,
  useGetSessionClientsQuery,
} from "@api/graphql/types-and-hooks";
import { useUIState } from "@providers/UIStateProvider";
import { defaultSortBy, useVideoSessionData } from "@providers/VideoSessionProvider";

const UPLOAD_STATUS: SearchableDropdownItem[] = [
  {
    id: TherapySessionStatusEnums.Uploading,
    label: "Pending Upload",
  },
  {
    id: JSON.stringify([TherapySessionStatusEnums.Scheduled, TherapySessionStatusEnums.Processing]),
    label: "Processing",
  },
  {
    id: TherapySessionStatusEnums.Completed,
    label: "Completed",
  },
  {
    id: TherapySessionStatusEnums.Failed,
    label: "Failed",
  },
];

export const FilterBar: React.FC = () => {
  const { pathname } = useLocation();
  const {
    setFilterSelectedClients,
    setFilterSelectedProviders,
    filterSelectedClients,
    filterSelectedProviders,
    filterSessionDates,
    setFilterSessionDates,
    filterSelectedRating,
    setFilterSelectedRating,
    sortBy,
    setSortBy,
    isUserRBT,
    // totalFilteredMediaCount, removing for now to prevent filter bar distored
    isFilterActive,
    clearFeedFilters,
    filterByUploadStatus,
    setFilterByUploadStatus,
  } = useVideoSessionData();
  const { sidebarCollapsed } = useUIState();
  const [sessionClients, setSessionClients] = useState<SearchableDropdownItem[]>([]);
  const [sessionProviders, setSessionProviders] = useState<SearchableDropdownItem[]>([]);

  const [recentSearchesForClient, setRecentSearchesForClient] = useState<SearchableDropdownItem[]>([]);
  const [recentSearchesForProvider, setRecentSearchesForProvider] = useState<SearchableDropdownItem[]>([]);

  const { data: sessionClientsData, isFetching: isSessionClientsDataLoading } = useGetSessionClientsQuery(
    {},
    {
      queryKey: ["getSessionClients"],
      refetchOnWindowFocus: false,
      retry: false,
      enabled: pathname.includes("/video-sessions"),
    },
  );
  const { data: sessionProvidersData, isFetching: isSessionProvidersDataLoading } = useGetProvidersForSessionQuery(
    {},
    {
      queryKey: ["getProvidersForSession"],
      refetchOnWindowFocus: false,
      retry: false,
      enabled: pathname.includes("/video-sessions") && !isUserRBT,
    },
  );
  useEffect(() => {
    if (sessionClientsData) {
      setSessionClients(
        sessionClientsData?.learnersRecentSession?.edges?.map((item) => ({
          id: item?.node?.sessionLearners.id || "",
          label: `${item?.node?.sessionLearners.learnermetadata?.firstName || ""} ${item?.node?.sessionLearners.learnermetadata?.lastName || ""}`,
        })) || [],
      );
    }
  }, [sessionClientsData]);

  useEffect(() => {
    if (sessionProvidersData) {
      setSessionProviders(
        sessionProvidersData?.getProvidersForSession?.edges?.map((item) => ({
          id: item?.node?.id || "",
          label: `${item?.node?.providermetadata?.firstName || ""} ${item?.node?.providermetadata?.lastName || ""}`,
          category: "RBT",
        })) || [],
      );
    }
  }, [sessionProvidersData]);

  const handleRecentSearchAddForClient = (item: SearchableDropdownItem) => {
    setRecentSearchesForClient((prev) => [...prev, item].slice(-5)); // Keep the last 5 searches
  };

  const handleRecentSearchAddForProvider = (item: SearchableDropdownItem) => {
    setRecentSearchesForProvider((prev) => [...prev, item].slice(-5)); // Keep the last 5 searches
  };

  const handleDateChange = (newDates: CalendarDateRange) => {
    setFilterSessionDates(newDates);
  };

  return (
    <div className="w-full max-md:gap-2 gap-y-2 pb-4 flex flex-row flex-wrap justify-between items-center">
      <div className={`${sidebarCollapsed ? "gap-2" : "gap-1"} flex flex-wrap -mx-4 px-4 items-center`}>
        <SearchableDropdown
          items={sessionClients}
          label="Client"
          placeholder="Search Client"
          onSelectionChange={(items: SearchableDropdownItem[]) => {
            setFilterSelectedClients(items);
          }}
          recentSearches={recentSearchesForClient}
          onRecentSearchAdd={handleRecentSearchAddForClient}
          isLoading={isSessionClientsDataLoading}
          selectedItems={filterSelectedClients}
          disabled={isSessionClientsDataLoading}
        />
        {!isUserRBT ? (
          <SearchableDropdown
            items={sessionProviders}
            label="RBT"
            placeholder="Search RBT"
            onSelectionChange={(items: SearchableDropdownItem[]) => {
              setFilterSelectedProviders(items);
            }}
            recentSearches={recentSearchesForProvider}
            onRecentSearchAdd={handleRecentSearchAddForProvider}
            isLoading={isSessionProvidersDataLoading}
            selectedItems={filterSelectedProviders}
            disabled={isSessionProvidersDataLoading}
          />
        ) : null}
        <CalendarPicker
          placeholder="Session Date"
          selectedDateRange={filterSessionDates}
          inputSize="md"
          onDateChange={handleDateChange}
          mode="filter"
        />
        <Select
          items={[
            { id: "1", primary: "★" },
            { id: "2", primary: "★ ★" },
            { id: "3", primary: "★ ★ ★" },
            { id: "4", primary: "★ ★ ★ ★" },
            { id: "5", primary: "★ ★ ★ ★ ★" },
          ]}
          title=""
          placeholderText="Session Rating"
          setSelected={(item: SelectItem) => {
            setFilterSelectedRating(item);
          }}
          selected={filterSelectedRating}
          className="flex"
          inputContainerClassName="mt-0 w-40"
          actionItem={{
            label: "Clear",
            onClick: () => {
              setFilterSelectedRating(undefined);
            },
          }}
        />
        <SearchableDropdown
          items={UPLOAD_STATUS}
          label="Upload Status"
          placeholder="Upload Status"
          onSelectionChange={(items: SearchableDropdownItem[]) => {
            setFilterByUploadStatus(items);
          }}
          selectedItems={filterByUploadStatus}
        />
        <Button
          text="Clear Filters"
          appearance="link"
          className={`text-interaction-primary !px-2 no-underline hover:no-underline ${isFilterActive ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"}`}
          onClick={clearFeedFilters}
        />
      </div>
      <div className="gap-1 flex flex-row sm:justify-between items-center">
        {/* Removing for now to prevent UI distored */}
        {/* <Small>{totalFilteredMediaCount} Videos</Small> */}
        <Select
          items={[
            { id: MediaSortByEnum.DescSessionDate, primary: "Most Recent Session" },
            { id: MediaSortByEnum.DescSessionUploadDate, primary: "Newest Upload" },
            { id: MediaSortByEnum.DescSessionRating, primary: "Highest Session Rating" },
            { id: MediaSortByEnum.AscSessionRating, primary: "Lowest Session Rating" },
          ]}
          title=""
          placeholderText=""
          selected={sortBy}
          setSelected={(item) => {
            setSortBy(item);
          }}
          inputContainerClassName="mt-0 w-52"
          className="flex"
          actionItem={{
            label: "Reset Sort",
            onClick: () => {
              setSortBy(defaultSortBy);
            },
          }}
        />
      </div>
    </div>
  );
};
