import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { scrollToDivById } from "@utils/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";

import { ApiReportReportStatusChoices, ReportType } from "@api/graphql/types-and-hooks";
import useGenerateDocx from "@components/HtmlToDocx/useGenerateDOCX";
import { Legend } from "@components/Legend/Legend";
import { ReportHeaderSection } from "@components/ReportHeaderSection/ReportHeaderSection";
import { SupportedContentType } from "@components/SectionWidget/SectionWidget";
import { SignatureSection } from "@components/SignatureSection/SignatureSection";
import { notifyWarn } from "@components/notifications/notifications";
import { useSignatureHandler } from "@hooks/useSignatureHandler";
import { GeneralInfoSection } from "@pages/EvaluationDetails/Components/GeneralInfoSection";
import { SectionWidgetWrapper } from "@pages/EvaluationDetails/Components/SectionWidgetWrapper";
import {
  FilterSectionSubRoute,
  findErrorSection,
  useGetFilteredPageSectionsByLocation,
} from "@pages/EvaluationDetails/Components/helper";
import { FooterButtonRow } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/FooterButtonRow";
import { StickyNav } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/StickyNav/StickyNav";
import { useAdminData } from "@providers/AdminDataProvider.tsx";
import { useEvaluationData } from "@providers/EvaluationProvider";

const pageLocation: FilterSectionSubRoute = "review-and-sign";

export const EvaluationReviewAndSign: React.FC = () => {
  const [isWarningVisible, setIsWarningVisible] = useState<boolean>(false);
  const filteredSections = useGetFilteredPageSectionsByLocation(pageLocation);
  const appendixSections = useGetFilteredPageSectionsByLocation("appendix");
  const flags = useFlags<FeatureFlags>();
  const { hasCustomOutputTemplate } = useAdminData();
  const { evaluationQuery } = useEvaluationData();
  const evaluation = evaluationQuery.data?.getReports?.edges[0]?.node;
  const reportIsCompleted = evaluation?.reportStatus === ApiReportReportStatusChoices.Completed;
  const { isLoading } = evaluationQuery;
  const {
    signature,
    setSignature,
    closingRemark,
    setClosingRemark,
    professionalTitle,
    setProfessionalTitle,
    licenseNumber,
    setLicenseNumber,
    timestamp,
    setTimestamp,
    isGenerating,
    setIsGenerating,
    handleSignReport,
  } = useSignatureHandler({
    report: evaluation as unknown as ReportType,
    isCompleted: reportIsCompleted,
    refetchData: evaluationQuery?.refetch,
  });

  const { generateDocx, loading } = useGenerateDocx({
    fetchLogoUrl: async () => {
      const res = await evaluationQuery?.refetch();
      const report = res.data?.getReports?.edges[0]?.node as unknown as ReportType;
      return report?.reportHeaderLogoSignedUrl;
    },
  });

  const supportedContentTypes = ["Text", "Table"] as SupportedContentType[];
  if (flags["upload-images-component"]) {
    supportedContentTypes.push("Image");
  }

  return (
    <div className="flex w-full flex-col">
      <div className="grid grid-cols-4 gap-x-1">
        <div id="EvaluationReport" className={`col-span-3 flex w-full flex-col gap-y-4`}>
          {hasCustomOutputTemplate ? null : (
            <ReportHeaderSection
              report={evaluation as unknown as ReportType}
              refetchData={evaluationQuery?.refetch}
              reportIsCompleted={reportIsCompleted}
            />
          )}
          <GeneralInfoSection />
          {filteredSections.map((section) => (
            <SectionWidgetWrapper
              key={section.id}
              section={section}
              permissions={{
                canDelete: false,
                canEdit: false,
              }}
              supportedContentTypes={supportedContentTypes}
              allowInLineEdit={!reportIsCompleted}
              showErrorWarning={isWarningVisible}
            />
          ))}
          <SignatureSection
            signature={signature}
            setSignature={setSignature}
            closingRemark={closingRemark}
            setClosingRemark={setClosingRemark}
            timestamp={timestamp}
            setTimestamp={setTimestamp}
            professionalTitle={professionalTitle}
            setProfessionalTitle={setProfessionalTitle}
            licenseNumber={licenseNumber}
            setLicenseNumber={setLicenseNumber}
            isReadOnly={reportIsCompleted || isGenerating}
          />
          {flags["dynamic-report-output-diagnosis-builder"] ? (
            <>
              <div className=" -mt-4">
                <Legend title="Appendix" position="center" />
              </div>
              {appendixSections.map((section) => (
                <SectionWidgetWrapper
                  key={section.id}
                  section={section}
                  permissions={{
                    canDelete: false,
                    canEdit: false,
                  }}
                  supportedContentTypes={supportedContentTypes}
                  allowInLineEdit={!reportIsCompleted}
                  showErrorWarning={isWarningVisible}
                />
              ))}
            </>
          ) : null}
        </div>

        <div className="flex w-full flex-col pl-8">
          <StickyNav state={filteredSections} />
        </div>
      </div>
      {!reportIsCompleted ? (
        <FooterButtonRow
          primaryButtonTitle={flags["dynamic-report-output-diagnosis-builder"] ? "Complete Report" : "Sign & Complete"}
          primaryButtonLoading={loading || isGenerating || isLoading}
          primaryButtonAction={async () => {
            const errorSection = findErrorSection(filteredSections);
            if (errorSection) {
              setIsWarningVisible(true);
              scrollToDivById(errorSection.id);
            } else if (signature.replace(/\s/g, "").length === 0) {
              notifyWarn("To generate the report, you need to provide your signature.");
              scrollToDivById("signature");
            } else {
              setIsGenerating(true);
              const element = document.getElementById("EvaluationReport");
              if (!flags["dynamic-report-output-diagnosis-builder"]) {
                const file = await generateDocx(element);
                if (file) {
                  await handleSignReport(file);
                }
              } else {
                await handleSignReport(null);
              }
            }
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
