import { datadogRum } from "@datadog/browser-rum";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { FeedbackDatum, WYSIWYG } from "@fronterahealth/frontera-ui-components";

import { AssessmentReportFieldsEnums, useAddUpdateAssessmentFieldValuesMutation } from "@api/graphql/types-and-hooks";
import { AiFeedbackDialog } from "@components/AiFeedbackDialog/AiFeedbackDialog";
import { AssessmentReportHeadingType } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/ReportSummaryPart1";
import { fieldEnumToHumanLabelMapping } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/assessmentFieldEnumMapping";

interface SmartFieldWrapperProps {
  section: AssessmentReportHeadingType;
  field: AssessmentReportFieldsEnums;
  existingContent?: string | null;
  existingId?: string | null | undefined;
  behaviorIndex?: number | null;
  isDisplayMode?: boolean;
  onValueSave?: () => void;
  showTitle?: boolean;
  refetch?: () => void;
}

export const SmartFieldWrapper: React.FC<SmartFieldWrapperProps> = ({
  field,
  section,
  existingContent,
  existingId,
  behaviorIndex,
  isDisplayMode = false,
  onValueSave,
  showTitle = true,
  refetch,
}) => {
  const { assessmentId } = useParams();

  const [aiFeedbackVote, setAiFeedbackVote] = useState<boolean>(false);
  const [aiFeedbackQuestion, setAiFeedbackQuestion] = useState<string>("");
  const [aiFeedbackAnswer, setAiFeedbackAnswer] = useState<string>("");
  const [aiFeedbackDialogOpen, setAiFeedbackDialogOpen] = useState<boolean>(false);
  const addUpdateAssessmentFieldValuesMutation = useAddUpdateAssessmentFieldValuesMutation({});
  const { isPending } = addUpdateAssessmentFieldValuesMutation;
  const isSaving = isPending;

  const onAiGoalVote = (datum: FeedbackDatum, vote: boolean) => {
    datadogRum.addAction("ai-vote", {
      type: "report-field",
      direction: vote ? "up-vote" : "down-vote",
      field,
    });
    setAiFeedbackVote(vote);
    setAiFeedbackAnswer(datum.content);
    setAiFeedbackQuestion(datum.question);
    setAiFeedbackDialogOpen(true);
  };

  return (
    <>
      <AiFeedbackDialog
        isDialogOpen={aiFeedbackDialogOpen}
        setDialogOpen={setAiFeedbackDialogOpen}
        positiveFeedback={aiFeedbackVote}
        objectType="reportField"
        objectId={existingId || null}
        promptAnswer={aiFeedbackAnswer}
        promptQuestion={aiFeedbackQuestion}
      />
      <WYSIWYG
        isSaving={isSaving}
        onSave={async ({ content }) => {
          await addUpdateAssessmentFieldValuesMutation
            .mutateAsync({
              assessmentId: assessmentId || "<missing-id>",
              assessmentFieldDetail: {
                fieldKey: field,
                fieldValue: content,
                behaviorIndex: behaviorIndex,
              },
            })
            .then(() => {
              if (section === "BIP" && onValueSave) {
                onValueSave();
              }
              if (refetch) {
                refetch();
              }
            });
        }}
        autoSave
        existingContent={existingContent || ""}
        key={`${section}-${field}`}
        title={fieldEnumToHumanLabelMapping[field]}
        placeholder={isDisplayMode ? "" : "Fill me in"}
        displayOnlyMode={isDisplayMode}
        showTitle={showTitle}
        onUpVote={(datum) => onAiGoalVote(datum, true)}
        onDownVote={(datum) => onAiGoalVote(datum, false)}
      />
    </>
  );
};
