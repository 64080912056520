import { getIdList } from "@utils/helpers";
import { capitalizeString } from "@utils/utils";
import { isNil } from "lodash";
import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MultiSelectItem, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  ApiInsuranceDetailsInNetworkChoices,
  ApiInsuranceDetailsInsuranceTypeChoices,
  ApiInsuranceDetailsInsurerChoices,
  CaregiverInput,
  CreateLearnerCaregiverMutationVariables,
  useCreateLearnerCaregiverMutation,
} from "@api/graphql/types-and-hooks";
import { ContentRow } from "@components/ContentRow/ContentRow";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import {
  AvailabilityDays,
  AvailabilityTimes,
  BCBA_OPTIONS,
  NEAREST_LOCATIONS_OPTIONS,
  PSYCHOLOGIST_OPTIONS,
  REFERRAL_SOURCE_OPTIONS,
  SMSAgreementItem,
} from "@pages/NewClient/NewClientOnboarding/NewClientOnboarding.interfaces";
import { AdditionalProperty } from "@pages/NewClient/helpers";
import { useAdminData } from "@providers/AdminDataProvider";
import { useClientData } from "@providers/ClientProvider";

interface NewClientOnboardingProps {
  setIsFormDirty?: (val: boolean) => void;
}
export const NewClientOnboarding: React.FC<NewClientOnboardingProps> = ({ setIsFormDirty }) => {
  const [searchParams] = useSearchParams();

  const { learnersQuery } = useAdminData();
  const { caregivers, isCaregiverLoading, assignableLearnerProviders, isProvidersLoading } = useClientData();

  const { refetch: refetchLearners } = learnersQuery;

  const formRef = useRef<HTMLFormElement | null>(null);

  const createLearnerCaregiver = useCreateLearnerCaregiverMutation({
    onSuccess: async (data) => {
      if (data.createLearnerCaregiver?.status) {
        notifySuccess("Client created successfully");
        refetchLearners();
        go("/clients");
      } else if (!data.createLearnerCaregiver?.status && data.createLearnerCaregiver?.message) {
        notifyError(data.createLearnerCaregiver?.message);
      } else {
        notifyError("Something Went Wrong!");
      }
    },
  });

  const go = useNavigate();

  const defaultValues = {
    learner: {
      // @ts-ignore: Ignoring the compiler and risking bugs because: see above
      hasPreviousDiagnosis: "no",
    },
    isExistingCaregiver: "no",
    sendIntake: true,
  };
  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormRadioInput,
    RegisteredFormSelected,
    RegisteredCheckboxList,
    RegisteredPhoneNumberInput,
    RegisteredFormMultiSelect,
    watch,
    setValue,
    unregister,
  } = useFormUtils<CreateLearnerCaregiverMutationVariables & AdditionalProperty>({
    /**
     * We are complicating the mutationFn here and pulling off form data from the native
     * event (instead of relying on React Hook Form's data management) because the data structure
     * for "availability" and how we're rendering the input are fairly difficult to reconcile
     *
     * `availability` as per the data type is an array of {day: string, timeOfDay:string} objects
     * Any time React Hook Form sees an array of objects, it assumes the user will submit 0 or more
     * and expects you to use the append/fields setup from useFieldArray(). In our case, we don't actually
     * want to allow the user to additionally add an extra form field for every availability combo
     *
     * Instead, how we're rendering the input for this is a list of 4-option checkbox rows for each day of the week
     * Because of this, we do some hackery here to get the data from how it's rendered to how it needs to be submitted
     */
    mutationFn: async (params, e) => {
      // @ts-ignore: Ignoring the compiler and risking bugs because: FormData is not type correctly here
      const formData = new FormData(e?.nativeEvent?.target);
      const slots = Array.from(formData.entries())
        .filter(([name]) => name.includes("availability"))
        // this split on "." works because our formKey for these Form inputs look like this
        // `availability.${day}` and the value is "morning" | "afternoon"  | etc
        .map(([name, value]) => ({
          day: name.split(".").pop()?.toUpperCase() || "",
          timeOfDay: (value as string).toUpperCase(),
        }));

      const { learner, insurance, caregiver, providerIds: providers } = params;

      learner.diagnosisAppointmentDate === "" && delete learner.diagnosisAppointmentDate;
      learner.assessmentAppointmentDate === "" && delete learner.assessmentAppointmentDate;

      // @ts-ignore: Ignoring the compiler and risking bugs because: see above
      learner.hasPreviousDiagnosis === "yes"
        ? (learner.hasPreviousDiagnosis = true)
        : (learner.hasPreviousDiagnosis = false);

      const newParams = {
        learner,
        insurance: {
          ...insurance,
          insuranceType: insurance?.insuranceType ? convertDBString(insurance.insuranceType) : null,
          insurer: insurance?.insurer ? convertDBString(insurance.insurer) : null,
          inNetwork: insurance?.inNetwork ? convertDBString(insurance?.inNetwork) : null,
        },
        caregiver: {
          ...caregiver,
          smsAgreement: Boolean(caregiver?.smsAgreement),
        },
        providerIds: providers ? getIdList(providers as unknown as MultiSelectItem[]) : null,
        availability: { slots },
        sendIntake: true,
      };
      await createLearnerCaregiver!.mutateAsync(newParams as CreateLearnerCaregiverMutationVariables);
    },
    defaultValues: {
      learner: {
        // @ts-ignore: Ignoring the compiler and risking bugs because: see above
        hasPreviousDiagnosis: defaultValues.learner.hasPreviousDiagnosis,
        firstName: "",
        lastName: "",
        birthDate: "",
        diagnosisAppointmentDate: "",
      },
      isExistingCaregiver: defaultValues.isExistingCaregiver,
      sendIntake: defaultValues.sendIntake,
      caregiver: {
        firstName: "",
        lastName: "",
        email: "",
        smsAgreement: false,
      },
      insurance: {
        inNetwork: null,
      },
      availability: {
        // @ts-ignore: Ignoring the compiler and risking bugs because: see above
        FRIDAY: false,
        MONDAY: false,
        SATURDAY: false,
        SUNDAY: false,
        THURSDAY: false,
        TUESDAY: false,
        WEDNESDAY: false,
      },
    },
  });

  // calculation for tracks any change in the form.
  const initialValues = useRef(watch());

  useEffect(() => {
    const currentValues = watch();
    if (JSON.stringify(currentValues) !== JSON.stringify(initialValues.current) && setIsFormDirty) {
      setIsFormDirty(true);
    }
  }, [watch]);

  // @ts-ignore: Ignoring the compiler and risking bugs because: see above
  const clientHasPreviousDiagnosis = watch("learner.hasPreviousDiagnosis") === "yes";

  const isExistingCaregiver = watch("isExistingCaregiver");

  const selectedCaregiverID = isExistingCaregiver === "yes" ? watch("caregiver.caregiverId") : null;
  useEffect(() => {
    if (selectedCaregiverID) {
      const caregiver = caregivers.find((caregiver) => caregiver.id === selectedCaregiverID);
      if (caregiver) {
        setValue("caregiver", {
          caregiverId: caregiver.id,
          firstName: caregiver.caregivermetadata?.firstName,
          lastName: caregiver.caregivermetadata?.lastName,
          email: caregiver.caregivermetadata?.email,
          nearestLocation: caregiver.caregivermetadata?.nearestLocation,
          phoneNumber1: caregiver.caregivermetadata?.phoneNumber1,
          referralSource: caregiver.caregivermetadata?.referralSource,
          smsAgreement: caregiver.caregivermetadata?.smsAgreement ? "yes" : "no",
        } as unknown as CaregiverInput);
      }
    }
    if (!selectedCaregiverID) {
      unregister("caregiver.caregiverId", { keepDefaultValue: true });
    }
  }, [selectedCaregiverID, unregister]);

  return (
    <FormContainer onSubmit={onSubmit} ref={formRef}>
      <div className={`${!searchParams.get("client-type") ? "mt-10" : ""} flex w-full flex-col gap-y-4`}>
        <ContentRow title="Client Details">
          <div className="col-span-3 flex flex-col">
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learner.firstName"
                inputSize="full"
                formState={formState}
                label="First Name"
              />
              <RegisteredFormInput
                formKey="learner.lastName"
                inputSize="full"
                formState={formState}
                label="Last Name"
              />
            </div>

            <div className="gap-x-4 lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learner.birthDate"
                inputSize="full"
                type="date"
                formState={formState}
                label="Date of Birth"
              />
              <div />
            </div>

            <div className="gap-x-4 lg:grid lg:grid-cols-2">
              <RegisteredFormRadioInput
                formState={formState}
                formKey="learner.hasPreviousDiagnosis"
                title="Has Previous Diagnosis"
                items={[
                  {
                    id: "no",
                    title: "No",
                  },
                  {
                    id: "yes",
                    title: "Yes",
                  },
                ]}
              />
              <div />
            </div>

            <div className="gap-x-4 lg:grid lg:grid-cols-2">
              {clientHasPreviousDiagnosis ? (
                <>
                  <RegisteredFormInput
                    formKey="learner.assessmentAppointmentDate"
                    inputSize="full"
                    type="datetime-local"
                    formState={formState}
                    required={false}
                    label="Assessment Appointment Date"
                  />

                  <RegisteredFormSelected
                    formKey="learner.assessmentBcba"
                    formState={formState}
                    placeholderText={""}
                    title="BCBA for Assessment"
                    required={false}
                    errorMessage={formState.errors.learner?.assessmentBcba?.message}
                    items={BCBA_OPTIONS.map((o) => ({ primary: o }))}
                  />
                </>
              ) : (
                <>
                  <RegisteredFormInput
                    formKey="learner.diagnosisAppointmentDate"
                    inputSize="full"
                    required={false}
                    type="datetime-local"
                    formState={formState}
                    label="Diagnosis Appointment Date"
                  />

                  <RegisteredFormSelected
                    formKey="learner.diagnosisPsychologist"
                    formState={formState}
                    placeholderText={""}
                    required={false}
                    // required={!clientHasPreviousDiagnosis}
                    title="Psychologist for Diagnosis"
                    errorMessage={formState.errors.learner?.diagnosisPsychologist?.message}
                    items={PSYCHOLOGIST_OPTIONS.map((o) => ({ primary: o }))}
                  />
                </>
              )}
            </div>
          </div>
        </ContentRow>
        <ContentRow title="Caregiver Details">
          <div className="col-span-3 flex flex-col">
            <DoubleColumnContainer>
              <RegisteredFormRadioInput
                formState={formState}
                formKey="isExistingCaregiver"
                title="Existing Caregiver?"
                items={[
                  {
                    id: "yes",
                    title: "Yes",
                  },
                  {
                    id: "no",
                    title: "No",
                  },
                ]}
              />
            </DoubleColumnContainer>
            {isExistingCaregiver === "yes" && (
              <RegisteredFormSelected
                formKey="caregiver.caregiverId"
                formState={formState}
                placeholderText={""}
                title="Select Existing Caregiver"
                isLoading={isCaregiverLoading}
                items={
                  caregivers
                    .sort((a, b) =>
                      (a.caregivermetadata?.firstName || "") > (b.caregivermetadata?.firstName || "") ? 1 : -1,
                    )
                    .map((o) => ({
                      primary: `${o?.caregivermetadata?.firstName} ${o?.caregivermetadata?.lastName}`,
                      secondary: o.caregivermetadata?.email,
                      id: o?.id,
                    })) as unknown as SelectItem[]
                }
              />
            )}
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="caregiver.firstName"
                inputSize="full"
                formState={formState}
                label="First Name"
                disabled={isExistingCaregiver === "yes"}
                readOnly={!isNil(selectedCaregiverID)}
              />
              <RegisteredFormInput
                formKey="caregiver.lastName"
                inputSize="full"
                formState={formState}
                label="Last Name"
                disabled={isExistingCaregiver === "yes"}
                readOnly={!isNil(selectedCaregiverID)}
              />
            </div>

            <div className="gap-x-4 lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="caregiver.email"
                inputSize="full"
                helpText="An invite for digital onboarding will be sent to this email address."
                formState={formState}
                label="Email Address"
                readOnly={!isNil(selectedCaregiverID)}
                disabled={isExistingCaregiver === "yes"}
              />
            </div>
            <div className="gap-x-4 lg:grid lg:grid-cols-2">
              <RegisteredPhoneNumberInput
                formKey="caregiver.phoneNumber1"
                formState={formState}
                defaultCountry="US"
                label="Phone Number"
                readOnly={!isNil(selectedCaregiverID)}
                required={true}
                disabled={isExistingCaregiver === "yes"}
              />
            </div>

            <div className="gap-x-4 lg:grid lg:grid-cols-2">
              <RegisteredFormSelected
                formKey="caregiver.nearestLocation"
                formState={formState}
                placeholderText={""}
                title="Nearest Location"
                errorMessage={formState.errors.caregiver?.nearestLocation?.message}
                items={NEAREST_LOCATIONS_OPTIONS.map((o) => ({ primary: o }))}
                readOnly={!isNil(selectedCaregiverID)}
                disabled={isExistingCaregiver === "yes"}
              />
              <RegisteredFormSelected
                formKey="caregiver.referralSource"
                formState={formState}
                placeholderText={""}
                required={false}
                title="Referral Source"
                items={REFERRAL_SOURCE_OPTIONS.map((o) => ({ primary: o }))}
                readOnly={!isNil(selectedCaregiverID)}
                disabled={isExistingCaregiver === "yes"}
              />
            </div>

            <div className="gap-x-4 lg:grid lg:grid-cols-1">
              <RegisteredCheckboxList
                formKey="caregiver.smsAgreement"
                formState={formState}
                required={false}
                title="SMS Agreement"
                items={SMSAgreementItem}
                readOnly={!isNil(selectedCaregiverID)}
                disabled={isExistingCaregiver === "yes"}
              />
              <div />
            </div>
          </div>
        </ContentRow>
        <ContentRow title="Providers" subtitle="Assign the providers that will be working with this client.">
          <RegisteredFormMultiSelect
            title={"Assign providers"}
            items={assignableLearnerProviders.sort((a, b) => (a.primary > b.primary ? 1 : -1))}
            placeholderText={"available providers"}
            formKey="providerIds"
            formState={formState}
            mode={"tags"}
            isLoading={isProvidersLoading}
            required={false}
          />
        </ContentRow>
        <ContentRow title="Insurance">
          <div className="col-span-3 flex flex-col">
            <div className="gap-x-4 lg:grid lg:grid-cols-2">
              <RegisteredFormSelected
                formKey={`insurance.insuranceType`}
                title="Insurance Type"
                formState={formState}
                errorMessage={formState.errors.insurance?.insuranceType?.message}
                items={Object.values(ApiInsuranceDetailsInsuranceTypeChoices).map((o) => ({
                  primary: convertReadableString(o),
                }))}
                placeholderText={""}
              />
              <div />
            </div>

            <div className="gap-x-4 lg:grid lg:grid-cols-2">
              <RegisteredFormSelected
                formKey={`insurance.insurer`}
                title="Insurer"
                errorMessage={formState.errors.insurance?.insurer?.message}
                formState={formState}
                items={Object.values(ApiInsuranceDetailsInsurerChoices).map((o) => ({
                  primary: convertReadableString(o),
                }))}
                placeholderText={""}
              />
              <div />

              <RegisteredFormRadioInput
                formState={formState}
                formKey="insurance.inNetwork"
                title="In-Network"
                items={Object.keys(ApiInsuranceDetailsInNetworkChoices).map((o) => {
                  return {
                    title: convertReadableString(o),
                    id: o,
                  };
                })}
              />
            </div>
          </div>
        </ContentRow>
        <ContentRow title="Availability">
          <div className="gap-x-4  lg:grid lg:grid-cols-1">
            {AvailabilityDays.map((day) => {
              const formattedDay = capitalizeString(day);
              return (
                <RegisteredCheckboxList
                  key={formattedDay}
                  // @ts-ignore: Ignoring the compiler and risking bugs because: We are overriding how the React Hook Form typings work
                  formKey={`availability.${day}`}
                  title={formattedDay}
                  required={false}
                  legend={formattedDay}
                  orientation="horizontal"
                  items={AvailabilityTimes}
                />
              );
            })}
          </div>
        </ContentRow>
      </div>
      <div className="mt-6">
        <SubmitButton isLoading={formState.isSubmitting} buttonText="Save & Continue" />
      </div>
    </FormContainer>
  );
};
