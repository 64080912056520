import { EyeIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Dialog, Select, SelectItem, Table } from "@fronterahealth/frontera-ui-components";

import { LearnerType, useProviderByIdQuery, useUpdateProviderLearnersMutation } from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAdminData } from "@providers/AdminDataProvider";
import { useProvidersData } from "@providers/ProvidersDataProvider";

export const ProviderClients: React.FC = () => {
  const { providerId } = useParams();
  const { providersQuery } = useProvidersData();

  const { learnersQuery } = useAdminData();

  const allLearners = (learnersQuery?.data?.learner || []).filter(Boolean) as LearnerType[];
  const go = useNavigate();

  const [addClientDialogOpen, setAddClientDialogOpen] = useState(false);
  const [selectedClientToAdd, setSelectedClientToAdd] = useState<SelectItem | null>(null);

  const providerDetailsQuery = useProviderByIdQuery(
    { providerId: providerId ? providerId : "" },
    {
      queryKey: ["provider-by-id", providerId],
      enabled: !!providerId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data, refetch } = providerDetailsQuery;

  const updateProviderLearnersMutation = useUpdateProviderLearnersMutation({});

  const { data: updateData } = updateProviderLearnersMutation;

  useEffect(() => {
    if (updateData?.updateProviderLearners) {
      if (updateData?.updateProviderLearners.status) {
        notifySuccess(updateData.updateProviderLearners?.message ? updateData.updateProviderLearners?.message : "");
      } else {
        notifyError(updateData.updateProviderLearners?.message ? updateData.updateProviderLearners?.message : "");
      }
    }
  }, [updateData]);

  const learners =
    (data?.providerById?.learnerproviderSet?.edges?.map((edge) => edge?.node?.learner) as LearnerType[]) || [];

  const learnerIdSet = learners.map((learner) => learner.id);

  const onRemoveClient = async (id: string) => {
    const newLearnerIdSet = learnerIdSet.filter((learnerId) => learnerId !== id);

    await updateProviderLearnersMutation.mutateAsync({
      learners: newLearnerIdSet,
      providerId: providerId ? providerId : "",
    });
    await refetch();
    await providersQuery.refetch();
  };

  const onAddClient = async () => {
    if (!selectedClientToAdd) return;
    const newLearnerIdSet = [...learnerIdSet, selectedClientToAdd.id!];

    await updateProviderLearnersMutation.mutateAsync({
      learners: newLearnerIdSet,
      providerId: providerId ? providerId : "",
    });
    await refetch();
    await providersQuery.refetch();
  };

  return (
    <div className="flex w-full flex-col items-start">
      <Dialog
        open={addClientDialogOpen}
        setOpen={setAddClientDialogOpen}
        title={"Add Client to Provider"}
        primaryButton={{
          text: "Add Client",
          onClick: async () => {
            await onAddClient();
            setAddClientDialogOpen(false);
          },
        }}
        secondaryButton={undefined}
      >
        <Select
          title={"Available Clients"}
          items={
            allLearners
              .filter((learner) => !learnerIdSet.includes(learner.id))
              .map((learner) => ({
                id: learner.id,
                primary: `${learner.learnermetadata?.firstName} ${learner.learnermetadata?.lastName}`,
              }))
              .sort((a, b) => (a.primary || "").localeCompare(b.primary || "")) as SelectItem[]
          }
          placeholderText={"Select a client"}
          selected={selectedClientToAdd}
          setSelected={setSelectedClientToAdd}
        />
      </Dialog>
      <Button
        onClick={() => {
          setAddClientDialogOpen(true);
        }}
        text={"Add Client"}
        appearance={"primary"}
        className="self-end"
      />
      <div className="flow-root mt-8 bg-white w-full">
        <Table<{
          id: string;
          globalId: string;
          clientName: string;
          caregiverNames: string;
          caregiverEmails: string;
        }>
          data={learners.map((learner) => {
            const base64DecodedLearnedId = atob(learner.id);
            const databaseId = base64DecodedLearnedId.split("LearnerType:")?.pop();
            const learnerCaregiversMetadata = learner?.relationshipSet?.edges?.map(
              (node) => node?.node?.caregiver?.caregivermetadata,
            );
            return {
              id: databaseId || "<missing-database-id>",
              globalId: learner.id,
              clientName: `${learner.learnermetadata?.firstName} ${learner.learnermetadata?.lastName}`,
              caregiverEmails: learnerCaregiversMetadata?.map((caregiver) => caregiver?.email).join(", ") || "",
              caregiverNames:
                learnerCaregiversMetadata
                  ?.map((caregiver) => `${caregiver?.firstName} ${caregiver?.lastName}`)
                  .join(", ") || "",
            };
          })}
          actions={[
            {
              label: "View Client",
              Icon: EyeIcon,
              callback: (client) => {
                go(`/clients/${client.globalId}`);
              },
            },
            {
              label: "Remove Client",
              Icon: TrashIcon,
              callback: async (client) => {
                onRemoveClient(client.globalId);
              },
            },
          ]}
          columns={[
            { dataIndex: "id", key: "id", title: "Client ID" },
            { dataIndex: "clientName", key: "clientName", title: "Client Name" },
            { dataIndex: "caregiverNames", key: "caregiverNames", title: "Caregiver Name(s)" },
            { dataIndex: "caregiverEmails", key: "caregiverEmails", title: "Caregiver Email(s)" },
          ]}
          showSearch
        />
      </div>
    </div>
  );
};
