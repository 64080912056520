import { useState } from "react";

import { Button, Dialog, Table, TextArea } from "@fronterahealth/frontera-ui-components";

interface DsmCriterion {
  id: string;
  parentId?: string;
  isChecked: boolean;
  isCheckDisabled: boolean;
  description: string;
  note: string | null;
  isAllowNote: boolean;
}

interface DsmTableProps {
  data: DsmCriterion[];
  isLoading?: boolean;
  onSave: (data: DsmCriterion[]) => Promise<unknown>;
  displayOnlyMode: boolean;
}

export const DSM_TABLE_FIELD_IDENTIFIER = "DSM_Table";

const getDefaultDsmData = (): DsmCriterion[] => {
  return [
    {
      id: "A",
      isChecked: false,
      isCheckDisabled: true,
      description:
        "Persistent deficits in social communication and social interaction across multiple contexts, as manifested by all of the following, currently or by history:",
      note: null,
      isAllowNote: false,
    },
    {
      id: "A1",
      parentId: "A",
      isChecked: false,
      isCheckDisabled: false,
      description: "Deficits in social-emotional reciprocity",
      note: null,
      isAllowNote: true,
    },
    {
      id: "A2",
      parentId: "A",
      isChecked: false,
      isCheckDisabled: false,
      description: "Deficits in nonverbal communicative behaviors used for social interaction",
      note: null,
      isAllowNote: true,
    },
    {
      id: "A3",
      parentId: "A",
      isChecked: false,
      isCheckDisabled: false,
      description: "Deficits in developing, maintaining, and understanding relationships",
      note: null,
      isAllowNote: true,
    },
    {
      id: "B",
      isChecked: false,
      isCheckDisabled: true,
      description:
        "Restricted, repetitive patterns of behavior, interests, or activities, as manifested by at least two of the following, currently or by history:",
      note: null,
      isAllowNote: false,
    },
    {
      id: "B1",
      parentId: "B",
      isChecked: false,
      isCheckDisabled: false,
      description: "Stereotyped or repetitive motor movements, use of objects, or speech",
      note: null,
      isAllowNote: true,
    },
    {
      id: "B2",
      parentId: "B",
      isChecked: false,
      isCheckDisabled: false,
      description:
        "Insistence on sameness, inflexible adherence to routines, or ritualized patterns of verbal or nonverbal behavior",
      note: null,
      isAllowNote: true,
    },
    {
      id: "B3",
      parentId: "B",
      isChecked: false,
      isCheckDisabled: false,
      description: "Highly restricted, fixated interests that are abnormal in intensity or focus",
      note: null,
      isAllowNote: true,
    },
    {
      id: "B4",
      parentId: "B",
      isChecked: false,
      isCheckDisabled: false,
      description:
        "Hyper- or hyporeactivity to sensory input or unusual interest in sensory aspects of the environment",
      note: null,
      isAllowNote: true,
    },
    {
      id: "C",
      isChecked: false,
      isCheckDisabled: false,
      description: "Symptoms must be present in the early developmental period",
      note: null,
      isAllowNote: true,
    },
    {
      id: "D",
      isChecked: false,
      isCheckDisabled: false,
      description:
        "Symptoms cause clinically significant impairment in social, occupational, or other important areas of current functioning",
      note: null,
      isAllowNote: true,
    },
    {
      id: "E",
      isChecked: false,
      isCheckDisabled: false,
      description:
        "These differences are not better explained by intellectual disability or global developmental delay",
      note: null,
      isAllowNote: true,
    },
  ];
};

export const DsmTable: React.FC<DsmTableProps> = ({ data, onSave, isLoading = false, displayOnlyMode = false }) => {
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);
  const [selectedCriterion, setSelectedCriterion] = useState<DsmCriterion | null>(null);
  const [dsmNote, setDsmNote] = useState<string | null>(null);
  const [tableData, setTableData] = useState(() =>
    getDefaultDsmData().map((defaultDsmCriterion: DsmCriterion) => {
      if (!data) return defaultDsmCriterion;
      const dsmCriterion = data.find((d) => d.id === defaultDsmCriterion.id);
      return { ...defaultDsmCriterion, ...dsmCriterion };
    }),
  );

  const dsmNoteDialog = () => {
    return (
      <Dialog
        open={isNoteDialogOpen}
        setOpen={setIsNoteDialogOpen}
        title={`Note for Criterion ${selectedCriterion?.id}`}
        primaryButton={{
          text: "Save Note",
          buttonAction: "regular",
          onClick: () => {
            if (!selectedCriterion) return;
            const newTableData = tableData.map((criterion) =>
              criterion.id === selectedCriterion.id ? { ...criterion, note: dsmNote } : criterion,
            );
            setTableData(newTableData);
            onSave(newTableData);
            setIsNoteDialogOpen(false);
          },
          className: "px-6",
        }}
        secondaryButton={{
          text: "Close",
          onClick: () => setIsNoteDialogOpen(false),
        }}
      >
        <TextArea
          label="Note"
          autoFocus
          value={dsmNote || ""}
          onChange={(event) => {
            setDsmNote(event.target.value);
          }}
        />
      </Dialog>
    );
  };

  const enabledCheckClasses = "text-hatch-600 focus:ring-hatch-600";
  const disabledCheckClasses = "text-limestone-200 focus:ring-limestone-600";
  const getCheckboxClasses = (record: DsmCriterion) => {
    const isDisabled = record.isCheckDisabled || displayOnlyMode;
    return isDisabled ? disabledCheckClasses : enabledCheckClasses;
  };

  return (
    <>
      {selectedCriterion && dsmNoteDialog()}
      <Table
        loading={isLoading}
        defaultItemsPerPage={20}
        cellClassName="cursor-default"
        className="my-1"
        data={tableData}
        columns={[
          {
            dataIndex: "isChecked",
            key: "isChecked",
            title: "",
            className: "border-r-2 align-top",
            render: (value, record) => {
              // Don't display checkbox if it is disabled and unchecked
              if (record.isCheckDisabled && !record.isChecked) return;
              return (
                <input
                  checked={value as boolean}
                  disabled={record.isCheckDisabled || displayOnlyMode}
                  type="checkbox"
                  className={`h-4 w-4 rounded border-gray-300 ${getCheckboxClasses(record)}`}
                  onChange={(evt) => {
                    evt.stopPropagation();
                    record.isChecked = !record.isChecked;
                    if (record.parentId) {
                      const parentRecord = tableData.find((dsmCriterion) => dsmCriterion.id === record.parentId);
                      const hasChildChecked = tableData
                        .filter((dsmCriterion) => {
                          return dsmCriterion.parentId === record.parentId;
                        })
                        .reduce((hasChildChecked, dsmCriterion) => {
                          return dsmCriterion.isChecked || hasChildChecked;
                        }, false);
                      parentRecord!.isChecked = hasChildChecked;
                    }
                    onSave(tableData);
                  }}
                />
              );
            },
          },
          {
            dataIndex: "description",
            key: "description",
            title: "DSM-5 Criterion",
            render: (value, record) => {
              return (
                <table className="w-full text-text-secondary">
                  <tbody>
                    <tr>
                      <td className="w-[40px] align-top">
                        <b>{record.id}.</b>
                      </td>
                      <td className="align-top">{value}</td>
                      <td className="w-[120px] pl-4 align-top">
                        {!displayOnlyMode && (
                          <Button
                            text={`${record?.note?.length ? "Edit" : "Add"} note`}
                            appearance="text"
                            buttonAction="regular"
                            onClick={() => {
                              setSelectedCriterion(record);
                              setDsmNote(record.note);
                              setIsNoteDialogOpen(true);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                    {Boolean(record?.note?.length) && (
                      <tr>
                        <td></td>
                        <td colSpan={2} className="pt-2">
                          <div className="bg-limestone-50 border border-limestone-200 p-2 rounded-md">
                            {record.note}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              );
            },
          },
        ]}
      ></Table>
    </>
  );
};
