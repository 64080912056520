import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Column, Paragraph, Small, Table } from "@fronterahealth/frontera-ui-components";

import { useLearnerByIdQuery, useUpdateLearnerProvidersMutation } from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { AddLearnerProviders } from "@pages/ClientDetailsSubPages/ClientProviders/AddLearnerProviders";

interface ClientProvidersProps {}

export interface AssignableLearnerProvidersType {
  id: string;
  name: string;
  email: string;
  roles: string;
}

export const ClientProviders: React.FC<ClientProvidersProps> = ({}) => {
  const { clientId } = useParams();
  const [isNewProviderDialogueOpen, setIsNewProviderDialogueOpen] = useState<boolean>(false);
  const [learnerProvidersList, setLearnerProvidersList] = useState<AssignableLearnerProvidersType[]>([]);

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );
  const { data, refetch, isFetching } = learnerDetailsQuery;

  const updateLearnerProviderMutation = useUpdateLearnerProvidersMutation({
    onSuccess: (data) => {
      if (data.updateLearnerProviders?.status) {
        notifySuccess(data?.updateLearnerProviders?.message || "");
        refetch();
      } else {
        notifyError("Failed to remove provider!");
      }
    },
  });

  const { mutate: updateLearnerProvider } = updateLearnerProviderMutation;

  const columns: Column<AssignableLearnerProvidersType>[] = [
    {
      title: "Provider Name",
      key: "provider_name",
      dataIndex: "name",
    },
    {
      title: "Type",
      dataIndex: "roles",
      key: "roles",
    },
  ];

  useEffect(() => {
    if (data && data.learnerById) {
      setLearnerProvidersList(
        data.learnerById.learnerproviderSet.edges.map((edge) => {
          const roles = (() => {
            const rolesStringArray = edge?.node?.provider?.userRoles;

            if (!Array.isArray(rolesStringArray)) return ""; // Ensure it's an array

            try {
              const roleNames = rolesStringArray
                .map((role) => JSON.parse(role)?.name)
                .filter((name) => name === "bcba" || name === "rbt")
                .map((name) => name.toUpperCase()); // Remove any null/undefined values

              return roleNames.join(", "); // Join role names into a single string
            } catch {
              return ""; // Return empty string on error
            }
          })();

          return {
            id: edge?.node?.provider.id || "",
            name: `${edge?.node?.provider.providermetadata?.firstName} ${edge?.node?.provider.providermetadata?.lastName}`,
            roles: roles,
            email: edge?.node?.provider.providermetadata?.email || "",
          };
        }),
      );
    }
  }, [data]);

  return (
    <div className="flex w-full flex-col items-start gap-4">
      <div className="flex w-full justify-end">
        <Button
          text="Add Provider"
          appearance="secondary"
          onClick={() => {
            setIsNewProviderDialogueOpen(true);
          }}
          disabled={isFetching}
        />
      </div>
      <div className="flow-root w-full">
        <Table
          data={learnerProvidersList}
          columns={columns}
          loading={isFetching}
          actions={[
            {
              label: "View provider profile",
              callback: (provider) => {
                window.open(`/providers/${provider.id}/details`, "_blank");
              },
            },
            {
              label: "Remove provider mapping",
              callback: (provider) => {
                updateLearnerProvider({
                  learnerId: clientId || "",
                  providerIds: learnerProvidersList.filter((item) => item.id !== provider.id).map((item) => item.id),
                });
              },
            },
          ]}
          emptyMessage={
            <div className="flex flex-col items-center">
              <Paragraph displayType="loud">No Providers Added/Mapped Yet</Paragraph>
              <Small>There are no providers mapped to this client yet.</Small>
            </div>
          }
        />
      </div>
      <AddLearnerProviders
        isDialogOpen={isNewProviderDialogueOpen}
        setDialogOpen={setIsNewProviderDialogueOpen}
        onAddProvider={() => {
          refetch();
        }}
        selectedProviders={learnerProvidersList}
        clientId={clientId}
      />
    </div>
  );
};
