import { formatDurationToMinSec } from "@utils/utils";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Label } from "@fronterahealth/frontera-ui-components";

import { ApiTherapySessionSessionStatusChoices } from "@api/graphql/types-and-hooks";
import { FeedTooltip } from "@pages/VideoSessions/components/Media/FeedTooltip";
import { MediaCardType } from "@pages/VideoSessions/components/MediaCards";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface FeedMediaCardMomentContentProps {
  media: MediaCardType;
}

export const FeedMediaCardMomentContent: React.FC<FeedMediaCardMomentContentProps> = ({ media }) => {
  const { sidebarMomentsList, setSelectedTab } = useVideoSessionData();
  const navigate = useNavigate();
  const isMediaDetailRedirect: boolean =
    media.therapySession?.sessionStatus === ApiTherapySessionSessionStatusChoices.Completed;
  return (
    <div className={`flex flex-col gap-1 ${isMediaDetailRedirect ? "opacity-100" : "opacity-25 cursor-default"}`}>
      {sidebarMomentsList
        .filter((item) => item.isVisible)
        .map((moment) => {
          return (
            <div key={moment.label} className="flex flex-row justify-between">
              <FeedTooltip
                text={
                  moment.label === "Aggression"
                    ? ""
                    : moment.label === "Screaming & Crying"
                      ? ""
                      : `“Other Maladaptive” includes hitting, kicking, self-injury, and property destruction.`
                }
                showTooltip={moment.label === "Other Maladaptive"}
              >
                <Label size="extra-small">{moment.label}</Label>
              </FeedTooltip>

              <div
                className={`flex p-1 bg-limestone-50 hover:bg-limestone-100 ${isMediaDetailRedirect ? "cursor-pointer" : "cursor-default"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isMediaDetailRedirect) {
                    setSelectedTab("moments");
                    navigate(`/video-sessions/${media.id}?mid=${encodeURIComponent(moment.label)}`);
                  }
                }}
              >
                {moment.label === "Aggression" ? (
                  <Label
                    size="extra-small"
                    className={`${isMediaDetailRedirect ? "cursor-pointer" : "cursor-default"}`}
                  >
                    {media.mediaClassifications?.nonContinuousInteractions?.[0]?.interactionCount || 0}
                  </Label>
                ) : moment.label === "Screaming & Crying" ? (
                  <Label
                    size="extra-small"
                    className={`${isMediaDetailRedirect ? "cursor-pointer" : "cursor-default"}`}
                  >
                    {formatDurationToMinSec(
                      media.mediaClassifications?.continuousInteractions?.[0]?.totalDuration || 0,
                    )}
                  </Label>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
