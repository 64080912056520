import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { Paragraph, Small, TabNavigation } from "@fronterahealth/frontera-ui-components";

import { useLearnerByIdQuery } from "@api/graphql/types-and-hooks";
import { tabToName } from "@pages/ClientDetails/ClientDetails";

const clientIntakeDetailsNavigationTabs = ["overview", "availability", "insurance", "EHR-data", "files", "forms"];

export const ClientIntakeDetails = () => {
  const { pathname, state } = useLocation();
  const go = useNavigate();

  const { clientId } = useParams();

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );
  const { data } = learnerDetailsQuery;

  return (
    <div>
      {data?.learnerById?.relationshipSet.edges.length === 0 ? (
        <div className="flex flex-col justify-start gap-2 w-96 px-8">
          <Paragraph displayType="loud">No Intake Form Information Yet</Paragraph>
          <div className="flex flex-col items-center gap-3">
            <Small colorType="secondary">
              To collect intake information, you'll need to invite a caregiver to complete the form. First, add a
              caregiver and then you may send an intake form invitation to them.
            </Small>
            <Small
              colorType="secondary"
              className="underline"
              onClick={() => {
                go(`/clients/${clientId}/caregivers`);
              }}
            >
              Go to Caregiver Tab
            </Small>
          </div>
        </div>
      ) : (
        <>
          <TabNavigation
            tabs={clientIntakeDetailsNavigationTabs.map((tab) => ({
              current: pathname.split("/").pop() === tab,
              type: "component",
              name: tab,
              Component: (
                <Link to={tab} state={state}>
                  {tabToName(tab)}
                </Link>
              ),
            }))}
          />
          <div className="py-8">
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};
