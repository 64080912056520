import { config } from "@config/config";
import { GraphQLClient, Variables } from "graphql-request";

import { useAuthorization } from "@api/useAuthorization.tsx";

const endpoint = config.endPoint;

export const FronteraGraphQLClient = new GraphQLClient(endpoint);

export const useCustomFetcher = <TData, TVariables extends Variables>(
  query: string,
): ((variables?: TVariables) => Promise<TData>) => {
  const { getAuthorizationHeader, handleApiError } = useAuthorization();

  return async (variables?: TVariables) => {
    try {
      const headers = await getAuthorizationHeader();
      return await FronteraGraphQLClient.request({
        document: query,
        variables,
        requestHeaders: headers,
      });
    } catch (err) {
      throw await handleApiError(err as Error, query, variables);
    }
  };
};
