import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Badge, Dialog, Label, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

interface CreateClientDialog {
  setDialogOpen: (open: boolean) => void;
  isDialogOpen: boolean;
}

export enum ClientCreationType {
  ClientCaregiver = "Client (+Caregiver Intake)",
  ClientOnly = "Client Only",
}

export enum ClientCreationId {
  ClientCaregiver = "client-caregiver",
  ClientOnly = "client-lite",
}

interface ClientOption {
  id: ClientCreationId;
  title: ClientCreationType;
  description: string;
  recommendedTags: string[];
}

const clientOptions: ClientOption[] = [
  {
    id: ClientCreationId.ClientCaregiver,
    title: ClientCreationType.ClientCaregiver,
    description:
      "Add a new client and provide additional details to send an intake form to their caregiver. This ensures all necessary information is collected upfront.",
    recommendedTags: ["Onboarding new clients"],
  },
  {
    id: ClientCreationId.ClientOnly,
    title: ClientCreationType.ClientOnly,
    description:
      "Create a new client profile with just the basic details. Ideal for cases where intake forms are handled manually or when adding existing clients into the system.",
    recommendedTags: ["Manual Intake", "Backfilling existing clients"],
  },
];
export const CreateClientDialog: React.FC<CreateClientDialog> = ({ isDialogOpen, setDialogOpen }) => {
  const navigate = useNavigate();
  const [selectedClientOption, setSelectedClientOption] = useState<ClientCreationId | null>(null);
  const [showError, setShowError] = useState(false);

  const handleContinue = () => {
    if (!selectedClientOption) {
      setShowError(true);
      return;
    }
    navigate(`/clients/new?client-type=${selectedClientOption}`);

    setDialogOpen(false);
  };
  return (
    <Dialog
      title="Create New Client"
      open={isDialogOpen}
      setOpen={setDialogOpen}
      primaryButton={{
        text: "Continue",
        onClick: handleContinue,
        disabled: !selectedClientOption,
      }}
      secondaryButton={{ text: "Cancel", onClick: () => setDialogOpen(false) }}
      size="2xl"
      overflowBehavior="scroll"
    >
      <div className="flex flex-col gap-4">
        <Small>
          Which type of client do you need to create? You’ll be able to add caregiver info later in the client profile
          if you need to.
        </Small>

        {clientOptions.map((option) => (
          <label
            key={option.id}
            className={`border-2 p-4 rounded-lg flex justify-between items-center cursor-pointer transition-all ${
              selectedClientOption === option.id ? "border-hatch-500" : "border-gray-300"
            }`}
          >
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <Small displayType="loud" colorType="primary">
                  {option.title}
                </Small>
                <Paragraph size="small">{option.description}</Paragraph>
              </div>

              <div className="mt-2 flex flex-wrap gap-2 items-center">
                <Label size="extra-small" displayType="loud" colorType="primary">
                  Recommended For:
                </Label>
                {option.recommendedTags.map((tag) => (
                  <Badge key={tag} appearance="neutral" text={tag} />
                ))}
              </div>
            </div>
            <input
              type="radio"
              name="clientType"
              value={option.id}
              checked={selectedClientOption === option.id}
              onChange={() => setSelectedClientOption(option.id)}
              className="w-5 h-5 text-primary checked:ring-0 focus:ring-0"
            />
          </label>
        ))}
        {showError && <Small className="text-red-500">⚠️ Please select at least one option before continuing.</Small>}
      </div>
    </Dialog>
  );
};
