import { formatDuration } from "@utils/utils";
import { useEffect, useState } from "react";

import { Label } from "@fronterahealth/frontera-ui-components";

import { ClassificationSubClassEnum } from "@api/graphql/types-and-hooks";
import { VideoMomentInterface } from "@pages/VideoSessions/components/Moments/VideoMoments";

export const MomentTypeMapping: Record<ClassificationSubClassEnum, { color: string; label: string }> = {
  [ClassificationSubClassEnum.Aggression]: { color: "bg-globemallow-600", label: "Aggression" },
  [ClassificationSubClassEnum.CryingAndScreaming]: { color: "bg-hatch-600", label: "Crying And Screaming" },
};

interface MomentCardProps {
  moment: VideoMomentInterface;
  videoSeeking: boolean;
  id: string;
  activeMomentId: string | null;
  onActiveMomentChange: (newActiveMomentId: string | null) => void;
  onClick: (startTime: number, endTime: number, momentId: string) => void;
}
export const MomentCard: React.FC<MomentCardProps> = ({
  id,
  moment,
  videoSeeking,
  activeMomentId,
  onActiveMomentChange,
  onClick,
}) => {
  // TODO: Add Feedback Logic back in
  // const [feedbackValue, setFeedbackValue] = useState("");

  const handleMomentClick = (startTime: number, endTime: number, momentId: string) => {
    const newActiveId = momentId === activeMomentId ? null : momentId;

    if (onActiveMomentChange) {
      onActiveMomentChange(newActiveId);
    }
    if (startTime !== undefined && endTime !== undefined) {
      onClick(startTime, endTime, momentId);
    }
  };

  const [momentCardSeeking, setMomentCardSeeking] = useState<false | number>(false);

  useEffect(() => {
    if (videoSeeking === false) {
      setMomentCardSeeking(false);
    }
  }, [videoSeeking]);

  const handleMomentCardClick = () => {
    setMomentCardSeeking(moment.startTime);
    handleMomentClick(moment.startTime, moment.endTime, id);
  };

  return (
    <div
      onClick={handleMomentCardClick}
      data-moment-id={id}
      className={`cursor-pointer ${activeMomentId === id ? "border-limestone-900" : ""} ${momentCardSeeking === moment.startTime ? "bg-limestone-50 animate-pulse border-green-800 border" : "bg-white border-limestone-100 border hover:border-limestone-300 "} hover:bg-limestone-50 relative flex flex-col items-start px-4 p-2 transition-all rounded-lg gap-2`}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-1">
          <div className={`h-2 w-2 rounded-full ${MomentTypeMapping[moment.type!].color} mr-2`} />
          <Label size="small" colorType="primary">
            {MomentTypeMapping[moment.type!].label}
          </Label>
        </div>
        <div className="flex flex-col">
          <div
            onClick={handleMomentCardClick}
            className="flex items-center justify-end group gap-x-1 text-interaction-primary  hover:text-interaction-primary-hover hover:underline cursor-pointer transition-all"
          >
            <Label
              size="small"
              className="!text-interaction-primary cursor-pointer group-hover:text-interaction-primary-hover transition-all"
            >
              {formatDuration(moment.startTime, moment.endTime)}
            </Label>
          </div>
          <Label size="extra-small" className="text-right">
            Confidence: {(moment.confidenceLevel * 100).toFixed(0)}%
          </Label>
        </div>
      </div>

      {/* <div className="flex items-center justify-between w-full">
        <Tooltip text={"Confidence Interval"} placement="right" showInfoIcon={false}>
          <div className="flex items-center gap-x-1 bg-limestone-50 rounded-sm px-2 py-1">
            <ChartBarSquareIcon className="text-text-secondary w-4 h-4 mr" />
            <Small className="text-text-secondary">{(moment.confidenceLevel * 100).toFixed(0)}%</Small>
          </div>
        </Tooltip>
        {/* TODO: Add Feedback Logic back in */}
      {/* <PopoverPanel
          content={
            <div className="p-4">
              <Paragraph>Tell us what's wrong with it</Paragraph>
              <TextArea value={feedbackValue} onChange={(e) => setFeedbackValue(e.target.value)} label={"Feedback"} />
              <Button text={"Submit"} appearance={"primary"} />
            </div>
          }
          direction="bottom-left"
          height="max-h-[300px]"
          width="w-[364px]"
        >
          <FlagIcon className="w-4 h-4 text-interaction-primary hover:text-interaction-primary-hover transition-all" />
        </PopoverPanel> 
      </div> */}
    </div>
  );
};
