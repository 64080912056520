import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { FeatureFlagSwitch } from "@utils/FeatureFlags/FeatureFlagSwitch";
import React from "react";

import { Divider, Label, Paragraph } from "@fronterahealth/frontera-ui-components";

import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface SideFilterPanelItem {
  label: string;
  count?: number;
  active?: boolean;
}

interface SideFilterPanelProps {
  items: SideFilterPanelItem[];
  onSelect: (label: string) => void;
  showMediaFilters?: boolean;
}
const colorMap: Record<string, { border: string; bg: string }> = {
  "globemallow-500": { border: "border-globemallow-500", bg: "bg-globemallow-500" },
  "hatch-500": { border: "border-hatch-500", bg: "bg-hatch-500" },
};

export const SideFilterPanel: React.FC<SideFilterPanelProps> = ({ items, onSelect, showMediaFilters = true }) => {
  const { sidebarMomentsList, setSidebarMomentsList } = useVideoSessionData();

  const toggleMomentVisisblity = (index: number) => {
    const updatedMomentsList = sidebarMomentsList.map((moment, i) =>
      i === index ? { ...moment, isVisible: !moment.isVisible } : moment,
    );

    setSidebarMomentsList(updatedMomentsList);
  };

  return (
    <div className="bg-white rounded-2xl w-full">
      {showMediaFilters ? (
        <div className="p-4">
          {items.map((item) => (
            <div
              key={item.label}
              className={`flex justify-between items-center px-1 py-2 cursor-pointer transition ${
                item.active ? "bg-limestone-50 font-semibold" : "hover:bg-gray-50"
              }`}
              onClick={() => onSelect(item.label)}
            >
              <Label size="small" className="cursor-pointer" displayType={item.active ? "loud" : "normal"}>
                {item.label}
              </Label>
              {item.count ? (
                <Label size="small" className="cursor-pointer" displayType={item.active ? "loud" : "normal"}>
                  {item.count}
                </Label>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}

      <FeatureFlagSwitch
        flagKey={"maladaptive-behaviors"}
        flagEnabled={
          sidebarMomentsList.length ? (
            <div className="flex flex-col">
              {showMediaFilters ? <Divider className="my-0" /> : null}
              <div className="p-4">
                <Paragraph size="small" displayType="loud">
                  Moments
                </Paragraph>
                {sidebarMomentsList.map((moment, index) => {
                  return (
                    <div
                      key={moment.label}
                      onClick={() => toggleMomentVisisblity(index)}
                      className="group grid grid-cols-6 px-1 py-2 justify-between items-center hover:bg-gray-50 cursor-pointer"
                    >
                      <div className="flex flex-row col-span-5 gap-2 items-center">
                        <span
                          className={`max-w-3 max-h-3 rounded-full flex items-center justify-center border-2 ${
                            colorMap[moment.color]?.border || "border-gray-300"
                          }`}
                        >
                          <span
                            className={`w-1.5 h-1.5 ${
                              moment.isVisible ? colorMap[moment.color]?.bg : "bg-white"
                            } rounded-full`}
                          ></span>
                        </span>
                        <Label
                          size="small"
                          className={`${moment.isVisible ? "" : "!text-limestone-300"} cursor-pointer`}
                          displayType={moment.isVisible ? "loud" : "normal"}
                        >
                          {moment.label}
                        </Label>
                      </div>

                      {moment.isVisible ? (
                        <EyeIcon className="w-4 h-4 text-text-primary opacity-0 group-hover:opacity-100 transition duration-200" />
                      ) : (
                        <EyeSlashIcon className="w-4 h-4 text-text-primary opacity-0 group-hover:opacity-100 transition duration-200" />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null
        }
        flagDisabled={null}
      />
    </div>
  );
};
