import { EyeIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Badge, BadgeProps, Column, Table } from "@fronterahealth/frontera-ui-components";

import { useAdminData } from "@providers/AdminDataProvider";

export const INTAKE_VALUES = ["Complete", "In Progress", "Started", "Awaiting"] as const;
export type IntakeType = (typeof INTAKE_VALUES)[number];
export const STAGE_VALUES = [
  "Diagnosis Waitlist",
  "Diagnosis Pending",
  "Assessment Waitlist",
  "Assessment Pending",
  "Assessment Complete",
] as const;

const BadgeSettingFromIntake: { [key in IntakeType]: BadgeProps["appearance"] } = {
  Complete: "success",
  Awaiting: "warn",
  "In Progress": "neutral",
  Started: "neutral",
};

export type StageType = (typeof STAGE_VALUES)[number];

export interface FronteraClient {
  id: string;
  clientId: string;
  name: string;
  caregiver: string;
  intake?: IntakeType;
  intakePagesCompleted: number;
  created: Date;
  nextAppointment?: Date;
  stage: StageType;
  nearestLocation?: string;
  dob: string;
}

interface ClientTableProps {
  clients: (FronteraClient | null)[];
  loading?: boolean;
  onRowClick: (client: FronteraClient) => void;
}

export const ClientTable: React.FC<ClientTableProps> = ({ clients, loading, onRowClick }) => {
  const go = useNavigate();
  const { isOnboardingEnabled } = useAdminData();
  const columns: Column<FronteraClient>[] = [
    { dataIndex: "clientId", key: "clientId", title: "Client ID", className: "w-24" },
    { dataIndex: "name", key: "name", title: "Name" },
    { dataIndex: "dob", key: "dob", title: "Date of Birth" },
    { dataIndex: "caregiver", key: "caregiver", title: "Caregiver" },
    isOnboardingEnabled
      ? ({
          dataIndex: "intake",
          key: "intake",
          title: "Intake",
          render: (value: IntakeType, record: FronteraClient) => (
            <div className="min-w-36">
              <Badge
                appearance={BadgeSettingFromIntake[value]}
                text={`${value}${value === "In Progress" ? ` (${record.intakePagesCompleted} of 10)` : ""}`}
              />
            </div>
          ),
        } as Column<FronteraClient>)
      : null,
    {
      dataIndex: "created",
      key: "created",
      title: "Created",
      render: (value: Date) => (value ? format(value, "MM/dd/yyyy") : ""),
    },
    isOnboardingEnabled
      ? ({
          dataIndex: "nextAppointment",
          key: "nextAppointment",
          title: "Next Appointment",
          render: (value: Date) => (value ? format(value, "MM/dd/yyyy") : ""),
        } as Column<FronteraClient>)
      : null,
    isOnboardingEnabled
      ? ({
          dataIndex: "nearestLocation",
          key: "nearestLocation",
          title: "Nearest Clinic",
        } as Column<FronteraClient>)
      : null,
  ].filter((col): col is Column<FronteraClient> => col !== null);

  return (
    <div className="mt-8 flow-root bg-white w-full">
      <Table
        onRowClick={onRowClick}
        defaultItemsPerPage={10}
        showPagination
        loading={loading}
        showSearch
        inputClassName="w-96"
        itemsPerPageOptions={[5, 10, 20, 50]}
        actions={[
          {
            label: "View",
            Icon: EyeIcon,
            callback: (client) => {
              go(`/clients/${client.id}`, {
                state: {
                  id: client.id,
                  name: client.name,
                  intake: client.intake,
                  stage: client.stage,
                },
              });
            },
          },
        ]}
        data={(clients.filter((client) => client !== null) as FronteraClient[]).sort((clientA, clientB) =>
          clientB.created > clientA.created ? 1 : -1,
        )}
        columns={columns}
      />
    </div>
  );
};
