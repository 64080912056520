import React, { useEffect, useState } from "react";

import { Select, SelectItem } from "@fronterahealth/frontera-ui-components";
import {
  PopoverMenu,
  PopoverMenuItem,
} from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

/**
 * Shape of the input JSON:
 * {
 *   "options": string[],
 *   "answer": string | null
 * }
 */
interface InputData {
  options: string[];
  answer: string | null;
}

interface SelectFieldProps {
  inputJson: InputData;
  /**
   * Callback that receives an InputData object:
   * { options: string[], answer: string | null }
   */
  onChange: (json: InputData) => Promise<void>;
  actionButtonMenu: PopoverMenuItem[];
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
  displayOnlyMode: boolean;
}

export const SelectField: React.FC<SelectFieldProps> = ({
  inputJson,
  onChange,
  actionButtonMenu,
  permissions,
  displayOnlyMode = false,
}) => {
  const convertOptions = (opts: string[]): SelectItem[] => opts.map((o, index) => ({ primary: o, id: String(index) }));

  const [options, setOptions] = useState<SelectItem[]>([]);
  const [selected, setSelected] = useState<SelectItem | null>(null);

  useEffect(() => {
    if (Array.isArray(inputJson.options)) {
      setOptions(convertOptions(inputJson.options));
    } else {
      console.error("Invalid inputJson: 'options' must be an array");
      setOptions([]);
    }
    if (inputJson.answer !== null) {
      const found = convertOptions(inputJson.options).find((item) => item.primary === inputJson.answer);
      setSelected(found || null);
    } else {
      setSelected(null);
    }
  }, [inputJson]);

  const handleSelectChange = (newSelected: SelectItem) => {
    setSelected(newSelected);
    const output: InputData = {
      options: options.map((item) => item.primary),
      answer: newSelected.primary,
    };
    onChange(output);
  };

  return (
    <div className="group relative pr-6">
      <div data-testid="select-input-container">
        <div className="relative">
          <Select
            title=""
            items={options}
            placeholderText="Select"
            selected={selected || undefined}
            setSelected={handleSelectChange}
            className={`${!displayOnlyMode ? "-mb-6" : ""}`}
            displayOnlyMode={displayOnlyMode}
            variant="report"
          />
        </div>
      </div>
      <div
        data-testid="select-actions-panel"
        className={`group absolute -right-1 top-0 flex-col gap-y-4 ${
          permissions.canEdit ? "opacity-0 group-hover:opacity-100" : "pointer-events-none opacity-0"
        } transition-all`}
      >
        {actionButtonMenu.length ? (
          <PopoverMenu mode="secondary" menuItems={actionButtonMenu ?? []} openDirection="to-bottom-left" />
        ) : null}
      </div>
    </div>
  );
};
