import { MultiSelectItem, TabLink } from "@fronterahealth/frontera-ui-components";
import { RadioInputItem } from "@fronterahealth/frontera-ui-components/RadioInput/RadioInput";

export enum GenderUiValues {
  "Agender" = "Agender",
  "Girl/woman" = "Girl/woman",
  "Genderqueer or genderfluid" = "Genderqueer or genderfluid",
  "Gender identity is not listed" = "Gender identity is not listed",
  "Boy/man" = "Boy/man",
  "Non-binary" = "Non-binary",
  "Prefer not to disclose" = "Prefer not to disclose",
  "Questioning or unsure" = "Questioning or unsure",
}

export enum GenderApiValues {
  Agender = "agender",
  F = "F",
  GenderqueerOrGenderfluid = "genderqueer_or_genderfluid",
  GenderNotListed = "gender_not_listed",
  M = "M",
  NonBinary = "non_binary",
  PreferNotToDisclose = "prefer_not_to_disclose",
  QuestioningOrUnsure = "questioning_or_unsure",
}

export const GenderMappingToUi = {
  [GenderApiValues.Agender]: "Agender",
  [GenderApiValues.F]: "Girl/woman",
  [GenderApiValues.GenderqueerOrGenderfluid]: "Genderqueer or genderfluid",
  [GenderApiValues.GenderNotListed]: "Gender identity is not listed",
  [GenderApiValues.M]: "Boy/man",
  [GenderApiValues.NonBinary]: "Non-binary",
  [GenderApiValues.PreferNotToDisclose]: "Prefer not to disclose",
  [GenderApiValues.QuestioningOrUnsure]: "Questioning or unsure",
};

export const GenderMappingToApi = {
  [GenderUiValues["Agender"]]: GenderApiValues.Agender,
  [GenderUiValues["Girl/woman"]]: GenderApiValues.F,
  [GenderUiValues["Genderqueer or genderfluid"]]: GenderApiValues.GenderqueerOrGenderfluid,
  [GenderUiValues["Gender identity is not listed"]]: GenderApiValues.GenderNotListed,
  [GenderUiValues["Boy/man"]]: GenderApiValues.M,
  [GenderUiValues["Non-binary"]]: GenderApiValues.NonBinary,
  [GenderUiValues["Prefer not to disclose"]]: GenderApiValues.PreferNotToDisclose,
  [GenderUiValues["Questioning or unsure"]]: GenderApiValues.QuestioningOrUnsure,
};

export interface CheckboxItem {
  title: string;
  id: string;
}

export interface MedicalNecessityDsm5Criteria {
  name: string;
  checklistItems: CheckboxItem[];
}
export const MEDICAL_NECESSITY_DSM5_CRITERIA: MedicalNecessityDsm5Criteria[] = [
  {
    name: "Persistent deficits in social communication and social interaction across multiple contexts including:",
    checklistItems: [
      {
        title: "Deficits in social-emotional reciprocity",
        id: "A1",
      },
      {
        title: "Deficits in nonverbal communicative behaviors used for social interaction",
        id: "A2",
      },
      {
        title: "Deficits in developing, maintaining, and understanding relationships",
        id: "A3",
      },
    ],
  },
  {
    name: "Restricted, repetitive patterns of behavior, interests, or activities including:",
    checklistItems: [
      {
        title: "Stereotyped or repetitive motor movements",
        id: "B1",
      },
      {
        title: "Insistence on sameness, inflexible adherence to routines, or ritualized patterns of behavior",
        id: "B2",
      },
      {
        title: "Highly restricted, fixated interests that are abnormal in intensity or focus",
        id: "B3",
      },
      {
        title: "Hyper- or hyporeactivity to sensory input or unusual interest in sensory aspects of the environment",
        id: "B4",
      },
    ],
  },
];

export const STG_TABS = (currentTab: string): TabLink[] => {
  const tabs: TabLink[] = [
    {
      href: "#",
      name: "Details",
      type: "simple",
      current: currentTab === "Details",
    },
    {
      href: "#",
      name: "Necessity",
      type: "simple",
      current: currentTab === "Necessity",
    },
  ];

  return tabs;
};

type PageContent = {
  title: string;
  description: string;
  question: string;
  optionItems: CheckboxItem[] | RadioInputItem[] | MultiSelectItem[];
};

type PrioritizePageContent = {
  safetyConcern: PageContent;
  familyLife: PageContent;
  engagementAbility: PageContent;
  communicationAbility: PageContent;
  socialDesires: PageContent;
};

export const PRIORITIZE_PAGE_CONTENT: PrioritizePageContent = {
  safetyConcern: {
    title: "Safety Concerns",
    question: "Do any of the following behaviors represent a signification concern to the client?",
    description:
      "Review and customize the safety concerns for your client. Remove any behaviors that aren’t relevant, and add any that apply. You may use single words or complete sentences.",
    optionItems: [
      { id: "sib", primary: "SIB (Self Injurious Behaviors)" },
      { id: "aggression", primary: "Aggression" },
      { id: "property-destruction", primary: "Property Destruction" },
      { id: "meltdowns", primary: "Meltdowns" },
      { id: "elopement", primary: "Elopement" },
      { id: "pica", primary: "PICA" },
    ],
  },
  familyLife: {
    title: "Family Life",
    question: "Are any of these needs significantly affecting the learner or their family?",
    description:
      "Review and customize the family life needs (e.g. daily living skills, routines, self-care, sleep patterns) for your client. Remove any needs that aren’t relevant, and add any that apply. You may use single words or complete sentences.",
    optionItems: [
      { id: "lack-of-toilet-training", primary: "Lack of Toilet Training" },
      { id: "sleep-challenges", primary: "Sleep Challenges" },
      { id: "disrobing", primary: "Disrobing" },
      { id: "difficulty-communicating", primary: "Difficulty Communicating" },
      { id: "low-toleration", primary: "Low Toleration" },
      { id: "inappropriate-sexual-behavior", primary: "Inappropriate Sexual Behavior" },
    ],
  },
  engagementAbility: {
    title: "Engagement Ability",
    question:
      "Does the child independently and willingly interact with a given activity or item for at least a minute and tolerate others near them?",
    description:
      "Indicate whether your client can independently and willingly engage with adults and caregivers. This helps us understand their readiness for learning and determine appropriate teaching strategies.",
    optionItems: [
      { id: "yes", title: "Yes" },
      { id: "no", title: "No" },
    ],
  },
  communicationAbility: {
    title: "Communication Ability",
    question: "What is the client’s current ability to communicate their wants and needs?",
    description:
      "Indicate how your client typically communicates their wants and needs to help us determine their baseline and next steps for services.",
    optionItems: [
      { id: "the-client-does-not-speak", title: "The client does not speak" },
      {
        id: "the-client-can-say-a-few-words-but-not-consistently",
        title: "The client can say a few words but not consistently",
      },
      {
        id: "the-client-can-communicate-their-wants-and-needs-vocally-but-struggles-to-do-so",
        title: "The client can communicate their wants and needs vocally but struggles to do so",
      },
      {
        id: "the-client-is-able-to-communicate-fluently-using-words",
        title: "The client is able to communicate fluently using words",
      },
      {
        id: "the-client-communicates-basic-wants-and-needs-using-sign-language-or-technology",
        title: "The client communicates basic wants and needs using sign language or technology (e.g. a tablet)",
      },
      {
        id: "the-client-communicates-fluently-using-sign-language-or-technology",
        title: "The client communicates fluently using sign language or technology (e.g. a tablet)",
      },
    ],
  },
  socialDesires: {
    title: "Social Desires",
    question: "What is most socially significant to the family?",
    description:
      "Describe what is socially meaningful for your client and their family (e.g. peer interaction, family activities, community participation).",
    optionItems: [],
  },
};

export const LOCKED_TABLE_FIELD_IDENTIFIERS: string[] = [
  // Catalight lock tables
  "catalight_vineland_metadata",
  "catalight_vineland_scores_table",
];
