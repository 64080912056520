import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";

import { MultiSelectItem } from "@fronterahealth/frontera-ui-components";

import {
  CaregiverMetadataType,
  CaregiverType,
  useGetCaregiversDataQuery,
  useGetProvidersForAssigningToLearnerQuery,
} from "@api/graphql/types-and-hooks";

export type CaregiverMetaType = Pick<CaregiverType, "id"> & {
  caregivermetadata: Pick<
    CaregiverMetadataType,
    "firstName" | "lastName" | "email" | "phoneNumber1" | "smsAgreement" | "nearestLocation" | "referralSource"
  >;
};

interface ClientContextI {
  caregivers: CaregiverMetaType[];
  isCaregiverLoading: boolean;
  assignableLearnerProviders: MultiSelectItem[];
  isProvidersLoading: boolean;
}

export const ClientContext = createContext<ClientContextI | undefined>(undefined);

export const useClientData = () => {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error("useClientData must be used within a ClientProvider");
  }
  return context;
};

export const ClientProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [caregivers, setCaregivers] = useState<CaregiverMetaType[]>([]);
  const [assignableLearnerProviders, setAssignableLearnerProviders] = useState<MultiSelectItem[]>([]);
  const { data: caregiversData, isLoading: isCaregiverLoading } = useGetCaregiversDataQuery(
    {},
    {
      queryKey: ["getCaregiversDataQuery"],
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data: providersToAssignLearner, isLoading: isProvidersLoading } = useGetProvidersForAssigningToLearnerQuery(
    {},
    {
      queryKey: ["getProvidersForAssigningToLearner"],
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (caregiversData) {
      const caregiversList: CaregiverMetaType[] = caregiversData?.caregivers?.edges.length
        ? caregiversData.caregivers.edges.map((item) => {
            return {
              id: item?.node?.id ?? "",
              caregivermetadata: {
                firstName: item?.node?.caregivermetadata?.firstName ?? "",
                lastName: item?.node?.caregivermetadata?.lastName ?? "",
                email: item?.node?.caregivermetadata?.email ?? "",
                phoneNumber1: item?.node?.caregivermetadata?.phoneNumber1 ?? "",
                smsAgreement: item?.node?.caregivermetadata?.smsAgreement ?? false,
                nearestLocation: item?.node?.caregivermetadata?.nearestLocation ?? "",
                referralSource: item?.node?.caregivermetadata?.referralSource ?? "",
              },
            };
          })
        : [];

      setCaregivers(caregiversList);
    }
  }, [caregiversData]);

  useEffect(() => {
    if (providersToAssignLearner) {
      const providers: MultiSelectItem[] =
        providersToAssignLearner.getProvidersForAssigningToLearner?.edges.map((edge) => {
          return {
            id: edge?.node?.id || "",
            primary: `${edge?.node?.providermetadata?.firstName || "N/A"} ${edge?.node?.providermetadata?.lastName || ""} (${edge?.node?.rolesNameOnly?.map((item) => item?.toUpperCase()).join(",")})`,
            secondary: `${edge?.node?.providermetadata?.email}`,
          };
        }) || [];
      setAssignableLearnerProviders(providers || []);
    }
  }, [providersToAssignLearner]);

  return (
    <ClientContext.Provider value={{ caregivers, isCaregiverLoading, assignableLearnerProviders, isProvidersLoading }}>
      {children}
    </ClientContext.Provider>
  );
};
