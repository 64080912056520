import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Column, Paragraph, Small, Table } from "@fronterahealth/frontera-ui-components";

import {
  ApiCaregiverMetadataInvitationStatusChoices,
  CaregiverInput,
  useLearnerByIdQuery,
  useReissueCaregiverInvitationMutation,
} from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import {
  AddUpdateCaregiverDialogue,
  caregiverListType,
} from "@pages/ClientDetailsSubPages/Caregivers/AddUpdateCaregiverDialogue";
import CopyInvitationButton from "@pages/ClientDetailsSubPages/CopyInvitationButton";

export const ClientDetailsCaregivers: React.FC = () => {
  const { clientId } = useParams();
  const [isNewCaregiverDialogueOpen, setIsNewCaregiverDialogueOpen] = useState<boolean>(false);
  const [selectedCaregiver, setSelectedCaregiver] = useState<CaregiverInput | null>(null);
  const [caregiverList, setCaregiverList] = useState<caregiverListType[]>([]);

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data, refetch, isFetching } = learnerDetailsQuery;

  const { mutate: reissueInvitation, isPending } = useReissueCaregiverInvitationMutation({
    onSuccess: (data) => {
      if (data?.reissueCaregiverInvitation?.status) {
        notifySuccess(`Invite sent! An invite was sent to ${selectedCaregiver?.email}`);
        refetch();
      } else {
        notifyError(data?.reissueCaregiverInvitation?.message || "Failed to send invitation");
      }
    },
    onError: () => {
      notifyError("Failed to send invitation. Please try again.");
    },
  });

  const invitationHeading = (invitationStatus: string) => {
    if (invitationStatus === "PENDING") {
      return `Pending Invite`;
    } else if (invitationStatus === "EXPIRED") {
      return `Invite Expired`;
    } else if (invitationStatus === "ACCEPTED") {
      return "Invite Accepted";
    } else {
      return "Not yet sent";
    }
  };
  useEffect(() => {
    if (data && data.learnerById) {
      const caregivers = data.learnerById?.relationshipSet.edges.map((edge) => {
        const caregiver = edge?.node?.caregiver;
        const caregiverMetadata = caregiver?.caregivermetadata;
        const inviteStatus = caregiver?.caregivermetadata?.invitationStatus;

        return {
          caregiverId: caregiver?.id || "",
          firstName: caregiverMetadata?.firstName || "",
          lastName: caregiverMetadata?.lastName || "",
          email: caregiverMetadata?.email || "",
          phoneNumber1: caregiverMetadata?.phoneNumber1 || "",
          nearestLocation: caregiverMetadata?.nearestLocation || "",
          referralSource: caregiverMetadata?.referralSource || "",
          smsAgreement: caregiverMetadata?.smsAgreement || false,
          intakeInvite: inviteStatus || null,
          invitationUrl: caregiver?.caregivermetadata?.invitationUrl || null,
          auth0Id: caregiver?.auth0User?.auth0id || null,
        };
      });

      setCaregiverList(caregivers || []);
    }
  }, [data]);

  const handleReissueInvitation = (caregiverId: string) => {
    reissueInvitation({
      caregiverId,
    });
  };

  const columns: Column<caregiverListType>[] = [
    {
      title: "Caregiver Name",
      key: "caregiver_name",
      dataIndex: "firstName",
      render: (_, record) => {
        return `${record.firstName} ${record.lastName}`;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Intake Invite",
      key: "intakeInvite",
      dataIndex: "intakeInvite",
      className: "w-40",
      render: (_, record) => {
        return <div className="w-full ">{invitationHeading(record.intakeInvite || "")}</div>;
      },
    },
    {
      title: "",
      dataIndex: "invitationUrl",
      key: "invitationUrl",
      render: (_, record) => {
        if (record.intakeInvite === ApiCaregiverMetadataInvitationStatusChoices.Accepted) return <></>;
        return (
          <div className="flex flex-row gap-2 items-baseline justify-end">
            <CopyInvitationButton invitationUrl={record.invitationUrl || ""} />
            <Button
              onClick={() => handleReissueInvitation(record.caregiverId || "")}
              text={
                isPending && selectedCaregiver?.caregiverId === record.caregiverId
                  ? "Sending..."
                  : record.intakeInvite
                    ? "Resend Invite"
                    : "Send Invite"
              }
              appearance="primary"
              buttonAction="regular"
              disabled={(isFetching && selectedCaregiver?.caregiverId === record.caregiverId) || !record.caregiverId}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="flex w-full flex-col items-start gap-4">
      <div className="flex w-full justify-end">
        <Button
          text="Add Caregiver"
          appearance="secondary"
          onClick={() => {
            if (caregiverList.length === 0) {
              setIsNewCaregiverDialogueOpen(true);
            }
            return;
          }}
          disabled={caregiverList.length > 0 || isFetching}
        />
      </div>
      <div className="flow-root w-full">
        <Table
          data={caregiverList}
          columns={columns}
          loading={isFetching}
          onRowClick={(rowData) => {
            setSelectedCaregiver(rowData);
          }}
          actions={[
            {
              label: "View",
              callback: (caregiver) => {
                setSelectedCaregiver(caregiver);
                setIsNewCaregiverDialogueOpen(true);
              },
            },
          ]}
          emptyMessage={
            <div className="flex flex-col items-center">
              <Paragraph displayType="loud">No Caregivers Added Yet</Paragraph>
              <Small>Caregivers added to this client will show here</Small>
            </div>
          }
        />
      </div>

      <AddUpdateCaregiverDialogue
        isDialogOpen={isNewCaregiverDialogueOpen}
        setDialogOpen={setIsNewCaregiverDialogueOpen}
        onAddCaregiver={() => {
          refetch();
        }}
        selectedCaregiver={selectedCaregiver}
        clientId={clientId}
      />
    </div>
  );
};
