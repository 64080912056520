import { useAuth0 } from "@auth0/auth0-react";
import { downloadLearnerFileById } from "@utils/helpers";
import { RequiredFields } from "@utils/utils";
import { useCallback, useEffect, useState } from "react";

import {
  Button,
  Divider,
  ExistingFileInterface,
  FileInstance,
  FileUploadAndParse,
  Select,
  SelectItem,
} from "@fronterahealth/frontera-ui-components";

import { FileTypeEnum } from "@api/graphql/types-and-hooks";
import { ContentRow } from "@components/ContentRow/ContentRow";
import { languageCodeMap } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/constants";
import { groupInterviewsByType } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/Interviews/utils";
import {
  INTERVIEW_TYPE_TYPE,
  interviewTypeMap,
} from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/constants";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/hooks";

interface InterviewsSelectionProps {
  reportDoneCallback: (bool: boolean) => void;
  existingInterviewsTypes: string[];
  onNewInterviewAdded: (id: string) => void;
  existingInterviews: ExistingFileInterface[];
}

export const InterviewsSelection: React.FC<InterviewsSelectionProps> = ({
  reportDoneCallback,
  existingInterviewsTypes,
  existingInterviews,
  onNewInterviewAdded,
}) => {
  const [selectedInterviewType, setSelectedInterviewType] = useState<RequiredFields<SelectItem, "id"> | null>(null);
  const [fileStatuses, setFileStatuses] = useState<FileInstance[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<SelectItem | null>(null);

  useEffect(() => {
    if (existingInterviews?.length) {
      const interviewMetadata = JSON.parse(existingInterviews[0].metadata);
      const interviewType = interviewMetadata.interview_type as INTERVIEW_TYPE_TYPE;
      setSelectedInterviewType({ primary: interviewTypeMap[interviewType], id: interviewType });
      if (interviewMetadata && interviewMetadata.language) {
        const existingLanguageCode = interviewMetadata.language;
        const languageItem = languageCodeMap[existingLanguageCode];
        if (languageItem) {
          setSelectedLanguage({ primary: languageItem, id: existingLanguageCode });
        } else {
          console.error("FAILED TO PARSE LANGUAGE METADATA: Invalid language code");
        }
      }
    }
  }, [existingInterviews]);

  useEffect(() => {
    if (
      fileStatuses.length > 0 &&
      fileStatuses.every((file) => file.fileStatus === "done" || file.fileStatus === "parsing")
    ) {
      reportDoneCallback(true);
      onNewInterviewAdded(selectedInterviewType?.id || "");
    }
  }, [JSON.stringify(fileStatuses), existingInterviews, onNewInterviewAdded]);

  const interviewTypeLocked = !!existingInterviews?.length || fileStatuses.length > 0;

  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({
    /* NOTE: Bring this back when we do tagging of interview types */
    metadata: { interview_type: selectedInterviewType?.id, language: selectedLanguage?.id },
  });
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const { getAccessTokenSilently } = useAuth0();

  const showUploader = selectedInterviewType;
  const fileKind: FileTypeEnum = FileTypeEnum.Interview;

  return (
    <div className="flex flex-col">
      <Select
        title={"Intake Interview Language"}
        disabled={interviewTypeLocked}
        items={[
          { primary: "English", id: "en" },
          { primary: "Spanish", id: "es" },
          { primary: "Hindi", id: "hi" },
        ]}
        placeholderText={"Select Language"}
        selected={selectedLanguage}
        setSelected={setSelectedLanguage}
        hintText="Optional"
      />

      {selectedLanguage ? (
        <>
          <Select
            hintText="Optional"
            title={"Interview Type"}
            disabled={interviewTypeLocked}
            items={Object.entries(interviewTypeMap)
              .map(([id, primary]) => ({ id, primary }))
              .filter((item) => !existingInterviewsTypes.includes(item?.id ?? ""))}
            placeholderText={"Select Interview Type"}
            selected={selectedInterviewType}
            setSelected={(item) => {
              setSelectedInterviewType({
                primary: item.primary,
                id: item.id || "<missing-id>",
              });
            }}
          />
          {showUploader ? (
            <FileUploadAndParse
              key={selectedInterviewType.id}
              fileKind={fileKind}
              title={"Interview Files (Audio, Video, Text, or Images)"}
              supportedFileTypes={[
                "mp4",
                "mpeg",
                "mp3",
                "amr",
                "ogg",
                "m4a",
                "webm",
                "wav",
                "flac",
                "pdf",
                "doc",
                "docx",
                "png",
                "jpeg",
                "jpg",
                "mov",
              ]}
              maxSize={"2GB"}
              existingFiles={existingInterviews.length ? existingInterviews : undefined}
              S3BucketUrlRetriever={S3BucketUrlRetriever}
              S3BucketUploadCallback={S3BucketUploadCallback}
              reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
              fileParseStatusRetriever={fileParseStatusRetriever}
              removeFileCallback={removeFileCallback}
              updateFileStatusCallback={setFileStatuses}
              allowFileDownload={true}
              downloadFileCallback={async (fileId) => {
                const token = await getAccessTokenSilently();
                await downloadLearnerFileById({ fileId: fileId, token: token });
              }}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

interface InterviewsProps {
  existingInterviews: ExistingFileInterface[];
}
export const Interviews: React.FC<InterviewsProps> = ({ existingInterviews }) => {
  const [numberOfInterviews, setNumberOfInterviews] = useState<number>(1);
  const [readyForNewInterview, setReadyForNewInterview] = useState<boolean>(false);
  const existingInterviewsByType = groupInterviewsByType(existingInterviews);
  const existingInterviewsTypes = Object.keys(existingInterviewsByType);
  const [newlyAddedInterviewTypes, setNewlyAddedInterviewTypes] = useState<string[]>([]);
  const onNewInterviewAdded = useCallback((item: string) => {
    if (!item.trim()) return; // Ignore empty strings or whitespace-only values

    setNewlyAddedInterviewTypes((types) => (types.includes(item) ? types : [...types, item]));
  }, []);

  useEffect(() => {
    setNewlyAddedInterviewTypes([]);
  }, [existingInterviews]);

  useEffect(() => {
    if (existingInterviewsTypes.length + newlyAddedInterviewTypes.length) {
      setNumberOfInterviews(existingInterviewsTypes.length + newlyAddedInterviewTypes.length);
      if (existingInterviews.every((a) => a.fileStatus === "done" || a.fileStatus === "parsing")) {
        setReadyForNewInterview(true);
      }
    } else {
      setReadyForNewInterview(false);
    }
  }, [existingInterviewsTypes.length, newlyAddedInterviewTypes.length]);

  const newInterviewsCount = numberOfInterviews - (existingInterviewsTypes.length || 0);
  const combinedInterviewsTypes = [...existingInterviewsTypes, ...newlyAddedInterviewTypes];

  if (existingInterviews.length) {
    return (
      <ContentRow
        title="Interview"
        subtitle="Recordings (audio/video), written notes, or transcripts related to parent, child, provider, or other interviews"
      >
        {/* RENDER ANY EXISTING INTERVIEWS */}
        {Object.entries(existingInterviewsByType)
          .sort(([typeA], [typeB]) => (typeA > typeB ? 1 : -1))
          .map(([, interviews], index) => {
            return (
              <div key={index} className="flex flex-col">
                <InterviewsSelection
                  key={JSON.stringify(interviews)}
                  existingInterviews={interviews}
                  reportDoneCallback={setReadyForNewInterview}
                  onNewInterviewAdded={onNewInterviewAdded}
                  existingInterviewsTypes={combinedInterviewsTypes}
                />
                {numberOfInterviews > 1 && index !== numberOfInterviews - 1 ? <Divider /> : null}
              </div>
            );
          })}

        {/* RENDER NEWLY UPLOADED INTERVIEWS */}
        {Array(newInterviewsCount < 0 ? 0 : newInterviewsCount)
          .fill(0)
          .map((_, index) => {
            return (
              <div key={index} className="flex flex-col">
                <InterviewsSelection
                  existingInterviews={[]}
                  reportDoneCallback={setReadyForNewInterview}
                  onNewInterviewAdded={onNewInterviewAdded}
                  existingInterviewsTypes={combinedInterviewsTypes}
                />
                {numberOfInterviews > 1 && index !== numberOfInterviews - 1 ? <Divider /> : null}
              </div>
            );
          })}

        {/* RENDER BUTTON TO ADD NEW ONES */}
        {readyForNewInterview && numberOfInterviews === combinedInterviewsTypes.length ? (
          <Button
            className="self-end"
            appearance="link"
            text={"Add Another"}
            onClick={() => {
              setNumberOfInterviews((num) => num + 1);
              setReadyForNewInterview(false);
            }}
          />
        ) : null}
      </ContentRow>
    );
  }
  return (
    <ContentRow title="Interview" subtitle="Parent, Child, Providers, Family Members, etc">
      {Array(numberOfInterviews)
        .fill(0)
        .map((_, index) => {
          return (
            <div key={index} className="flex flex-col">
              <InterviewsSelection
                existingInterviews={[]}
                reportDoneCallback={setReadyForNewInterview}
                onNewInterviewAdded={onNewInterviewAdded}
                existingInterviewsTypes={combinedInterviewsTypes}
              />
              {numberOfInterviews > 1 && index !== numberOfInterviews - 1 ? <Divider /> : null}
            </div>
          );
        })}
      {readyForNewInterview && numberOfInterviews === combinedInterviewsTypes.length ? (
        <Button
          className="self-end"
          appearance="link"
          text={"Add Another"}
          onClick={() => {
            setNumberOfInterviews((num) => num + 1);
            setReadyForNewInterview(false);
          }}
        />
      ) : null}
    </ContentRow>
  );
};
