import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import React from "react";

import { Button, Heading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { useManuallyMarkMediaAsViewedMutation } from "@api/graphql/types-and-hooks";
import { UserInitialsView } from "@components/UserInitial/UserInitialsView";
import { MediaCardType } from "@pages/VideoSessions/components/MediaCards";
import { SessionRating } from "@pages/VideoSessions/components/Utils/SessionRating";
import { useAdminData } from "@providers/AdminDataProvider";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface HeaderContentProps {
  learnerFullName: string;
  providerFirstName?: string;
  providerLastName?: string;
  date: string;
  sessionRating?: number;
  media?: MediaCardType;
  isNoMediaStatus?: boolean;
}

export const HeaderContent: React.FC<HeaderContentProps> = ({
  learnerFullName,
  providerFirstName,
  providerLastName,
  date,
  sessionRating,
  media,
  isNoMediaStatus,
}) => {
  const { roles } = useAdminData();
  const { setSelectedTab, isVideoDetailsPage, allFilteredMedias, setAllFilteredMedias } = useVideoSessionData();
  const markAsReviewedMutation = useManuallyMarkMediaAsViewedMutation();
  const { isPending } = markAsReviewedMutation;

  const handleMarkAsReviewed = async (isReviewed: boolean) => {
    const response = await markAsReviewedMutation.mutateAsync({
      mediId: media ? media?.id : "",
      isReviewed,
    });
    if (response.manuallyMarkMediaAsViewed?.status) {
      setAllFilteredMedias([
        ...allFilteredMedias.map((listMedia) =>
          listMedia.id === response.manuallyMarkMediaAsViewed?.media?.id
            ? { ...listMedia, isViewed: response.manuallyMarkMediaAsViewed.media.isViewed }
            : listMedia,
        ),
      ]);
    }
  };

  return (
    <div className="flex justify-between w-[100%]">
      <div className="w-9/10">
        <div className="flex flex-col sm:flex-row sm:items-baseline gap-1 sm:gap-4">
          <Heading type="h2">{learnerFullName}</Heading>
          <Paragraph displayType="normal">{date}</Paragraph>
        </div>
        <div className="flex flex-wrap gap-2 mt-1">
          <Paragraph colorType="secondary" className="text-sm">
            Attending Provider:{" "}
          </Paragraph>
          <UserInitialsView firstName={providerFirstName || ""} lastName={providerLastName || ""} />
          {sessionRating && <SessionRating rating={sessionRating} />}
        </div>
      </div>
      {isVideoDetailsPage ? null : (
        <div className="flex flex-row gap-2 items-center">
          <div className="w-1/10">
            <DocumentMagnifyingGlassIcon
              onClick={(e) => {
                e.preventDefault();
                setSelectedTab("transcript");
              }}
              className={`h-6 w-6 text-limestone-600 inline mr-2 ${isNoMediaStatus ? "pointer-events-none opacity-10" : ""}`}
            />
          </div>
          {roles.includes("bcba") ? (
            <Button
              text={
                media && media.isViewed ? (
                  <Small className="text-text-primary">
                    Reviewed <CheckIcon className="h-4 w-5 text-interaction-primary inline stroke-5 drop-shadow-md" />
                  </Small>
                ) : (
                  "Mark As Reviewed"
                )
              }
              disabled={isPending || isNoMediaStatus}
              appearance="secondary"
              onClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                if (media) {
                  handleMarkAsReviewed(!media.isViewed);
                }
                return;
              }}
              className={`rounded-lg !px-3 !py-2 ${media && media.isViewed ? "!ring-gray-200 !bg-gray-50 " : "  !ring-limestone-200 "} !text-text-primary !text-sm !font-normal !hover:bg-limestone-50`}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
