import { format } from "date-fns";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  UpdateLearnerIntakeOverviewDetailsMutationVariables,
  useLearnerByIdQuery,
  useUpdateLearnerIntakeOverviewDetailsMutation,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { getStage } from "@pages/ClientDetails/ClientDetails";
import {
  BCBA_OPTIONS,
  PSYCHOLOGIST_OPTIONS,
} from "@pages/NewClient/NewClientOnboarding/NewClientOnboarding.interfaces";

export const ClientDetailsDetails: React.FC = () => {
  const { clientId } = useParams();

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data, refetch, isFetching } = learnerDetailsQuery;

  const updateLearnerOverviewMutation = useUpdateLearnerIntakeOverviewDetailsMutation({});

  const { data: updateData } = updateLearnerOverviewMutation;

  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormSelected,
    RegisteredFormRadioInput,
    watch,
    reset,
    unregister,
  } = useFormUtils<UpdateLearnerIntakeOverviewDetailsMutationVariables>({
    mutationFn: async (params) => {
      await updateLearnerOverviewMutation.mutateAsync({
        learnerDetails: {
          ...params.learnerDetails,
          // @ts-ignore: Ignoring the compiler and risking bugs because: because of how we convert hasPreviousDiagnosis to boolean
          hasPreviousDiagnosis: params.learnerDetails.hasPreviousDiagnosis === "yes" ? true : false,
        },
        learner: clientId ? clientId : "",
      });
      await refetch();
    },
  });

  useEffect(() => {
    if (data && data.learnerById?.learnermetadata) {
      const learner = data.learnerById?.learnermetadata;

      const stage = getStage(
        learner.hasPreviousDiagnosis,
        learner?.diagnosisAppointmentDate,
        learner?.assessmentAppointmentDate,
      );

      reset({
        learnerDetails: {
          bcbaForAssessment: learner?.assessmentBcba,
          physclogistForDiagnosis: learner?.diagnosisPsychologist,

          assessmentAppointmentDate: learner?.assessmentAppointmentDate
            ? format(new Date(learner?.assessmentAppointmentDate), "yyyy-MM-dd'T'HH:mm")
            : "",
          diagnosisAppointmentDate: learner?.diagnosisAppointmentDate
            ? format(new Date(learner?.diagnosisAppointmentDate), "yyyy-MM-dd'T'HH:mm")
            : "",
          // @ts-ignore: Ignoring the compiler and risking bugs because: because of how we convert hasPreviousDiagnosis to boolean
          hasPreviousDiagnosis: stage === "Assessment Waitlist" || learner.assessmentAppointmentDate ? "yes" : "no",
        },
      });
    }
  }, [data, reset]);

  useEffect(() => {
    if (updateData?.updateLearnerIntakeOverviewDetails) {
      if (updateData?.updateLearnerIntakeOverviewDetails.status) {
        notifySuccess(
          updateData.updateLearnerIntakeOverviewDetails?.message
            ? updateData.updateLearnerIntakeOverviewDetails?.message
            : "",
        );
      } else {
        notifyError(
          updateData.updateLearnerIntakeOverviewDetails?.message
            ? updateData.updateLearnerIntakeOverviewDetails?.message
            : "",
        );
      }
    }
  }, [updateData]);

  // @ts-ignore: Ignoring the compiler and risking bugs because: see above
  const clientHasPreviousDiagnosis = watch("learnerDetails.hasPreviousDiagnosis") === "yes";
  // @ts-ignore: Ignoring the compiler and risking bugs because: see above
  const clientHasNoPreviousDiagnosis = watch("learnerDetails.hasPreviousDiagnosis") === "no";

  useEffect(() => {
    if (data?.learnerById) {
      if (clientHasPreviousDiagnosis) {
        unregister("learnerDetails.diagnosisAppointmentDate", { keepDefaultValue: true });
        unregister("learnerDetails.physclogistForDiagnosis", { keepDefaultValue: true });
      }
      if (clientHasNoPreviousDiagnosis) {
        unregister("learnerDetails.assessmentAppointmentDate", { keepDefaultValue: true });
        unregister("learnerDetails.bcbaForAssessment", { keepDefaultValue: true });
      }
    }
  }, [unregister, clientHasPreviousDiagnosis, clientHasNoPreviousDiagnosis, data]);

  const formLoading = isFetching;

  return (
    <div className="flex w-full flex-col items-start">
      <FormContainer onSubmit={onSubmit}>
        <DoubleColumnContainer>
          <RegisteredFormRadioInput
            formState={formState}
            formKey="learnerDetails.hasPreviousDiagnosis"
            title="Has Previous Diagnosis"
            required={false}
            disabled
            items={[
              {
                id: "no",
                title: "No",
              },
              {
                id: "yes",
                title: "Yes",
              },
            ]}
          />
        </DoubleColumnContainer>

        <div className="gap-x-4 lg:grid lg:grid-cols-2">
          {clientHasPreviousDiagnosis ? (
            <>
              <RegisteredFormInput
                isLoading={formLoading}
                formKey="learnerDetails.assessmentAppointmentDate"
                inputSize="full"
                errorMessage={formState.errors.learnerDetails?.assessmentAppointmentDate?.message?.toString()}
                type="datetime-local"
                formState={formState}
                required={clientHasPreviousDiagnosis}
                label="Assessment Appointment Date"
              />

              <RegisteredFormSelected
                isLoading={formLoading}
                formKey="learnerDetails.bcbaForAssessment"
                formState={formState}
                placeholderText={""}
                errorMessage={formState.errors.learnerDetails?.bcbaForAssessment?.message?.toString()}
                title="BCBA for Assessment"
                required={clientHasPreviousDiagnosis}
                items={BCBA_OPTIONS.map((o) => ({ primary: o }))}
              />
            </>
          ) : (
            <>
              <RegisteredFormInput
                isLoading={formLoading}
                formKey="learnerDetails.diagnosisAppointmentDate"
                inputSize="full"
                required={!clientHasPreviousDiagnosis}
                type="datetime-local"
                errorMessage={formState.errors.learnerDetails?.diagnosisAppointmentDate?.message?.toString()}
                formState={formState}
                label="Diagnosis Appointment Date"
              />

              <RegisteredFormSelected
                isLoading={formLoading}
                formKey="learnerDetails.physclogistForDiagnosis"
                formState={formState}
                placeholderText={""}
                required={!clientHasPreviousDiagnosis}
                errorMessage={formState.errors.learnerDetails?.physclogistForDiagnosis?.message?.toString()}
                title="Psychologist for Diagnosis"
                items={PSYCHOLOGIST_OPTIONS.map((o) => ({ primary: o }))}
              />
            </>
          )}
        </div>
        <div className="mt-6">
          <SubmitButton isLoading={formState.isSubmitting} buttonText="Update" />
        </div>
      </FormContainer>
    </div>
  );
};
