import { useAuth0 } from "@auth0/auth0-react";
import { downloadLearnerFileById } from "@utils/helpers";

import { ExistingFileInterface, FileUploadAndParse } from "@fronterahealth/frontera-ui-components";

import { FileTypeEnum } from "@api/graphql/types-and-hooks";
import { ContentRow } from "@components/ContentRow/ContentRow";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/hooks";

interface ClinicalNotesProps {
  existingClinicalNotes: ExistingFileInterface[];
}
export const ClinicalNotes: React.FC<ClinicalNotesProps> = ({ existingClinicalNotes }) => {
  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({});
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const { getAccessTokenSilently } = useAuth0();
  const fileKind: FileTypeEnum = FileTypeEnum.ClinicalNotes;
  return (
    <ContentRow title="Clinical Notes" subtitle="Other supporting clinical documents">
      <FileUploadAndParse
        key={JSON.stringify(existingClinicalNotes)}
        fileKind={fileKind}
        title={"Documents"}
        supportedFileTypes={["pdf", "png", "jpeg", "jpg", "doc", "docx"]}
        maxSize={"1GB"}
        existingFiles={existingClinicalNotes}
        S3BucketUrlRetriever={S3BucketUrlRetriever}
        S3BucketUploadCallback={S3BucketUploadCallback}
        reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
        fileParseStatusRetriever={fileParseStatusRetriever}
        removeFileCallback={removeFileCallback}
        hintText="Optional"
        allowFileDownload={true}
        downloadFileCallback={async (fileId) => {
          const token = await getAccessTokenSilently();
          await downloadLearnerFileById({ fileId: fileId, token: token });
        }}
      />
    </ContentRow>
  );
};
