import React, { PropsWithChildren } from "react";

import { Paragraph, Tooltip } from "@fronterahealth/frontera-ui-components";

interface FeedTooltipProps extends PropsWithChildren {
  text: string;
  showTooltip?: boolean;
}

export const FeedTooltip: React.FC<FeedTooltipProps> = ({ children, text, showTooltip = true }) => {
  return showTooltip ? (
    <Tooltip
      showInfoIcon={false}
      text={
        <Paragraph size="small" className="text-white">
          {text}
        </Paragraph>
      }
      className="bg-black"
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
