import { GenderApiValues } from "@utils/constants";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { SelectItem } from "@fronterahealth/frontera-ui-components";

import { LearnerGeneralDetailsQuery, useLearnerGeneralDetailsQuery } from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer, TripleColumnHeader } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import {
  GeneralDetailsType,
  generalDetailsMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/ClientDetails/Mappings/generalDetailsMapping";
import { getGenderValue } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { USA_STATES_LIST } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/state";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const extractGeneralDetailsData = (queryData: LearnerGeneralDetailsQuery) => {
  return {
    ...queryData.learnerById?.learnermetadata,
    gender: getGenderValue(queryData.learnerById?.learnermetadata?.gender),
  } as GeneralDetailsType;
};
export const GeneralDetails: React.FC = () => {
  const { clientId } = useParams();
  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/client-details/general-details" : undefined;

  const config = useGetQueryConfig<LearnerGeneralDetailsQuery>(customQueryKey);

  const learnerDetailsQuery = useLearnerGeneralDetailsQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data, isFetching: formLoading } = learnerDetailsQuery;

  const { formState, onSubmit, RegisteredFormInput, RegisteredFormSelected, reset, watch } =
    useFormUtils<GeneralDetailsType>({
      mutationFn: async () => {
        await Promise.resolve();
      },
      displayOnlyMode: pathname.includes("all"),
    });

  useEffect(() => {
    if (data) {
      reset(extractGeneralDetailsData(data));
    }
  }, [data, reset]);

  const genderWatch = watch("gender") as string;

  return (
    <div className="flex flex-col">
      <FormContainer onSubmit={onSubmit} noValidate>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            label={generalDetailsMapping.firstName[chosenLanguage]}
            readOnly
            formKey="firstName"
            formState={formState}
          />
          <RegisteredFormInput
            isLoading={formLoading}
            label={generalDetailsMapping.lastName[chosenLanguage]}
            readOnly
            formKey="lastName"
            formState={formState}
          />
        </DoubleColumnContainer>

        <DoubleColumnContainer>
          <RegisteredFormInput
            formKey="preferredName"
            isLoading={formLoading}
            label={generalDetailsMapping.preferredName?.[chosenLanguage] ?? "Preferred Name"}
            required={false}
            readOnly
            formState={formState}
          />
        </DoubleColumnContainer>

        <DoubleColumnContainer>
          <RegisteredFormInput
            formKey="birthDate"
            readOnly
            formState={formState}
            // @ts-ignore: Ignoring the compiler and risking bugs because: birthdate has any type
            label={generalDetailsMapping.birthDate[chosenLanguage]}
            isLoading={formLoading}
          />
        </DoubleColumnContainer>

        <DoubleColumnContainer>
          <RegisteredFormInput
            formKey="gender"
            label={generalDetailsMapping.gender?.[chosenLanguage] ?? "Gender"}
            readOnly
            formState={formState}
            isLoading={formLoading}
          />

          {genderWatch === "Gender identity is not listed" && (
            <RegisteredFormSelected
              formKey="gender"
              title={generalDetailsMapping.gender?.[chosenLanguage] ?? "Gender"}
              readOnly
              formState={formState}
              items={
                Object.values(GenderApiValues).map((o) => ({
                  primary: getGenderValue(o),
                })) as SelectItem[]
              }
              placeholderText="Select Gender"
              isLoading={formLoading}
            />
          )}
        </DoubleColumnContainer>

        <DoubleColumnContainer>
          <RegisteredFormInput
            formState={formState}
            isLoading={formLoading}
            label={generalDetailsMapping.ssn?.[chosenLanguage] ?? "SSN"}
            formKey="ssn"
            readOnly
            pattern={/^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/}
            maxLength={11}
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormInput
            formState={formState}
            label={generalDetailsMapping.address?.[chosenLanguage] ?? "Address Line 1"}
            formKey="address"
            required={false}
            isLoading={formLoading}
            readOnly
          />
          <RegisteredFormInput
            formState={formState}
            isLoading={formLoading}
            label={generalDetailsMapping.addressLine2?.[chosenLanguage] ?? "Address Line 2"}
            formKey="addressLine2"
            required={false}
            readOnly
          />
        </DoubleColumnContainer>
        <TripleColumnHeader>
          <RegisteredFormInput
            formState={formState}
            formKey="city"
            required={false}
            label={generalDetailsMapping.city?.[chosenLanguage] ?? "City"}
            isLoading={formLoading}
            readOnly
          />
          <RegisteredFormSelected
            formState={formState}
            isLoading={formLoading}
            title={generalDetailsMapping.state?.[chosenLanguage] ?? "State"}
            formKey="state"
            required={false}
            items={USA_STATES_LIST.map((o: string) => ({ primary: o }))}
            placeholderText={""}
            readOnly
          />
          <RegisteredFormInput
            formState={formState}
            formKey="zip"
            required={false}
            label="Zip"
            isLoading={formLoading}
            readOnly
          />
        </TripleColumnHeader>
      </FormContainer>
    </div>
  );
};
