import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { Variables } from "graphql-request";

import { notifyError } from "@components/notifications/notifications.ts";

export const useAuthorization = () => {
  const { getAccessTokenSilently, logout } = useAuth0();

  const getAuthorizationHeader = async (): Promise<Headers> => {
    let token: string = "";
    try {
      /**
       * Auth0 internally via this SDK (getAccessTokenSilently) will simply retrieve the cached access_token from storage and return that
       * if that token is not expired. This means we can call this a million times and no requests out to Auth0 will be made
       *
       * If the cached access_token is within 1 minute of expiring, it will automatically make a request to /oauth/token for a new
       * access_token using the cached refresh_token. if that refresh_token is expired, this will throw an error and we will redirect
       * the user to logout
       */
      token = await getAccessTokenSilently();
    } catch (err) {
      console.error("Error with getAccessTokenSilently", err);
      datadogRum.addError(err, { type: "getAccessTokenSilentlyError" });
      await logout({
        logoutParams: {
          returnTo: `${window.location.protocol}//${window.location.host}`,
        },
      });
      throw new Error(String(err));
    }

    const headers: Headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    return headers;
  };

  const handleApiError = async (err: Error, query: string, variables?: Variables | unknown) => {
    /**
     * If FDM errors with Unauthorized (this shouldn't really happen much anymore because of above logic),
     * then log this for telemetry purposes and again, take the user back to login to have them start over
     */
    if (String(err).includes("Not Authorized")) {
      datadogRum.addError(err, { type: "fdmNotAuthorizedError", requestMetadata: { query, variables } });
      await logout({
        logoutParams: {
          returnTo: `${window.location.protocol}//${window.location.host}`,
        },
      });
      throw new Error(String(err));
    }

    /**
     * Send an actual Datadog Error (a more first class citizen) on all FDM API errors
     */
    datadogRum.addError(err, { type: "fdmApiRequestError", requestMetadata: { query, variables } });
    console.error("Error with request to FDM", err);
    console.error(err);
    /**
     *  Pop up a toast so that on GET requests where simply empty data shows up,
     *  we can now see that an error happened
     */
    notifyError("Something went wrong with our API request");

    /**
     * Rethrow the error so that downstream callers of this can handle their own error handling
     */
    throw new Error(String(err));
  };

  return { getAuthorizationHeader, handleApiError };
};
