import { CommentCount } from "@pages/VideoSessions/components/EmojiComments/EmojiCommentButtons";

export const convertTimestampToSeconds = (timestamp: string): number => {
  const [hh, mm, ss] = timestamp.split(":").map(Number);
  return hh * 3600 + mm * 60 + ss;
};

export const getEmojiCount = (value: string, commentsCount?: string): number => {
  if (!commentsCount) return 0;
  const parsedCounts: CommentCount[] = JSON.parse(commentsCount);
  return parsedCounts.find((comment) => comment.comment_type.toLowerCase() === value.toLowerCase())?.count || 0;
};
