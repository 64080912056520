import {
  ApiOrganizationAbExportTemplateNameChoices,
  AssessmentType,
  Maybe,
  ReportSectionsTypeEdge,
  ReportType,
  ShortTermGoalType,
} from "@api/graphql/types-and-hooks";
import { TransformedData } from "@components/PDFGenerator/catalightUtils";

export const getReportTemplatePath = (template: ApiOrganizationAbExportTemplateNameChoices): string => {
  switch (template) {
    case ApiOrganizationAbExportTemplateNameChoices.LearnAssessmentReportDocxTemplateDocx:
      return "/learn_assessment_report_docx_template.docx";
    case ApiOrganizationAbExportTemplateNameChoices.CatalightAssessmentReportDocxTemplateDocx:
      return "/catalight_assessment_report_docx_template.docx";
    case ApiOrganizationAbExportTemplateNameChoices.AssessmentReportDocxTemplateDocx:
    default:
      return "/assessment_report_docx_template.docx";
  }
};

export interface BehaviorInterventionPlan {
  behavior: string;
  definition: string;
  examples: string;
  nonexamples: string;
  frequencySeverityDuration: string;
  commonAntecedents: string;
  currentConsequences: string;
  functionalHypothesis: string;
  replacementBehaviors: string;
  antecedentModifications: string;
  strategiesForReinforcingReplacementBehavior: string;
  strategiesForReducingTargetBehavior: string;
  summaryStatements: string;
}

export const getReportTemplateFont = (template: ApiOrganizationAbExportTemplateNameChoices): string => {
  switch (template) {
    case ApiOrganizationAbExportTemplateNameChoices.LearnAssessmentReportDocxTemplateDocx:
      return "Calibri";
    case ApiOrganizationAbExportTemplateNameChoices.CatalightAssessmentReportDocxTemplateDocx:
      return "Century Gothic";
    case ApiOrganizationAbExportTemplateNameChoices.AssessmentReportDocxTemplateDocx:
    default:
      return "Public Sans";
  }
};

export interface ExportWithTemplateProps {
  assessmentData: AssessmentType;
}

export interface ExportWithTemplateV2Props {
  reportData: ReportType;
}
export interface GoalTypeData {
  goalType: string;
  formattedGoalType: string;
  summary?: string;
  goals: MappedGoal[];
}

export interface SignatureType {
  signature: string;
  date: string;
}

export type FieldMapValue = string | number | boolean | Buffer | null | undefined | TransformedData[];
export interface FieldMap {
  [key: string]: FieldMapValue;
}
export interface MappedGoal {
  description: string;
  goalName: string;
  goalType: string;
  masteryCriteria: string;
  medicalNecessityCriteria: string;
  timelineEstimation: string;
  baselineData: string;
  establishBaselineOnTreatment: string;
  programGoal: string;
  type: string;
  children?: MappedGoal[];
}

export const mapGoal = (goal: MappedGoal): MappedGoal => {
  return {
    description: goal.description || "",
    goalName: goal.goalName || "",
    goalType: goal.goalType || "",
    masteryCriteria: goal.masteryCriteria || "",
    medicalNecessityCriteria: goal.medicalNecessityCriteria || "",
    timelineEstimation: goal.timelineEstimation || "",
    baselineData: goal.baselineData && goal.baselineData !== "<missing-baseline-data>" ? goal.baselineData : "",
    establishBaselineOnTreatment: goal.establishBaselineOnTreatment || "",
    programGoal: goal.programGoal || "",
    type: goal.type || "",
    children: Array.isArray(goal.children) ? goal.children.map(mapGoal) : [],
  };
};

export const toTitleCase = (str: string): string => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const createGoalTypeSummary = (
  goals: MappedGoal[],
): Record<string, { goals: MappedGoal[]; formattedType: string }> => {
  const summaries: Record<string, { goals: MappedGoal[]; formattedType: string }> = {};

  goals.forEach((goal) => {
    const mappedGoal = mapGoal(goal);
    if (!summaries[mappedGoal.goalType]) {
      summaries[mappedGoal.goalType] = {
        goals: [],
        formattedType: toTitleCase(mappedGoal.goalType),
      };
    }
    summaries[mappedGoal.goalType].goals.push(mappedGoal);
  });

  return summaries;
};

export const markdownToDocxtemplater = (markdown: string, font: string = "Public Sans") => {
  const fontStyle = () => {
    return `<w:rFonts w:ascii="${font}" w:hAnsi="${font}" w:cs="${font}"/>`;
  };

  // Helper function to convert markdown-style headers to Word styles
  const convertHtmlToOoxml = (html: string) => {
    return (
      html
        // Convert <p><strong>Text</strong></p> pattern
        .replace(/<p><strong>(.*?)<\/strong><\/p>/g, (_match, content) => {
          return `<w:p><w:r><w:rPr><>${fontStyle()}<w:b/></w:rPr><w:t xml:space="preserve">${escapeXml(content)}</w:t></w:r></w:p>`;
        })
        // Convert empty paragraphs
        .replace(/<p><\/p>/g, '<w:p><w:r><w:t xml:space="preserve"> </w:t></w:r></w:p>')
        // Convert remaining paragraphs
        .replace(/<p>(.*?)<\/p>/g, (_match, content) => {
          if (content.trim() === "") {
            return '<w:p><w:r><w:t xml:space="preserve"> </w:t></w:r></w:p>';
          }
          return `<w:p><w:r><w:rPr>${fontStyle()}</w:rPr><w:t xml:space="preserve">${escapeXml(content)}</w:t></w:r></w:p>`;
        })
    );
  };

  // Escape XML special characters
  const escapeXml = (unsafe: string) => {
    return unsafe.replace(/[<>&'"]/g, (c) => {
      switch (c) {
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        case "&":
          return "&amp;";
        case "'":
          return "&apos;";
        case '"':
          return "&quot;";
      }
      return c;
    });
  };

  if (markdown.trim().startsWith("<p>")) {
    return convertHtmlToOoxml(markdown);
  }
  const convertHeaders = (text: string) => {
    return text
      .replace(/^#\s+(.+)$/gm, (_match, content) => {
        return `<w:p><w:pPr>${fontStyle()}<w:pStyle w:val="Heading1"/></w:pPr>${convertInlineStyles(content)}</w:p>`;
      })
      .replace(/^##\s+(.+)$/gm, (_match, content) => {
        return `<w:p><w:pPr>${fontStyle()}<w:pStyle w:val="Heading2"/></w:pPr>${convertInlineStyles(content)}</w:p>`;
      })
      .replace(/^###\s+(.+)$/gm, (_match, content) => {
        return `<w:p><w:pPr>${fontStyle()}<w:pStyle w:val="Heading3"/></w:pPr>${convertInlineStyles(content)}</w:p>`;
      })
      .replace(/^####\s+(.+)$/gm, (_match, content) => {
        return `<w:p><w:pPr>${fontStyle()}<w:pStyle w:val="Heading4"/></w:pPr>${convertInlineStyles(content)}</w:p>`;
      });
  };

  // Convert inline styles (bold, italic, underline, strikethrough)
  const convertInlineStyles = (text: string) => {
    const segments = [];
    let currentIndex = 0;

    const patterns = [
      { regex: /\*\*(.+?)\*\*/g, tag: "b" }, // Bold
      { regex: /\*(.+?)\*/g, tag: "i" }, // Italic
      { regex: /__(.+?)__/g, tag: "u" }, // Underline
      { regex: /~(.+?)~/g, tag: "strike" }, // Strikethrough
    ];

    while (currentIndex < text.length) {
      let match = null;
      let earliestIndex = text.length;
      let matchedPattern = null;

      for (const pattern of patterns) {
        pattern.regex.lastIndex = currentIndex;
        const m = pattern.regex.exec(text);
        if (m && m.index < earliestIndex) {
          match = m;
          earliestIndex = m.index;
          matchedPattern = pattern;
        }
      }

      if (match) {
        if (match.index > currentIndex) {
          segments.push({ text: text.slice(currentIndex, match.index), style: null });
        }
        segments.push({ text: match[1], style: matchedPattern?.tag });
        currentIndex = match.index + match[0].length;
      } else {
        segments.push({ text: text.slice(currentIndex), style: null });
        break;
      }
    }

    // Convert segments to OOXML format
    return segments
      .map(({ text, style }) => {
        if (!style) {
          return `<w:r><w:rPr>${fontStyle()}</w:rPr><w:t xml:space="preserve">${escapeXml(text)}</w:t></w:r>`;
        }
        return `<w:r><w:rPr>${fontStyle()}<w:${style}/></w:rPr><w:t xml:space="preserve">${escapeXml(text)}</w:t></w:r>`;
      })
      .join("");
  };

  const convertBullets = (text: string) => {
    return text.replace(/^(\s*)\*\s+(.*)$/gm, (_match, indent, content) => {
      const level = Math.floor(indent.length / 2);
      const formattedContent = convertInlineStyles(content); // Process inline styles
      return (
        "<w:p>" +
        "<w:pPr>" +
        '<w:pStyle w:val="ListParagraph"/>' +
        "<w:numPr>" +
        `<w:ilvl w:val="${level}"/>` +
        '<w:numId w:val="1"/>' +
        "</w:numPr>" +
        `<w:keepLines/>` + // Prevents line breaking
        `<w:keepNext/>` + // Keeps bullet and content together
        "</w:pPr>" +
        formattedContent +
        "</w:p>"
      );
    });
  };

  // Convert newlines to paragraphs and apply inline styles
  const convertParagraphs = (text: string) => {
    return text
      .replace(/\u00A0/g, " ") // Replace non-breaking spaces
      .split("\n")
      .map((line) => {
        if (line.trim() === "") return "";
        return `<w:p>
                  <w:pPr>
                    <w:jc w:val="left"/>  <!-- Align text left -->
                    <w:wordWrap w:val="auto"/>  <!-- Enable word wrapping -->
                  </w:pPr>
                  <w:r>
                    <w:rPr>${fontStyle()}</w:rPr>
                    <w:t xml:space="preserve">${line}</w:t>
                  </w:r>
                </w:p>`;
      })
      .join("");
  };

  const convertLineBreaks = (text: string) => {
    return text.replace(/&nbsp;/g, "");
  };

  const convertAmpersands = (text: string) => {
    return text.replace(/&/g, "and");
  };

  let docxText = markdown;
  docxText = convertLineBreaks(docxText);
  docxText = convertAmpersands(docxText);
  docxText = convertHeaders(docxText);
  docxText = convertBullets(docxText);
  docxText = convertParagraphs(docxText);

  return docxText;
};

/**
 * Sorts report sections by their pageLocation and rankOrder properties.
 * Items with "report-part-1" pageLocation come first, followed by "report-part-2".
 * Within each pageLocation group, items are sorted by rankOrder.
 * @param reportSections - The report sections object containing edges to sort
 * @returns A new report sections object with sorted edges, or empty array if invalid input
 */
export const sortReportSectionsByRankOrder = (
  reportSections: Maybe<ReportSectionsTypeEdge>[],
): Maybe<ReportSectionsTypeEdge>[] => {
  if (!reportSections) {
    return [];
  }

  const sortedEdges = [...reportSections]
    .filter(
      (edge): edge is NonNullable<ReportSectionsTypeEdge> =>
        edge != null &&
        edge.node != null &&
        typeof edge.node.rankOrder === "number" &&
        typeof edge.node.pageLocation === "string",
    )
    .sort((a, b) => {
      // First compare page locations
      const locationA = a.node?.pageLocation ?? "";
      const locationB = b.node?.pageLocation ?? "";

      if (locationA === locationB) {
        // If same location, sort by rankOrder
        const rankA = a.node?.rankOrder ?? 0;
        const rankB = b.node?.rankOrder ?? 0;
        return rankA - rankB;
      }

      // Put "report-part-1" before "report-part-2"
      if (locationA === "report-part-1") return -1;
      if (locationB === "report-part-1") return 1;
      return 0;
    });

  return sortedEdges;
};

export const sortSectionFieldsByRankOrder = (section: Maybe<ReportSectionsTypeEdge>): Maybe<ReportSectionsTypeEdge> => {
  if (!section || !section.node || !section.node.sectionField || !section.node.sectionField.edges) {
    return null;
  }

  const sortedEdges = [...section.node.sectionField.edges]
    .filter((edge): edge is NonNullable<typeof edge> => edge != null)
    .sort((a, b) => {
      const rankA = a.node?.rankOrder ?? 0;
      const rankB = b.node?.rankOrder ?? 0;
      return rankA - rankB;
    });

  return {
    ...section,
    node: {
      ...section.node,
      sectionField: {
        ...section.node.sectionField,
        edges: sortedEdges,
      },
    },
  };
};

/**
 * Verifies that an object has no duplicate keys
 * @param data The object to check for duplicates
 * @returns True if there are no duplicates, throws an error if duplicates are found
 */
export const verifyUniqueFieldMapKeys = <T extends object>(data: T): boolean => {
  const duplicateKeys: string[] = [];
  const seenKeys = new Set<string>();

  Object.keys(data as Record<string, unknown>).forEach((key) => {
    if (seenKeys.has(key)) {
      duplicateKeys.push(key);
    } else {
      seenKeys.add(key);
    }
  });

  if (duplicateKeys.length > 0) {
    console.error(`Duplicate keys found in data object: ${duplicateKeys.join(", ")}`);
    throw new Error(`Duplicate keys found in data object: ${duplicateKeys.join(", ")}`);
  }

  return true;
};

/**
 * Preprocesses a ShortTermGoalType object to replace undefined string values with empty strings
 * @param goal The ShortTermGoalType object to preprocess
 * @returns A new ShortTermGoalType object with undefined values replaced with empty strings
 */
export const preprocessShortTermGoalNode = (goal: ShortTermGoalType): ShortTermGoalType => {
  return {
    ...goal,
    description: goal.description ?? "",
    assessmentToolSource: goal.assessmentToolSource ?? "",
    // Set baselineData based on establishBaselineOnTreatment flag
    baselineData: goal.establishBaselineOnTreatment
      ? "Baseline Data will be established once treatment begins."
      : goal.baselineData ?? "",
    externalId: goal.externalId ?? "",
    masteryCriteria: goal.masteryCriteria ?? "",
    medicalNecessityCriteria: goal.medicalNecessityCriteria ?? "",
    programGoal: goal.programGoal ?? "",
    predictionId: goal.predictionId ?? "",
  };
};
