import React from "react";
import { useNavigate } from "react-router-dom";

import { MultiSelect, Small } from "@fronterahealth/frontera-ui-components";

import {
  ConnectionTypeEnum,
  CreateProviderMutationVariables,
  LearnerType,
  ProviderRoleEnums,
  useCreateProviderMutation,
} from "@api/graphql/types-and-hooks";
import { ContentRow } from "@components/ContentRow/ContentRow";
import { FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifySuccess } from "@components/notifications/notifications";
import { useAdminData } from "@providers/AdminDataProvider";
import { useProvidersData } from "@providers/ProvidersDataProvider";

function getCustomString(key: ProviderRoleEnums): string {
  switch (key) {
    case ProviderRoleEnums.Bcba:
      return "BCBA";
    case ProviderRoleEnums.Rbt:
      return "RBT";
    case ProviderRoleEnums.AssessmentBcba:
      return "Assessment BCBA";
    default:
      // Capitalize the entire string if not matched
      return (key as string).replace(/_/g, " ").toUpperCase();
  }
}

export const NewProvider: React.FC = () => {
  const { providersQuery } = useProvidersData();
  const createProvider = useCreateProviderMutation({
    onSuccess: async () => {
      providersQuery.refetch();
    },
  });
  const go = useNavigate();
  const { learnersQuery } = useAdminData();

  const [selectedClients, setSelectedClients] = React.useState<{ id?: string; primary: string }[]>([]);

  const learners = (learnersQuery?.data?.learner || []).filter(Boolean) as LearnerType[];

  const { formState, onSubmit, RegisteredFormInput, RegisteredCheckboxList, RegisteredFormRadioInput } =
    useFormUtils<CreateProviderMutationVariables>({
      defaultValues: {
        provider: {
          firstName: "",
          lastName: "",
          email: "",
          providerRole: [],
          connectionType: ConnectionTypeEnum.UsernamePassword,
        },
      },
      mutationFn: async (params) => {
        const learnerIds = selectedClients.filter((c) => !!c.id).map((c) => c.id!);
        await createProvider!
          .mutateAsync({
            provider: { ...params.provider, learners: learnerIds },
          })
          .then(() => {
            notifySuccess("Provider Created Successfully");
            go("/providers");
          });
      },
    });

  return (
    <FormContainer onSubmit={(e) => onSubmit(e)}>
      <div className="mt-10 flex w-full flex-col gap-y-4">
        <ContentRow title="Provider Details" subtitle="Enter the provider's details below.">
          <div className="gap-x-4  lg:grid lg:grid-cols-2">
            <RegisteredFormInput
              formKey="provider.firstName"
              inputSize="full"
              formState={formState}
              label="First Name"
            />
            <RegisteredFormInput formKey="provider.lastName" inputSize="full" formState={formState} label="Last Name" />
          </div>
          <div className="gap-x-4  lg:grid lg:grid-cols-2">
            <RegisteredFormInput
              formKey="provider.email"
              inputSize="full"
              formState={formState}
              label="Email"
              type="email"
            />
            <div />
          </div>
        </ContentRow>
        <ContentRow
          title="Provider Role Assignment"
          subtitle="Select the role and subsequent tool access for the provider."
        >
          <RegisteredCheckboxList
            formState={formState}
            formKey="provider.providerRole"
            title="Provider Role"
            items={Object.keys(ProviderRoleEnums).map((key) => ({
              id: ProviderRoleEnums[key as keyof typeof ProviderRoleEnums],
              title: getCustomString(ProviderRoleEnums[key as keyof typeof ProviderRoleEnums]),
            }))}
          />
        </ContentRow>
        <ContentRow
          title="User Invitation Configuration"
          subtitle={
            <>
              <Small className="max-w-[80%]">
                A <b>Username & Password</b> account type will have the user create a new password when accepting the
                invitation. <br /> A <b>Google OAuth</b> account type will have the user use their Google account when
                accepting the invitation.
              </Small>
            </>
          }
        >
          <RegisteredFormRadioInput
            formState={formState}
            formKey="provider.connectionType"
            title="Auth0 Account Type"
            required
            items={[
              { id: ConnectionTypeEnum.UsernamePassword, title: "Username & Password" },
              { id: ConnectionTypeEnum.GoogleOauth, title: "Google OAuth" },
            ]}
          />
        </ContentRow>
        <ContentRow title="Client Mappings" subtitle="Assign the clients that will be working with this Provider.">
          <MultiSelect
            title={"Assigned Clients"}
            items={(
              learners?.map((l) => ({
                id: l.id,
                primary: `${l.learnermetadata?.firstName} ${l.learnermetadata?.lastName}`,
              })) || []
            ).sort((a, b) => (a.primary > b.primary ? 1 : -1))}
            placeholderText={"available clients"}
            selected={selectedClients}
            setSelected={(items) => setSelectedClients(items)}
            mode={"tags"}
          />
        </ContentRow>
      </div>

      <div className="mt-6">
        <SubmitButton isLoading={formState.isSubmitting} buttonText="Save & Continue" />
      </div>
    </FormContainer>
  );
};
