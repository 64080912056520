import { PencilSquareIcon } from "@heroicons/react/24/solid";
import React from "react";

import { Input, Paragraph, SignatureInput, Small, TextArea } from "@fronterahealth/frontera-ui-components";

import { useAdminData } from "@providers/AdminDataProvider";

const PolygonShape: React.FC = () => {
  return (
    <div className="relative">
      <svg
        className="absolute inset-y-1.5 -right-11 w-16 bottom-0"
        width="40"
        height="37.07"
        viewBox="0 0 40 37.07"
        xmlns="http://www.w3.org/2000/svg"
        style={{ opacity: 1 }}
      >
        {/* Half hexagon shape with direct rotation applied to points */}
        <polygon points="40,0 20,0 0,18.535 20,37.07 40,37.07" fill="#E77757" />
      </svg>
    </div>
  );
};

interface SignatureSectionProps {
  closingRemark: string;
  setClosingRemark: (val: string) => void;
  signature: string;
  setSignature: (val: string) => void;
  timestamp: Date;
  setTimestamp: (val: Date) => void;
  isReadOnly?: boolean;
  licenseNumber: string;
  setLicenseNumber: (val: string) => void;
  professionalTitle: string;
  setProfessionalTitle: (val: string) => void;
}

export const SignatureSection: React.FC<SignatureSectionProps> = ({
  signature,
  setSignature,
  closingRemark,
  setClosingRemark,
  timestamp,
  setTimestamp,
  isReadOnly,
  licenseNumber,
  setLicenseNumber,
  professionalTitle,
  setProfessionalTitle,
}) => {
  const { isCatalightUser } = useAdminData();
  return (
    <div className="pageBreak">
      <section className="px-8 py-6 mb-4 rounded-3xl bg-white" id="signature">
        <div className="flex items-center mb-4" id="signature-signature" data-sticky-nav-header>
          <div className="border p-2 rounded mr-2">
            <PencilSquareIcon className="h-6 w-6 text-text-primary" />
          </div>
          <div>
            <Paragraph data-dynamic-section displayType="loud" colorType="primary">
              Signature
            </Paragraph>
          </div>
        </div>
        {!isCatalightUser ? (
          <div className={isReadOnly ? "mb-2" : ""}>
            <Small data-dynamic-field displayType="normal" colorType="secondary">
              Closing Remarks
            </Small>
            <TextArea
              value={closingRemark}
              displayOnlyMode={isReadOnly}
              onChange={(e) => setClosingRemark(e.target.value)}
              label=""
              name="closing-remarks"
              hintText=""
            />
          </div>
        ) : (
          <></>
        )}
        <div className={isReadOnly ? "mb-10" : "mb-6"}>
          <Small data-dynamic-field displayType="normal" colorType="secondary">
            Professional Title
          </Small>
          {isReadOnly ? (
            <Small colorType="primary" className="-mb-6">
              {professionalTitle}
            </Small>
          ) : (
            <Input
              name="professional-title"
              label=""
              inputSize="full"
              className="-mb-7"
              placeholder=""
              variant="report"
              value={professionalTitle}
              displayOnlyMode={isReadOnly}
              onChange={(e) => setProfessionalTitle(e.target.value)}
            />
          )}
        </div>
        <div className={isReadOnly ? "mb-10" : "mb-6"}>
          <Small data-dynamic-field displayType="normal" colorType="secondary">
            License/cert.#
          </Small>
          {isReadOnly ? (
            <Small colorType="primary" className="-mb-6">
              {licenseNumber}
            </Small>
          ) : (
            <Input
              name="license-number"
              label=""
              inputSize="full"
              className="-mb-7"
              placeholder=""
              variant="report"
              value={licenseNumber}
              displayOnlyMode={isReadOnly}
              onChange={(e) => setLicenseNumber(e.target.value)}
            />
          )}
        </div>
        <Paragraph displayType="normal" className={isReadOnly ? "mb-6" : ""}>
          {isReadOnly
            ? `This assessment report was approved by:`
            : `By clicking “Sign & Complete”, you are stating that the information you have provided in this assessment report is correct to your best understanding.`}
        </Paragraph>
        {!isReadOnly && <PolygonShape />}

        <SignatureInput
          key={isReadOnly ? JSON.stringify(timestamp) : "signature-component"}
          className="mt-0 captureSignature"
          name="signature"
          data-testid="signature-input"
          date={timestamp}
          inputLabel=""
          placeholder="first and last name"
          value={signature}
          onInputChange={(val) => {
            setSignature(val);
            setTimestamp(new Date());
          }}
          defaultValue={signature}
          inputSize="md"
          isSignatureReadOnlyMode={isReadOnly}
        />
      </section>
    </div>
  );
};
