import { useAuth0 } from "@auth0/auth0-react";
import { downloadLearnerFileById } from "@utils/helpers";

import { ExistingFileInterface, FileUploadAndParse } from "@fronterahealth/frontera-ui-components";

import { FileTypeEnum } from "@api/graphql/types-and-hooks";
import { ContentRow } from "@components/ContentRow/ContentRow";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/hooks";

interface BehavioralObservationsProps {
  existingBehavioralObservations: ExistingFileInterface[];
}
export const BehavioralObservations: React.FC<BehavioralObservationsProps> = ({ existingBehavioralObservations }) => {
  const { getAccessTokenSilently } = useAuth0();
  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({});
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const fileKind: FileTypeEnum = FileTypeEnum.BehavioralObservations;
  return (
    <ContentRow title="Behavioral Observations" subtitle="Behavioral Observations files">
      <FileUploadAndParse
        key={JSON.stringify(existingBehavioralObservations)}
        fileKind={fileKind}
        title={"Behavioral Observations"}
        supportedFileTypes={["pdf", "docx"]}
        maxSize={"1GB"}
        existingFiles={existingBehavioralObservations}
        S3BucketUrlRetriever={S3BucketUrlRetriever}
        S3BucketUploadCallback={S3BucketUploadCallback}
        reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
        fileParseStatusRetriever={fileParseStatusRetriever}
        removeFileCallback={removeFileCallback}
        hintText="Optional"
        allowFileDownload={true}
        downloadFileCallback={async (fileId) => {
          const token = await getAccessTokenSilently();
          await downloadLearnerFileById({ fileId: fileId, token: token });
        }}
      />
    </ContentRow>
  );
};
