import React, { PropsWithChildren, useState } from "react";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";
import {
  NestedOpenDirection,
  PopoverMenu,
  PopoverMenuItem,
} from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

import { ShortTermGoalType, useRemoveReportTreatmentPlanShortTermGoalMutation } from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

interface ShortTermGoalCardProps extends PropsWithChildren {
  onClick: () => void;
  goalObject: ShortTermGoalType;
  refetchGoals: () => Promise<void>;
  onSaveToFolder?: () => void;
  uiGoalId?: string;
  useLongTermGoals?: boolean;
  submenuGoalsList: PopoverMenuItem[];
  submenuGoalsOpenDirection?: NestedOpenDirection;
}

export const ShortTermGoalCard: React.FC<ShortTermGoalCardProps> = ({
  goalObject,
  useLongTermGoals,
  onClick,
  children,
  refetchGoals,
  onSaveToFolder,
  uiGoalId,
  submenuGoalsList,
  submenuGoalsOpenDirection = "right",
}) => {
  const { assessmentQuery } = useAssessmentBuilderData();
  const assessmentReport = assessmentQuery?.data?.getReports?.edges?.[0]?.node;
  const learnerId = assessmentReport?.learner?.id || "<missing-learner-id>";
  const removeShortTermGoalMutation = useRemoveReportTreatmentPlanShortTermGoalMutation({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  return (
    <div>
      <div
        onClick={onClick}
        className="flex flex-col h-full hover:cursor-pointer hover:border-limestone-300 border transition-all rounded-md shadow bg-white"
      >
        {/* Header section with fixed height */}
        <div className="flex items-center p-4 flex-shrink-0">
          <div className="w-7 flex-shrink-0">
            <Paragraph displayType="loud" colorType="secondary">
              {uiGoalId ?? null}
            </Paragraph>
          </div>
          <div className="flex-grow overflow-hidden">
            <Paragraph displayType="loud" colorType="primary" className="truncate">
              {goalObject?.goalName}
            </Paragraph>
          </div>
          <div className="w-6 flex-shrink-0 ml-2">
            <PopoverMenu
              openDirection="to-bottom-left"
              submenuOpenDirection={submenuGoalsOpenDirection}
              menuItems={[
                {
                  label: "Edit",
                  callback: () => onClick(),
                },
                {
                  label: "Save to Folder...",
                  callback: () => {
                    if (onSaveToFolder) onSaveToFolder();
                  },
                },
                {
                  label: "Move to ",
                  submenu: submenuGoalsList,
                },
                {
                  label: deleteLoading ? "Deleting..." : "Delete",
                  callback: async () => {
                    setDeleteLoading(true);
                    try {
                      await removeShortTermGoalMutation.mutateAsync({
                        learner: learnerId,
                        shortTermGoalId: goalObject.id,
                      });
                      notifySuccess("Successfully Deleted Short Term Goal");
                      await refetchGoals();
                      setDeleteLoading(false);
                    } catch (err) {
                      console.error("Failed To Delete Short Term Goal", err);
                      notifyError("Failed To Delete Short Term Goal");
                    }
                  },
                },
              ]}
            />
          </div>
        </div>

        {/* Description section with scrollable content */}
        <div className="p-4 pt-0">
          <Small displayType="normal" className="h-full overflow-y-auto">
            {useLongTermGoals ?? true ? goalObject?.description : goalObject.masteryCriteria}
          </Small>
        </div>

        {/* Footer section for children */}
        <div className="flex-shrink-0 p-4 pt-0">{children}</div>
      </div>
    </div>
  );
};
