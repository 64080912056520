import { forwardRef, memo } from "react";

import { VideoJsPlayerOptions, VideoPlayer, VideoPlayerHandle } from "@fronterahealth/frontera-ui-components";

interface VideoPlayerContentProps {
  videoUrl: string;
  onDurationChange?: (duration: number) => void;
  onSeeking?: () => void;
  onSeeked?: () => void;
  onTimeUpdate?: (time: number) => void;
  enabledFocus?: boolean;
  onPlayerReady?: () => void;
}

export const VideoPlayerContent = memo(
  forwardRef<VideoPlayerHandle, VideoPlayerContentProps>(
    ({ videoUrl, onDurationChange, enabledFocus, onSeeked, onSeeking, onTimeUpdate, onPlayerReady }, ref) => {
      const videoPlayerOptions: VideoJsPlayerOptions = {
        sources: [{ src: videoUrl, type: "video/mp4" }],
        preload: "auto",
        fluid: true,
        autoplay: false,
        loop: false,
        muted: true,
        controlBar: {
          autoPlay: false,
          pictureInPictureToggle: true,
          playbackRateMenuButton: false,
          children: [
            "playToggle",
            "currentTimeDisplay",
            "timeDivider",
            "durationDisplay",
            "progressControl",
            "fullscreenToggle",
            "volumePanel",
            "skipBackward",
            "skipForward",
          ],
          progressControl: true,
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          autoHide: false,
          skipButtons: { forward: 10, backward: 10 },
        },
        inactivityTimeout: 0,
        userActions: {
          click: false,
          doubleClick: true,
        },
        html5: {
          preloadTextTracks: true,
        },
      };

      return (
        <div className={`w-full aspect-video rounded-t-2xl  overflow-hidden`} data-video-player="true">
          <div className="video-sessions-player w-full h-full relative">
            <VideoPlayer
              ref={ref}
              src={videoUrl}
              onSeeked={onSeeked}
              onLoadedMetadata={(duration) => {
                onDurationChange?.(duration);
              }}
              onReady={onPlayerReady}
              onSeeking={onSeeking}
              onTimeUpdate={(state) => onTimeUpdate && onTimeUpdate(state.playedSeconds)}
              options={videoPlayerOptions}
              enableFocus={enabledFocus}
              className={`w-full h-full vjs-keep-controls vjs-user-active video-js vjs-default-skin`}
            />
          </div>
        </div>
      );
    },
  ),
);
