import { groupArrayByKeyValue } from "@utils/helpers.tsx";
import { getHierarchicalIdentifier, isMissingValue } from "@utils/utils.ts";
import { useEffect, useState } from "react";

import {
  ApiAiSuggestedTargetsTimelineEstimationTypeChoices,
  ApiLongTermGoalTimelineEstimationTypeChoices,
  ApiShortTermGoalTimelineEstimationTypeChoices,
  ApiTargetTimelineEstimationTypeChoices,
  ReportLongTermShortTermGoalsQuery,
  ReportTypeEnums,
  useReportLongTermShortTermGoalsQuery,
} from "@api/graphql/types-and-hooks.tsx";
import { convertReadableString } from "@components/forms/utils.ts";
import { GoalOverviewItem } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/components/GoalOverview.tsx";
import { useAdminData } from "@providers/AdminDataProvider";

const convertNullToPlaceholder = (
  val1: number | null | undefined,
  val2:
    | ApiTargetTimelineEstimationTypeChoices
    | ApiLongTermGoalTimelineEstimationTypeChoices
    | ApiAiSuggestedTargetsTimelineEstimationTypeChoices
    | ApiShortTermGoalTimelineEstimationTypeChoices
    | null
    | undefined,
  placeholder: string,
): string => {
  if (val1 && val2) {
    return `${val1} ${convertReadableString(val2)}`;
  } else if (val1) {
    return `${val1}`;
  } else if (val2) {
    return `${convertReadableString(val2)}`;
  } else {
    return placeholder;
  }
};

export const LTG_STG_Targets_To_Overview = (
  data: ReportLongTermShortTermGoalsQuery | undefined,
  isCatalightUser: boolean,
): GoalOverviewItem[] => {
  return (
    data?.getReports?.edges?.[0]?.node?.reportTreatmentPlan?.longtermgoalSet.edges
      ?.map((edge) => edge?.node)
      ?.map((ltg) => {
        return {
          description: isMissingValue(ltg?.description),
          goalName: isMissingValue(ltg?.goalName),
          goalType: isMissingValue(ltg?.goalType),
          masteryCriteria: isMissingValue(ltg?.masteryCriteria),
          timelineEstimation: convertNullToPlaceholder(ltg?.timelineEstimationValue, ltg?.timelineEstimationType, "-"),
          baselineData: isMissingValue(ltg?.baselineData),
          establishBaselineOnTreatment: ltg?.establishBaselineOnTreatment ? "Yes" : "No",
          programGoal: isMissingValue(ltg?.programGoal),
          type: "ltg",
          children: ltg?.shorttermgoalSet?.edges
            ?.map((edge) => edge?.node)
            ?.map((stg, stgIndex) => {
              const targets: GoalOverviewItem[] =
                stg?.targetSet?.edges
                  ?.map((edge) => edge?.node)
                  ?.map((target, targetIndex) => {
                    return {
                      description: isMissingValue(target?.targetDescription),
                      goalName: isMissingValue(
                        `${getHierarchicalIdentifier(stgIndex, targetIndex)}${target?.targetName}`,
                      ),
                      goalType: isMissingValue(ltg?.goalType),
                      masteryCriteria: isMissingValue(target?.masteryCriteria),
                      baselineData: isMissingValue(target?.baselineData),
                      establishBaselineOnTreatment: target?.establishBaselineOnTreatment ? "Yes" : "No",
                      timelineEstimation: convertNullToPlaceholder(
                        target?.timelineEstimationValue,
                        target?.timelineEstimationType,
                        "-",
                      ),
                      type: "target",
                    };
                  }) || [];
              return {
                description: isMissingValue(stg?.description),
                goalName: isMissingValue(`${getHierarchicalIdentifier(stgIndex)}${stg?.goalName}`),
                goalType: isMissingValue(ltg?.goalType),
                masteryCriteria: isMissingValue(stg?.masteryCriteria),
                medicalNecessityCriteria: stg?.hasMedicalNecessity
                  ? isMissingValue(stg?.medicalNecessityCriteria)
                  : undefined,
                timelineEstimation: convertNullToPlaceholder(
                  stg?.timelineEstimationValue,
                  stg?.timelineEstimationType,
                  "-",
                ),
                programGoal: isMissingValue(stg?.programGoal),
                assessmentToolSource: isCatalightUser ? isMissingValue(stg?.assessmentToolSource) : "",
                type: "stg",
                baselineData: isMissingValue(stg?.baselineData),
                establishBaselineOnTreatment: stg?.establishBaselineOnTreatment ? "Yes" : "No",
                children: targets,
              };
            }),
        };
      }) || []
  );
};

export const useGoals = ({ reportId, useLongTermGoals }: { reportId?: string; useLongTermGoals: boolean }) => {
  const [goalSections, setGoalSections] = useState<Record<string, GoalOverviewItem[]> | null>(null);
  const { isCatalightUser } = useAdminData();

  const { data: longTermShortTermTargets } = useReportLongTermShortTermGoalsQuery(
    { id: reportId as string, reportType: ReportTypeEnums.InitialAssessment },
    {
      queryKey: ["get-report-long-term-short-term-goals-query", reportId],
      enabled: !!reportId,
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    const formattedGoals = LTG_STG_Targets_To_Overview(longTermShortTermTargets, isCatalightUser);
    // For short term goals, filter down to goals that have children (the short term goals)
    const filteredGoals = useLongTermGoals
      ? formattedGoals
      : formattedGoals.filter((formattedGoal) => {
          return Number(formattedGoal?.children?.length) > 0;
        });
    const groupedGoals: Record<string, GoalOverviewItem[]> = groupArrayByKeyValue(filteredGoals, "goalName");
    setGoalSections(groupedGoals);
  }, [longTermShortTermTargets]);
  return { goalSections };
};
