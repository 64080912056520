import { useAuth0 } from "@auth0/auth0-react";
import { UseQueryResult, useInfiniteQuery } from "@tanstack/react-query";
import { convertHeadersToObject } from "@utils/utils";
import React, { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  CalendarDateRange,
  SearchableDropdownItem,
  SelectItem,
  VideoPlayerHandle,
} from "@fronterahealth/frontera-ui-components";

import { FronteraGraphQLClient } from "@api/graphql/graphql-service";
import {
  GetMediaCommentsByMediaIdQuery,
  GetSessionSummaryBySessionIdQuery,
  GetTranscriptDataByMediaIdDocument,
  MediaCommentEnum,
  MediaSortByEnum,
  TranscriptLineRecordTypeEdge,
  useGetMediaByProviderByMediaIdQuery,
  useGetMediaCommentsByMediaIdQuery,
  useGetSessionSummaryBySessionIdQuery,
} from "@api/graphql/types-and-hooks";
import { Comment } from "@pages/VideoSessions/components/Comments/helper";
import { MediaCardType } from "@pages/VideoSessions/components/MediaCards";
import { SideMomentPanelItem } from "@pages/VideoSessions/types";
import { useAdminData } from "@providers/AdminDataProvider";

export type videoSessionNavigationTabType = "activity" | "transcript" | "summary" | "moments";

interface VideoProvidersContextI {
  selectedTab: videoSessionNavigationTabType;
  setSelectedTab: (val: videoSessionNavigationTabType) => void;
  selectedMedia: MediaCardType | null; // This will now be derived from URL
  filterSelectedClients: SearchableDropdownItem[];
  setFilterSelectedClients: (val: SearchableDropdownItem[]) => void;
  filterSelectedProviders: SearchableDropdownItem[];
  setFilterSelectedProviders: (val: SearchableDropdownItem[]) => void;
  filterSessionDates: CalendarDateRange | undefined;
  setFilterSessionDates: (val: CalendarDateRange) => void;
  filterSelectedRating: SelectItem | undefined;
  setFilterSelectedRating: (val: SelectItem | undefined) => void;
  playerRef: React.RefObject<VideoPlayerHandle>;
  currentTime: number;
  setCurrentTime: (time: number) => void;
  sortBy: SelectItem;
  setSortBy: (val: SelectItem) => void;
  totalFilteredMediaCount: number;
  setTotalFilteredMediaCount: (val: number) => void;
  filteredCommentType: SearchableDropdownItem[] | [];
  setFilteredCommentType: (val: SearchableDropdownItem[]) => void;
  commentsByMediaIdQuery: UseQueryResult<GetMediaCommentsByMediaIdQuery, unknown>;
  editingCommentId: string | null;
  setEditingCommentId: (val: string | null) => void;
  isUserRBT: boolean;
  allTranscriptData: TranscriptLineRecordTypeEdge[] | [];
  isTranscriptLoading: boolean;
  clearFilters: () => void;
  clearFeedFilters: () => void;
  isVideoDetailsPage: boolean;
  getSessionSummaryBySessionIdQuery: UseQueryResult<GetSessionSummaryBySessionIdQuery, unknown>;

  isMediaLoading: boolean;
  setIsMediaLoading: (val: boolean) => void;
  allFilteredMedias: MediaCardType[];
  setAllFilteredMedias: React.Dispatch<React.SetStateAction<MediaCardType[]>>;
  allFilteredComments: Comment[];
  setAllFilteredComments: (val: Comment[]) => void;
  filterIsReviewed: boolean | undefined;
  setFilterIsReviewed: (val: boolean | undefined) => void;
  isFilterActive: boolean | undefined;
  setIsFilterActive: (val: boolean) => void;
  filterByUploadStatus: SearchableDropdownItem[];
  setFilterByUploadStatus: (val: SearchableDropdownItem[]) => void;

  sidebarMomentsList: SideMomentPanelItem[];
  setSidebarMomentsList: (val: SideMomentPanelItem[]) => void;

  filteredMomentType: SearchableDropdownItem[] | [];
  setFilteredMomentType: (val: SearchableDropdownItem[]) => void;
}

export const VideoSessionContext = createContext<VideoProvidersContextI | undefined>(undefined);

export const useVideoSessionData = () => {
  const context = useContext(VideoSessionContext);
  if (context === undefined) {
    throw new Error("useVideoSessionData must be used within a VideoSessionProvider");
  }
  return context;
};
export const defaultSortBy = {
  id: MediaSortByEnum.DescSessionUploadDate,
  primary: "Newest Upload",
};
const momentsList: SideMomentPanelItem[] = [
  { label: "Aggression", isVisible: true, color: "globemallow-500" },
  { label: "Screaming & Crying", isVisible: true, color: "hatch-500" },
];
export const VideoSessionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { mediaId } = useParams();
  const { pathname } = useLocation();

  const { roles } = useAdminData();
  const { getAccessTokenSilently } = useAuth0();

  const isUserRBT: boolean = roles.includes("rbt") && !roles.includes("bcba") && !roles.includes("admin");
  // Fetch media data based on URL parameter
  const { data: mediaData } = useGetMediaByProviderByMediaIdQuery(
    { mediaId: mediaId || "" },
    { enabled: Boolean(mediaId) },
  );

  const [selectedTab, setSelectedTab] = useState<videoSessionNavigationTabType>("activity");
  const [filterSelectedClients, setFilterSelectedClients] = useState<SearchableDropdownItem[]>([]);
  const [filterSelectedProviders, setFilterSelectedProviders] = useState<SearchableDropdownItem[]>([]);
  const [filterSessionDates, setFilterSessionDates] = useState<CalendarDateRange | undefined>({});
  const [filterSelectedRating, setFilterSelectedRating] = useState<SelectItem | undefined>();
  const [filterIsReviewed, setFilterIsReviewed] = useState<boolean | undefined>();
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SelectItem>(defaultSortBy);
  const [filterByUploadStatus, setFilterByUploadStatus] = useState<SearchableDropdownItem[]>([]);

  const [totalFilteredMediaCount, setTotalFilteredMediaCount] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const playerRef = useRef<VideoPlayerHandle>(null);

  const [filteredCommentType, setFilteredCommentType] = useState<SearchableDropdownItem[]>([]);
  const [editingCommentId, setEditingCommentId] = useState<string | null>("");
  const [allTranscriptData, setAllTranscriptData] = useState<TranscriptLineRecordTypeEdge[] | []>([]);
  const [isTranscriptLoading, setIsTranscriptLoading] = useState<boolean>(false);
  const [sidebarMomentsList, setSidebarMomentsList] = useState<SideMomentPanelItem[]>(momentsList);

  const [filteredMomentType, setFilteredMomentType] = useState<SearchableDropdownItem[]>([]);

  const queryKey = React.useMemo(
    () => ["getMediaComments", mediaId, filteredCommentType],
    [mediaId, filteredCommentType],
  );

  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(false);
  const [allFilteredMedias, setAllFilteredMedias] = useState<MediaCardType[]>([]);
  const [allFilteredComments, setAllFilteredComments] = useState<Comment[]>([]);

  const commentsByMediaIdQuery = useGetMediaCommentsByMediaIdQuery(
    {
      mediaId: mediaId || "",
      commentTypes: filteredCommentType.length
        ? (filteredCommentType.map((item) => item?.id) as unknown as MediaCommentEnum)
        : undefined,
    },
    {
      queryKey,
      enabled: Boolean(mediaId) && pathname.includes(`/video-sessions/${mediaId}`),
    },
  );

  const transcriptByMediaIdQuery = useInfiniteQuery({
    queryKey: ["getTranscriptDataByMediaId", mediaId],
    queryFn: async ({ pageParam = { after: null } }) => {
      const { after } = pageParam;
      const variables = { mediaId, after };
      const token = await getAccessTokenSilently();
      const authHeaders: HeadersInit = new Headers();
      authHeaders.append("Authorization", `Bearer ${token}`);
      const headers = convertHeadersToObject(authHeaders);
      const response = await FronteraGraphQLClient.request(GetTranscriptDataByMediaIdDocument, variables, headers);

      return response.getTranscriptDataByMediaId;
    },
    initialPageParam: { after: null },
    getNextPageParam: (lastPage) => {
      if (lastPage.pageInfo.hasNextPage) {
        return {
          after: lastPage.pageInfo.endCursor, // Use the cursor for more efficient pagination
        };
      }
      return undefined; // No more pages to fetch
    },
    enabled: Boolean(mediaId),
    refetchOnWindowFocus: false,
    retry: false,
  });
  const {
    data: transcriptData,
    isFetchingNextPage: isTranscriptNextPageFetching,
    isFetching: isTranscriptFetching,
    hasNextPage: isTranscriptHasNextPage,
    fetchNextPage: fetchTranscriptNextPage,
  } = transcriptByMediaIdQuery;

  // Transform mediaData into MediaCardType format
  const selectedMedia: MediaCardType | null = React.useMemo(() => {
    if (!mediaData?.getMediaByProviderByMediaId) return null;

    return {
      id: mediaData.getMediaByProviderByMediaId.id,
      createdDate: mediaData.getMediaByProviderByMediaId.createdDate,
      mediaType: mediaData.getMediaByProviderByMediaId.mediaType,
      mediaStatus: mediaData.getMediaByProviderByMediaId.mediaStatus,
      therapySession: mediaData.getMediaByProviderByMediaId.therapySession,
      commentsCount: mediaData.getMediaByProviderByMediaId.commentsCount,
      mediaClassifications: mediaData.getMediaByProviderByMediaId.mediaClassifications,
    } as MediaCardType;
  }, [mediaData]);

  const getSessionSummaryBySessionIdQuery = useGetSessionSummaryBySessionIdQuery(
    {
      therapySessionId: selectedMedia?.therapySession?.id || "",
    },
    {
      queryKey: ["getSessionSummaryBySessionId", selectedMedia],
      enabled: Boolean(selectedMedia?.therapySession?.id),
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  useEffect(() => {
    setIsFilterActive(
      filterSelectedClients.length > 0 ||
        filterSelectedProviders.length > 0 ||
        filterByUploadStatus.length > 0 ||
        (filterSessionDates?.startDate !== undefined && filterSessionDates?.endDate !== undefined) ||
        filterSelectedRating !== undefined ||
        filterIsReviewed !== undefined,
    );
  }, [
    filterSelectedClients,
    filterSelectedProviders,
    filterSessionDates,
    filterSelectedRating,
    filterIsReviewed,
    filterByUploadStatus,
  ]);

  useEffect(() => {
    if (isTranscriptFetching || isTranscriptNextPageFetching) {
      setIsTranscriptLoading(true);
    } else {
      setIsTranscriptLoading(false);
    }
  }, [isTranscriptFetching, isTranscriptNextPageFetching]);

  useEffect(() => {
    if (isTranscriptHasNextPage && !isTranscriptNextPageFetching) {
      fetchTranscriptNextPage();
    }
  }, [isTranscriptHasNextPage, isTranscriptNextPageFetching, fetchTranscriptNextPage]);

  useEffect(() => {
    if (transcriptData && !isTranscriptHasNextPage && !isTranscriptNextPageFetching) {
      const dataTemp = transcriptData.pages
        .map((group) => {
          return [...group.edges];
        })
        .flat(); // Append new edges
      setAllTranscriptData(dataTemp);
    }
  }, [transcriptData, isTranscriptHasNextPage, isTranscriptNextPageFetching]);

  const clearFilters = () => {
    setSelectedTab("activity");
    setFilteredCommentType([]);
  };

  const clearFeedFilters = () => {
    setSortBy(defaultSortBy);
    setFilterSelectedClients([]);
    setFilterSelectedProviders([]);
    setFilterSessionDates({});
    setFilterByUploadStatus([]);
    setFilterSelectedRating(undefined);
    setFilterIsReviewed(undefined);
    setIsFilterActive(false);
  };

  return (
    <VideoSessionContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        selectedMedia,
        filterSelectedClients,
        setFilterSelectedClients,
        filterSelectedProviders,
        setFilterSelectedProviders,
        filterSessionDates,
        setFilterSessionDates,
        filterSelectedRating,
        setFilterSelectedRating,
        sortBy,
        setSortBy,
        totalFilteredMediaCount,
        setTotalFilteredMediaCount,
        playerRef,
        currentTime,
        setCurrentTime,
        filteredCommentType,
        setFilteredCommentType,
        commentsByMediaIdQuery,
        editingCommentId,
        setEditingCommentId,
        isUserRBT,
        allTranscriptData,
        isTranscriptLoading,
        clearFilters,
        isVideoDetailsPage: !!mediaId,
        getSessionSummaryBySessionIdQuery,
        isMediaLoading,
        setIsMediaLoading,
        allFilteredMedias,
        setAllFilteredMedias,
        allFilteredComments,
        setAllFilteredComments,
        filterIsReviewed,
        setFilterIsReviewed,
        clearFeedFilters,
        isFilterActive,
        setIsFilterActive,
        filterByUploadStatus,
        setFilterByUploadStatus,

        sidebarMomentsList,
        setSidebarMomentsList,

        filteredMomentType,
        setFilteredMomentType,
      }}
    >
      {children}
    </VideoSessionContext.Provider>
  );
};
