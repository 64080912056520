import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import React from "react";

import { Paragraph, SignatureInput } from "@fronterahealth/frontera-ui-components";

import PolygonShape from "@components/Signature/PolygonShape";
import { HeadingSection } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/StickyNav/StickyNav";

interface SignaturePartProps {
  signature: string;
  setSignature: (val: string) => void;
  date: Date;
  setDate: (val: Date) => void;
  isReadOnly?: boolean;
}

export const SignaturePart: React.FC<SignaturePartProps> = ({ signature, setSignature, date, setDate, isReadOnly }) => {
  return (
    <div className="pageBreak">
      <HeadingSection id="signature" key="signature" Icon={PencilSquareIcon} label="Signature" isLast>
        <Paragraph className="px-4" displayType="normal">
          {isReadOnly
            ? `This assessment report was approved by:`
            : `By clicking “Sign & Complete”, you are stating that the information you have provided in this assessment report is correct to your best understanding.`}
        </Paragraph>
        {!isReadOnly && <PolygonShape />}
        {isReadOnly ? (
          <div className="flex h-20 flex-col gap-y-2 rounded bg-hatch-50 p-5 align-middle mt-5 captureSignature">
            <p className="text-2xl leading-6" style={{ fontFamily: "cursive" }}>
              {signature}
            </p>
            <p className="text-sm">{format(date, "MM/dd/yyyy h:mm a")}</p>
          </div>
        ) : (
          <SignatureInput
            className="mt-0 captureSignature"
            name="signature"
            date={date}
            inputLabel=""
            value={signature}
            onInputChange={(val) => {
              setSignature(val);
              setDate(new Date());
            }}
            defaultValue={signature}
            inputSize="md"
            isSignatureReadOnlyMode={isReadOnly}
          />
        )}
      </HeadingSection>
    </div>
  );
};
