import { ArrowPathIcon, CheckIcon, ExclamationTriangleIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PropsWithChildren, useState } from "react";

import { Small } from "@fronterahealth/frontera-ui-components";
import {
  PopoverMenu,
  PopoverMenuItem,
} from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

import { useAdminData } from "@providers/AdminDataProvider";

interface FieldComponentProps extends PropsWithChildren {
  id: string;
  title?: string;
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
  placeholder: string;
  isDeleting?: boolean;
  onEditTitle: (newTitle: string) => Promise<unknown>;
  actionButtonMenu?: PopoverMenuItem[];
}

export const FieldComponent: React.FC<FieldComponentProps> = ({
  id,
  permissions,
  title = "",
  placeholder,
  onEditTitle,
  children,
  actionButtonMenu,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [tempSuccessShowing, setTempSuccessShowing] = useState<boolean>(false);
  const [errorShowing, setErrorShowing] = useState<boolean>(false);
  const { hasCustomOutputTemplate } = useAdminData();

  const handleSave = async () => {
    try {
      setTempSuccessShowing(true);
      await onEditTitle(newTitle);
      setTempSuccessShowing(false);
      setIsEditing(false);
    } catch (err) {
      console.error(`Error when saving field title`, err);
      setErrorShowing(true);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(e.target.value);
  };

  return (
    <div className=" mt-4 rounded-3xl bg-white" id={id} onMouseOver={(e) => e.stopPropagation()}>
      <div className={`flex items-center justify-between ${isEditing ? "pb-10" : ""}`}>
        <div className="flex items-center relative group w-full" id={id}>
          {isEditing && permissions.canEdit ? (
            <div className="w-full">
              <input
                className="peer block w-full border-0 py-1.5 text-text-primary placeholder-limestone-200 ring-inset ring-limestone-200 placeholder:text-limestone-400 sm:text-sm sm:leading-6 focus:ring-0 focus:outline-none cursor-text"
                type="text"
                defaultValue={newTitle}
                placeholder={placeholder}
                onChange={handleTitleChange}
                autoFocus
              />
              <div className="absolute -bottom-8 left-3 flex items-center gap-x-2">
                <XMarkIcon
                  onClick={() => {
                    setErrorShowing(false);
                    setTempSuccessShowing(false);
                    setNewTitle(title);
                    setIsEditing(false);
                  }}
                  className="h-6 w-6 cursor-pointer rounded-md border border-limestone-200 bg-transparent p-1 text-text-secondary transition-all hover:bg-limestone-50"
                />
                <div
                  className={`flex h-6 w-6 items-center justify-center rounded-md border ${
                    tempSuccessShowing
                      ? "border-green-700 bg-green-100"
                      : errorShowing
                        ? "border-red-700 bg-red-100"
                        : "border-limestone-200"
                  }`}
                >
                  {tempSuccessShowing ? (
                    <ArrowPathIcon className="h-6 w-6 animate-spin p-1 text-text-secondary" />
                  ) : (
                    <>
                      {errorShowing ? (
                        <ExclamationTriangleIcon className="h-6 w-6 p-1 text-red-700" />
                      ) : (
                        <CheckIcon
                          onClick={handleSave}
                          className={`h-6 w-6 p-1 cursor-pointer ${
                            tempSuccessShowing ? "text-green-700" : "text-text-secondary"
                          }`}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <Small
                displayType="normal"
                colorType="secondary"
                data-dynamic-field
                className={`${permissions.canEdit ? "cursor-pointer py-2" : ""} `}
                onClick={() => permissions.canEdit && setIsEditing(true)}
              >
                {newTitle}
              </Small>
              {permissions.canEdit && !hasCustomOutputTemplate ? (
                <PencilIcon
                  onClick={() => setIsEditing(true)}
                  className="h-6 w-6 ml-1 cursor-pointer rounded-md border border-limestone-200 p-1 text-text-secondary opacity-0 group-hover:opacity-100"
                />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        {permissions.canEdit && actionButtonMenu && !hasCustomOutputTemplate ? (
          <PopoverMenu openDirection="to-bottom-left" menuItems={actionButtonMenu} />
        ) : (
          <></>
        )}
      </div>

      {children}
    </div>
  );
};
