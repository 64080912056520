import { CheckboxItem, PRIORITIZE_PAGE_CONTENT } from "@utils/constants";
import { getIdList, matchOrCreateItems } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { MultiSelectItem, Paragraph } from "@fronterahealth/frontera-ui-components";
import { RadioInputItem } from "@fronterahealth/frontera-ui-components/RadioInput/RadioInput";

import {
  AssessmentPrioritizeFieldsInput,
  ReportPageLocationEnums,
  ReportTreatmentPlanPrioritizeFieldsInput,
  useAddUpdateReportTreatmentPlanPrioritizeFieldsMutation,
} from "@api/graphql/types-and-hooks";
import { ConfirmGenerationDialog } from "@components/ConfirmGenerationDialog/ConfirmGenerationDialogV2";
import { ContentRow } from "@components/ContentRow/ContentRow";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { transformQueryData } from "@components/forms/utils";
import { notifyError } from "@components/notifications/notifications";
import { FooterButtonRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/FooterButtonRow";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

export const Prioritize: React.FC = () => {
  const { assessmentQuery } = useAssessmentBuilderData();

  const { refetch } = assessmentQuery;
  const assessmentReport = assessmentQuery?.data?.getReports?.edges?.[0]?.node;

  const { assessmentId: reportId } = useParams();
  const [promptDialogOpen, setPromptDialogOpen] = useState<boolean>(false);

  const addUpdatePrioritizeMutation = useAddUpdateReportTreatmentPlanPrioritizeFieldsMutation({});
  const { safetyConcern, communicationAbility, familyLife, engagementAbility, socialDesires } = PRIORITIZE_PAGE_CONTENT;

  const {
    onSubmit,
    RegisteredCheckboxList,
    formState,
    RegisteredFormRadioInput,
    RegisteredFormTextArea,
    RegisteredFormMultiSelect,
    reset,
  } = useFormUtils<ReportTreatmentPlanPrioritizeFieldsInput>({
    defaultValues: {
      safetyConcerns: [],
      engagementAbility: false,
      familyLife: [],
      communicationAbility: [],
      socialDesires: "",
    },
    mutationFn: async (params) => {
      const variable = {
        ...params,
        engagementAbility: params.engagementAbility === ("yes" as unknown as boolean),
      };

      await addUpdatePrioritizeMutation.mutateAsync(
        {
          reportId: reportId ? reportId : "missing-report-id",
          prioritizeFieldsDetails: {
            ...variable,
            familyLife: variable.familyLife.length
              ? getIdList(variable.familyLife as unknown as MultiSelectItem[])
              : [],
            communicationAbility: variable.communicationAbility || [],
            safetyConcerns: variable.safetyConcerns.length
              ? getIdList(variable.safetyConcerns as unknown as MultiSelectItem[])
              : [],
          },
        },
        {
          onSuccess: async () => {
            await refetch(); // refetch assessmentBuilderProvide data
            setPromptDialogOpen(true);
          },
          onError: async (error) => {
            console.error("Error Saving Prioritize Page", error);
            notifyError("Error Saving Prioritize Page");
          },
        },
      );
    },
  });

  useEffect(() => {
    if (assessmentReport) {
      const selectedAssessmenmt = assessmentReport;

      if (selectedAssessmenmt) {
        const converted = transformQueryData(selectedAssessmenmt, {
          "reportTreatmentPlanPrioritizeFields.engagementAbility": (value) =>
            value === false || value === "no" ? "no" : "yes",
        });

        if (converted.reportTreatmentPlanPrioritizeFields) {
          reset({
            ...converted.reportTreatmentPlanPrioritizeFields,
            safetyConcerns: converted.reportTreatmentPlanPrioritizeFields?.safetyConcerns
              ? matchOrCreateItems(
                  converted.reportTreatmentPlanPrioritizeFields.safetyConcerns as string[],
                  safetyConcern.optionItems as MultiSelectItem[],
                )
              : [],
            familyLife: converted.reportTreatmentPlanPrioritizeFields?.familyLife
              ? matchOrCreateItems(
                  converted.reportTreatmentPlanPrioritizeFields.familyLife as string[],
                  familyLife.optionItems as MultiSelectItem[],
                )
              : [],
          } as unknown as AssessmentPrioritizeFieldsInput);
        }
      }
    }
  }, [reset, assessmentReport]);

  return (
    <>
      <ConfirmGenerationDialog
        type={ReportPageLocationEnums.TreatmentPlan}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      <FormContainer onSubmit={onSubmit}>
        <div className="flex flex-col w-full gap-y-4">
          <ContentRow title={familyLife.title} subtitle={familyLife.description}>
            <Paragraph>{familyLife.question}</Paragraph>
            <RegisteredFormMultiSelect
              required={false}
              mode="tags"
              title={""}
              items={familyLife.optionItems as MultiSelectItem[]}
              formKey={"familyLife"}
              formState={formState}
              placeholderText="need(s)"
              className="-mt-6"
            />
          </ContentRow>
          <ContentRow title={engagementAbility.title} subtitle={engagementAbility.description}>
            <Paragraph>{engagementAbility.question}</Paragraph>
            <RegisteredFormRadioInput
              title={""}
              items={engagementAbility.optionItems as RadioInputItem[]}
              formKey={"engagementAbility"}
              formState={formState}
            />
          </ContentRow>
          <ContentRow title={communicationAbility.title} subtitle={communicationAbility.description}>
            <Paragraph>{communicationAbility.question}</Paragraph>
            <RegisteredCheckboxList
              required={false}
              formState={formState}
              orientation="vertical"
              formKey="communicationAbility"
              title={""}
              items={communicationAbility.optionItems as CheckboxItem[]}
            />
          </ContentRow>
          <ContentRow title={socialDesires.title} subtitle={socialDesires.description}>
            <RegisteredFormTextArea
              required={false}
              formState={formState}
              formKey="socialDesires"
              label={socialDesires.question}
            />
          </ContentRow>
          <FooterButtonRow
            primaryButtonType="submit"
            primaryButtonDisabled={formState.isSubmitting}
            primaryButtonLoading={formState.isSubmitting}
          />
        </div>
      </FormContainer>
    </>
  );
};
