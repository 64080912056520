import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Small } from "@fronterahealth/frontera-ui-components";

import { NotAllowedPage } from "@components/Authorize/Authorize";
import { ContentRow } from "@components/ContentRow/ContentRow";
import { NewClientLite } from "@pages/NewClient/NewClientLite/NewClientLite";
import { NewClientOnboarding } from "@pages/NewClient/NewClientOnboarding/NewClientOnboarding";
import { useAdminData } from "@providers/AdminDataProvider";
import { ClientCreationId, ClientCreationType } from "@routes/ClientDashboard/CreateClientDialog";

export const NewClient: React.FC = () => {
  const { isOnboardingEnabled } = useAdminData();
  const [selectedClientType, setSelectedClientType] = useState<ClientCreationId | null>(null);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams) {
      setSelectedClientType(searchParams.get("client-type") as ClientCreationId);
    }
  }, [searchParams]);

  const handleClientTypeChange = (newType: ClientCreationId) => {
    if (isFormDirty) {
      const confirmSwitch = window.confirm("You have unsaved changes. Are you sure you want to switch?");
      if (!confirmSwitch) {
        return;
      }
    }

    setIsFormDirty(false);
    setSearchParams({ "client-type": newType });
    setSelectedClientType(newType);
  };

  if (!isOnboardingEnabled) {
    return <NotAllowedPage />;
  }
  return (
    <div className="mt-10 flex w-full flex-col gap-y-4">
      <ContentRow title="Client Types">
        <div className="col-span-3 flex flex-col">
          <div className="gap-x-4 lg:grid lg:grid-cols-2 ">
            {[
              { id: ClientCreationId.ClientCaregiver, title: ClientCreationType.ClientCaregiver },
              {
                id: ClientCreationId.ClientOnly,
                title: ClientCreationType.ClientOnly,
              },
            ].map((item) => (
              <label key={item.id} className="flex flex-row space-x-2 items-center cursor-pointer">
                <input
                  type="radio"
                  name="clientCreationType"
                  value={item.id}
                  checked={selectedClientType === item.id} // ✅ Manually control checked state
                  onChange={() => handleClientTypeChange(item.id)}
                  className="hidden"
                />
                <span
                  className={`w-4 h-4 border-1 rounded-full flex items-center justify-center ring-1 ring-gray-300 bg-white border-gray-300 ${
                    selectedClientType === item.id ? "ring-hatch-600 border-hatch-600 border-[5px]" : "border-gray-300"
                  }`}
                />
                <Small displayType="loud">{item.title}</Small>
              </label>
            ))}
          </div>
        </div>
      </ContentRow>

      {selectedClientType === ClientCreationId.ClientOnly ? (
        <NewClientLite setIsFormDirty={setIsFormDirty} />
      ) : (
        <NewClientOnboarding setIsFormDirty={setIsFormDirty} />
      )}
    </div>
  );
};
