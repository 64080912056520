import React, { ChangeEvent, useEffect, useState } from "react";

import { RadioInput } from "@fronterahealth/frontera-ui-components";
import {
  PopoverMenu,
  PopoverMenuItem,
} from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";
import { RadioInputItem } from "@fronterahealth/frontera-ui-components/RadioInput/RadioInput";

interface InputData {
  options: string[];
  answer: string;
}

interface RadioInputFieldProps {
  inputJson: InputData;
  /**
   * Callback that receives an InputData object:
   * { options: string[], answer: string }
   */
  onChange: (json: InputData) => Promise<void>;
  actionButtonMenu: PopoverMenuItem[];
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
  displayOnlyMode: boolean;
  inputName: string;
}

export const RadioInputField: React.FC<RadioInputFieldProps> = ({
  inputJson,
  onChange,
  actionButtonMenu,
  permissions,
  displayOnlyMode = false,
  inputName = "",
}) => {
  const convertOptions = (opts: string[]): RadioInputItem[] =>
    opts.map((o) => ({
      id: o,
      title: o,
    }));

  const [options, setOptions] = useState<RadioInputItem[]>([]);
  const [selected, setSelected] = useState<string>("");

  useEffect(() => {
    if (Array.isArray(inputJson.options)) {
      setOptions(convertOptions(inputJson.options));
    } else {
      console.error("Invalid inputJson: 'options' must be an array");
      setOptions([]);
    }

    setSelected(inputJson.answer || "");
  }, [inputJson]);

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSelected = e.target.value;
    setSelected(newSelected);

    const output: InputData = {
      options: options.map((item) => item.title),
      answer: newSelected,
    };

    onChange(output);
  };

  return (
    <div className="group relative pr-6">
      <div data-testid="radio-input-container">
        <div className="relative overflow-auto">
          <RadioInput
            items={options}
            title=""
            name={inputName}
            legend="Report"
            onChange={handleRadioChange}
            disabled={displayOnlyMode}
            selectedId={selected}
            variant={displayOnlyMode ? "default" : "report"}
          />
        </div>
      </div>
      <div
        data-testid="radio-actions-panel"
        className={`group absolute -right-1 top-1 flex-col gap-y-4 ${
          permissions.canEdit ? "opacity-0 group-hover:opacity-100" : "pointer-events-none opacity-0"
        } mt-2 transition-all`}
      >
        {actionButtonMenu.length ? (
          <PopoverMenu mode="secondary" menuItems={actionButtonMenu} openDirection="to-bottom-left" />
        ) : null}
      </div>
    </div>
  );
};
