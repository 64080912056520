import { PlayCircleIcon } from "@heroicons/react/24/solid";
import React from "react";

import { Badge } from "@fronterahealth/frontera-ui-components";

import { ApiTherapySessionSessionStatusChoices } from "@api/graphql/types-and-hooks";
import { convertReadableString } from "@components/forms/utils";
import { FeedTooltip } from "@pages/VideoSessions/components/Media/FeedTooltip";
import { NO_MEDIA_STATUS } from "@pages/VideoSessions/const";

interface FeedMediaCardThumbnailProps {
  status: ApiTherapySessionSessionStatusChoices;
}

export const FeedMediaCardThumbnail: React.FC<FeedMediaCardThumbnailProps> = ({ status }) => {
  const hasNoMedia = NO_MEDIA_STATUS.includes(status);
  const processingStatuses = new Set([
    ApiTherapySessionSessionStatusChoices.Processing,
    ApiTherapySessionSessionStatusChoices.Scheduled,
  ]);
  const getStatusAppearance = () => {
    return status === ApiTherapySessionSessionStatusChoices.Failed
      ? "error"
      : processingStatuses.has(status) || status === ApiTherapySessionSessionStatusChoices.Uploading
        ? "processing"
        : "error";
  };

  const getTooltipTextByStatus = () => {
    if (processingStatuses.has(status))
      return "Your video is currently being processed. Please check back in a few hours!";
    if (status === ApiTherapySessionSessionStatusChoices.Failed)
      return "We ran into an issue processing your video upload. Don’t worry we’ll automatically retry uploading for you, check back in 5–7 days. If the issue persists, contact us at support@fronterahealth.com.";
    if (status === ApiTherapySessionSessionStatusChoices.Uploading)
      return "Video upload is still pending. Ask the original uploader to check their Wi-Fi and retry the upload.";
    return "";
  };

  return (
    <div className="w-full h-[120px] flex items-center justify-center bg-black rounded-[4px] relative">
      {hasNoMedia ? (
        <div className="absolute left-2 top-2">
          <FeedTooltip text={`${getTooltipTextByStatus()}`}>
            <Badge
              text={
                status === ApiTherapySessionSessionStatusChoices.Uploading
                  ? "Pending Upload"
                  : convertReadableString(status)
              }
              appearance={getStatusAppearance()}
              dot
            />
          </FeedTooltip>
        </div>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
          <PlayCircleIcon className="h-9 w-9 text-white" />
        </div>
      )}
    </div>
  );
};
