import { FeatureFlagSwitch } from "@utils/FeatureFlags/FeatureFlagSwitch";
import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Label } from "@fronterahealth/frontera-ui-components";

import { ApiTherapySessionSessionStatusChoices } from "@api/graphql/types-and-hooks";
import { FeedMediaCardEmojiPanel } from "@pages/VideoSessions/components/Media/FeedMediaCardEmojiPanel";
import { FeedMediaCardHeader } from "@pages/VideoSessions/components/Media/FeedMediaCardHeader";
import { FeedMediaCardMomentContent } from "@pages/VideoSessions/components/Media/FeedMediaCardMomentContent";
import { FeedMediaCardThumbnail } from "@pages/VideoSessions/components/Media/FeedMediaCardThumbnail";
import { MediaCardType } from "@pages/VideoSessions/components/MediaCards";
import { displayDate } from "@pages/VideoSessions/components/Utils/formatDate";
import { NO_MEDIA_STATUS } from "@pages/VideoSessions/const";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface FeedMediaCardProps {
  media?: MediaCardType;
}

export const FeedMediaCard: React.FC<FeedMediaCardProps> = ({ media }) => {
  const navigate = useNavigate();
  const flags = useFlags<FeatureFlags>();

  const { sidebarMomentsList } = useVideoSessionData();

  if (!media || !media.therapySession) return null;

  const learnerName = media.therapySession.sessionlearnerSet?.edges[0]?.node?.sessionLearners?.learnermetadata;
  const learnerFullName = learnerName ? `${learnerName.firstName} ${learnerName.lastName}` : "";
  const providerMeta = media.therapySession.sessionproviderSet?.edges[0]?.node?.provider?.providermetadata;
  const sessionDate = media.therapySession.createdDate;
  const startDate = new Date(sessionDate);
  const formattedDate = displayDate(media.totalDuration ?? 0, startDate);
  const isMissingMedia = NO_MEDIA_STATUS.includes(
    (media.therapySession?.sessionStatus as ApiTherapySessionSessionStatusChoices) ?? "",
  );
  const hasVisibleMoment = sidebarMomentsList.some((moment) => moment.isVisible);

  const isMediaDetailRedirect: boolean =
    media && media.therapySession?.sessionStatus === ApiTherapySessionSessionStatusChoices.Completed;

  return (
    <div
      className={`w-full border border-limestone-200 rounded-lg bg-white p-3 flex flex-col drop-shadow-sm gap-2 ${isMediaDetailRedirect ? "cursor-pointer" : "cursor-default"}  hover:shadow-[0px_8px_12px_rgba(145,158,171,0.25)] duration-200`}
      onClick={() => {
        if (isMediaDetailRedirect) {
          navigate(`/video-sessions/${media.id}`);
        }
      }}
    >
      <FeedMediaCardHeader
        learnerFullName={learnerFullName}
        providerLastName={providerMeta?.lastName ?? ""}
        providerFirstName={providerMeta?.firstName ?? ""}
        date={formattedDate}
        sessionRating={media.therapySession?.sessionRating ?? undefined}
        media={media}
        isMissingMedia={isMissingMedia}
      />

      <FeedMediaCardThumbnail
        status={(media.therapySession?.sessionStatus as ApiTherapySessionSessionStatusChoices) ?? ""}
      />

      <div className="justify-between flex flex-row items-center">
        <Label
          displayType="loud"
          size="extra-small"
          className={`${isMissingMedia ? "opacity-25" : hasVisibleMoment && flags["maladaptive-behaviors"] ? "opacity-100" : "opacity-0"}`}
          colorType="secondary"
        >
          Moments
        </Label>

        <FeedMediaCardEmojiPanel commentCount={media.commentsCount} disabled={isMissingMedia} />
      </div>
      <FeatureFlagSwitch
        flagKey={"maladaptive-behaviors"}
        flagEnabled={hasVisibleMoment ? <FeedMediaCardMomentContent media={media} /> : null}
        flagDisabled={null}
      />
    </div>
  );
};
