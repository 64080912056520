import { PlusIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";

import { Button, Small } from "@fronterahealth/frontera-ui-components";

import { ApiOrganizationAbExportTemplateNameChoices } from "@api/graphql/types-and-hooks";
import { AddRecommendationsBank } from "@components/RecommendationsBank/AddRecommendationsBank/AddRecommendationsBank";
import { ContentBlockCard } from "@components/SectionWidget/ContentBlockCard";
import {
  ContentTypeAndBespokeType,
  SupportedContentType,
  getContentTypeAndBespokeType,
} from "@components/SectionWidget/SectionWidget";
import { useAdminData } from "@providers/AdminDataProvider";

interface AddContentMenuProps {
  sectionId: string;
  sectionType: "regular" | "recommendation";
  supportedContentTypes: SupportedContentType[];
  onAddContent: (contentTypeAndBespokeType: ContentTypeAndBespokeType, rankOrder: number) => Promise<unknown>;
  newRankOrder: number;
  className?: string;
}

interface HoverAreaProps {
  setShowContentMenu: (bool: boolean) => void;
  sectionType: "regular" | "recommendation";
}
export const HoverArea: React.FC<HoverAreaProps> = ({ setShowContentMenu, sectionType }) => {
  const { orgnizationDetailsQuery } = useAdminData();

  const isCustomTemplateUser =
    orgnizationDetailsQuery.data?.getUserOrgnizationDetails?.abExportTemplateName !=
      ApiOrganizationAbExportTemplateNameChoices.AssessmentReportDocxTemplateDocx || false;

  return isCustomTemplateUser ? (
    <></>
  ) : (
    <>
      <div className="px-4 -mb-4">
        <div
          data-testid="add-content-hover-area"
          className="flex items-center w-full group"
          onClick={() => setShowContentMenu(true)}
        >
          <div
            className={`transition-all my-3 group-hover:border-globemallow-600 border group-hover:bg-globemallow-600 border-limestone-200 bg-limestone-200 rounded-full w-2 h-2`}
          />
          <div className={`px-2 text-center`}>
            <Small
              data-testid="add-content-button"
              displayType="normal"
              className={`cursor-pointer text-limestone-300 group-hover:text-globemallow-600 transition-all `}
            >
              Add {sectionType === "regular" ? "Content" : "Recommendation"}
            </Small>
          </div>
          <div
            className={`transition-all my-3 group-hover:border-globemallow-600 border group-hover:bg-globemallow-600 border-limestone-200 bg-limestone-200 rounded-full w-2 h-2`}
          ></div>
        </div>
      </div>
    </>
  );
};

export const AddContentMenu: React.FC<AddContentMenuProps> = ({
  sectionId,
  sectionType = "regular",
  supportedContentTypes,
  onAddContent,
  newRankOrder,
  className,
}) => {
  const [showContentMenu, setShowContentMenu] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { orgnizationDetailsQuery } = useAdminData();

  const isCustomTemplateUser =
    orgnizationDetailsQuery.data?.getUserOrgnizationDetails?.abExportTemplateName !=
      ApiOrganizationAbExportTemplateNameChoices.AssessmentReportDocxTemplateDocx || false;

  const closeContentMenu = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setShowContentMenu(false);
    }
  };

  useEffect(() => {
    if (showContentMenu) {
      document.addEventListener("mousedown", closeContentMenu);
    }
    return () => {
      document.removeEventListener("mousedown", closeContentMenu);
    };
  }, [showContentMenu]);

  const handleAddContent = async (type: SupportedContentType) => {
    const contentTypeAndBespokeType = getContentTypeAndBespokeType(type);
    if (contentTypeAndBespokeType) {
      await onAddContent(contentTypeAndBespokeType, newRankOrder);
    }
    setShowContentMenu(false);
  };

  return isCustomTemplateUser ? (
    <></>
  ) : (
    <div className={`flex justify-center ${className}`} onMouseOver={(e) => e.stopPropagation()}>
      {showContentMenu ? (
        sectionType === "recommendation" ? (
          <div className="flex items-center space-x-2">
            <AddRecommendationsBank
              aiGoals={[]}
              sectionId={sectionId}
              onAdd={async () => {
                // TODO: BACKPORT SHORTCUT - see if we need this, we may not
                // await evaluationQuery.refetch();
                setShowContentMenu(false);
              }}
              newRankOrder={newRankOrder}
            />
            <Button
              text={
                <Small className={"flex items-center px-2 text-white"}>
                  <PlusIcon className="mr-2 h-4 w-4" />
                  Custom Recommendation
                </Small>
              }
              buttonAction="action"
              appearance={"primary"}
              onClick={() => handleAddContent("Text")}
            />
          </div>
        ) : (
          <div className="flex flex-row mt-4" ref={divRef}>
            {supportedContentTypes.map((_typeName, index) => (
              <ContentBlockCard
                key={`${index}_${_typeName}`}
                typeName={_typeName}
                onClick={() => handleAddContent(_typeName)}
              />
            ))}
          </div>
        )
      ) : (
        <HoverArea sectionType={sectionType} setShowContentMenu={setShowContentMenu} />
      )}
    </div>
  );
};
