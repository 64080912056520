import {
  HeadingLevel,
  ImageRun,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
  convertInchesToTwip,
} from "docx";

export interface Style {
  size?: number;
  bold?: boolean;
  italic?: boolean;
  color?: string;
  backgroundColor?: string;
  bulletLevel?: number;
}

export type SectionChildrenType = Paragraph | Table | ImageRun;

export const getIndent = (element: HTMLElement): number => {
  if (element.hasAttribute("data-stg-info") || element.hasAttribute("data-stg")) {
    return 1;
  } else if (element.hasAttribute("data-target-info") || element.hasAttribute("data-target")) {
    return 1.5;
  }
  return 0;
};

export const getSpacing = (element: HTMLElement) => {
  return {
    after:
      element.hasAttribute("convertedLabel") ||
      element.hasAttribute("data-stg-info") ||
      element.hasAttribute("data-target-info") ||
      element.hasAttribute("data-ltg-info")
        ? 0
        : 200,
    before: element.hasAttribute("data-target") || element.hasAttribute("data-stg") ? 200 : 0,
  };
};

export const parseText = (text: string): string => {
  return text
    .replace(/&quot;/g, '"')
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/\n\s*/g, " ")
    .trim();
};

export const parseTable = (tableElement: HTMLElement): Table => {
  const rows: TableRow[] = [];

  // Query all row elements in the table
  const rowElements = tableElement.querySelectorAll("thead tr, tbody tr");

  // Get the number of columns by checking the first row
  const firstRow = rowElements[0];
  const cellElementsInFirstRow = firstRow.querySelectorAll("th, td");
  const numberOfColumns = cellElementsInFirstRow.length;

  // Calculate the width in twips for each column (1 inch = 1440 twips)
  const totalWidthTwips = 1440 * 6; // Assuming 6 inches total table width
  const columnWidthTwips = totalWidthTwips / numberOfColumns;

  // Create an array of column widths
  const columnWidths = new Array(numberOfColumns).fill(columnWidthTwips);

  // Process each row
  rowElements.forEach((rowElement) => {
    const cells: TableCell[] = [];
    const cellElements = rowElement.querySelectorAll("th, td");

    // Process each cell in the row
    cellElements.forEach((cellElement) => {
      const cellText = parseText(cellElement.textContent || "");
      const colspan = parseInt(cellElement.getAttribute("colspan") || "1", 10);

      const cell = new TableCell({
        children: [
          new Paragraph({
            alignment: "center",
            children: [
              new TextRun({
                text: colspan > 1 && cellElement.tagName.toLowerCase() !== "th" ? "No Existing Data." : cellText,
                bold: cellElement.tagName.toLowerCase() === "th", // Make the text bold
              }),
            ],
          }),
        ],
        shading:
          cellElement.tagName.toLowerCase() === "th"
            ? {
                fill: "#F7F6F5",
                type: ShadingType.CLEAR,
                color: "auto",
              }
            : rowElement.getAttribute("data-custom-attributes")?.split(" ").includes("highlighted")
              ? {
                  // bg-gray-200
                  fill: "#EDEAE7",
                  type: ShadingType.CLEAR,
                  color: "auto",
                }
              : undefined,
        margins: {
          top: 100,
          bottom: 100,
          left: 100,
          right: 100,
        },
        columnSpan: colspan, // Set the column span for the cell
      });

      cells.push(cell);
    });

    // Create the row and add it to the rows array
    const row = new TableRow({
      children: cells,
    });

    rows.push(row);
  });

  // Create the table with the rows and set the column widths
  const table = new Table({
    rows: rows,
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    columnWidths: columnWidths,
    margins: { bottom: 40 },
  });

  return table;
};

export const extractStyle = (element: Element): Style => {
  const style: Style = {};

  const inlineStyle = element.getAttribute("style");
  const converted = element.getAttribute("converted");

  if (inlineStyle) {
    const styles = inlineStyle.split(";").map((style) => style.trim());
    styles.forEach((styleString) => {
      const [property, value] = styleString.split(":").map((part) => part.trim());
      switch (property) {
        case "font-size":
          style.size = converted === "true" ? 24 : parseInt(value, 10);
          break;
        case "font-weight":
          style.bold = value === "bold" || parseInt(value, 10) >= 700;
          break;
        case "font-style":
          style.italic = value === "italic";
          break;
        case "color":
          if (value.startsWith("rgb")) {
            const hexColor = rgbToHex(value);
            if (hexColor) {
              style.color = hexColor;
            } else {
              style.color = value;
            }
          } else {
            style.color = value;
          }
          break;
        case "background-color":
          if (value.startsWith("rgb")) {
            const hexColor = rgbToHex(value);
            if (hexColor) {
              style.backgroundColor = hexColor;
            } else {
              style.backgroundColor = value;
            }
          } else {
            style.backgroundColor = value;
          }
          break;
      }
    });
  }

  return style;
};

export const rgbToHex = (rgbColor: string): string | null => {
  if (!rgbColor) {
    return null;
  }
  const rgbValues = rgbColor.match(/\d+/g);

  if (!rgbValues || rgbValues.length !== 3) {
    return null;
  }

  const hexColor =
    "#" +
    rgbValues
      .map((value) => {
        const intValue = parseInt(value, 10);
        if (isNaN(intValue) || intValue < 0 || intValue > 255) {
          return "00";
        }
        const hex = intValue.toString(16).toUpperCase();
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("");

  return hexColor;
};

export const parseHorizontalRule = (): Paragraph => {
  const hrParagraph = new Paragraph({
    children: [],
    border: {
      bottom: {
        color: "#EDEAE7",
        space: 1,
        style: "single",
        size: 12,
      },
    },
  });
  return hrParagraph;
};

let instanceCounter = 0;

export const parseList = (element: HTMLElement): Paragraph[] => {
  const listItems = Array.from(element.children).filter((child) => (child as Element).tagName.toLowerCase() === "li");

  const isOrderedList =
    element.tagName.toLowerCase() === "ol" &&
    Array.from(element.children).some((child) => (child as HTMLElement).getAttribute("data-list") === "ordered");

  const instanceValue = isOrderedList ? instanceCounter++ : instanceCounter;

  return listItems.map((li) => {
    const listElement = li as HTMLElement;
    const listStyles = extractStyle(listElement);

    return new Paragraph({
      ...(isOrderedList
        ? {
            numbering: {
              reference: "ordered-list",
              level: 0,
              instance: instanceValue,
            },
          }
        : {
            bullet: {
              level: listElement.classList.contains("ql-indent-1") ? 1 : 0,
            },
          }),
      run: {
        size: listStyles.size || 22,
        bold: listStyles.bold ?? true,
        color: listStyles.color,
      },
      text: parseText(li.textContent || ""),
      spacing: { after: 100 },
    });
  });
};

export const parseImage = (element: HTMLElement): ImageRun | null => {
  const src = element.getAttribute("src");
  if (!src) return null;

  const isBase64 = src.startsWith("data:image");
  if (!isBase64) {
    return null;
  }

  const width = element.getAttribute("width");
  const height = element.getAttribute("height");
  const imageRun = new ImageRun({
    data: src,
    transformation: {
      width: width ? parseInt(width, 10) : 120,
      height: height ? parseInt(height, 10) : 40,
    },
  });
  return imageRun;
};

export const createHeading = (element: HTMLElement, tagName: string, indent: number): Paragraph => {
  let headingLevel: (typeof HeadingLevel)[keyof typeof HeadingLevel];
  let spacing: { before?: number; after?: number } = {};
  let style: string | undefined;

  switch (tagName) {
    case "h1":
      headingLevel = HeadingLevel.HEADING_1;
      break;
    case "h2":
      headingLevel = HeadingLevel.HEADING_2;
      spacing = { before: 6, after: 6 };
      break;
    case "h3":
      headingLevel = HeadingLevel.HEADING_3;
      spacing = { after: element.hasAttribute("data-sticky-nav-header") ? 300 : 100 };
      break;
    case "h4":
    case "label":
      headingLevel = HeadingLevel.HEADING_4;
      style = "heading4";
      break;
    default:
      throw new Error("Invalid heading tag");
  }

  return new Paragraph({
    text: parseText(element.textContent || ""),
    heading: headingLevel,
    spacing,
    style,
    indent: { left: convertInchesToTwip(indent) },
  });
};
