import React, { useCallback, useEffect } from "react";

import { Heading, WYSIWYG } from "@fronterahealth/frontera-ui-components";

import { NoData } from "@pages/VideoSessions/components/NoData/NoData";
import { DataLoading } from "@pages/VideoSessions/components/Utils/DataLoading";
import { convertTimestampToSeconds } from "@pages/VideoSessions/components/Utils/helper";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface VideoSummaryProps {
  onTimeStampClick: (time: number) => void;
  onDurationClick: (startTime: number, endTime: number) => void;
}

declare global {
  interface Window {
    handleTimestampClick: (start: string, end?: string) => void;
  }
}

const convertTimestampsToButtons = () => {
  const container = document.getElementById("markdownSummary");
  if (!container) return;

  const timestampRegex = /\b(\d{2}:\d{2}:\d{2})(?:-(\d{2}:\d{2}:\d{2}))?\b/g;

  // Skip if a button with data-processed="true" already exists
  if (container.querySelector('[data-processed="true"]')) {
    return;
  }

  container.innerHTML = container.innerHTML.replace(timestampRegex, (_, start, end) => {
    if (end) {
      return `<button data-start="${start}" data-end="${end}" data-processed="true" class="text-blue-500  mx-1">${start}-${end}</button>`;
    } else {
      return `<button data-start="${start}" data-end="" data-processed="true" class="text-blue-500  mx-1">${start}</button>`;
    }
  });
};

export const VideoSummary: React.FC<VideoSummaryProps> = ({ onDurationClick, onTimeStampClick }) => {
  const { getSessionSummaryBySessionIdQuery } = useVideoSessionData();
  const { data, isFetching } = getSessionSummaryBySessionIdQuery;
  const sessionSummary = data?.getSessionSummaryBySessionId?.prediction;
  const trimmedSummary = sessionSummary ? sessionSummary?.replace(/Session Description:\s*/i, "").trim() : "";

  useEffect(() => {
    if (data && data.getSessionSummaryBySessionId) {
      convertTimestampsToButtons();
    }
  }, [data]);

  // Callback function to handle timestamp clicks
  const handleTimestampClick = useCallback(
    (start: string, end?: string) => {
      const startTime = convertTimestampToSeconds(start);
      const endTime = end ? convertTimestampToSeconds(end) : undefined;

      if (endTime !== undefined) {
        onDurationClick(startTime, endTime);
      } else {
        onTimeStampClick(startTime);
      }
    },
    [onDurationClick, onTimeStampClick],
  );

  useEffect(() => {
    if (data && data.getSessionSummaryBySessionId && !isFetching) {
      const container = document.getElementById("markdownSummary");
      if (!container) return;

      container.querySelectorAll("button").forEach((btn) => {
        btn.addEventListener("click", (e) => {
          const target = e.currentTarget as HTMLButtonElement;
          const start = target.getAttribute("data-start") || "0";
          const end = target.getAttribute("data-end") || undefined;

          handleTimestampClick(start, end);
        });
      });
    }
  }, [data]);

  return (
    <div className="bg-white rounded-lg p-4 pt-4 gap-3 grid grid-flow-row auto-rows-max cursor-pointer max-lg:min-h-[calc(100vh-18rem)] lg:overflow-y-auto lg:h-[calc(100vh-10rem)] ">
      <Heading type="h3" className="text-text-primary">
        Session Summary
      </Heading>

      {isFetching ? (
        <div className="flex w-full flex-row justify-center">
          <DataLoading text="Session Summary loading.." />
        </div>
      ) : data && data.getSessionSummaryBySessionId ? (
        <div id="markdownSummary">
          <WYSIWYG
            displayOnlyMode
            title=""
            onSave={async () => {}}
            isSaving={false}
            placeholder=""
            existingContent={trimmedSummary}
          />
        </div>
      ) : (
        <NoData
          title="Summary Unavailable for This Session"
          description="We couldn’t generate a session summary for this video. This might happen due to certain system limitations or the nature of the session content. Please review the video directly for any insights or observations you need."
        />
      )}
    </div>
  );
};
