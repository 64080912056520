import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import {
  ArrowDownTrayIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  FlagIcon,
  HomeIcon,
  NewspaperIcon,
  QuestionMarkCircleIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { FeatureFlagGate } from "@utils/FeatureFlags/FeatureFlagGate";
import { FeatureFlagPreviewer } from "@utils/FeatureFlags/FeatureFlagPreviewer";
import { useFlags, useLDClient, useLDClientError } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";

import { Button, Heading, NavItem, SidebarLayout, Small, Tooltip } from "@fronterahealth/frontera-ui-components";

import { useAdminData } from "@providers/AdminDataProvider";
import { useUIState } from "@providers/UIStateProvider";

import { AccessTokenRetriever } from "./AccessTokenRetriever";
import { AllRoutes, roleMapping, roleType } from "./config";

export const MenuSections = ["Clinical Tools", "Resources"] as const;
export type MenuSectionsType = (typeof MenuSections)[number];

interface MenuItem {
  path: AllRoutes;
  name: string;
  section: MenuSectionsType;
  Icon: React.ComponentType;
  roles: roleType[];
}

export const getMenuItems = (flags: Record<string, boolean>): MenuItem[] => {
  return [
    {
      path: "/clients",
      name: "Clients",
      Icon: HomeIcon,
      roles: roleMapping["/clients"],
      section: "Clinical Tools",
    },
    {
      path: "/providers",
      name: "Providers",
      Icon: UsersIcon,
      roles: roleMapping["/providers"],
      section: "Clinical Tools",
    },
    {
      path: "/evaluations",
      name: "Evaluations",
      Icon: NewspaperIcon,
      roles: roleMapping["/evaluations"],
      section: "Clinical Tools",
    },
    {
      path: flags["assessment-builder-backport"] ? "/assessment-reports-v2" : "/assessment-reports",
      name: "Assessment Reports",
      Icon: NewspaperIcon,
      roles: roleMapping[flags["assessment-builder-backport"] ? "/assessment-reports-v2" : "/assessment-reports"],
      section: "Clinical Tools",
    },
    {
      path: "/downloads",
      name: "Assessment Templates",
      Icon: ArrowDownTrayIcon,
      roles: roleMapping["/downloads"],
      section: "Resources",
    },
    {
      path: "/assessment-reports?trigger-walkthrough=true",
      name: "Get Started",
      Icon: QuestionMarkCircleIcon,
      roles: roleMapping["/assessment-reports"],
      section: "Resources",
    },
    {
      path: "/session-analyzer",
      name: "Session Analyzer",
      Icon: ChartBarIcon,
      roles: roleMapping["/session-analyzer"],
      section: "Clinical Tools",
    },
    {
      path: "/video-sessions",
      name: "Video Sessions",
      Icon: VideoCameraIcon,
      roles: roleMapping["/video-sessions"],
      section: "Clinical Tools",
    },
  ];
};

const filterMenuItems = (userRoles: string[], menuItems: MenuItem[], isOnboardingEnabled: boolean): MenuItem[] => {
  return menuItems
    .filter((item) => item.roles.some((role) => userRoles.includes(role)))
    .filter((item) => (!isOnboardingEnabled ? item.path !== "/clients" : item));
};

export const IndexLayout: React.FC = () => {
  const flags = useFlags();
  const { user, isLoading, logout } = useAuth0();
  const ldClient = useLDClient();
  const ldError = useLDClientError();
  const {
    roles,
    assessmentCreditsRemaining,
    assessmentCreditsUsed,
    isSelfServeUser,
    isFetchingAssessmentCredits,
    isOnboardingEnabled,
  } = useAdminData();
  const { pathname } = useLocation();

  const orgName = `${user![config.auth0Audience + "/orgName"] ?? ""}`;
  if (!user && !isLoading && pathname !== "/login") {
    return <Navigate to="/login" />;
  }

  const appIsBootstrapping = isLoading || (!ldClient && !ldError);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const { onSidebarCollapse } = useUIState();

  if (user && !isLoading && pathname === "/") {
    if (roles.includes("psychologist")) return <Navigate to="/evaluations" />;
    if (roles.includes("admin")) return <Navigate to="/clients" />;
    if (roles.includes("assessment-bcba"))
      return <Navigate to={flags["assessment-builder-backport"] ? "/assessment-reports-v2" : "/assessment-reports"} />;
    if (roles.includes("bcba") || roles.includes("rbt")) return <Navigate to="/video-sessions" />;
  }

  const menuItems = getMenuItems(flags);

  const filteredMenuItems = filterMenuItems(roles, menuItems, isOnboardingEnabled);
  const invertedTheme =
    pathname.includes("/clients") ||
    pathname.includes("/providers") ||
    pathname.includes("/evaluations") ||
    pathname.includes("/assessment-reports") ||
    pathname.includes("/assessment-reports-v2") ||
    pathname.includes("/products") ||
    pathname.includes("/checkout") ||
    pathname.includes("/downloads") ||
    pathname.includes("/session-analyzer") ||
    pathname.includes("/video-sessions") ||
    pathname.includes("/settings");

  return (
    <React.Fragment>
      <SidebarLayout
        invertedTheme={invertedTheme}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        onCollapseChange={onSidebarCollapse}
        user={
          user
            ? {
                imageSrc: user.picture ? user.picture : "",
                title: user.name ?? user.email ?? "",
                logout: () =>
                  logout({
                    logoutParams: {
                      returnTo: `${window.location.protocol}//${window.location.host}`,
                    },
                  }),
                orgName,
              }
            : null
        }
        collapsedSidePanelContent={
          <div className="flex flex-col justify-start size-full">
            <ul>
              {!user ? (
                <NavItem Icon={HomeIcon} name="Home" active={true} href="#" iconOnly />
              ) : (
                <>
                  {MenuSections.map((section) => (
                    <div className="flex flex-col mb-8" key={section}>
                      <>
                        {filteredMenuItems
                          .filter((item) => item.section === section)
                          .map((item) => {
                            if (item.name === "Session Analyzer") {
                              return (
                                <FeatureFlagGate key={item.name} flagKey="session-analyzer">
                                  <Link to={item.path} key={item.path}>
                                    <Tooltip key={item.name} placement="right" text={item.name} showInfoIcon={false}>
                                      <NavItem
                                        iconOnly
                                        Icon={item.Icon}
                                        name={item.name}
                                        active={pathname.includes(item.path)}
                                        href="#"
                                      />
                                    </Tooltip>
                                  </Link>
                                </FeatureFlagGate>
                              );
                            }

                            if (item.name === "Video Sessions") {
                              return (
                                <FeatureFlagGate key={item.name} flagKey="video-tooling">
                                  <Link to={item.path} key={item.path}>
                                    <Tooltip key={item.name} placement="right" text={item.name} showInfoIcon={false}>
                                      <NavItem
                                        iconOnly
                                        Icon={item.Icon}
                                        name={item.name}
                                        active={pathname.includes(item.path)}
                                        href="#"
                                      />
                                    </Tooltip>
                                  </Link>
                                </FeatureFlagGate>
                              );
                            }

                            if (item.name === "Evaluations") {
                              return (
                                <FeatureFlagGate key={item.name} flagKey="diagnosis-builder">
                                  <Link to={item.path} key={item.path}>
                                    <Tooltip key={item.name} placement="right" text={item.name} showInfoIcon={false}>
                                      <NavItem
                                        iconOnly
                                        Icon={item.Icon}
                                        name={item.name}
                                        active={pathname.includes(item.path)}
                                        href="#"
                                      />
                                    </Tooltip>
                                  </Link>
                                </FeatureFlagGate>
                              );
                            }

                            if (item.name === "Get Started" && !isSelfServeUser) {
                              return;
                            }

                            return (
                              <Link
                                to={item.path}
                                reloadDocument={item.name === "Get Started" && pathname.includes("/assessment-reports")}
                                key={item.path}
                              >
                                <Tooltip key={item.name} placement="right" text={item.name} showInfoIcon={false}>
                                  <NavItem
                                    iconOnly
                                    Icon={item.Icon}
                                    name={item.name}
                                    active={pathname.split("/")[1] === item.path.split("/")[1]}
                                    href="#"
                                  />
                                </Tooltip>
                              </Link>
                            );
                          })}
                      </>
                    </div>
                  ))}
                </>
              )}
            </ul>

            <FeatureFlagGate flagKey="flag-viewer">
              <Tooltip showInfoIcon={false} text="Feature Flags" placement="right">
                <div
                  className={`my-4 cursor-pointer flex flex-col px-4 py-2  hover:bg-globemallow-50 transition-all rounded-md w-full`}
                >
                  <FlagIcon className={"text-globemallow-500"} />
                </div>
              </Tooltip>
            </FeatureFlagGate>

            <FeatureFlagGate flagKey="access-token-retriever">
              <AccessTokenRetriever iconOnly />
            </FeatureFlagGate>
            <div>
              {isSelfServeUser && (
                <Link to="/settings">
                  <ul>
                    <NavItem Icon={Cog6ToothIcon} iconOnly name="Account" active={false} href="#" />
                  </ul>
                </Link>
              )}
            </div>
          </div>
        }
        sidePanelContent={
          <div className="flex flex-col justify-between size-full">
            <ul>
              {!user ? (
                <NavItem Icon={HomeIcon} name="Home" active={true} href="#" />
              ) : (
                <>
                  {MenuSections.map((section) => (
                    <div className="flex flex-col mb-2 mt-2" key={section}>
                      <Small className="mb-4" displayType="loud">
                        {section}
                      </Small>
                      <>
                        {filteredMenuItems
                          .filter((item) => item.section === section)
                          .map((item) => {
                            if (item.name === "Session Analyzer") {
                              return (
                                <FeatureFlagGate key={item.name} flagKey="session-analyzer">
                                  <Link to={item.path} key={item.path}>
                                    <NavItem
                                      Icon={item.Icon}
                                      name={item.name}
                                      active={pathname.includes(item.path)}
                                      href="#"
                                    />
                                  </Link>
                                </FeatureFlagGate>
                              );
                            }

                            if (item.name === "Video Sessions") {
                              return (
                                <FeatureFlagGate key={item.name} flagKey="video-tooling">
                                  <Link to={item.path} key={item.path}>
                                    <NavItem
                                      Icon={item.Icon}
                                      name={item.name}
                                      active={pathname.includes(item.path)}
                                      href="#"
                                    />
                                  </Link>
                                </FeatureFlagGate>
                              );
                            }

                            if (item.name === "Evaluations") {
                              return (
                                <FeatureFlagGate key={item.name} flagKey="diagnosis-builder">
                                  <Link to={item.path} key={item.path}>
                                    <NavItem
                                      Icon={item.Icon}
                                      name={item.name}
                                      active={pathname.includes(item.path)}
                                      href="#"
                                    />
                                  </Link>
                                </FeatureFlagGate>
                              );
                            }

                            if (item.name === "Get Started" && !isSelfServeUser) {
                              return;
                            }

                            return (
                              <Link
                                to={item.path}
                                reloadDocument={item.name === "Get Started" && pathname.includes("/assessment-reports")}
                                key={item.path}
                              >
                                <NavItem
                                  Icon={item.Icon}
                                  name={item.name}
                                  active={pathname.split("/")[1] === item.path.split("/")[1]}
                                  href="#"
                                />
                              </Link>
                            );
                          })}
                      </>
                    </div>
                  ))}
                </>
              )}
            </ul>
            <div className="flex space-y-4 flex-col">
              <FeatureFlagGate flagKey="flag-viewer">
                <FeatureFlagPreviewer />
              </FeatureFlagGate>
              <FeatureFlagGate flagKey="access-token-retriever">
                <AccessTokenRetriever />
              </FeatureFlagGate>
            </div>
            <div>
              {isFetchingAssessmentCredits ? null : isSelfServeUser && assessmentCreditsUsed === 0 ? (
                <div className="px-4 py-2 bg-hatch-50 flex flex-col items-start rounded-md w-full">
                  <Heading type="h4">Try it for free</Heading>
                  <Small className="mt-2">Start your first Assessment Report for free by clicking "New Report"</Small>
                </div>
              ) : isSelfServeUser && assessmentCreditsUsed > 0 ? (
                <div className="px-4 py-2 bg-hatch-50 flex items-center space-x-1 rounded-md w-full">
                  <Small displayType="loud">{assessmentCreditsRemaining}</Small>
                  <Small displayType="normal">Credits Remaining</Small>

                  <Link to="/products">
                    <Button style={{ padding: 0 }} className="p-0" appearance="link" text={"Get more"} />
                  </Link>
                </div>
              ) : null}
              {isSelfServeUser && (
                <Link to="/settings">
                  <div className="hover:bg-limestone-50 text-text-primary group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 items-center justify-center mt-6 w-full">
                    <div className="w-full flex items-center">
                      <Cog6ToothIcon className="text-text-primary group-hover:text-text-primary h-6 w-6 shrink-0 mr-2" />
                      <Small displayType="loud" className="text-limestone-900">
                        Account
                      </Small>
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        }
        mainContent={
          appIsBootstrapping ? (
            <div className="flex h-screen flex-col items-center justify-center">
              <Heading type="h2" className="text-limestone-400">
                Loading...
              </Heading>
            </div>
          ) : (
            <Outlet />
          )
        }
      />
    </React.Fragment>
  );
};
