import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { pathNameToBreadCrumbLinks } from "@utils/helpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link, Outlet, useLocation } from "react-router-dom";

import { Breadcrumb, Heading } from "@fronterahealth/frontera-ui-components";

import { FilterBar } from "@pages/VideoSessions/components/FilterBar";
import { MediaCards } from "@pages/VideoSessions/components/MediaCards";
import { SideFilterBar } from "@pages/VideoSessions/components/SideFilterBar";
import { useAdminData } from "@providers/AdminDataProvider";

export const VideoSessionsSummary: React.FC = () => {
  const location = useLocation();
  const { pathname } = useLocation();
  const { roles } = useAdminData();
  const breadcrumbLinks = pathNameToBreadCrumbLinks(pathname);
  const isDetailView = location.pathname.includes("/video-sessions/") && location.pathname !== "/video-sessions";

  const flags = useFlags<FeatureFlags>();
  const isFeatureFlagEnabled = flags["maladaptive-behaviors"];
  const isShowSideFilterBar = roles.includes("bcba") || isFeatureFlagEnabled;

  if (isDetailView) {
    return <Outlet />;
  }

  return (
    <div className="w-full  mx-auto flex flex-col gap-4 px-4">
      <Breadcrumb
        links={breadcrumbLinks}
        homeLink={{
          name: "Home",
          Component: <Link to={`/`}>Home</Link>,
          current: false,
          type: "component",
        }}
      />
      <Heading type="h1" className="text-xl md:text-4xl">
        Video Sessions
      </Heading>
      <div className="flex-1 grid lg:grid-cols-6 md:grid-cols-12 gap-4">
        {isShowSideFilterBar ? (
          <div className="col-span-1  sm:col-span-12 md:col-span-3 lg:col-span-1 lg:overflow-y-auto">
            <SideFilterBar />
          </div>
        ) : null}

        <div
          className={`${!isShowSideFilterBar ? "lg:col-span-6 sm:col-span-12 md:col-span-12" : "lg:col-span-5 sm:col-span-12 md:col-span-9"}`}
        >
          <FilterBar />

          <div className="h-[calc(100vh-200px)] overflow-y-auto">
            <MediaCards />
          </div>
        </div>
      </div>
    </div>
  );
};
