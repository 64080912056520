import React from "react";

import { Paragraph } from "@fronterahealth/frontera-ui-components";

import { getEmojiCount } from "@pages/VideoSessions/components/Utils/helper";
import { EMOJI_BUTTONS } from "@pages/VideoSessions/const";

interface FeedMediaCardEmojiPanelProps {
  commentCount: string;
  disabled?: boolean;
}

export const FeedMediaCardEmojiPanel: React.FC<FeedMediaCardEmojiPanelProps> = ({ commentCount, disabled = false }) => {
  return (
    <div className={`flex flex-row gap-2 p-1 border rounded-sm ${disabled ? "opacity-25" : ""}`}>
      {EMOJI_BUTTONS.map((emojiItem) => {
        return (
          <div key={Math.random()} className="flex flex-row gap-1 items-center">
            <span
              className={`
            flex items-center justify-center text-sm
            
          `}
            >
              {emojiItem.emoji}
            </span>

            <Paragraph size="extra-small" colorType="secondary">
              {getEmojiCount(emojiItem.value, commentCount) || 0}
            </Paragraph>
          </div>
        );
      })}
    </div>
  );
};
