import { CheckIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import React from "react";

import { Button, Label, Paragraph } from "@fronterahealth/frontera-ui-components";

import { useManuallyMarkMediaAsViewedMutation } from "@api/graphql/types-and-hooks";
import { UserInitialsView } from "@components/UserInitial/UserInitialsView";
import { FeedTooltip } from "@pages/VideoSessions/components/Media/FeedTooltip";
import { MediaCardType } from "@pages/VideoSessions/components/MediaCards";
import { useAdminData } from "@providers/AdminDataProvider";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface FeedMediaCardHeaderProps {
  learnerFullName: string;
  providerFirstName?: string;
  providerLastName?: string;
  date: string;
  sessionRating?: number;
  media?: MediaCardType;
  isMissingMedia?: boolean;
}

export const FeedMediaCardHeader: React.FC<FeedMediaCardHeaderProps> = ({
  learnerFullName,
  providerFirstName,
  providerLastName,
  date,
  media,
  sessionRating,
  isMissingMedia,
}) => {
  const { roles } = useAdminData();
  const { allFilteredMedias, setAllFilteredMedias } = useVideoSessionData();

  const markAsReviewedMutation = useManuallyMarkMediaAsViewedMutation();
  const { isPending } = markAsReviewedMutation;
  const handleMarkAsReviewed = async (isReviewed: boolean) => {
    const response = await markAsReviewedMutation.mutateAsync({
      mediId: media ? media?.id : "",
      isReviewed,
    });
    if (response.manuallyMarkMediaAsViewed?.status) {
      setAllFilteredMedias([
        ...allFilteredMedias.map((listMedia) =>
          listMedia.id === response.manuallyMarkMediaAsViewed?.media?.id
            ? { ...listMedia, isViewed: response.manuallyMarkMediaAsViewed.media.isViewed }
            : listMedia,
        ),
      ]);
    }
  };
  return (
    <div className="flex flex-col w-full gap-1">
      <div className="flex flex-row justify-between items-center">
        <Paragraph size="medium" colorType="primary">
          {learnerFullName}
        </Paragraph>
        {roles.includes("bcba") && !isMissingMedia ? (
          <FeedTooltip text={media?.isViewed ? "Video is reviewed" : "Mark video as reviewed"}>
            <Button
              text={<CheckIcon className={`h-4 w-4 ${media?.isViewed ? "text-white" : "text-limestone-600"}`} />}
              appearance={media?.isViewed ? "primary" : "secondary"}
              onClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                if (media) {
                  handleMarkAsReviewed(!media.isViewed);
                }
                return;
              }}
              disabled={isPending}
              className={`relative items-center !rounded-[4px] !px-1 !py-1 ${media && !media.isViewed ? "!ring-gray-200 !bg-gray-50 " : ""} !text-text-primary !text-sm !font-normal !hover:bg-limestone-50  !pb-1`}
            />
          </FeedTooltip>
        ) : null}
      </div>
      <div className="flex flex-row justify-between">
        <Label size="extra-small">{date}</Label>
        <div className="flex flex-row gap-2 items-center">
          <FeedTooltip text={`${providerFirstName || "N/A"} ${providerLastName || ""}`}>
            <UserInitialsView
              className="!h-5 !w-5 !text-[8px] leading-4 gap-2 "
              firstName={providerFirstName || ""}
              lastName={providerLastName || ""}
            />
          </FeedTooltip>

          {sessionRating && (
            <div className="flex flex-row items-center gap-2">
              <span className="w-1 h-1 bg-gray-500 rounded-full"></span>
              <FeedTooltip text="Ratings are provided by your RBT after each client session.">
                <div className="flex flex-row gap-0.5 items-center">
                  <StarIcon className="text-yellow-500 w-3 h-3" color="grey" />
                  <Label size="extra-small">{sessionRating}</Label>
                </div>
              </FeedTooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
