import React, { useEffect, useMemo, useRef, useState } from "react";

import { SearchableDropdown, SearchableDropdownItem } from "@fronterahealth/frontera-ui-components";

import { ClassificationSubClassEnum } from "@api/graphql/types-and-hooks";
import { convertReadableString } from "@components/forms/utils";
import { MediaCardType } from "@pages/VideoSessions/components/MediaCards";
import { MomentCard } from "@pages/VideoSessions/components/Moments/MomentCard";
import { NoData } from "@pages/VideoSessions/components/NoData/NoData";
import { DataLoading } from "@pages/VideoSessions/components/Utils/DataLoading";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface VideoMomentsProps {
  videoSeeking: boolean;
  media: MediaCardType;
  activeMomentId: string | null;
  onClick: (startTime: number, endTime: number, momentId: string) => void;
}

export interface VideoMomentInterface {
  startTime: number;
  endTime: number;
  confidenceLevel: number;
  type?: ClassificationSubClassEnum | null;
}

const MOMENT_TYPES: SearchableDropdownItem[] = [
  { id: ClassificationSubClassEnum.Aggression, label: convertReadableString(ClassificationSubClassEnum.Aggression) },
  {
    id: ClassificationSubClassEnum.CryingAndScreaming,
    label: convertReadableString(ClassificationSubClassEnum.CryingAndScreaming),
  },
];

export const convertMediaCardInteractionsToVideoMoments = (media: MediaCardType): VideoMomentInterface[] => {
  const continuousInteractions: VideoMomentInterface[] = (
    media.mediaClassifications?.continuousInteractions?.map(
      (interaction) =>
        interaction?.intervals?.map((interval) => ({
          startTime: interval?.startTime || 0,
          endTime: interval?.endTime || 0,
          confidenceLevel: interval?.confidenceLevel || 0,
          type: interaction.subclass,
        })) || [],
    ) || []
  ).flat();
  const nonContinuousInteractions: VideoMomentInterface[] = (
    media.mediaClassifications?.nonContinuousInteractions?.map(
      (interaction) =>
        interaction?.intervals?.map((interval) => ({
          startTime: interval?.startTime || 0,
          endTime: interval?.endTime || 0,
          confidenceLevel: interval?.confidenceLevel || 0,
          type: interaction.subclass as ClassificationSubClassEnum,
        })) || [],
    ) || []
  ).flat();
  return [...continuousInteractions, ...nonContinuousInteractions].sort((a, b) => a.startTime - b.startTime);
};

export const VideoMoments: React.FC<VideoMomentsProps> = ({ videoSeeking, media, activeMomentId, onClick }) => {
  const { filteredMomentType, setFilteredMomentType } = useVideoSessionData();
  const [localActiveMoment, setLocalActiveMoment] = useState<string | null>(null);
  const [allFilteredMoments, setAllFilteredMoments] = useState<VideoMomentInterface[]>([]);

  const momentsData: VideoMomentInterface[] = useMemo(() => convertMediaCardInteractionsToVideoMoments(media), [media]);
  const momentRef = useRef<HTMLDivElement>(null);

  const isFetching = false;
  const effectiveMomentId = localActiveMoment || activeMomentId;
  // Scroll to active comment when it changes
  useEffect(() => {
    if (effectiveMomentId && momentRef.current) {
      const activeElement = momentRef.current.querySelector(`[data-moment-id="${activeMomentId}"]`);
      if (activeElement) {
        activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [effectiveMomentId]);

  useEffect(() => {
    if (!filteredMomentType.length) {
      setAllFilteredMoments(momentsData);
    } else {
      const filteredData = momentsData.filter((moment) =>
        filteredMomentType.some((filter) => filter.id === moment.type),
      );
      setAllFilteredMoments(filteredData);
    }
  }, [momentsData, filteredMomentType]);

  return (
    <div
      ref={momentRef}
      className="bg-white rounded-lg p-4 pt-4 gap-3 grid grid-flow-row auto-rows-max cursor-pointer max-lg:min-h-[calc(100vh-18rem)] lg:overflow-y-auto lg:h-[calc(100vh-10rem)]"
    >
      <div className="flex flex-col max-[640px]:gap-4 gap-2">
        <div className="relative border-b border-gray-100 sm:pb-2 shadow-[0_4px_8px_0px_rgba(0, 0, 0, 0.10)]">
          <SearchableDropdown
            items={MOMENT_TYPES}
            label="Moment Type"
            placeholder=""
            onSelectionChange={(val) => setFilteredMomentType(val)}
            showSearch={false}
            selectedItems={filteredMomentType}
          />
        </div>
        {isFetching ? (
          <DataLoading text="Activity Loading" />
        ) : allFilteredMoments.length ? (
          <div className="lg:overflow-y-auto lg:h-[calc(100vh-18rem)] flex flex-col sm:mt-3 gap-y-4 !overflow-visible">
            {allFilteredMoments.map((moment) => {
              const momentID = `${moment.type}-${moment.startTime}-${moment.endTime}-${moment.confidenceLevel}`;
              return (
                <MomentCard
                  key={momentID}
                  id={momentID}
                  moment={moment}
                  onActiveMomentChange={(newActiveMomentId) => {
                    setLocalActiveMoment(newActiveMomentId);
                  }}
                  onClick={onClick}
                  videoSeeking={videoSeeking}
                  activeMomentId={effectiveMomentId}
                />
              );
            })}
          </div>
        ) : allFilteredMoments.length ? (
          <NoData
            title="No Moments Found"
            description="No moments matched your filters. You can try broadening your filter choices to see activity."
            showBorder={false}
          />
        ) : (
          <NoData
            title="No Moments Detected"
            description="It looks like no moments were detected in this video, or we haven't processed this video yet. Moments are detected based on the interactions that occur in the video."
          />
        )}
      </div>
    </div>
  );
};
