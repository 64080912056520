export const SkeletonLoadingMediaCard = () => (
  <div className="w-full  mx-auto">
    <div className="w-full rounded-t-lg sm:rounded-2xl bg-white py-6 max-md:py-6 px-6 max-md:px-8 flex flex-col drop-shadow-sm gap-3 sm:gap-4 cursor-pointer hover:shadow-lg transition-shadow duration-200">
      <BlankMediaCardContent />
    </div>
  </div>
);
export const BlankMediaCardContent = () => (
  <div className="w-full h-48 flex items-center justify-center bg-limestone-50 rounded-lg">
    <div className="animate-spin border-b-2 rounded-full h-7 w-7 border-text-primary" />
  </div>
);

export const FeedSkeletonLoader = () => {
  return (
    <div className="grid mobile:grid-cols-2 tablet:grid-cols-2 web:grid-cols-3 gap-4 overflow-y-auto">
      <SkeletonLoadingMediaCard />
      <SkeletonLoadingMediaCard />
      <SkeletonLoadingMediaCard />
    </div>
  );
};
