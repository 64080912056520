import { GenderApiValues } from "@utils/constants";
import { getGenderValue, setGenderValue } from "@utils/helpers";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  UpdateLearnerDetailsMutationVariables,
  useLearnerByIdQuery,
  useUpdateLearnerDetailsMutation,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

export const BasicInfo: React.FC = () => {
  const { clientId } = useParams();

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data, refetch, isFetching } = learnerDetailsQuery;

  const updateLearnerDetailsMutation = useUpdateLearnerDetailsMutation({});

  const { data: updateData } = updateLearnerDetailsMutation;

  const { formState, watch, onSubmit, RegisteredFormInput, RegisteredFormSelected, reset } =
    useFormUtils<UpdateLearnerDetailsMutationVariables>({
      mutationFn: async (params) => {
        const formattedGender = setGenderValue(params.learnerDetails.gender);

        await updateLearnerDetailsMutation.mutateAsync({
          learnerDetails: {
            ...params.learnerDetails,
            gender: formattedGender,
          },
          learner: clientId ? clientId : "",
        });
        await refetch();
      },
    });

  useEffect(() => {
    if (data && data.learnerById?.learnermetadata) {
      const learner = data.learnerById?.learnermetadata;
      const formattedGender = getGenderValue(learner.gender);

      reset({
        learnerDetails: {
          firstName: learner?.firstName,
          lastName: learner?.lastName,
          dob: learner?.birthDate,
          gender: formattedGender,
        },
      });
    }
  }, [data, reset]);

  useEffect(() => {
    if (updateData?.updateLearnerDetails) {
      if (updateData?.updateLearnerDetails.status) {
        notifySuccess(updateData.updateLearnerDetails?.message ? updateData.updateLearnerDetails?.message : "");
      } else {
        notifyError(updateData.updateLearnerDetails?.message ? updateData.updateLearnerDetails?.message : "");
      }
    }
  }, [updateData]);

  const formLoading = isFetching;
  const genderWatch = watch("learnerDetails.gender");

  return (
    <div className="flex w-full flex-col items-start">
      <FormContainer onSubmit={onSubmit}>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerDetails.firstName"
            inputSize="full"
            formState={formState}
            label="First Name"
          />
          <RegisteredFormInput
            formKey="learnerDetails.lastName"
            inputSize="full"
            formState={formState}
            label="Last Name"
          />
        </DoubleColumnContainer>

        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerDetails.dob"
            inputSize="full"
            type="date"
            formState={formState}
            label="Date of Birth"
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormSelected
            isLoading={formLoading}
            formKey="learnerDetails.gender"
            formState={formState}
            placeholderText={""}
            title="Gender"
            required={false}
            items={
              Object.values(GenderApiValues).map((o) => ({
                primary: getGenderValue(o),
              })) as SelectItem[]
            }
          />
          {genderWatch === "Gender identity is not listed" && (
            <RegisteredFormInput
              formKey="learnerDetails.specifyGender"
              formState={formState}
              required={false}
              label="Specify Gender"
            />
          )}
        </DoubleColumnContainer>

        <div className="mt-6">
          <SubmitButton isLoading={formState.isSubmitting} buttonText="Update" />
        </div>
      </FormContainer>
    </div>
  );
};
